import React , {useState , useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { ThreeDots } from  'react-loader-spinner'
import { toast } from 'react-toastify';
import {getAllAdmins , updateStatusOfAnyCuratedList ,getSingleVenderDeatils , updateIUmageOfAnyVendor , updateOfAnyVender , getAllProducts ,addNewCuratedLis , getAllCuratedLists } from '../../api/AdminApi'
import {useNavigate} from 'react-router-dom'
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
import { Col, Row, Table , Modal , Button ,Dropdown, ButtonGroup  } from 'react-bootstrap';

 
const ViewAllvendors = () => {
    const navigate = useNavigate()
    const TABLE_HEADERS = [
        {
            prop: "name",
            title: "List Name"
        },
        {
            prop: "icon",
            title: "Icon",
            cell: (prop) => {
                return (
                    <img src={prop.icon} alt="user imag" style={{maxWidth : '50px', maxHeight : '50px', borderRadius : '10px'}} />
                )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            prop: "products",
            title: "Products"
        },
        {
            prop: "status",
            title: "Status",
            cell: (prop) => {
                return (
                        <Dropdown as={ButtonGroup}>
                            {
                                prop?.status == false && (
                                    <Button size="sm" variant="danger" style={{fontSize : '11px' , fontWeight : 600}} >In Active</Button>
                                )
                            }
                            {
                                prop?.status == true && (
                                    <Button size="sm" variant="success" style={{fontSize : '11px' , fontWeight : 600}} >Active</Button>
                                )
                            }
                            <Dropdown.Toggle split size="sm" variant="primary" id="dropdown-split-basic" />
                            <Dropdown.Menu style={{backgroundColor : 'transparent'}} >
                                <Dropdown.Item onClick={() => changeStatus(prop?.Id)} style={{backgroundColor : '#c23616', color : 'white'}} >In Active</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeStatus(prop?.Id)} style={{backgroundColor : '#10ac84', color : 'white'}} >Active</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }, 
        // {
        //     prop: "action",
        //     title: "Action",
        //     cell: (prop) => {
        //     return (
        //             <AiOutlineEdit style={{cursor : 'pointer'}} onClick={() => handleView(prop.Id)} ></AiOutlineEdit>
        //         )
        //     },
        // },
    ];
    const [ isFetching , setIsFetching ] = useState(false)
    const [selectedOption, setSelectedOption] = useState([]);
    const [ sendData , setSendData ] = useState({
        listName : "",
        icon : "",
        listItems : [],
        isAddedByAdmin : true,
    })
    const [ isGetting , setIsGetting ] = useState(false)
    const [ isSending , setIsSending ] = useState(false)
    const [ singleUserData , setSingleUserData ] = useState({})
    const [ uploadImage , setUploadImage ] = useState(null)
    const [ allData , setAllData ] = useState([])
    // add new modal
    const [addNew, setAddNew] = useState(false);
    const handleAddNewClose = () => {
        setUploadImage(null)
        setAddNew(false)
        setIsSending(false)
    }
    // getting data of user
    const handleAddNew = () => {
        setAddNew(true);
    }

    // sending data
    const addNewMyList = async () => {
        setIsSending(true)
        if(sendData?.icon === ""){
            toast.warning("Image Of List is Required");
            return;
        }
        let formData = new FormData();
        formData.append("listName" , sendData?.listName)
        formData.append("curatedListImage" , sendData?.icon)
        formData.append("listItems" , JSON.stringify(selectedOption))
        formData.append("isAddedByAdmin" , sendData?.isAddedByAdmin)
        const {data} = await addNewCuratedLis(formData);
        console.log("data got : ", data)
        if(data?.success === true){
            toast.success("New List Added SuccessFully")
            await delay(1500)
            handleAddNewClose()
            //navigate('/view-all-products')
            setSendData({
                listName : "",
                icon : "",
                listItems : [],
                isAddedByAdmin : true,
            })
        }else{
            toast.error(data?.message)
        }
        setIsSending(false)
    }

    // sleeping
    const delay = ms => new Promise(res => setTimeout(res, ms));

    // viuew modal
    const [view, setView] = useState(false);
    const handleViewClose = () => {
        setUploadImage(null)
        setView(false);
        setIsSending(false)
    }
    // getting data of user
    const handleView = async (id) => {
        setView(true);
        setIsGetting(true)
        const {data} = await getSingleVenderDeatils(id);
        if(data?.success === true){
            setSingleUserData(data?.Vender);
        }else{
            toast.error(data?.message)
        }
        setIsGetting(false)
    }

    // checking if signed in or not
    useEffect(() => {
        let adminToken = JSON.parse(localStorage.getItem('priceApp-admin-auth-token'))
        if(!adminToken){
            navigate('/signin')
        }
        let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
        if(!isAdmin){
            navigate('/signin')
        }
        if(isAdmin === false){
            navigate('/our stores')
        }
    },[])

    // changing status of vender
    const changeStatus = async (id) => {
        let isFound = allData.find(item => item.Id === id);
        if(isFound){
            const {data} = await updateStatusOfAnyCuratedList(id);
            console.log("status of change : ", data)
            if(data?.success === true){
                if(isFound.status == false){
                    isFound.status = true
                    toast.success("List Activated Successfully");
                }else{
                    isFound.status = false
                    toast.success("List InActivated Successfully");
                }
                let newData = allData;
                let finalData = newData.filter(item => item.Id === id ? isFound : item );
                setAllData(finalData)
            }else{
                toast.success(data?.message);
            }
        }
    }

    // getting data
    useEffect(() => {
        const getData = async () => {
            setIsFetching(true)
            const {data} = await getAllCuratedLists();
            if(data?.success === true){
                let allDataArr = []
                for(let i = 0; i !== data?.AllCuratedLists.length; i++){
                    let ArrFF = []
                    let allSubs = data?.AllCuratedLists[i]?.Products;
                    allSubs.map((item , index) => (
                        ArrFF.push( <p style={{whiteSpace: 'pre'}}> {index + 1 + "-" + item?.productName}</p> )
                    ))
                    let newArr = {
                        subCate : ArrFF,
                        name: data?.AllCuratedLists[i]?.Name,
                        products : ArrFF,
                        Id: data?.AllCuratedLists[i]?.Id,
                        status: data?.AllCuratedLists[i]?.status,
                        icon: data?.AllCuratedLists[i]?.Icon ?  process.env.REACT_APP_LIVE_SERVER + "/curatedListsImages/" + data?.AllCuratedLists[i]?.Icon  : "https://bxecreative.com/wp-content/uploads/2016/04/dummy-post-horisontal.jpg",
                    }
                    allDataArr.push(newArr)
                    setAllData(allDataArr)
                    }
            }
            setIsFetching(false)
        }
        getData()
    },[])

    // getting all products
    const [options, setOptions] = useState([]);
    useEffect(() => {
        const getData = async () => {
            const {data} = await getAllProducts();
            if(data?.success === true){
                let allDataArr = []
                for(let i = 0; i !== data?.AllProducts[0]?.AllData.length; i++){
                    let newArr = {
                        value: data?.AllProducts[0]?.AllData[i]?.Id,
                        label: data?.AllProducts[0]?.AllData[i]?.Name,
                    }
                    allDataArr.push(newArr)
                }
                setOptions(allDataArr)
            }
        }
        getData()
    },[])

    // updating data
    const updateData = async () => {
        setIsSending(true)
        const {data} = await updateOfAnyVender(singleUserData._id , singleUserData);
        console.log("first response : ", data)
        if(data?.success === true){
            toast.success(data?.message)

            // uploading image
            if(uploadImage !== null){
                let formData = new FormData();
                formData.append("venderImage", uploadImage)
                const res = await updateIUmageOfAnyVendor(singleUserData._id, formData);
                if(res?.data?.success === true){
                    toast.success("Image Uploaded SuccessFully")
                }else{
                    toast.error("Image Could Not be Uploaded.")
                }
            }
            // getting updated data
            setIsFetching(true)
            const response = await getAllAdmins();
            if(response?.data?.success === true){
                let allDataArr = []
                for(let i = 0; i !== response?.data?.AllAdmins.length; i++){
                    let newArr = {
                        email: response?.data?.AllAdmins[i]?.email,
                        userName: response?.data?.AllAdmins[i]?.username,
                        Id: response?.data?.AllAdmins[i]?._id,
                        status: response?.data?.AllAdmins[i]?.status,
                        image: process.env.REACT_APP_LIVE_SERVER + "/vendorsImages/" + response?.data?.AllAdmins[i]?.profilePic,
                    }
                    allDataArr.push(newArr)
                }
                setAllData(allDataArr)
            }
            setIsFetching(false)

            await delay(1500)
            handleViewClose()
        }else{
            toast.error(data?.message)
        }
        setIsSending(false)
    }
    
    // adding/removing items from array
    const checkItem = (e) => {
        let newArr = []
        newArr = Array.isArray(e) ? e.map((x) => x.value) : []
        setSelectedOption(newArr)
        setSendData({...sendData , listItems : newArr})
        console.log("newArr : ",newArr)
        if(newArr?.length > 0){
            let newMyArr = selectedOption;
            let neeew = newMyArr.filter(item => item !== e[0].value);
            setSelectedOption(neeew)
        }
    }


    return (
        <>
            {
                isFetching === true ? (
                    <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                        <ThreeDots
                            height = "60"
                            width = "60"
                            radius = "9"
                            color = 'green'
                            ariaLabel = 'three-dots-loading'
                            wrapperStyle
                            wrapperClass
                        />
                    </div>
                ) : (
                    <div style={{marginLeft : '20px', marginRight : '30px'}}>
                        <DatatableWrapper
                            body={allData}
                            headers={TABLE_HEADERS}
                            paginationOptionsProps={{
                                initialState: {
                                    rowsPerPage: 10,
                                    options: [5, 10, 15, 20]
                                }
                            }}
                            >
                            <Row className="p-4 mb-4" >
                                <Col
                                    xs={12}
                                    lg={4}
                                    className="flex-col d-flex justify-content-start align-items-start"
                                >
                                    <h4>All Curated Lists</h4>
                                </Col>
                                <Col
                                    xs={12}
                                    sm={6}
                                    lg={4}
                                    className="flex-col mb-2 d-flex justify-content-lg-center align-items-center justify-content-sm-start mb-sm-0"
                                >
                                    <Button size="lg" variant="info" onClick={handleAddNew} style={{fontSize : '15px' , fontWeight : 600}} >Add New</Button>
                                </Col>
                                <Col
                                    xs={12}
                                    sm={6}
                                    lg={4}
                                    className="flex-col d-flex justify-content-end align-items-end"
                                >
                                    {/* <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder="Search here..."
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            onBlur = {() => getAllData()}
                                            onChange={(e) => setText(e.target.value)}
                                        />
                                            <Button variant="info" onClick={getSearchedMerchants}>Search</Button>
                                    </InputGroup> */}
                                </Col>
                            </Row>
                            <Table striped bordered hover className="ml-2 mr-2" >
                                <TableHeader />
                                <TableBody />
                            </Table>
                            <Row className="p-2 mb-4">
                                <Col
                                    xs={12}
                                    sm={6}
                                    lg={6}
                                    className="flex-col mb-2 d-flex justify-content-lg-start align-items-start justify-content-sm-start mb-sm-0"
                                >
                                    <PaginationOptions />
                                </Col>
                                <Col
                                    xs={12}
                                    sm={6}
                                    lg={6}
                                    className="flex-col d-flex justify-content-end align-items-end"
                                >
                                    <Pagination />
                                </Col>
                            </Row>
                        </DatatableWrapper>
                    </div>
                )
            }

            {/* view and update modal */}
            <Modal
                show={view}
                onHide={handleViewClose}
                backdrop="static"
                keyboard={false}
                //style={{marginTop : '100px'}}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Vender Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isGetting === true ? (
                            <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                <ThreeDots
                                    height = "60"
                                    width = "60"
                                    radius = "9"
                                    color = 'green'
                                    ariaLabel = 'three-dots-loading'
                                    wrapperStyle
                                    wrapperClass
                                />
                            </div>
                        ) : (
                            <div className="flex justify-center" >
                                <div className='flex justify-center flex-col align-center pb-4  max-w-[400px] w-[100%] mx-auto  pt-3' >
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                    <img src={ process.env.REACT_APP_LIVE_SERVER + "/vendorsImages/" + singleUserData?.profilePic} alt="cover pic" style={{maxWidth : '80px', maxHeight : '80px', borderRadius : '10px'}} />
                                        <input type="file" accept="image/*" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' onChange={(e) => setUploadImage(e.target.files[0])} />
                                    </div>
                                    {
                                        uploadImage !== null && (
                                            <div className="flex justify-between  mt-4">
                                                <img src={URL.createObjectURL(uploadImage)} alt="cover pic" style={{maxWidth : '80px', maxHeight : '80px', borderRadius : '10px'}} />
                                                <button className='bg-[#e74c3c] mt-3 rounded-md p-2 text-white font-semibold mx-auto max-h-[40px]' onClick={() => setUploadImage(null)} >Remove</button>
                                            </div>
                                        )
                                    }
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>User Name:</h6>
                                        <input type="text" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.username} onChange={(e) => setSingleUserData({...singleUserData , username : e.target.value})} />
                                    </div>
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>Email:</h6>
                                        <input type="email" placeholder="Enter Email Please ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.email} onChange={(e) => setSingleUserData({...singleUserData , email : e.target.value})} />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        isSending === true ? (
                            <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                <ThreeDots
                                    height = "60"
                                    width = "60"
                                    radius = "9"
                                    color = 'green'
                                    ariaLabel = 'three-dots-loading'
                                    wrapperStyle
                                    wrapperClass
                                />
                            </div>
                        ) : (
                            <>
                                <Button variant="danger" onClick={handleViewClose}>
                                    Close
                                </Button>
                                <Button variant="success" onClick={updateData} >Update Now</Button>
                            </>
                        )
                    }
                </Modal.Footer>
            </Modal>
            {/* view and update modal end */}

            {/* add new modal */}
            <Modal
                show={addNew}
                onHide={handleAddNewClose}
                backdrop="static"
                keyboard={false}
                //style={{marginTop : '100px'}}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Add New List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex justify-center" >
                        <div className='flex justify-center flex-col align-center pb-4  max-w-[400px] w-[100%] mx-auto' >
                            <div className='flex flex-col pt-0 pl-3 font-semibold' >
                                <h6>Choose Icon:</h6>
                                <input type="file" accept="image/*" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' onChange={(e) => setSendData({...sendData , icon : e.target.files[0]})} />
                            </div>
                            {
                                sendData.icon !== "" && (
                                    <div className="flex justify-between ">
                                        <img src={URL.createObjectURL(sendData.icon)} alt="cover pic" style={{maxWidth : '80px', maxHeight : '80px', borderRadius : '10px'}} />
                                        <button className='bg-[#e74c3c] mt-3 rounded-md p-2 text-white font-semibold mx-auto max-h-[40px]' onClick={() => setUploadImage(null)} >Remove</button>
                                    </div>
                                )
                            }
                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                <h6>Title:</h6>
                                <input type="text" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={sendData?.listName} onChange={(e) => setSendData({...sendData , listName : e.target.value})} />
                            </div>
                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                <h6>Select Products:</h6>
                                {/* <Select
                                    isMulti={true}
                                    onChange={checkItem}
                                    options={options}
                                /> */}
                                <select className="form-select" multiple={true} data-choices data-choices-sorting="true" onChange={(e) => setSelectedOption([...selectedOption ,  e.target.value]) } >
                                    {
                                        options?.length > 0 ? (
                                            options?.map((item) => (
                                                <option value={item?.value} >{item?.label}</option>
                                            ))
                                        ) : (
                                            <option>No Products Found</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    {
                        isSending === true ? (
                            <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                <ThreeDots
                                    height = "60"
                                    width = "60"
                                    radius = "9"
                                    color = 'green'
                                    ariaLabel = 'three-dots-loading'
                                    wrapperStyle
                                    wrapperClass
                                />
                            </div>
                        ) : (
                            <>
                                <Button variant="danger" onClick={handleAddNewClose}>
                                    Close
                                </Button>
                                <Button variant="success" onClick={addNewMyList} >Add Now</Button>
                            </>
                        )
                    }
                </Modal.Footer>
            </Modal>
            {/* add new modal end */}
        </>
    );
}

export default ViewAllvendors;
