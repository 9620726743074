import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import {
  updateSingleServeAreaStatus,
  updateStatusServeAreas,
  addNewServeArea,
  getAllServeAreas,
} from "../../api/AdminApi";
import { useNavigate } from "react-router-dom";
import {
  DatatableWrapper,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import {
  Col,
  Row,
  Table,
  Modal,
  Button,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import { AiOutlineEdit } from "react-icons/ai";
import PlacesAutocomplete from "../../components/PlacesAutocomplete";
import axios from "axios";

const ViewAllvendors = () => {
  const navigate = useNavigate();
  const TABLE_HEADERS = [
    {
      prop: "name",
      title: "Location Name",
    },
    {
      prop: "lat",
      title: "Latitude",
    },
    {
      prop: "lng",
      title: "Longitude",
    },
    {
      prop: "km",
      title: "Max Distance / km",
    },
    {
      prop: "status",
      title: "Status",
      cell: (prop) => {
        return (
          <Dropdown as={ButtonGroup} style={{ zIndex: "0" }}>
            {prop?.status == false && (
              <Button
                size="sm"
                variant="danger"
                style={{ fontSize: "11px", fontWeight: 600, zIndex: 0 }}
              >
                In Active
              </Button>
            )}
            {prop?.status == true && (
              <Button
                size="sm"
                variant="success"
                style={{ fontSize: "11px", fontWeight: 600, zIndex: 0 }}
              >
                Active
              </Button>
            )}
            <Dropdown.Toggle
              split
              size="sm"
              variant="primary"
              id="dropdown-split-basic"
              style={{ zIndex: 0 }}
            />
            <Dropdown.Menu
              style={{ backgroundColor: "transparent", zIndex: -1 }}
            >
              <Dropdown.Item onClick={() => changeStatus(prop?.Id)}>
                Declined
              </Dropdown.Item>
              <Dropdown.Item onClick={() => changeStatus(prop?.Id)}>
                Approved
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      prop: "action",
      title: "Action",
      cell: (prop) => {
        return (
          <AiOutlineEdit
            style={{ cursor: "pointer" }}
            onClick={() => handleView(prop)}
          ></AiOutlineEdit>
        );
      },
    },
  ];

  const [isFetching, setIsFetching] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [selectLocation, setSelectLocation] = useState({});
  const [allData, setAllData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  // sleeping
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  // checking if signed in or not
  useEffect(() => {
    let adminToken = JSON.parse(
      localStorage.getItem("priceApp-admin-auth-token")
    );
    if (!adminToken) {
      navigate("/signin");
    }
    let isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
    if (!isAdmin) {
      navigate("/signin");
    }
    if (isAdmin === false) {
      navigate("/our stores");
    }
  }, []);

  // changing status of vender
  const changeStatus = async (id) => {
    let isFound = allData.find((item) => item.Id === id);
    if (isFound) {
      const { data } = await updateSingleServeAreaStatus(id);
      if (data?.success === true) {
        if (isFound.status == false) {
          isFound.status = true;
          toast.success("Service Area Activated Successfully");
        } else {
          isFound.status = false;
          toast.success("Service Area InActivated Successfully");
        }
        let newData = allData;
        let finalData = newData.filter((item) =>
          item.Id === id ? isFound : item
        );
        setAllData(finalData);
      } else {
        toast.success(data?.message);
      }
    }
  };

  const responseServerArea = (data) => {
    if (data?.success === true) {
      let allDataArr = [];
      for (let i = 0; i !== data?.AllServeArea.length; i++) {
        let newArr = {
          name: data?.AllServeArea[i]?.name,
          lng: data?.AllServeArea[i]?.lng,
          isActive:
            data?.AllServeArea[i]?.isActive == true ? "Active" : "In-Active",
          lat: data?.AllServeArea[i]?.lat,
          km: data?.AllServeArea[i]?.km,
          Id: data?.AllServeArea[i]?._id,
          status: data?.AllServeArea[i]?.isActive,
        };
        allDataArr.push(newArr);
        setAllData(allDataArr);
      }
    }
  };

  // getting data
  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      const { data } = await getAllServeAreas();
      responseServerArea(data);
      setIsFetching(false);
    };
    getData();
  }, []);

  // getting data of user
  const handleView = async (userData) => {
    setShowModal(true);
    const dataServeArea = allData.find((value) => value?.Id === userData?.Id);
    setSelectLocation(dataServeArea);
  };

  // add new modal
  const handleAddNewClose = () => {
    setSelectLocation({});
    setShowModal(false);
    setIsSending(false);
    setIsFetching(false);
  };
  // getting data of user
  const handleAddNew = async (id) => {
    setShowModal(true);
  };

  // sending data
  const selectModalDone = async () => {
    setIsSending(true);
    try {
      let data;
      if (selectLocation?.Id) {
        const { name, lng, lat, isActive, km, Id } = selectLocation;
        const responseData = await updateStatusServeAreas(selectLocation.Id, {
          name,
          isActive: isActive === "Active" ? true : false,
          km,
          lat,
          lng,
          _id: Id,
        });
        data = responseData?.data;
      } else {
        const responseData = await addNewServeArea(selectLocation);
        data = responseData?.data;
      }
      if (data?.success === true) {
        toast.success("New Serve Area Added SuccessFully");
        setIsFetching(true);
        const res = await getAllServeAreas();
        responseServerArea(res.data);
        setIsFetching(false);
      } else {
        toast.error(data?.message);
      }
      handleAddNewClose();
    } finally {
    }
  };

  const onPlaceSelectedMap = async (location) => {
    const { data } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${location?.value?.place_id}&key=${process.env.REACT_APP_API_KEY_MAPS}`
    );
    const { lng, lat } = data?.results?.[0]?.geometry?.location;
    setSelectLocation({
      ...selectLocation,
      name: location?.label,
      lng: lng,
      lat: lat,
    });
  };

  return (
    <>
      {isFetching === true ? (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "auto" }}
        >
          <ThreeDots
            height="60"
            width="60"
            radius="9"
            color="green"
            ariaLabel="three-dots-loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      ) : (
        <DatatableWrapper
          body={allData}
          headers={TABLE_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 10,
              options: [5, 10, 15, 20],
            },
          }}
          style={{ zIndex: 0 }}
        >
          <Row className="p-4 mb-4">
            <Col
              xs={9}
              className="flex-col d-flex justify-content-start align-items-start"
            >
              <h4>All Service Areas</h4>
            </Col>
            <Col className="flex-col mb-2 d-flex justify-content-lg-center align-items-end justify-content-sm-start mb-sm-0">
              <Button
                size="lg"
                variant="info"
                onClick={handleAddNew}
                style={{ fontSize: "15px", fontWeight: 600 }}
              >
                Add New
              </Button>
            </Col>
          </Row>
          <Table striped bordered hover className="ml-2 mr-2">
            <TableHeader />
            <TableBody />
          </Table>
          <Row className="p-2 mb-4">
            <Col
              xs={12}
              sm={6}
              lg={6}
              className="flex-col mb-2 d-flex justify-content-lg-start align-items-start justify-content-sm-start mb-sm-0"
            >
              <PaginationOptions />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={6}
              className="flex-col d-flex justify-content-end align-items-end"
            >
              <Pagination />
            </Col>
          </Row>
        </DatatableWrapper>
      )}

      {/* add new modal */}
      <Modal
        show={showModal}
        onHide={handleAddNewClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectLocation?.Id ? "Update" : "Add New"} Service Area
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-center">
            <div className="flex justify-start flex-col align-center pb-4 w-[100%] mx-auto pt-1">
              <Row>
                <Col sm={12} md={12} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Location Name:</h6>
                    <PlacesAutocomplete
                      onPlaceSelected={onPlaceSelectedMap}
                      value={selectLocation.name}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Latitude:</h6>
                    <input
                      type="text"
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      style={{ border: "1px solid black" }}
                      value={selectLocation?.lat}
                      disabled
                    />
                  </div>
                </Col>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Longitude:</h6>
                    <input
                      type="text"
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      style={{ border: "1px solid black" }}
                      value={selectLocation?.lng}
                      disabled
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Radius/KM:</h6>
                    <input
                      type="number"
                      name="km"
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      style={{ border: "1px solid black" }}
                      value={selectLocation?.km}
                      onChange={(e) =>
                        setSelectLocation({
                          ...selectLocation,
                          km: e.target.value,
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isSending === true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <ThreeDots
                height="60"
                width="60"
                radius="9"
                color="green"
                ariaLabel="three-dots-loading"
                wrapperStyle
                wrapperClass
              />
            </div>
          ) : (
            <>
              <Button variant="danger" onClick={handleAddNewClose}>
                Close
              </Button>
              <Button variant="success" onClick={selectModalDone}>
                {selectLocation?.Id ? "Update Now" : "Add Now"}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewAllvendors;
