import React , {useState , useEffect} from 'react'
import {AreaChart} from 'amazing-react-charts'
import {getDashboardOrders ,getAlLProductsCount , getAlLStoresCount , getAlLOffersCount} from "../api/AdminApi";
import {useNavigate} from "react-router-dom"
import {getAdminGraphData} from "../api/AdminApi"
import moment from "moment"
// importing chart
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top"
    },
    title: {
      display: true,
      text: "Monthly Sales"
    }
  }
};


const HomePage = () => {
  const [ labels , setLables ] = useState([])
  const [ graphData , setGraphData ] = useState([])
  const data = {
      labels,
      datasets: [
        {
          label: "Monthly Sales",
          data: graphData,
          backgroundColor: "rgba(255, 99, 132, 0.5)"
        }
      ]
  };
  const navigate = useNavigate()
  const [ allData , setAllData ] = useState(null);
  const [ allProdsCount , setAllProdsCount ] = useState(0);
  const [ allStoresCount , setAllStoresCount ] = useState(0);
  const [ allOffersCount , setAllOffersCount ] = useState(0);
  const [ lowStockProducts , setAllLowStockProducts] = useState([]);

  // getting data
  useEffect(() => {
    // getting orders related data
      const getData = async () => {
        const {data} = await getDashboardOrders();
        if(data?.success === true){
          let newObj = {
            totalOrders : data?.TotalOrdersCount,
            newOrders : data?.TodayOrdersCount,
            completedOrders : data?.CompletedOrdersCount,
            cancelledOrders : data?.CancelledOrdersCount,
            recentOrders : data?.RecentOrders,
          }
          setAllData(newObj)
        }else{
          setAllData(null)
        }
      }

      // getting all stores count
      const getProductsCount = async () => {
        const {data} = await getAlLProductsCount();
        if(data?.success === true){
          setAllLowStockProducts(data?.LessStockProducts)
          setAllProdsCount(data?.AllProductCount)
        }else{
          setAllProdsCount(0)
        }
      }

      // getting all products count
      const getStoresCount = async () => {
        const {data} = await getAlLStoresCount();
        if(data?.success === true){
          setAllStoresCount(data?.AllStoreCount)
        }else{
          setAllStoresCount(0)
        }
      }

      // getting all offers count
      const getOfferCount = async () => {
        const {data} = await getAlLOffersCount();
        if(data?.success === true){
          setAllOffersCount(data?.AllOffers)
        }else{
          setAllOffersCount(0)
        }
      }

      // getting graph data
      const getGraphData = async () => {
        const {data} = await getAdminGraphData();
        if(data?.success === true){
          let newLabeles = [] , newData = [] , newMyArray = data?.Data.reverse()
            for(let i = 0; i !== newMyArray?.length; i++){
              newLabeles.push(newMyArray[i]?.date)
              newData.push(newMyArray[i]?.amount)
            }
            setLables(newLabeles)
            setGraphData(newData)
        }
      }

      getData()
      getProductsCount()
      getStoresCount()
      getOfferCount()
      getGraphData()
  },[])

  return (
    <>
        <div className="row" style={{padding : "15px", zIndex : 0}} >
          <h3 style={{marginBottom : "15px"}} >Admin Dashboard</h3>
          <div className="col-12 col-lg-3 col-md-6 mb-2 z-0" onClick={() => navigate(`/orders`)} >
            <div className="container-card"  >
              <div className="content">
                <p className="text-center">Total Orders</p>
                <h1 className="text-center">{allData?.totalOrders}</h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-md-6 mb-2 z-0" onClick={() => navigate("/orders")}>
            <div className="container-card">
              <div className="content">
                <p className="text-center">New Orders</p>
                <h1 className="text-center">{allData?.newOrders}</h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-md-6 mb-2 z-0" onClick={() => navigate("/orders")}>
            <div className="container-card" >
              <div className="content">
                <p className="text-center" >Completed Orders</p>
                <h1 className="text-center">{allData?.completedOrders}</h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-md-6 mb-2 z-0" onClick={() => navigate("/orders")}>
            <div className="container-card">
              <div className="content">
                <p className="text-center">Cancelled Orders</p>
                <h1 className="text-center">{allData?.cancelledOrders}</h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-md-6 mb-2 z-0" onClick={() => navigate("/our stores")}>
            <div className="container-card">
              <div className="content">
                <p className="text-center">Total Stores</p>
                <h1 className="text-center"> {allStoresCount}</h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-md-6 mb-2 z-0" onClick={() => navigate("/all products")}>
            <div className="container-card">
              <div className="content">
                <p className="text-center">Total Products</p>
                <h1 className="text-center">{allProdsCount}</h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-md-6 mb-2 z-0" onClick={() => navigate("/manage offers")}>
            <div className="container-card">
              <div className="content">
                <p className="text-center">Total Offers</p>
                <h1 className="text-center">{allOffersCount}</h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-md-6 mb-2 z-0" >
            <div className="card border-0 p-3">
              <div className="content">
                <h4 className="text-start mb-3 border-bottom pb-2">
                  Recent Orders
                </h4>
                <div className="d-flex justify-content-between border-bottom my-2 pb-1"  >
                        <div className="min-w-[20%]">
                          <h6>Customer</h6>
                        </div>
                        <div className="min-w-[20%]">
                          <h6>Posted On</h6>
                        </div>
                        <div className="min-w-[20%]">
                          <h6>Store</h6>
                        </div>
                        <div className="min-w-[20%]">
                          <h6>Address</h6>
                        </div>
                        <div className="min-w-[20%]">
                          <h6>Status</h6>
                        </div>
                </div>
                {
                  allData?.recentOrders?.length > 0 ? (
                    allData?.recentOrders[0]?.AllData?.map((item) => (
                        <div className="d-flex justify-content-between border-bottom my-2 pb-1" onClick={() => navigate(`/order-details/${item?.Id}`)}  >
                          <div className="min-w-[20%]">
                            {/* <img
                              src={"http://41.76.110.60:8081" + "/usersImages/" + item?.customerPic}
                              className="cust-img rounded-circle me-2"
                              alt=""
                            /> */}
                            <span>{item?.customerName}</span>
                          </div>
                          <div className="min-w-[20%]">
                            <small>{moment(item?.createdAt).format("DD/MMM/YY")}</small>
                          </div>
                          <div className="min-w-[20%]">
                            <small>{item?.store}</small>
                          </div>
                          <div className="min-w-[20%]">
                            <small>{item?.orderAddress}</small>
                          </div>
                          <div className="min-w-[20%]">
                            <small>{item?.status}</small>
                          </div>
                        </div>
                    ))
                  ) : (
                    <h4>No Recent Orders Found</h4>
                  )
                }
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-md-6 mb-2 z-0">
            <div className="card border-0 p-3">
              <div className="content">
                {/* <div style={{display : ""}} >
                  
                </div> */}
                <h4 className="text-start mb-3 border-bottom pb-2">
                  Sales
                </h4>
                    <Bar options={options} data={data} />
              </div>
            </div>
            <hr />
            <div className="col-12 col-lg-12 mt-2">
            <div className="card border-0 p-3">
              <div className="content" >
                <h4 className="text-start mb-3 border-bottom pb-2" >
                  Low Inventory Products
                </h4>
                <div className="d-flex justify-content-between border-bottom my-2 pb-1">
                    <div className="min-w-[25%]">
                      <h4>Product</h4>
                    </div>
                    <div className="min-w-[25%]">
                      <h6>Store</h6>
                    </div>
                    <div className="min-w-[25%]">
                      <h6>Price</h6>
                    </div>
                    <div className="min-w-[25%]">
                      <h6>Quantity</h6>
                    </div>
                  </div>
                {
                  lowStockProducts?.length > 0 ? (
                    lowStockProducts[0]?.AllData?.map((item) => (
                        <div className="d-flex justify-content-between border-bottom my-2 pb-1" onClick={() => navigate(`/product-details/${item?.Id}`)}>
                          <div className="min-w-[25%]">
                            <img
                              src={"http://41.76.110.60:8081" + "/productsImages/" + item?.customerPic}
                              className="cust-img rounded-circle me-2"
                              alt=""
                            />
                            <span>{item?.Name?.length > 20 ? item?.Name?.substring(0,20)+'...':item?.Name}</span>
                          </div>
                          <div className="min-w-[25%]">
                            <small>{item?.Store}</small>
                          </div>
                          <div className="min-w-[25%]">
                            <small>{item?.Price}</small>
                          </div>
                          <div className="min-w-[25%]">
                            <small>{item?.quantity}</small>
                          </div>
                        </div>
                    ))
                  ) : (
                    <h4>No Low Inventory Products Found</h4>
                  )
                }
              </div>
            </div>
            </div>
          </div>
        </div>

    </>
  )
}

export default HomePage;