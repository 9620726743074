import React , {useState , useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { ThreeDots } from  'react-loader-spinner'
import { toast } from 'react-toastify';
import {getAllCoupons , updateStatusOfAnyCoupon , getMySingleCouponDeatils , updateOfAnyCoupon , addNewCoupon} from '../../api/AdminApi'
import {useNavigate} from 'react-router-dom'
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
import { Col, Row, Table , Modal , Button , Dropdown, ButtonGroup  } from 'react-bootstrap';
import { AiOutlineEdit} from 'react-icons/ai'
import moment from 'moment'


const ViewAllvendors = () => {
    const navigate = useNavigate()
    const TABLE_HEADERS = [
        {
            prop: "code",
            title: "Code"
        },
        {
            prop: "disPercent",
            title: "Dis. %age"
        },
        {
            prop: "isActive",
            title: "Status" 
        },
        {
            prop: "minAmountForCoupon",
            title: "Min. Amt. To Apply"
        },
        
        {
            prop: "endin",
            title: "Expires In"
        },
        {
            prop: "desc",
            title: "Description",
            
        },
        {
            prop: "status",
            title: "Status",
            cell: (prop) => {
                return (
                        <Dropdown as={ButtonGroup} style={{zIndex : "0"}} >
                            {
                                prop?.status == false && (
                                    <Button size="sm" variant="danger" style={{fontSize : '11px' , fontWeight :600, zIndex : 0}} >In Active</Button>
                                )
                            }
                            {
                                prop?.status == true && (
                                    <Button size="sm" variant="success" style={{fontSize : '11px' , fontWeight : 600 , zIndex : 0 }} >Active</Button>
                                )
                            }
                            <Dropdown.Toggle split size="sm" variant="primary" id="dropdown-split-basic" style={{zIndex : 0}} />
                            <Dropdown.Menu style={{backgroundColor : 'transparent', zIndex : -1}} >
                                <Dropdown.Item onClick={() => changeStatus(prop?.Id)}  >Declined</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeStatus(prop?.Id)}  >Approved</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            prop: "action",
            title: "Action",
            cell: (prop) => {
            return (
                    <AiOutlineEdit style={{cursor : 'pointer'}} onClick={() => handleView(prop.Id)} ></AiOutlineEdit>
                )
            },
        },
    ];

    const [ isFetching , setIsFetching ] = useState(false)
    const [ isGetting , setIsGetting ] = useState(false)
    const [ isSending , setIsSending ] = useState(false)
    const [ singleUserData , setSingleUserData ] = useState({})
    const [ uploadImage , setUploadImage ] = useState(null)
    const [ allData , setAllData ] = useState([])
    // sleeping
    const delay = ms => new Promise(res => setTimeout(res, ms));

    // checking if signed in or not
    useEffect(() => {
        let adminToken = JSON.parse(localStorage.getItem('priceApp-admin-auth-token'))
        if(!adminToken){
            navigate('/signin')
        }
        let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
        if(!isAdmin){
            navigate('/signin')
        }
        if(isAdmin === false){
            navigate('/our stores')
        }
    },[])

    // changing status of vender
    const changeStatus = async (id) => {
        let isFound = allData.find(item => item.Id === id);
        if(isFound){
            const {data} = await updateStatusOfAnyCoupon(id);
            if(data?.success === true){
                if(isFound.status == false){
                    isFound.status = true
                    toast.success("Coupon Activated Successfully");
                }else{
                    isFound.status = false
                    toast.success("Coupon InActivated Successfully");
                }
                let newData = allData;
                let finalData = newData.filter(item => item.Id === id ? isFound : item );
                setAllData(finalData)
            }else{
                toast.success(data?.message);
            }
        }
    }

    // getting data
    useEffect(() => {
        const getData = async () => {
            setIsFetching(true)
            const {data} = await getAllCoupons();
            if(data?.success === true){
                let allDataArr = []
                for(let i = 0; i !== data?.AllCoupons.length; i++){
                    let newArr = {
                        code: data?.AllCoupons[i]?.code,
                        disPercent: data?.AllCoupons[i]?.disPercent + " " + "%",
                        isActive: data?.AllCoupons[i]?.isActive == true ? "Active" : "In-Active",
                        minAmountForCoupon: data?.AllCoupons[i]?.minAmountForCoupon,
                        desc: data?.AllCoupons[i]?.desc,
                        endin: moment(data?.AllCoupons[i]?.expiryDate).format('MM Do YY, h:mm:ss a'),
                        Id: data?.AllCoupons[i]?._id,
                        status: data?.AllCoupons[i]?.isActive,
                    }
                    allDataArr.push(newArr)
                }
                console.log("allDataArr : ",allDataArr)
                setAllData(allDataArr)
            }
            setIsFetching(false)
        }
        getData()
    },[])

    // viuew modal
    const [view, setView] = useState(false);
    const handleViewClose = () => {
        setUploadImage(null)
        setView(false);
        setIsSending(false)
    }
    // getting data of user
    const handleView = async (id) => {
        setView(true);
        setIsGetting(true)
        const {data} = await getMySingleCouponDeatils(id);
        if(data?.success === true){
            setSingleUserData(data?.Vender);
        }else{
            toast.error(data?.message)
        }
        setIsGetting(false)
    }

    // updating data
    const updateData = async () => {
        setIsSending(true)
        const {data} = await updateOfAnyCoupon(singleUserData._id , singleUserData);
        if(data?.success === true){
            toast.success(data?.message)
            // getting updated data
            setIsFetching(true)
            const response = await getAllCoupons();
            if(response?.data?.success === true){
                let allDataArr = []
                for(let i = 0; i !== response?.data?.AllCoupons.length; i++){
                    let newArr = {
                        code: response?.data?.AllCoupons[i]?.code,
                        disPercent: response?.data?.AllCoupons[i]?.disPercent + " " + "%",
                        isActive: response?.data?.AllCoupons[i]?.isActive == true ? "Active" : "In-Active",
                        minAmountForCoupon: response?.data?.AllCoupons[i]?.minAmountForCoupon,
                        desc: response?.data?.AllCoupons[i]?.desc,
                        endin: moment(response?.data?.AllCoupons[i]?.expiryDate).format('MM Do YY, h:mm:ss a'),
                        Id: response?.data?.AllCoupons[i]?._id,
                        status: response?.data?.AllCoupons[i]?.isActive,
                    }
                    allDataArr.push(newArr)
                }
                console.log("allDataArr : ",allDataArr)
                setAllData(allDataArr)
            }
            setIsFetching(false)

            await delay(1500)
            handleViewClose()
        }else{
            toast.error(data?.message)
        }
        setIsSending(false)
    }

    const [userData , setUserData ] = useState({
        disPercent : "",
        expiryDate : "",
        minAmountForCoupon : "",
        startDate : "",
        desc : ""
    })

    // add new modal
    const [addNew, setAddNew] = useState(false);
    const handleAddNewClose = () => {
        setUploadImage(null)
        setUserData({
            disPercent : "",
            expiryDate : "",
            minAmountForCoupon : "",
            startDate : "",
            desc : ""
        })
        setAddNew(false);
        setIsSending(false)
    }
    // getting data of user
    const handleAddNew = async (id) => {
        setAddNew(true);
    }

    // sending data
    const addNewVender = async () => {
        setIsSending(true)
        const {data} = await addNewCoupon(userData);
        if(data?.success === true){
            toast.success("New Coupon Added SuccessFully")

            setIsFetching(true)
            const res = await getAllCoupons();
            if(res?.data?.success === true){
                let allDataArr = []
                for(let i = 0; i !== res?.data?.AllCoupons.length; i++){
                    let newArr = {
                        code: res?.data?.AllCoupons[i]?.code,
                        disPercent: res?.data?.AllCoupons[i]?.disPercent + " " + "%",
                        isActive: res?.data?.AllCoupons[i]?.isActive == true ? "Active" : "In-Active",
                        minAmountForCoupon: res?.data?.AllCoupons[i]?.minAmountForCoupon,
                        desc: res?.data?.AllCoupons[i]?.desc,
                        endin: moment(res?.data?.AllCoupons[i]?.expiryDate).format('MM Do YY, h:mm:ss a'),
                        Id: res?.data?.AllCoupons[i]?._id,
                        status: res?.data?.AllCoupons[i]?.isActive,
                    }
                    allDataArr.push(newArr)
                }
                console.log("allDataArr : ",allDataArr)
                setAllData(allDataArr)
            }
            setIsFetching(false)

            await delay(1000)
            handleAddNewClose()
        }else{
            toast.error(data?.message)
        }
        setIsFetching(false)
    }

    return (
        <>
            {
                isFetching === true ? (
                    <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                        <ThreeDots
                            height = "60"
                            width = "60"
                            radius = "9"
                            color = 'green'
                            ariaLabel = 'three-dots-loading'
                            wrapperStyle
                            wrapperClass
                        />
                    </div>
                ) : (
                    <DatatableWrapper
                        body={allData}
                        headers={TABLE_HEADERS}
                        paginationOptionsProps={{
                            initialState: {
                                rowsPerPage: 10,
                                options: [5, 10, 15, 20]
                            }
                        }}
                        style={{zIndex : 0}}
                        >
                        <Row className="p-4 mb-4">
                            <Col
                                xs={12}
                                lg={4}
                                className="flex-col d-flex justify-content-start align-items-start"
                            >
                                <h4>All Coupons</h4>
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="flex-col mb-2 d-flex justify-content-lg-center align-items-center justify-content-sm-start mb-sm-0"
                            >
                                <Button size="lg" variant="info" onClick={handleAddNew} style={{fontSize : '15px' , fontWeight : 600}} >Add New</Button>
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="flex-col d-flex justify-content-end align-items-end"
                            >
                                {/* <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Search here..."
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        onBlur = {() => getAllData()}
                                        onChange={(e) => setText(e.target.value)}
                                    />
                                        <Button variant="info" onClick={getSearchedMerchants}>Search</Button>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Table striped bordered hover className="ml-2 mr-2" >
                            <TableHeader />
                            <TableBody />
                        </Table>
                        <Row className="p-2 mb-4">
                            <Col
                                xs={12}
                                sm={6}
                                lg={6}
                                className="flex-col mb-2 d-flex justify-content-lg-start align-items-start justify-content-sm-start mb-sm-0"
                            >
                                <PaginationOptions />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={6}
                                className="flex-col d-flex justify-content-end align-items-end"
                            >
                                <Pagination />
                            </Col>
                        </Row>
                    </DatatableWrapper>
                )
            }

            {/* view and update modal */}
            <Modal
                show={view}
                onHide={handleViewClose}
                backdrop="static"
                keyboard={false}
                //style={{marginTop : '100px'}}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Coupon Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isGetting === true ? (
                            <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                <ThreeDots
                                    height = "60"
                                    width = "60"
                                    radius = "9"
                                    color = 'green'
                                    ariaLabel = 'three-dots-loading'
                                    wrapperStyle
                                    wrapperClass
                                />
                            </div>
                        ) : (
                            <div className="flex justify-center" >
                                <div className='flex justify-start flex-col align-center pb-4 w-[100%] mx-auto  pt-3' >
                                    <Row >
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Code:</h6>
                                                <input type="text" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.code}  disabled={true} />
                                            </div>                                                                      
                                        </Col>
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Discount Percentage:</h6>
                                                <input type="number" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.disPercent} onChange={(e) => setSingleUserData({...singleUserData , disPercent : e.target.value})} />
                                            </div>                                                                      
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Min. Amount to Apply:</h6>
                                                <input type="number" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.minAmountForCoupon} onChange={(e) => setSingleUserData({...singleUserData , minAmountForCoupon : e.target.value})} />
                                            </div>                                                                      
                                        </Col>
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Description:</h6>
                                                <textArea type="text" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.desc} onChange={(e) => setSingleUserData({...singleUserData , desc : e.target.value})} />
                                            </div>                                                                      
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Start Date</h6>
                                                <input type="date" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={moment(singleUserData?.startDate).format('YYYY-MM-DD')} onChange={(e) => setSingleUserData({...singleUserData , startDate : e.target.value})} />
                                            </div>                                                                      
                                        </Col>
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Expiry Date</h6>
                                                <input type="date" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={moment(singleUserData?.expiryDate).format('YYYY-MM-DD')} onChange={(e) => setSingleUserData({...singleUserData , expiryDate : e.target.value})} />
                                            </div>                                                                      
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        isSending === true ? (
                            <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                <ThreeDots
                                    height = "60"
                                    width = "60"
                                    radius = "9"
                                    color = 'green'
                                    ariaLabel = 'three-dots-loading'
                                    wrapperStyle
                                    wrapperClass
                                />
                            </div>
                        ) : (
                            <>
                                <Button variant="danger" onClick={handleViewClose}>
                                    Close
                                </Button>
                                <Button variant="success" onClick={updateData} >Update Now</Button>
                            </>
                        )
                    }
                </Modal.Footer>
            </Modal>
            {/* view and update modal end */}

            {/* add new modal */}
            <Modal
                show={addNew}
                onHide={handleAddNewClose}
                backdrop="static"
                keyboard={false}
                //style={{marginTop : '100px'}}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add New Coupon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex justify-center" >
                        <div className='flex justify-start flex-col align-center pb-4 w-[100%] mx-auto pt-3' >
                            <h6 className='text-2xl font-bold text-center' >Add New Coupon</h6>
                            <Row >
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>Discount Percentage Amount:</h6>
                                        <input type="number"  placeholder="Enter Discount Percentage ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: '1px solid black'}} value={userData?.disPercent} onChange={(e) => setUserData({...userData , disPercent : e.target.value})} />
                                    </div>                                                                      
                                </Col>
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>Start Date:</h6>
                                        <input type="date" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: '1px solid black'}} value={userData?.startDate} onChange={(e) => setUserData({...userData , startDate : e.target.value})} />
                                    </div>                                                                      
                                </Col>
                            </Row>
                            <Row >
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>End Date:</h6>
                                        <input type="date" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: '1px solid black'}} value={userData?.expiryDate} onChange={(e) => setUserData({...userData , expiryDate : e.target.value})}  />
                                    </div>                                                                      
                                </Col>
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>Min Amount to Apply Coupon:</h6>
                                        <input type="number" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: '1px solid black'}} value={userData?.minAmountForCoupon} onChange={(e) => setUserData({...userData , minAmountForCoupon : e.target.value})}  />
                                    </div>                                                                      
                                </Col>
                            </Row>
                            <Row >
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>Description (any):</h6>
                                        <textArea type="text" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: '1px solid black'}} value={userData?.desc} onChange={(e) => setUserData({...userData , desc : e.target.value})}  />
                                    </div>                                                                      
                                </Col>
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                                                                                                
                                </Col>
                            </Row>
                            {/* <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                <h6>Choose Product:</h6> 
                                <select class="form-select" aria-label="Default select example" onChange={(e) => setUserData({...userData , subCategories : e.target.value})} >
                                    <option selected disabled></option>
                                    {
                                        allSubParent?.length > 0 ? (
                                            allSubParent?.map((item) => (
                                                <option value={item?._id}>{item?.name}</option>
                                            ))
                                        ) : (
                                            <option >No Products Found</option>
                                        )
                                    }
                                </select>
                            </div> */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        isSending === true ? (
                            <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                <ThreeDots
                                    height = "60"
                                    width = "60"
                                    radius = "9"
                                    color = 'green'
                                    ariaLabel = 'three-dots-loading'
                                    wrapperStyle
                                    wrapperClass
                                />
                            </div>
                        ) : (
                            <>
                                <Button variant="danger" onClick={handleAddNewClose}>
                                    Close
                                </Button>
                                <Button variant="success" onClick={addNewVender} >Add Now</Button>
                            </>
                        )
                    }
                </Modal.Footer>
            </Modal>
            {/* add new modal end */}
        </>
    );
}

export default ViewAllvendors;
