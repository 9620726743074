import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getAllOrders,
  getAllOrdersOfVender,
  getOrdersWithDateRange,
  getOrdersWithDateRangeForAdmin,
  updateStatusOfOrderByVender,
  updateStatusOfOrderByAdmin,
  acceptAnyOrderByVender,
  acceptAnyOrderByAdmin,
  cancelAnyOrderByAdmin,
  cancelAnyOrderByVender,
  getAllVehicleType,
  updateSingleVehicleTypeByAdmin,
  updateSingleVehicleTypeByVender,
  changeStatusOfOrderApprove,
} from "../../api/AdminApi";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import {
  Col,
  Row,
  Table,
  Form,
  Modal,
  Button,
  Dropdown,
  ButtonGroup,
  InputGroup,
  Badge,
} from "react-bootstrap";
import { GrFormView } from "react-icons/gr";
import { CSVLink } from "react-csv";
import moment from "moment";
import DropdownButton from "react-bootstrap/DropdownButton";

const ViewAllvendors = ({ socket }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const TABLE_HEADERS = [
    {
      prop: "orderNo",
      title: "Order No.",
    },
    {
      prop: "postedOn",
      title: "Received On",
    },
    {
      prop: "customerId",
      title: "Customer",
      cell: (prop) => {
        return (
          <div style={{ display: "flex", minWidth: "100px" }}>
            <h6 style={{ marginRight: "5px", marginTop: "5px" }}>
              {prop.customerName}
            </h6>
            {/* <img src={ prop.customerPic} alt="customer imag" style={{maxWidth : '30px', maxHeight : '30px', borderRadius : '10px'}} /> */}
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      prop: "myStore",
      title: "Store",
    },
    {
      prop: "totalAmt",
      title: "total Amount",
    },
    {
      prop: "orderAddress",
      title: "Address",
    },
    {
      prop: "deliveryFee",
      title: "Delivery Fee",
    },
    {
      prop: "isPaymentDone",
      title: "Payment Status",
    },
    {
      prop: "orderStatus",
      title: "Status",
      cell: (prop) => {
        return (
          <>
            {prop?.orderStatus === "Order Expired" ? (
              <Button
                size="sm"
                style={{
                  backgroundColor: "#f3f1f1",
                  border: "none",
                  fontSize: "10px",
                  color: "black",
                  fontWeight: 600,
                }}
              >
                {prop?.orderStatus}
              </Button>
            ) : null}

{prop?.orderStatus === "Awaiting Approval" ? (
              <Button
                size="sm"
                style={{
                  backgroundColor: "#fcf495",
                  border: "none",
                  fontSize: "12px",
                  color: "black",
                  fontWeight: 600,
                }}
              >
                {prop?.orderStatus}
              </Button>
            ) : null}

            {prop?.orderStatus === "Pending" ? (
              <Button
                size="sm"
                style={{
                  backgroundColor: "#fdf3fa",
                  border: "none",
                  fontSize: "12px",
                  color: "black",
                  fontWeight: 600,
                }}
              >
                {prop?.orderStatus}
              </Button>
            ) : null}

            {prop?.orderStatus === "Delivered" ? (
              <Button
                size="sm"
                style={{
                  backgroundColor: "#f3fdf3",
                  border: "none",
                  fontSize: "12px",
                  color: "black",
                  fontWeight: 600,
                }}
              >
                {prop?.orderStatus}
              </Button>
            ) : null}

            {prop?.orderStatus === "Order Ready" ? (
              <Button
                size="sm"
                style={{
                  backgroundColor: "#fdf9f3",
                  border: "none",
                  fontSize: "12px",
                  color: "black",
                  fontWeight: 600,
                }}
              >
                {prop?.orderStatus}
              </Button>
            ) : null}

            {prop?.orderStatus === "Picked Up" ? (
              <Button
                size="sm"
                style={{
                  backgroundColor: "#f3fbfd  ",
                  border: "none",
                  fontSize: "12px",
                  color: "black",
                  fontWeight: 600,
                }}
              >
                {prop?.orderStatus}
              </Button>
            ) : null}

            {prop?.orderStatus === "Accepted" ? (
              <Button
                size="sm"
                style={{
                  backgroundColor: "lightBlue",
                  border: "none",
                  fontSize: "12px",
                  color: "black",
                  fontWeight: 600,
                }}
              >
                {prop?.orderStatus}
              </Button>
            ) : null}

            {/* {
                            prop?.orderStatus === "Cancelled" || prop?.orderStatus == "Cancelled By Customer" ? (
                                <Button size="sm" style={{backgroundColor : "crimson" ,border : "none" , fontSize : "12px" , color : "white" , fontWeight : 600}} >
                                        {prop?.orderStatus}
                                </Button>
                            ) : (
                                null
                            )
                        } */}

            {prop.isCancelledByRider == true ||
            prop.isCancelledByCustomer == true ||
            prop.isCancelledByPartner == true ||
            prop.isCancelledByAdmin == true ||
            prop?.orderStatus === "Cancelled" ||
            prop?.orderStatus == "Cancelled By Customer" ? (
              <Button
                size="sm"
                style={{
                  backgroundColor: "crimson",
                  border: "none",
                  fontSize: "10px",
                  color: "white",
                  fontWeight: 600,
                }}
              >
                {prop?.orderStatus}
              </Button>
            ) : null}
          </>
        );
      },
    },
    {
      prop: "lastUpdated",
      title: "Last Updated",
    },
    {
      prop: "action",
      title: "View",
      cell: (prop) => {
        return (
          <GrFormView
            style={{ cursor: "pointer", fontSize: "25px" }}
            onClick={() => {
              setAllProds(prop.myProducts);
              handleView(prop?.Id);
            }}
          ></GrFormView>
        );
      },
    },
    {
      prop: "vehicle",
      title: "Vehicle",
      cell: (prop) => {
        return prop?.orderStatus == "Accepted" ? (
          <DropdownButton id="dropdown-basic-button" title={prop?.vehicleName}>
            {allVehicleTypes?.map((item) => (
              <Dropdown.Item
                href=""
                style={{ backgroundColor: "#1abc9c", color: "white" }}
                onClick={() => changeVehicleTypeOfOrder(prop.Id, item?._id)}
              >
                {item?.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        ) : (
          <p>{prop?.vehicleName}</p>
        );
      },
    },
    {
      prop: "status",
      title: "Change Status",
      cell: (prop) => {
        return prop?.orderStatus == "Pending" ||
          prop?.orderStatus == "Accepted" ||prop?.orderStatus == "Awaiting Approval"? (
          <DropdownButton id="dropdown-basic-button" title={prop?.orderStatus}>
           {prop?.orderStatus == "Awaiting Approval"?  <Dropdown.Item
              href=""
              style={{ backgroundColor: "#27ae60", color: "white" }}
              onClick={() => changeOrderStatus(prop.Id)}
            >
              Approve Order 
            </Dropdown.Item>:null}
         {prop?.orderStatus == "Pending"?    <Dropdown.Item
              href=""
              style={{ backgroundColor: "#27ae60", color: "white" }}
              onClick={() => acceptThisOrder(prop.Id)}
            >
              Accept Order
            </Dropdown.Item>:null}
          {prop?.orderStatus == "Accepted" ?  <Dropdown.Item
              href=""
              style={{ backgroundColor: "#1abc9c", color: "white" }}
              onClick={() => changeOrderStatus(prop.Id)}
            >
              Mark Order Ready
            </Dropdown.Item>:null}
            <Dropdown.Item
              href=""
              style={{ backgroundColor: "#c0392b", color: "white" }}
              onClick={() => cancelThisOrder(prop.Id)}
            >
              Cancel Order
            </Dropdown.Item>
          </DropdownButton>
        ) : prop.isCancelledByRider == false &&
          prop.isCancelledByCustomer == false &&
          prop.isCancelledByPartner == false &&
          prop.isCancelledByAdmin == false ? (
          <DropdownButton id="dropdown-basic-button" title={prop?.orderStatus}>
            {prop?.orderStatus != "Order Expired" ? (
              <Dropdown.Item
                href=""
                style={{ backgroundColor: "#c0392b", color: "white" }}
                onClick={() => cancelThisOrder(prop.Id)}
              >
                Cancel Order
              </Dropdown.Item>
            ) : null}
          </DropdownButton>
        ) : (
          <p>N/A</p>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [isFetching, setIsFetching] = useState(false);
  const [prods, setAllProds] = useState([]);
  const [singleUserData, setSingleUserData] = useState({});
  const [allData, setAllData] = useState([]);
  const [allVehicleTypes, setVehicleTypes] = useState([]);
  const [userId, setUserId] = useState(null);
  // sleeping
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  // checking if signed in or not
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    let adminToken = JSON.parse(
      localStorage.getItem("priceApp-admin-auth-token")
    );
    if (!adminToken) {
      navigate("/signin");
    }
    let isMyAdmin = JSON.parse(localStorage.getItem("isAdmin"));
    if (isMyAdmin === false) {
      setIsAdmin(false);
    } else {
      setIsAdmin(true);
    }

    setUserId(JSON.parse(localStorage.getItem("user")));
  }, []);

  // filtering date
  const [dateSelector, setDateSelector] = useState({
    startingDate: "",
    endingDate: "",
  });
  const [csvData, setCsvData] = useState([]);

  // getting data
  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      if (isAdmin == true) {
        const { data } = await getAllOrders();
        if (data?.success === true) {
          //if(data?.AllOrders?.length > 0){
          let allDataArr = [],
            csvArray = [];
          if(data?.AllOrders[0]?.AllData?.length)
          for (let i = 0; i !== data?.AllOrders[0]?.AllData?.length; i++) {
            let newArr = {
              orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
              customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
              customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                ? process.env.REACT_APP_LIVE_SERVER +
                  "/usersImages/" +
                  data?.AllOrders[0]?.AllData[i]?.customerPic
                : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
              Id: data?.AllOrders[0]?.AllData[i]?.Id,
              myStore: data?.AllOrders[0]?.AllData[i]?.store,
              orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
              totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
              orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
              isCouponApplied:
                data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                  ? "Applied"
                  : "Not Applied",
              deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
              myProducts: data?.AllOrders[0]?.AllData[i]?.products,
              postedOn: moment(
                data?.AllOrders[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              lastUpdated: moment(
                data?.AllOrders[0]?.AllData[i]?.updatedAt
              ).format("DD MMM YY"),
              isPaymentDone:
                data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                  ? "Not Paid"
                  : "Paid",
              isCancelledByRider:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
              isCancelledByCustomer:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
              isCancelledByPartner:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
              isCancelledByAdmin:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
              vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
              vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
            };
            allDataArr.push(newArr);

            // making CSV array
            csvArray.push({
              Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
              Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
              Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
              Store: data?.AllOrders[0]?.AllData[i]?.store,
              Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
              Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
              Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
              isCouponApplied:
                data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                  ? "Applied"
                  : "Not Applied",
              Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
              Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
              Posted_On: moment(
                data?.AllOrders[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              Last_Updated: moment(
                data?.AllOrders[0]?.AllData[i]?.updatedAt
              ).format("DD MMM YY"),
              isPaymentDone:
                data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                  ? "Not Paid"
                  : "Paid",
            });
          }
          setAllData(allDataArr);
          setCsvData(csvArray);
        }
      } else {
        const { data } = await getAllOrdersOfVender();
        if (data?.success === true) {
          let allDataArr = [],
            csvArray = [];

          if (data?.AllOrders[0]?.AllData?.length > 0) {
            for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
              let newArr = {
                orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
                customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
                customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                  ? process.env.REACT_APP_LIVE_SERVER +
                    "/usersImages/" +
                    data?.AllOrders[0]?.AllData[i]?.customerPic
                  : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
                Id: data?.AllOrders[0]?.AllData[i]?.Id,
                myStore: data?.AllOrders[0]?.AllData[i]?.store,
                orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
                totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                myProducts: data?.AllOrders[0]?.AllData[i]?.products,
                postedOn: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                lastUpdated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
                isCancelledByRider:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
                isCancelledByCustomer:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
                isCancelledByPartner:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
                isCancelledByAdmin:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
                vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
                vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
              };
              allDataArr.push(newArr);

              // making CSV array

              csvArray.push({
                Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
                Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
                Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
                Store: data?.AllOrders[0]?.AllData[i]?.store,
                Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
                Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
                Posted_On: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                Last_Updated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
              });
            }
            setAllData(allDataArr);
            setCsvData(csvArray);
          }
        }
      }
      setIsFetching(false);
    };

    // getting all vehicle types
    const getAllMyVehicles = async () => {
      const { data } = await getAllVehicleType();
      console.log("allVehicleTypes  ====> : ", data);
      if (data?.success === true) {
        setVehicleTypes(data?.AllVehicles);
      } else {
        setVehicleTypes([]);
      }
    };

    getData();
    getAllMyVehicles();
  }, [isAdmin]);

  // view modal
  const [view, setView] = useState(false);
  const handleViewClose = () => {
    setView(false);
    setAllProds([]);
  };

  // getting data of user
  const handleView = async (id) => {
    //setView(true);
    window.open(`/order-details/${id}`, "_blank");
  };

  const [text, setText] = useState("");
  // getting all data on blur
  const getAllData = async () => {
    if (text?.length < 1) {
      setIsFetching(true);
      if (isAdmin === true) {
        const { data } = await getAllOrders();
        if (data?.success === true) {
          let allDataArr = [],
            csvArray = [];
        if(data?.AllOrders[0]?.AllData?.length)
          for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
            let newArr = {
              orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
              customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
              customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                ? process.env.REACT_APP_LIVE_SERVER +
                  "/usersImages/" +
                  data?.AllOrders[0]?.AllData[i]?.customerPic
                : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
              Id: data?.AllOrders[0]?.AllData[i]?.Id,
              storeId: data?.AllOrders[0]?.AllData[i]?.store,
              orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
              totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
              orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
              isCouponApplied:
                data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                  ? "Applied"
                  : "Not Applied",
              deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
              myProducts: data?.AllOrders[0]?.AllData[i]?.products,
              postedOn: moment(
                data?.AllOrders[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              lastUpdated: moment(
                data?.AllOrders[0]?.AllData[i]?.updatedAt
              ).format("DD MMM YY"),
              isPaymentDone:
                data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                  ? "Not Paid"
                  : "Paid",
              isCancelledByRider:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
              isCancelledByCustomer:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
              isCancelledByPartner:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
              isCancelledByAdmin:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
              vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
              vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
            };
            allDataArr.push(newArr);
            // making CSV array
            csvArray.push({
              Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
              Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
              Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
              Store: data?.AllOrders[0]?.AllData[i]?.store,
              Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
              Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
              Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
              isCouponApplied:
                data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                  ? "Applied"
                  : "Not Applied",
              Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
              Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
              Posted_On: moment(
                data?.AllOrders[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              Last_Updated: moment(
                data?.AllOrders[0]?.AllData[i]?.updatedAt
              ).format("DD MMM YY"),
              isPaymentDone:
                data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                  ? "Not Paid"
                  : "Paid",
            });
          }
          setAllData(allDataArr);
          setCsvData(csvArray);
        }
      } else {
        const { data } = await getAllOrdersOfVender();
        if (data?.success === true) {
          let allDataArr = [],
            csvArray = [];
          if (data?.AllOrders.length > 0) {
            for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
              let newArr = {
                orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
                customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
                customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                  ? process.env.REACT_APP_LIVE_SERVER +
                    "/usersImages/" +
                    data?.AllOrders[0]?.AllData[i]?.customerPic
                  : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
                Id: data?.AllOrders[0]?.AllData[i]?.Id,
                storeId: data?.AllOrders[0]?.AllData[i]?.store,
                orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
                totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                myProducts: data?.AllOrders[0]?.AllData[i]?.products,
                postedOn: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                lastUpdated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
                isCancelledByRider:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
                isCancelledByCustomer:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
                isCancelledByPartner:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
                isCancelledByAdmin:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
                vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
                vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
              };
              // making CSV array
              csvArray.push({
                Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
                Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
                Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
                Store: data?.AllOrders[0]?.AllData[i]?.store,
                Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
                Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
                Posted_On: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                Last_Updated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
              });
              allDataArr.push(newArr);
            }
          }
          setAllData(allDataArr);
          setCsvData(csvArray);
        }
      }
      setIsFetching(false);
    }
  };

  // getting searched item
  const getSearchedOrders = async () => {
    if (text?.length > 2) {
      let myData = [],
        csvArray = [];
      if (isAdmin === true) {
        const { data } = await getAllOrders();
        if (data?.success === true) {
          let allDataArr = [];
          if(data?.AllOrders[0]?.AllData?.length)
          for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
            let newArr = {
              orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
              customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
              customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                ? process.env.REACT_APP_LIVE_SERVER +
                  "/usersImages/" +
                  data?.AllOrders[0]?.AllData[i]?.customerPic
                : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
              Id: data?.AllOrders[0]?.AllData[i]?.Id,
              myStore: data?.AllOrders[0]?.AllData[i]?.store,
              orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
              totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
              orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
              isCouponApplied:
                data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                  ? "Applied"
                  : "Not Applied",
              deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
              myProducts: data?.AllOrders[0]?.AllData[i]?.products,
              postedOn: moment(
                data?.AllOrders[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              lastUpdated: moment(
                data?.AllOrders[0]?.AllData[i]?.updatedAt
              ).format("DD MMM YY"),
              isPaymentDone:
                data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                  ? "Not Paid"
                  : "Paid",
              isCancelledByRider:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
              isCancelledByCustomer:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
              isCancelledByPartner:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
              isCancelledByAdmin:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
              vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
              vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
            };
            myData.push(newArr);
            // making CSV array
            csvArray.push({
              Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
              Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
              Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
              Store: data?.AllOrders[0]?.AllData[i]?.store,
              Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
              Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
              Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
              isCouponApplied:
                data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                  ? "Applied"
                  : "Not Applied",
              Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
              Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
              Posted_On: moment(
                data?.AllOrders[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              Last_Updated: moment(
                data?.AllOrders[0]?.AllData[i]?.updatedAt
              ).format("DD MMM YY"),
              isPaymentDone:
                data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                  ? "Not Paid"
                  : "Paid",
            });
          }
        }
        setCsvData(csvArray);
      } else {
        const { data } = await getAllOrdersOfVender();
        if (data?.success === true) {
          let allDataArr = [];
          if (data?.AllOrders.length > 0) {
            if(data?.AllOrders[0]?.AllData?.length)
            for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
              let newArr = {
                orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
                customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
                customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                  ? process.env.REACT_APP_LIVE_SERVER +
                    "/usersImages/" +
                    data?.AllOrders[0]?.AllData[i]?.customerPic
                  : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
                Id: data?.AllOrders[0]?.AllData[i]?.Id,
                myStore: data?.AllOrders[0]?.AllData[i]?.store,
                orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
                totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                products: data?.AllOrders[0]?.AllData[i]?.products,
                lastUpdated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
                isCancelledByRider:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
                isCancelledByCustomer:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
                isCancelledByPartner:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
                isCancelledByAdmin:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
                vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
                vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
              };
              myData.push(newArr);
              // making CSV array
              csvArray.push({
                Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
                Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
                Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
                Store: data?.AllOrders[0]?.AllData[i]?.store,
                Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
                Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
                Posted_On: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                Last_Updated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
              });
            }
          }
        }
        setCsvData(csvArray);
      }
      // searching now
      let newArr = [],
        allDataArr = [];
      let myText = text.toLocaleLowerCase();
      for (let i = 0; i !== myData.length; i++) {
        if (
          myData[i]?.name?.toLowerCase()?.includes(myText) === true ||
          myData[i]?.orderNo == myText ||
          myData[i].address?.toLowerCase()?.includes(myText) === true ||
          myData[i].customerName?.toLowerCase()?.includes(myText) === true ||
          myData[i].storeId?.toLowerCase()?.includes(myText) === true ||
          myData[i].orderAddress?.toLowerCase()?.includes(myText) === true ||
          myData[i].orderStatus?.toLowerCase()?.includes(myText) === true ||
          myData[i].totalAmt == myText
        ) {
          newArr.push(myData[i]);
        }
      }
      setText("");
      setAllData(allDataArr);
      // setting csv file data
      // let newMyArr = allDataArr;
      // newMyArr.forEach(obj => {
      //     delete obj['customerPic'];
      //     delete obj['storeId'];
      //     delete obj['isCouponApplied'];
      //     delete obj['products'];
      // })
      // let csvArray = []
      // csvArray = newMyArr.map(object => object)
      // setCsvData(csvArray)
    }
  };

  // socket implementation
  useEffect(() => {
    // checking if any customer is signed in

    console.log(socket, "socket");

    if (socket != null) {
      let isMyAdmin = JSON.parse(localStorage.getItem("isAdmin"));
      if (isMyAdmin == true) {
        socket.on("newOrderReceivedByAdmin", async () => {
          // toast.success("New order Received");
          setIsFetching(true);
          if (isAdmin === true) {
            const { data } = await getAllOrders();
            if (data?.success === true) {
              let allDataArr = [],
                csvArray = [];
              if(data?.AllOrders[0]?.AllData?.length)
              for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
                let newArr = {
                  orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
                  customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
                  customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                    ? process.env.REACT_APP_LIVE_SERVER +
                      "/usersImages/" +
                      data?.AllOrders[0]?.AllData[i]?.customerPic
                    : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
                  Id: data?.AllOrders[0]?.AllData[i]?.Id,
                  storeId: data?.AllOrders[0]?.AllData[i]?.store,
                  orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
                  totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                  orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                  isCouponApplied:
                    data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                      ? "Applied"
                      : "Not Applied",
                  deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                  products: data?.AllOrders[0]?.AllData[i]?.products,
                  postedOn: moment(
                    data?.AllOrders[0]?.AllData[i]?.createdAt
                  ).format("DD MMM YY"),
                  lastUpdated: moment(
                    data?.AllOrders[0]?.AllData[i]?.updatedAt
                  ).format("DD MMM YY"),
                  isPaymentDone:
                    data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                      ? "Not Paid"
                      : "Paid",
                };
                //csvArray = allDataArr.map(object => object)
                allDataArr.push(newArr);
              }
              setAllData(allDataArr);
              // setting csv file data
              let newArr = allDataArr;
              newArr.forEach((obj) => {
                delete obj["customerPic"];
                delete obj["storeId"];
                delete obj["isCouponApplied"];
                delete obj["products"];
              });
              csvArray = newArr.map((object) => object);
              setCsvData(csvArray);
            }
          }
          setIsFetching(false);
        });
      } else {
        socket.on("newOrderReceivedForPartner", async (partnerId) => {
          let myUser = JSON.parse(localStorage.getItem("user"));
          if (myUser?.Id == partnerId) {
            // toast.success("New order Received By You");
            setIsFetching(true);
            const { data } = await getAllOrdersOfVender();
            if (data?.success === true) {
              let allDataArr = [],
                csvArray = [];
              if (data?.AllOrders.length > 0) {
                if(data?.AllOrders[0]?.AllData?.length)
                for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
                  let newArr = {
                    orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
                    customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
                    customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                      ? process.env.REACT_APP_LIVE_SERVER +
                        "/usersImages/" +
                        data?.AllOrders[0]?.AllData[i]?.customerPic
                      : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
                    Id: data?.AllOrders[0]?.AllData[i]?.Id,
                    storeId: data?.AllOrders[0]?.AllData[i]?.store,
                    orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
                    totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                    orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                    isCouponApplied:
                      data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                        ? "Applied"
                        : "Not Applied",
                    deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                    products: data?.AllOrders[0]?.AllData[i]?.products,
                    isPaymentDone:
                      data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                        ? "Not Paid"
                        : "Paid",
                  };
                  //csvArray = allDataArr.map(object => object)
                  allDataArr.push(newArr);
                }
                setAllData(allDataArr);
                // setting csv file data
                let newMyArr = allDataArr;
                newMyArr.forEach((obj) => {
                  delete obj["customerPic"];
                  delete obj["storeId"];
                  delete obj["isCouponApplied"];
                  delete obj["products"];
                });
                csvArray = newMyArr.map((object) => object);
                setCsvData(csvArray);
              }
            }
            setIsFetching(false);
          }
        });
      }
    }

    // getting new order notification
    socket.on("newOrderForDashboardNotification", async (partnerId, msg) => {
      let myUser = JSON.parse(localStorage.getItem("user"));
      // toast.success(msg);
      if (myUser?.Id == partnerId) {
        setIsFetching(true);
        const { data } = await getAllOrdersOfVender();
        if (data?.success === true) {
          let allDataArr = [],
            csvArray = [];
          if (data?.AllOrders.length > 0) {
            if(data?.AllOrders[0]?.AllData?.length)
            for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
              let newArr = {
                orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
                customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
                customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                  ? process.env.REACT_APP_LIVE_SERVER +
                    "/usersImages/" +
                    data?.AllOrders[0]?.AllData[i]?.customerPic
                  : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
                Id: data?.AllOrders[0]?.AllData[i]?.Id,
                myStore: data?.AllOrders[0]?.AllData[i]?.store,
                orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
                totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                myProducts: data?.AllOrders[0]?.AllData[i]?.products,
                postedOn: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                lastUpdated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
              };
              //csvArray = allDataArr.map(object => object)
              allDataArr.push(newArr);
            }
            setAllData(allDataArr);
            // setting csv file data
            let newMyArr = allDataArr;
            newMyArr.forEach((obj) => {
              delete obj["customerPic"];
              delete obj["storeId"];
              delete obj["isCouponApplied"];
              delete obj["products"];
            });
            csvArray = newMyArr.map((object) => object);
            setCsvData(csvArray);
          }
        } else {
          const { data } = await getAllOrders();
          if (data?.success === true) {
            //if(data?.AllOrders?.length > 0){

            let allDataArr = [],
              csvArray = [];
            if(data?.AllOrders[0]?.AllData?.length)
            for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
              let newArr = {
                orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
                customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
                customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                  ? process.env.REACT_APP_LIVE_SERVER +
                    "/usersImages/" +
                    data?.AllOrders[0]?.AllData[i]?.customerPic
                  : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
                Id: data?.AllOrders[0]?.AllData[i]?.Id,
                myStore: data?.AllOrders[0]?.AllData[i]?.store,
                orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
                totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                myProducts: data?.AllOrders[0]?.AllData[i]?.products,
                postedOn: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                lastUpdated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
              };
              allDataArr.push(newArr);
            }
            setAllData(allDataArr);
            // setting csv file data
            let newArr = allDataArr;
            if (newArr?.length > 0) {
              newArr.forEach((obj) => {
                delete obj["customerPic"];
                delete obj["storeId"];
                delete obj["isCouponApplied"];
                delete obj["products"];
              });
              csvArray = newArr.map((object) => object);
              setCsvData(csvArray);
            }
            //}
          }
        }
        setIsFetching(false);
      }
    });
  }, [socket]);

  // clearing filters
  const clearFilters = async () => {
    setDateSelector({ startingDate: "", endingDate: "" });
    setIsFetching(true);
    if (isAdmin === true) {
      const { data } = await getAllOrders();
      if (data?.success === true) {
        let allDataArr = [],
          csvArray = [];
        if(data?.AllOrders[0]?.AllData?.length)
        for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
          let newArr = {
            orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
            customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
            customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
              ? process.env.REACT_APP_LIVE_SERVER +
                "/usersImages/" +
                data?.AllOrders[0]?.AllData[i]?.customerPic
              : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
            Id: data?.AllOrders[0]?.AllData[i]?.Id,
            myStore: data?.AllOrders[0]?.AllData[i]?.store,
            orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
            totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
            orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
            isCouponApplied:
              data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                ? "Applied"
                : "Not Applied",
            deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
            myProducts: data?.AllOrders[0]?.AllData[i]?.products,
            postedOn: moment(data?.AllOrders[0]?.AllData[i]?.createdAt).format(
              "DD MMM YY"
            ),
            lastUpdated: moment(
              data?.AllOrders[0]?.AllData[i]?.updatedAt
            ).format("DD MMM YY"),
            isPaymentDone:
              data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                ? "Not Paid"
                : "Paid",
            isCancelledByRider:
              data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
            isCancelledByCustomer:
              data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
            isCancelledByPartner:
              data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
            isCancelledByAdmin:
              data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
            vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
            vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
          };
          allDataArr.push(newArr);
          // making CSV array
          csvArray.push({
            Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
            Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
            Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
            Store: data?.AllOrders[0]?.AllData[i]?.store,
            Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
            Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
            Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
            isCouponApplied:
              data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                ? "Applied"
                : "Not Applied",
            Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
            Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
            Posted_On: moment(data?.AllOrders[0]?.AllData[i]?.createdAt).format(
              "DD MMM YY"
            ),
            Last_Updated: moment(
              data?.AllOrders[0]?.AllData[i]?.updatedAt
            ).format("DD MMM YY"),
            isPaymentDone:
              data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                ? "Not Paid"
                : "Paid",
          });
        }
        setAllData(allDataArr);
        setCsvData(csvArray);
      }
    } else {
      const { data } = await getAllOrdersOfVender();
      if (data?.success === true) {
        let allDataArr = [],
          csvArray = [];
        if (data?.AllOrders.length > 0) {
          for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
            let newArr = {
              orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
              customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
              customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                ? process.env.REACT_APP_LIVE_SERVER +
                  "/usersImages/" +
                  data?.AllOrders[0]?.AllData[i]?.customerPic
                : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
              Id: data?.AllOrders[0]?.AllData[i]?.Id,
              myStore: data?.AllOrders[0]?.AllData[i]?.store,
              orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
              totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
              orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
              isCouponApplied:
                data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                  ? "Applied"
                  : "Not Applied",
              deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
              products: data?.AllOrders[0]?.AllData[i]?.products,
              lastUpdated: moment(
                data?.AllOrders[0]?.AllData[i]?.updatedAt
              ).format("DD MMM YY"),
              isPaymentDone:
                data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                  ? "Not Paid"
                  : "Paid",
              isCancelledByRider:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
              isCancelledByCustomer:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
              isCancelledByPartner:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
              isCancelledByAdmin:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
              vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
              vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
            };
            // making CSV array
            csvArray.push({
              Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
              Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
              Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
              Store: data?.AllOrders[0]?.AllData[i]?.store,
              Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
              Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
              Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
              isCouponApplied:
                data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                  ? "Applied"
                  : "Not Applied",
              Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
              Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
              Posted_On: moment(
                data?.AllOrders[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              Last_Updated: moment(
                data?.AllOrders[0]?.AllData[i]?.updatedAt
              ).format("DD MMM YY"),
              isPaymentDone:
                data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                  ? "Not Paid"
                  : "Paid",
            });
            allDataArr.push(newArr);
          }
        }
        setAllData(allDataArr);
        setCsvData(csvArray);
      }
    }
    setIsFetching(false);
  };

  // getting filtered data
  const getFilteredData = async () => {
    setIsFetching(true);
    if (isAdmin == false) {
      const { data } = await getOrdersWithDateRange(dateSelector);
      if (data?.success === true) {
        if (data?.AllOrders?.length > 0) {
          let allDataArr = [],
            csvArray = [];
          if(data?.AllOrders[0]?.AllData?.length)
          for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
            let newArr = {
              orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
              customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
              customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                ? process.env.REACT_APP_LIVE_SERVER +
                  "/usersImages/" +
                  data?.AllOrders[0]?.AllData[i]?.customerPic
                : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
              Id: data?.AllOrders[0]?.AllData[i]?.Id,
              myStore: data?.AllOrders[0]?.AllData[i]?.store,
              orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
              totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
              orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
              isCouponApplied:
                data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                  ? "Applied"
                  : "Not Applied",
              deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
              myProducts: data?.AllOrders[0]?.AllData[i]?.products,
              postedOn: moment(
                data?.AllOrders[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              lastUpdated: moment(
                data?.AllOrders[0]?.AllData[i]?.updatedAt
              ).format("DD MMM YY"),
              isPaymentDone:
                data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                  ? "Not Paid"
                  : "Paid",
              isCancelledByRider:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
              isCancelledByCustomer:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
              isCancelledByPartner:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
              isCancelledByAdmin:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
              vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
              vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
            };
            // making CSV array
            csvArray.push({
              Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
              Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
              Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
              Store: data?.AllOrders[0]?.AllData[i]?.store,
              Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
              Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
              Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
              isCouponApplied:
                data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                  ? "Applied"
                  : "Not Applied",
              Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
              Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
              Posted_On: moment(
                data?.AllOrders[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              Last_Updated: moment(
                data?.AllOrders[0]?.AllData[i]?.updatedAt
              ).format("DD MMM YY"),
              isPaymentDone:
                data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                  ? "Not Paid"
                  : "Paid",
            });
            allDataArr.push(newArr);
          }
          setAllData(allDataArr);
          // setting csv file data
          let newMyArr = allDataArr;
          newMyArr.forEach((obj) => {
            delete obj["customerPic"];
            delete obj["storeId"];
            delete obj["isCouponApplied"];
            delete obj["products"];
          });
          csvArray = newMyArr.map((object) => object);
          setCsvData(csvArray);
        } else {
          setAllData([]);
          toast.warning("No Record Found");
        }
      } else {
        setAllData([]);
        toast.error(data?.message);
      }
    } else {
      const { data } = await getOrdersWithDateRangeForAdmin(dateSelector);
      if (data?.success === true) {
        if (data?.AllOrders?.length > 0) {
          let allDataArr = [],
            csvArray = [];
          if(data?.AllOrders[0]?.AllData?.length)
          for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
            let newArr = {
              orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
              customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
              customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                ? process.env.REACT_APP_LIVE_SERVER +
                  "/usersImages/" +
                  data?.AllOrders[0]?.AllData[i]?.customerPic
                : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
              Id: data?.AllOrders[0]?.AllData[i]?.Id,
              myStore: data?.AllOrders[0]?.AllData[i]?.store,
              orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
              totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
              orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
              isCouponApplied:
                data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                  ? "Applied"
                  : "Not Applied",
              deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
              products: data?.AllOrders[0]?.AllData[i]?.products,
              postedOn: moment(
                data?.AllOrders[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              lastUpdated: moment(
                data?.AllOrders[0]?.AllData[i]?.updatedAt
              ).format("DD MMM YY"),
              isPaymentDone:
                data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                  ? "Not Paid"
                  : "Paid",
              isCancelledByRider:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
              isCancelledByCustomer:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
              isCancelledByPartner:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
              isCancelledByAdmin:
                data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
              vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
              vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
            };
            allDataArr.push(newArr);
            // making CSV array
            csvArray.push({
              Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
              Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
              Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
              Store: data?.AllOrders[0]?.AllData[i]?.store,
              Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
              Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
              Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
              isCouponApplied:
                data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                  ? "Applied"
                  : "Not Applied",
              Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
              Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
              Posted_On: moment(
                data?.AllOrders[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              Last_Updated: moment(
                data?.AllOrders[0]?.AllData[i]?.updatedAt
              ).format("DD MMM YY"),
              isPaymentDone:
                data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                  ? "Not Paid"
                  : "Paid",
            });
          }
          setAllData(allDataArr);
          setCsvData(csvArray);
        } else {
          setAllData([]);
          toast.warning("No Record Found");
        }
      } else {
        setAllData([]);
        toast.error(data?.message);
      }
    }

    setIsFetching(false);
  };

  // changing status of order
  const changeOrderStatus = async (id) => {
    let isFound = allData.find((item) => item.Id === id);
    if (isFound) {
      if(isFound?.orderStatus === "Awaiting Approval" ){
        const { data } = await changeStatusOfOrderApprove(id);
        if (data?.success === true) {
            isFound.orderStatus = "Pending";
            toast.success("Order is Approve Successfully");
          let newData = allData;
          let finalData = newData.filter((item) =>
            item.Id === id ? isFound : item
          );
          setAllData(finalData);
        } else {
          toast.error(data?.message);
        }
      }else{
      if (isAdmin === true) {
        const { data } = await updateStatusOfOrderByAdmin(id);
        if (data?.success === true) {
          if (isFound.orderStatus == "Accepted") {
            isFound.orderStatus = "Order Ready";
            toast.success("Order is Ready to be Delivered Successfully");
          }
          let newData = allData;
          let finalData = newData.filter((item) =>
            item.Id === id ? isFound : item
          );
          setAllData(finalData);
        } else {
          toast.error(data?.message);
        }
      } else {
        const { data } = await updateStatusOfOrderByVender(id);
        if (data?.success === true) {
          if (isFound.orderStatus == "Accepted") {
            isFound.orderStatus = "Order Ready";
            toast.success("Order is Ready to be Delivered Successfully");
          }
          let newData = allData;
          let finalData = newData.filter((item) =>
            item.Id === id ? isFound : item
          );
          setAllData(finalData);
        } else {
          toast.error(data?.message);
        }
      }
      

      // getting data
      if (isAdmin == true) {
        const res = await getAllOrders();
        if (res?.data?.success === true) {
          //if(res?.data?.AllOrders?.length > 0){
          let allDataArr = [],
            csvArray = [];
          if(res?.data?.data?.AllOrders[0]?.AllData?.length)
          for (let i = 0; i !== res?.data?.AllOrders[0]?.AllData.length; i++) {
            let newArr = {
              orderNo: res?.data?.AllOrders[0]?.AllData[i]?.refNo,
              customerName: res?.data?.AllOrders[0]?.AllData[i]?.customerName,
              customerPic: res?.data?.AllOrders[0]?.AllData[i]?.customerPic
                ? process.env.REACT_APP_LIVE_SERVER +
                  "/usersImages/" +
                  res?.data?.AllOrders[0]?.AllData[i]?.customerPic
                : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
              Id: res?.data?.AllOrders[0]?.AllData[i]?.Id,
              myStore: res?.data?.AllOrders[0]?.AllData[i]?.store,
              orderStatus: res?.data?.AllOrders[0]?.AllData[i]?.status,
              totalAmt: res?.data?.AllOrders[0]?.AllData[i]?.totalAmt,
              orderAddress: res?.data?.AllOrders[0]?.AllData[i]?.orderAddress,
              isCouponApplied:
                res?.data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                  ? "Applied"
                  : "Not Applied",
              deliveryFee: res?.data?.AllOrders[0]?.AllData[i]?.deliveryFee,
              myProducts: res?.data?.AllOrders[0]?.AllData[i]?.products,
              postedOn: moment(
                res?.data?.AllOrders[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              lastUpdated: moment(
                res?.data?.AllOrders[0]?.AllData[i]?.updatedAt
              ).format("DD MMM YY"),
              isPaymentDone:
                res?.data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                  ? "Not Paid"
                  : "Paid",
              isCancelledByRider:
                res?.data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
              isCancelledByCustomer:
                res?.data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
              isCancelledByPartner:
                res?.data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
              isCancelledByAdmin:
                res?.data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
              vehicleId: res?.data?.AllOrders[0]?.AllData[i]?.vehicleId,
              vehicleName: res?.data?.AllOrders[0]?.AllData[i]?.vehicleName,
            };
            allDataArr.push(newArr);

            // making CSV array
            csvArray.push({
              Order_No: res?.data?.AllOrders[0]?.AllData[i]?.refNo,
              Customer_Name: res?.data?.AllOrders[0]?.AllData[i]?.customerName,
              Order_Id: res?.data?.AllOrders[0]?.AllData[i]?.Id,
              Store: res?.data?.AllOrders[0]?.AllData[i]?.store,
              Order_Status: res?.data?.AllOrders[0]?.AllData[i]?.status,
              Total_Amount: res?.data?.AllOrders[0]?.AllData[i]?.totalAmt,
              Order_Address: res?.data?.AllOrders[0]?.AllData[i]?.orderAddress,
              isCouponApplied:
                res?.data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                  ? "Applied"
                  : "Not Applied",
              Delivery_Fee: res?.data?.AllOrders[0]?.AllData[i]?.deliveryFee,
              Ordered_Products: res?.data?.AllOrders[0]?.AllData[i]?.products,
              Posted_On: moment(
                res?.data?.AllOrders[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              Last_Updated: moment(
                res?.data?.AllOrders[0]?.AllData[i]?.updatedAt
              ).format("DD MMM YY"),
              isPaymentDone:
                res?.data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                  ? "Not Paid"
                  : "Paid",
            });
          }
          setAllData(allDataArr);
          setCsvData(csvArray);
        }
      } else {
        const response = await getAllOrdersOfVender();
        if (response?.data?.success === true) {
          let allDataArr = [],
            csvArray = [];
          //if(response?.data?.AllOrders[0]?.Allresponse?.Data?.length > 0){
          for (
            let i = 0;
            i !== response?.data?.AllOrders[0]?.AllData.length;
            i++
          ) {
            let newArr = {
              orderNo: response?.data?.AllOrders[0]?.AllData[i]?.refNo,
              customerName:
                response?.data?.AllOrders[0]?.AllData[i]?.customerName,
              customerPic: response?.data?.AllOrders[0]?.AllData[i]?.customerPic
                ? process.env.REACT_APP_LIVE_SERVER +
                  "/usersImages/" +
                  response?.data?.AllOrders[0]?.AllData[i]?.customerPic
                : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
              Id: response?.data?.AllOrders[0]?.AllData[i]?.Id,
              myStore: response?.data?.AllOrders[0]?.AllData[i]?.store,
              orderStatus: response?.data?.AllOrders[0]?.AllData[i]?.status,
              totalAmt: response?.data?.AllOrders[0]?.AllData[i]?.totalAmt,
              orderAddress:
                response?.data?.AllOrders[0]?.AllData[i]?.orderAddress,
              isCouponApplied:
                response?.data?.AllOrders[0]?.AllData[i]?.isCouponApplied ==
                true
                  ? "Applied"
                  : "Not Applied",
              deliveryFee:
                response?.data?.AllOrders[0]?.AllData[i]?.deliveryFee,
              myProducts: response?.data?.AllOrders[0]?.AllData[i]?.products,
              postedOn: moment(
                response?.data?.AllOrders[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              lastUpdated: moment(
                response?.data?.AllOrders[0]?.AllData[i]?.updatedAt
              ).format("DD MMM YY"),
              isPaymentDone:
                response?.data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                  ? "Not Paid"
                  : "Paid",
              isCancelledByRider:
                response?.data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
              isCancelledByCustomer:
                response?.data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
              isCancelledByPartner:
                response?.data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
              isCancelledByAdmin:
                response?.data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
              vehicleId: response?.data?.AllOrders[0]?.AllData[i]?.vehicleId,
              vehicleName:
                response?.data?.AllOrders[0]?.AllData[i]?.vehicleName,
            };
            allDataArr.push(newArr);
            // making CSV array
            csvArray.push({
              Order_No: response?.data?.AllOrders[0]?.AllData[i]?.refNo,
              Customer_Name:
                response?.data?.AllOrders[0]?.AllData[i]?.customerName,
              Order_Id: response?.data?.AllOrders[0]?.AllData[i]?.Id,
              Store: response?.data?.AllOrders[0]?.AllData[i]?.store,
              Order_Status: response?.data?.AllOrders[0]?.AllData[i]?.status,
              Total_Amount: response?.data?.AllOrders[0]?.AllData[i]?.totalAmt,
              Order_Address:
                response?.data?.AllOrders[0]?.AllData[i]?.orderAddress,
              isCouponApplied:
                response?.data?.AllOrders[0]?.AllData[i]?.isCouponApplied ==
                true
                  ? "Applied"
                  : "Not Applied",
              Delivery_Fee:
                response?.data?.AllOrders[0]?.AllData[i]?.deliveryFee,
              Ordered_Products:
                response?.data?.AllOrders[0]?.AllData[i]?.products,
              Posted_On: moment(
                response?.data?.AllOrders[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              Last_Updated: moment(
                response?.data?.AllOrders[0]?.AllData[i]?.updatedAt
              ).format("DD MMM YY"),
              isPaymentDone:
                response?.data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                  ? "Not Paid"
                  : "Paid",
            });
          }
          setAllData(allDataArr);
          setCsvData(csvArray);
        }
      }
      }
    }
  };

  // accept any order
  const acceptThisOrder = async (id) => {
    let isFound = allData.find((item) => item.Id === id);
    if (isFound) {
      if (isAdmin === true) {
        const { data } = await acceptAnyOrderByAdmin(id);
        if (data?.success === true) {
          if (isFound.orderStatus == "Pending") {
            isFound.orderStatus = "Accepted";
            toast.success("Order Accepted Successfully");
          }
          let newData = allData;
          let finalData = newData.filter((item) =>
            item.Id === id ? isFound : item
          );
          setAllData(finalData);
        } else {
          toast.error(data?.message);
        }
      } else {
        const { data } = await acceptAnyOrderByVender(id);
        if (data?.success === true) {
          if (isFound.orderStatus == "Pending") {
            isFound.orderStatus = "Accepted";
            toast.success("Order Accepted Successfully");
          }
          let newData = allData;
          let finalData = newData.filter((item) =>
            item.Id === id ? isFound : item
          );
          setAllData(finalData);
        } else {
          toast.error(data?.message);
        }
      }
    }
  };

  const handleRowClicked = (row) => [
    {
      when: (row) => row,
      style: {
        // "&:clicked": {
        backgroundColor: "red",
        color: "white",
        cursor: "pointer",
        //}
      },
    },
  ];

  // cancelling any order by admin/vender
  const cancelThisOrder = async (id) => {
    if (isAdmin === true) {
      const res = await cancelAnyOrderByAdmin(id);
      if (res?.data?.success === true) {
        toast.success("Order Cancelled and User Refunded Successfully");
        if (isAdmin == true) {
          const { data } = await getAllOrders();
          if (data?.success === true) {
            //if(data?.AllOrders?.length > 0){
            let allDataArr = [],
              csvArray = [];
            if(data?.AllOrders[0]?.AllData?.length)
            for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
              let newArr = {
                orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
                customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
                customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                  ? process.env.REACT_APP_LIVE_SERVER +
                    "/usersImages/" +
                    data?.AllOrders[0]?.AllData[i]?.customerPic
                  : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
                Id: data?.AllOrders[0]?.AllData[i]?.Id,
                myStore: data?.AllOrders[0]?.AllData[i]?.store,
                orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
                totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                myProducts: data?.AllOrders[0]?.AllData[i]?.products,
                postedOn: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                lastUpdated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
                isCancelledByRider:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
                isCancelledByCustomer:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
                isCancelledByPartner:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
                isCancelledByAdmin:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
                vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
                vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
              };
              allDataArr.push(newArr);

              // making CSV array
              csvArray.push({
                Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
                Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
                Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
                Store: data?.AllOrders[0]?.AllData[i]?.store,
                Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
                Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
                Posted_On: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                Last_Updated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
              });
            }
            setAllData(allDataArr);
            setCsvData(csvArray);
          }
        } else {
          const { data } = await getAllOrdersOfVender();
          if (data?.success === true) {
            let allDataArr = [],
              csvArray = [];
            if(data?.AllOrders[0]?.AllData?.length)
            //if(data?.AllOrders[0]?.AllData?.length > 0){
            for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
              let newArr = {
                orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
                customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
                customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                  ? process.env.REACT_APP_LIVE_SERVER +
                    "/usersImages/" +
                    data?.AllOrders[0]?.AllData[i]?.customerPic
                  : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
                Id: data?.AllOrders[0]?.AllData[i]?.Id,
                myStore: data?.AllOrders[0]?.AllData[i]?.store,
                orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
                totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                myProducts: data?.AllOrders[0]?.AllData[i]?.products,
                postedOn: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                lastUpdated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
                isCancelledByRider:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
                isCancelledByCustomer:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
                isCancelledByPartner:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
                isCancelledByAdmin:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
                vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
                vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
              };
              allDataArr.push(newArr);
              // making CSV array
              csvArray.push({
                Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
                Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
                Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
                Store: data?.AllOrders[0]?.AllData[i]?.store,
                Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
                Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
                Posted_On: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                Last_Updated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
              });
            }
            setAllData(allDataArr);
            setCsvData(csvArray);
          }
        }
      } else {
        toast.error(res?.data?.message);
      }
    } else {
      const res = await cancelAnyOrderByVender(id);
      if (res?.data?.success === true) {
        toast.success("Order Cancelled and User Refunded Successfully");
        if (isAdmin == true) {
          const { data } = await getAllOrders();
          if (data?.success === true) {
            //if(data?.AllOrders?.length > 0){
            let allDataArr = [],
              csvArray = [];
            if(data?.AllOrders[0]?.AllData?.length)
            for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
              let newArr = {
                orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
                customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
                customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                  ? process.env.REACT_APP_LIVE_SERVER +
                    "/usersImages/" +
                    data?.AllOrders[0]?.AllData[i]?.customerPic
                  : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
                Id: data?.AllOrders[0]?.AllData[i]?.Id,
                myStore: data?.AllOrders[0]?.AllData[i]?.store,
                orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
                totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                myProducts: data?.AllOrders[0]?.AllData[i]?.products,
                postedOn: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                lastUpdated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
                isCancelledByRider:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
                isCancelledByCustomer:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
                isCancelledByPartner:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
                isCancelledByAdmin:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
                vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
                vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
              };
              allDataArr.push(newArr);

              // making CSV array
              csvArray.push({
                Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
                Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
                Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
                Store: data?.AllOrders[0]?.AllData[i]?.store,
                Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
                Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
                Posted_On: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                Last_Updated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
              });
            }
            setAllData(allDataArr);
            setCsvData(csvArray);
          }
        } else {
          const { data } = await getAllOrdersOfVender();
          if (data?.success === true) {
            let allDataArr = [],
              csvArray = [];
            //if(data?.AllOrders[0]?.AllData?.length > 0){
            if(data?.AllOrders[0]?.AllData?.length)
            for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
              let newArr = {
                orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
                customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
                customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                  ? process.env.REACT_APP_LIVE_SERVER +
                    "/usersImages/" +
                    data?.AllOrders[0]?.AllData[i]?.customerPic
                  : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
                Id: data?.AllOrders[0]?.AllData[i]?.Id,
                myStore: data?.AllOrders[0]?.AllData[i]?.store,
                orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
                totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                myProducts: data?.AllOrders[0]?.AllData[i]?.products,
                postedOn: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                lastUpdated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
                isCancelledByRider:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
                isCancelledByCustomer:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
                isCancelledByPartner:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
                isCancelledByAdmin:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
                vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
                vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
              };
              allDataArr.push(newArr);
              // making CSV array
              csvArray.push({
                Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
                Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
                Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
                Store: data?.AllOrders[0]?.AllData[i]?.store,
                Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
                Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
                Posted_On: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                Last_Updated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
              });
            }
            setAllData(allDataArr);
            setCsvData(csvArray);
          }
        }
      } else {
        toast.error(res?.data?.message);
      }
    }
  };

  // changing vehicle type on any order by admin/vender
  const changeVehicleTypeOfOrder = async (id, vehicleId) => {
    if (isAdmin === true) {
      const res = await updateSingleVehicleTypeByAdmin(id, vehicleId);
      if (res?.data?.success === true) {
        toast.success(res?.data?.message);
        if (isAdmin == true) {
          const { data } = await getAllOrders();
          if (data?.success === true) {
            //if(data?.AllOrders?.length > 0){
            let allDataArr = [],
              csvArray = [];
            if(data?.AllOrders[0]?.AllData?.length)
            for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
              let newArr = {
                orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
                customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
                customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                  ? process.env.REACT_APP_LIVE_SERVER +
                    "/usersImages/" +
                    data?.AllOrders[0]?.AllData[i]?.customerPic
                  : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
                Id: data?.AllOrders[0]?.AllData[i]?.Id,
                myStore: data?.AllOrders[0]?.AllData[i]?.store,
                orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
                totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                myProducts: data?.AllOrders[0]?.AllData[i]?.products,
                postedOn: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                lastUpdated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
                isCancelledByRider:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
                isCancelledByCustomer:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
                isCancelledByPartner:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
                isCancelledByAdmin:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
                vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
                vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
              };
              allDataArr.push(newArr);

              // making CSV array
              csvArray.push({
                Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
                Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
                Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
                Store: data?.AllOrders[0]?.AllData[i]?.store,
                Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
                Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
                Posted_On: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                Last_Updated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
              });
            }
            setAllData(allDataArr);
            setCsvData(csvArray);
          }
        } else {
          const { data } = await getAllOrdersOfVender();
          if (data?.success === true) {
            let allDataArr = [],
              csvArray = [];
            //if(data?.AllOrders[0]?.AllData?.length > 0){
            if(data?.AllOrders[0]?.AllData?.length)
            for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
              let newArr = {
                orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
                customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
                customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                  ? process.env.REACT_APP_LIVE_SERVER +
                    "/usersImages/" +
                    data?.AllOrders[0]?.AllData[i]?.customerPic
                  : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
                Id: data?.AllOrders[0]?.AllData[i]?.Id,
                myStore: data?.AllOrders[0]?.AllData[i]?.store,
                orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
                totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                myProducts: data?.AllOrders[0]?.AllData[i]?.products,
                postedOn: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                lastUpdated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
                isCancelledByRider:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
                isCancelledByCustomer:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
                isCancelledByPartner:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
                isCancelledByAdmin:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
                vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
                vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
              };
              allDataArr.push(newArr);
              // making CSV array
              csvArray.push({
                Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
                Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
                Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
                Store: data?.AllOrders[0]?.AllData[i]?.store,
                Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
                Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
                Posted_On: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                Last_Updated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
              });
            }
            setAllData(allDataArr);
            setCsvData(csvArray);
          }
        }
      } else {
        toast.error(res?.data?.message);
      }
    } else {
      const res = await updateSingleVehicleTypeByVender(id, vehicleId);
      if (res?.data?.success === true) {
        toast.success(res?.data?.message);
        if (isAdmin == true) {
          const { data } = await getAllOrders();
          if (data?.success === true) {
            //if(data?.AllOrders?.length > 0){
            let allDataArr = [],
              csvArray = [];
            for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
              let newArr = {
                orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
                customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
                customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                  ? process.env.REACT_APP_LIVE_SERVER +
                    "/usersImages/" +
                    data?.AllOrders[0]?.AllData[i]?.customerPic
                  : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
                Id: data?.AllOrders[0]?.AllData[i]?.Id,
                myStore: data?.AllOrders[0]?.AllData[i]?.store,
                orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
                totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                myProducts: data?.AllOrders[0]?.AllData[i]?.products,
                postedOn: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                lastUpdated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
                isCancelledByRider:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
                isCancelledByCustomer:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
                isCancelledByPartner:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
                isCancelledByAdmin:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
                vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
                vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
              };
              allDataArr.push(newArr);

              // making CSV array
              csvArray.push({
                Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
                Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
                Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
                Store: data?.AllOrders[0]?.AllData[i]?.store,
                Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
                Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
                Posted_On: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                Last_Updated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
              });
            }
            setAllData(allDataArr);
            setCsvData(csvArray);
          }
        } else {
          const { data } = await getAllOrdersOfVender();
          if (data?.success === true) {
            let allDataArr = [],
              csvArray = [];
            //if(data?.AllOrders[0]?.AllData?.length > 0){
            if(data?.AllOrders[0]?.AllData?.length)
            for (let i = 0; i !== data?.AllOrders[0]?.AllData.length; i++) {
              let newArr = {
                orderNo: data?.AllOrders[0]?.AllData[i]?.refNo,
                customerName: data?.AllOrders[0]?.AllData[i]?.customerName,
                customerPic: data?.AllOrders[0]?.AllData[i]?.customerPic
                  ? process.env.REACT_APP_LIVE_SERVER +
                    "/usersImages/" +
                    data?.AllOrders[0]?.AllData[i]?.customerPic
                  : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png",
                Id: data?.AllOrders[0]?.AllData[i]?.Id,
                myStore: data?.AllOrders[0]?.AllData[i]?.store,
                orderStatus: data?.AllOrders[0]?.AllData[i]?.status,
                totalAmt: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                orderAddress: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                deliveryFee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                myProducts: data?.AllOrders[0]?.AllData[i]?.products,
                postedOn: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                lastUpdated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
                isCancelledByRider:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByRider,
                isCancelledByCustomer:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByCustomer,
                isCancelledByPartner:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByPartner,
                isCancelledByAdmin:
                  data?.AllOrders[0]?.AllData[i]?.isCancelledByAdmin,
                vehicleId: data?.AllOrders[0]?.AllData[i]?.vehicleId,
                vehicleName: data?.AllOrders[0]?.AllData[i]?.vehicleName,
              };
              allDataArr.push(newArr);
              // making CSV array
              csvArray.push({
                Order_No: data?.AllOrders[0]?.AllData[i]?.refNo,
                Customer_Name: data?.AllOrders[0]?.AllData[i]?.customerName,
                Order_Id: data?.AllOrders[0]?.AllData[i]?.Id,
                Store: data?.AllOrders[0]?.AllData[i]?.store,
                Order_Status: data?.AllOrders[0]?.AllData[i]?.status,
                Total_Amount: data?.AllOrders[0]?.AllData[i]?.totalAmt,
                Order_Address: data?.AllOrders[0]?.AllData[i]?.orderAddress,
                isCouponApplied:
                  data?.AllOrders[0]?.AllData[i]?.isCouponApplied == true
                    ? "Applied"
                    : "Not Applied",
                Delivery_Fee: data?.AllOrders[0]?.AllData[i]?.deliveryFee,
                Ordered_Products: data?.AllOrders[0]?.AllData[i]?.products,
                Posted_On: moment(
                  data?.AllOrders[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                Last_Updated: moment(
                  data?.AllOrders[0]?.AllData[i]?.updatedAt
                ).format("DD MMM YY"),
                isPaymentDone:
                  data?.AllOrders[0]?.AllData[i]?.isPaymentDone == false
                    ? "Not Paid"
                    : "Paid",
              });
            }
            setAllData(allDataArr);
            setCsvData(csvArray);
          }
        }
      } else {
        toast.error(res?.data?.message);
      }
    }
  };

  return (
    <>
      {isFetching === true ? (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "auto" }}
        >
          <ThreeDots
            height="60"
            width="60"
            radius="9"
            color="green"
            ariaLabel="three-dots-loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      ) : (
        <DatatableWrapper
          body={allData}
          headers={TABLE_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 10,
              options: [5, 10, 15, 20],
            },
          }}
          onRowClicked={handleRowClicked}
          //className="table-striped table-hover custom-table"
        >
          <Row className="p-4 mb-4">
            <Col
              xs={12}
              lg={2}
              className="flex-col d-flex justify-content-start align-items-start"
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h4>All Orders</h4>
                {csvData?.length > 0 ? (
                  <Button variant="success">
                    <CSVLink
                      data={csvData}
                      style={{ color: "white" }}
                      filename={`PriceApp_Orders${moment().format(
                        "DD-MM-YY, hh:mm a"
                      )}.csv`}
                    >
                      Export
                    </CSVLink>
                  </Button>
                ) : null}
              </div>
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={6}
              className="flex-col mb-2 d-flex justify-content-lg-center align-items-center justify-content-sm-start mb-sm-0"
            >
              <div className="flex md:flex-row space-between flex-col">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "15px",
                  }}
                >
                  <h6>Starting Date:</h6>
                  <input
                    type="date"
                    max={moment().format("YYYY-MM-DD")}
                    value={dateSelector?.startingDate}
                    onChange={(e) =>
                      setDateSelector({
                        ...dateSelector,
                        startingDate: e.target.value,
                      })
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "15px",
                  }}
                >
                  <h6>Ending Date:</h6>
                  <input
                    type="date"
                    max={moment().format("YYYY-MM-DD")}
                    value={dateSelector?.endingDate}
                    onChange={(e) =>
                      setDateSelector({
                        ...dateSelector,
                        endingDate: e.target.value,
                      })
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <Button variant="success">
                    <CSVLink data={csvData} style={{ color: "white" }}>
                      Export
                    </CSVLink>
                  </Button>
                  <Button variant="success" onClick={getFilteredData}>
                    Apply Filters
                  </Button>
                  {dateSelector?.endingDate !== "" ||
                  dateSelector?.startingDate !== "" ? (
                    <Button variant="danger" onClick={clearFilters}>
                      Clear Filters
                    </Button>
                  ) : null}
                </div>
              </div>
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="flex-col d-flex justify-content-end align-items-end"
            >
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Search Orders here..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  onBlur={() => getAllData()}
                  onChange={(e) => setText(e.target.value)}
                />
                <Button variant="info" onClick={getSearchedOrders}>
                  Search
                </Button>
              </InputGroup>
            </Col>
          </Row>
          <Table striped bordered hover className="ml-2 mr-2">
            <TableHeader />
            <TableBody />
          </Table>
          <Row className="p-2 mb-4">
            <Col
              xs={12}
              sm={6}
              lg={6}
              className="flex-col mb-2 d-flex justify-content-lg-start align-items-start justify-content-sm-start mb-sm-0"
            >
              <PaginationOptions />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={6}
              className="flex-col d-flex justify-content-end align-items-end"
            >
              <Pagination />
            </Col>
          </Row>
        </DatatableWrapper>
      )}

      {/* view and update modal */}
      <Modal
        show={view}
        onHide={handleViewClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Order Products Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Image</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Store</th>
              </tr>
            </thead>
            <tbody>
              {prods?.length > 0 ? (
                prods?.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.name}</td>
                    <td>
                      <img
                        alt="product imag"
                        style={{
                          maxWidth: "50px",
                          maxHeight: "50px",
                          borderRadius: "10px",
                        }}
                        src={
                          process.env.REACT_APP_LIVE_SERVER +
                          "/productsImages/" +
                          item?.image
                        }
                      />
                    </td>
                    <td>{item?.price}</td>
                    <td>{item?.qty}</td>
                    <td>{item?.store}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Products Found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleViewClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* view and update modal end */}
    </>
  );
};

export default ViewAllvendors;
