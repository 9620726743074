import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import {
  getAllProducts,
  updateStatusOfAnyProduct,
  getAllStores,
  getAllSubCategoriesForMain,
  getAllCategoriesOfAnyStore,
  getAllSubCategoriesOfAnyCategory,
  getAllProductsOfAnySubCategory,
  getAllParentCategories,
  getAllAdmins,
  getMySingleProductDeatils,
  updateIUmageOfAnyProduct,
  updateOfAnyProduct,
  addNewProduct,
  getAllProductsOfVender,
  addNewProductByVender,
  getAllStoresOfAnyVender,
  getAllParentCategoriesForVenders,
  getAllSubCategoriesOfVender,
  updateStatusOfAnyProductByVender,
  getMySingleProductDetailsByVender,
  updateOfAnyProductByVender,
  updateImageOfAnyProductByVender,
  getProductsWithDateRange,
  getProductsWithDateRangeForAdmin,
} from "../../api/AdminApi";
import { useNavigate, Link } from "react-router-dom";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import {
  Col,
  Row,
  Table,
  InputGroup,
  Form,
  Modal,
  Button,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import { AiOutlineEdit } from "react-icons/ai";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";

const ViewAllvendors = () => {
  const navigate = useNavigate();
  const TABLE_HEADERS = [
    {
      prop: "username",
      title: "Name",
      cell: (prop) => {
        return (
          <span
            style={{
              maxWidth: "50px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {prop?.username}
          </span>
        );
      },
    },
    {
      prop: "profilePic",
      title: "Image",
      cell: (prop) => {
        return (
          <img
            src={prop?.prodImage}
            alt="profile pic"
            style={{ maxWidth: "50px", maxHeight: "50%", borderRadius: "10px" }}
          />
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      prop: "addedOn",
      title: "Added On",
    },
    {
      prop: "price",
      title: "Price",
      sortable: true,
      filterable: true,
    },
    {
      prop: "store",
      title: "StoreName",
    },
    {
      prop: "owner",
      title: "Owner",
    },
    {
      prop: "quantity",
      title: "Quantity",
    },
    {
      prop: "parentCate",
      title: "Parent Category",
    },
    {
      prop: "subCate",
      title: "Sub Category",
    },
    {
      prop: "status",
      title: "Status",
      cell: (prop) => {
        return (
          <Dropdown as={ButtonGroup}>
            {prop?.status == false && (
              <Button
                size="sm"
                variant="danger"
                style={{ fontSize: "11px", fontWeight: 600 }}
              >
                In Active
              </Button>
            )}
            {prop?.status == true && (
              <Button
                size="sm"
                variant="success"
                style={{ fontSize: "11px", fontWeight: 600 }}
              >
                Active
              </Button>
            )}
            <Dropdown.Toggle
              split
              size="sm"
              variant="primary"
              id="dropdown-split-basic"
            />
            <Dropdown.Menu style={{ backgroundColor: "transparent" }}>
              <Dropdown.Item
                onClick={() => changeStatus(prop?.Id)}
                style={{ backgroundColor: "#c23616", color: "white" }}
              >
                Declined
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => changeStatus(prop?.Id)}
                style={{ backgroundColor: "#10ac84", color: "white" }}
              >
                Approved
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      prop: "action",
      title: "Action",
      cell: (prop) => {
        return (
          <Link target="_blank" to={`/product-details/${prop.Id}`}>
            <AiOutlineEdit style={{ cursor: "pointer" }} />
          </Link>
        );
      },
    },
  ];

  const [isFetching, setIsFetching] = useState(false);
  const [selectedMyStore, setSelectedMyStore] = useState("");
  const [selectedMyCate, setSelectedMyCate] = useState("");
  const [isGetting, setIsGetting] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [singleUserData, setSingleUserData] = useState({});
  const [uploadImage, setUploadImage] = useState(null);
  const [allParent, setAllParent] = useState([]);
  const [allSubParent, setAllSubParent] = useState([]);
  const [allParentNew, setAllParentNew] = useState([]);
  const [allSubParentNew, setAllSubParentNew] = useState([]);
  const [allStores, setAllStores] = useState([]);
  const [allVenders, setAllVenders] = useState([]);
  const [allData, setAllData] = useState([]);
  const [text, setText] = useState("");
  // filtering date
  const [dateSelector, setDateSelector] = useState({
    startingDate: "",
    endingDate: "",
  });
  const [csvData, setCsvData] = useState([]);
  // sleeping
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  // checking if signed in or not
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    let adminToken = JSON.parse(
      localStorage.getItem("priceApp-admin-auth-token")
    );
    if (!adminToken) {
      navigate("/signin");
    }
    let isMyAdmin = JSON.parse(localStorage.getItem("isAdmin"));
    if (isMyAdmin == false) {
      setIsAdmin(false);
    } else {
      setIsAdmin(true);
    }
  }, []);

  // getting data
  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      if (isAdmin === true) {
        const { data } = await getAllProducts();
        if (data?.success === true) {
          let allDataArr = [],
            csvArray = [];
          for (let i = 0; i !== data?.AllProducts[0]?.AllData.length; i++) {
            let newArr = {
              username: data?.AllProducts[0]?.AllData[i]?.Name,
              prodImage:
                data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                  ? data?.AllProducts[0]?.AllData[i]?.Image
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/productsImages/" +
                    data?.AllProducts[0]?.AllData[i]?.Image,
              price: data?.AllProducts[0]?.AllData[i]?.Price,
              store: data?.AllProducts[0]?.AllData[i]?.Store,
              owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
              parentCate: data?.AllProducts[0]?.AllData[i]?.CateName,
              subCate: data?.AllProducts[0]?.AllData[i]?.SubCateName,
              quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
              Id: data?.AllProducts[0]?.AllData[i]?.Id,
              addedOn: moment(
                data?.AllProducts[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              age:
                data?.AllProducts[0]?.AllData[i]?.isUnder18 === undefined
                  ? "Any"
                  : data?.AllProducts[0]?.AllData[i]?.isUnder18 == true
                  ? "Any"
                  : "18+",
              status:
                data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            };
            allDataArr.push(newArr);

            // pushing data for CSV
            csvArray.push({
              Product_Name: data?.AllProducts[0]?.AllData[i]?.Name,
              Product_Image:
                data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                  ? data?.AllProducts[0]?.AllData[i]?.Image
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/productsImages/" +
                    data?.AllProducts[0]?.AllData[i]?.Image,
              Product_Price: data?.AllProducts[0]?.AllData[i]?.Price,
              Store: data?.AllProducts[0]?.AllData[i]?.Store,
              Owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
              ParentCategory: data?.AllProducts[0]?.AllData[i]?.CateName,
              SubCategory: data?.AllProducts[0]?.AllData[i]?.SubCateName,
              Quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
              Product_Id: data?.AllProducts[0]?.AllData[i]?.Id,
              Added_On: moment(
                data?.AllProducts[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              Age_Limit:
                data?.AllProducts[0]?.AllData[i]?.isUnder18 === undefined
                  ? "Any"
                  : data?.AllProducts[0]?.AllData[i]?.isUnder18 == true
                  ? "Any"
                  : "18+",
              Status:
                data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            });
          }
          setAllData(allDataArr);
          // setting csv file data
          // let newArr = allDataArr;
          // // newArr.forEach(obj => {
          // //     delete obj['prodImage'];
          // // })
          // csvArray = newArr.map(object => object)
          setCsvData(csvArray);
        }
      } else {
        const { data } = await getAllProductsOfVender();
        if (data?.success === true) {
          let allDataArr = [],
            csvArray = [];
          for (let i = 0; i !== data?.AllProducts[0]?.AllData.length; i++) {
            let newArr = {
              username: data?.AllProducts[0]?.AllData[i]?.Name,
              prodImage:
                data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                  ? data?.AllProducts[0]?.AllData[i]?.Image
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/productsImages/" +
                    data?.AllProducts[0]?.AllData[i]?.Image,
              price: data?.AllProducts[0]?.AllData[i]?.Price,
              store: data?.AllProducts[0]?.AllData[i]?.Store,
              owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
              parentCate: data?.AllProducts[0]?.AllData[i]?.CateName,
              subCate: data?.AllProducts[0]?.AllData[i]?.SubCateName,
              quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
              Id: data?.AllProducts[0]?.AllData[i]?.Id,
              addedOn: moment(
                data?.AllProducts[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              status:
                data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            };
            allDataArr.push(newArr);

            // making CSV array
            csvArray.push({
              Product_Name: data?.AllProducts[0]?.AllData[i]?.Name,
              Product_Image:
                data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                  ? data?.AllProducts[0]?.AllData[i]?.Image
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/productsImages/" +
                    data?.AllProducts[0]?.AllData[i]?.Image,
              Product_Price: data?.AllProducts[0]?.AllData[i]?.Price,
              Store: data?.AllProducts[0]?.AllData[i]?.Store,
              Owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
              ParentCategory: data?.AllProducts[0]?.AllData[i]?.CateName,
              SubCategory: data?.AllProducts[0]?.AllData[i]?.SubCateName,
              Quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
              Product_Id: data?.AllProducts[0]?.AllData[i]?.Id,
              Added_On: moment(
                data?.AllProducts[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              Age_Limit:
                data?.AllProducts[0]?.AllData[i]?.isUnder18 === undefined
                  ? "Any"
                  : data?.AllProducts[0]?.AllData[i]?.isUnder18 == true
                  ? "Any"
                  : "18+",
              Status:
                data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            });
          }
          setAllData(allDataArr);
          // setting csv file data
          // let newArr = allDataArr;
          // newArr.forEach(obj => {
          //     delete obj['profilePic'];
          // })
          //csvArray = newArr.map(object => object)
          setCsvData(csvArray);
        }
      }
      setIsFetching(false);
    };
    getData();
  }, [isAdmin]);

  // changing status of vender
  const changeStatus = async (id) => {
    let isFound = allData.find((item) => item.Id === id);
    if (isFound) {
      if (isAdmin === true) {
        const { data } = await updateStatusOfAnyProduct(id);
        if (data?.success === true) {
          if (isFound.status == false) {
            isFound.status = true;
            toast.success("Product Activated Successfully");
          } else {
            isFound.status = false;
            toast.success("Product InActivated Successfully");
          }
          let newData = allData;
          let finalData = newData.filter((item) =>
            item.Id === id ? isFound : item
          );
          setAllData(finalData);
        } else {
          toast.success(data?.message);
        }
      } else {
        const { data } = await updateStatusOfAnyProductByVender(id);
        if (data?.success === true) {
          if (isFound.status == false) {
            isFound.status = true;
            toast.success("Product Activated Successfully");
          } else {
            isFound.status = false;
            toast.success("Product InActivated Successfully");
          }
          let newData = allData;
          let finalData = newData.filter((item) =>
            item.Id === id ? isFound : item
          );
          setAllData(finalData);
        } else {
          toast.success(data?.message);
        }
      }
    }
  };

  // getting categories + sub categories + venders + stores
  useEffect(() => {
    const getAllCate = async () => {
      if (isAdmin === true) {
        const { data } = await getAllParentCategories();
        if (data?.success === true) {
          setAllParent(data?.AllCategories);
        }
      } else {
        const { data } = await getAllParentCategoriesForVenders();
        if (data?.success === true) {
          setAllParent(data?.AllCategories);
        }
      }
    };
    getAllCate();

    const getAllSubCate = async () => {
      if (isAdmin === true) {
        const { data } = await getAllSubCategoriesForMain();
        if (data?.success === true) {
          setAllSubParent(data?.AllCategories);
        }
      } else {
        const { data } = await getAllSubCategoriesOfVender();
        if (data?.success === true) {
          setAllSubParent(data?.AllCategories);
        }
      }
    };
    getAllSubCate();

    const getAllVenders = async () => {
      const { data } = await getAllAdmins();
      if (data?.success === true) {
        setAllVenders(data?.AllAdmins);
      }
    };
    getAllVenders();

    const getAllMyStores = async () => {
      if (isAdmin === true) {
        const { data } = await getAllStores();
        if (data?.success === true) {
          setAllStores(data?.AllStores);
        }
      } else {
        const { data } = await getAllStoresOfAnyVender();
        if (data?.success === true) {
          setAllStores(data?.AllStores);
        }
      }
    };
    getAllMyStores();
  }, [isAdmin]);

  // view modal
  const [view, setView] = useState(false);
  const handleViewClose = () => {
    setUploadImage(null);
    setView(false);
    setIsSending(false);
  };
  // getting data of user
  const handleView = async (id) => {
    //setView(true);
    //navigate(`/product-details/${id}` ,'_blank')
    // setIsGetting(true)
    // if(isAdmin === true){
    //     const {data} = await getMySingleProductDeatils(id);
    //     if(data?.success === true){
    //         setSingleUserData(data?.Product);
    //     }else{
    //         toast.error(data?.message)
    //     }
    // }else{
    //     const {data} = await getMySingleProductDetailsByVender(id);
    //     if(data?.success === true){
    //         setSingleUserData(data?.Product);
    //     }else{
    //         toast.error(data?.message)
    //     }
    // }
    // setIsGetting(false)
  };

  // updating data of any product
  const updateData = async () => {
    setIsSending(true);
    if (isAdmin === true) {
      const { data } = await updateOfAnyProduct(
        singleUserData._id,
        singleUserData
      );
      if (data?.success === true) {
        toast.success(data?.message);

        // uploading image
        if (uploadImage !== null) {
          let formData = new FormData();
          formData.append("productImage", uploadImage);
          const res = await updateIUmageOfAnyProduct(
            singleUserData._id,
            formData
          );
          if (res?.data?.success === true) {
            toast.success("Image Uploaded SuccessFully");
          } else {
            toast.error("Image Could Not be Uploaded.");
          }
        }
        // getting updated data
        setIsFetching(true);
        const response = await getAllProducts();
        if (response?.data?.success === true) {
          let allDataArr = [],
            csvArray = [];
          for (
            let i = 0;
            i !== response?.data?.AllProducts[0]?.AllData.length;
            i++
          ) {
            let newArr = {
              username: response?.data?.AllProducts[0]?.AllData[i]?.Name,
              prodImage:
                response?.data?.AllProducts[0]?.AllData[i]?.Image?.indexOf(
                  "https"
                ) === 0
                  ? response?.data?.AllProducts[0]?.AllData[i]?.Image
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/productsImages/" +
                    response?.data?.AllProducts[0]?.AllData[i]?.Image,
              price: response?.data?.AllProducts[0]?.AllData[i]?.Price,
              store: response?.data?.AllProducts[0]?.AllData[i]?.Store,
              owner: response?.data?.AllProducts[0]?.AllData[i]?.OwnerName,
              parentCate: response?.data?.AllProducts[0]?.AllData[i]?.CateName,
              subCate: response?.data?.AllProducts[0]?.AllData[i]?.SubCateName,
              Id: response?.data?.AllProducts[0]?.AllData[i]?.Id,
              addedOn: moment(
                response?.data?.AllProducts[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              status:
                response?.data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? response?.data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            };
            allDataArr.push(newArr);

            // making CSV array
            csvArray.push({
              Product_Name: response?.data?.AllProducts[0]?.AllData[i]?.Name,
              Product_Image:
                response?.data?.AllProducts[0]?.AllData[i]?.Image?.indexOf(
                  "https"
                ) === 0
                  ? response?.data?.AllProducts[0]?.AllData[i]?.Image
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/productsImages/" +
                    response?.data?.AllProducts[0]?.AllData[i]?.Image,
              Product_Price: response?.data?.AllProducts[0]?.AllData[i]?.Price,
              Store: response?.data?.AllProducts[0]?.AllData[i]?.Store,
              Owner: response?.data?.AllProducts[0]?.AllData[i]?.OwnerName,
              ParentCategory:
                response?.data?.AllProducts[0]?.AllData[i]?.CateName,
              SubCategory:
                response?.data?.AllProducts[0]?.AllData[i]?.SubCateName,
              Quantity: response?.data?.AllProducts[0]?.AllData[i]?.quantity,
              Product_Id: response?.data?.AllProducts[0]?.AllData[i]?.Id,
              Added_On: moment(
                response?.data?.AllProducts[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              Age_Limit:
                response?.data?.AllProducts[0]?.AllData[i]?.isUnder18 ===
                undefined
                  ? "Any"
                  : response?.data?.AllProducts[0]?.AllData[i]?.isUnder18 ==
                    true
                  ? "Any"
                  : "18+",
              Status:
                response?.data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? response?.data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            });
          }
          setAllData(allDataArr);
          // setting csv file data
          // let newArr = allDataArr;
          // newArr.forEach(obj => {
          //     delete obj['profilePic'];
          // })
          // csvArray = newArr.map(object => object)
          setCsvData(csvArray);
        }
        setIsFetching(false);

        await delay(1500);
        handleViewClose();
      } else {
        toast.error(data?.message);
      }
    } else {
      const { data } = await updateOfAnyProductByVender(
        singleUserData._id,
        singleUserData
      );
      if (data?.success === true) {
        toast.success(data?.message);

        // uploading image
        if (uploadImage !== null) {
          let formData = new FormData();
          formData.append("productImage", uploadImage);
          const res = await updateImageOfAnyProductByVender(
            singleUserData._id,
            formData
          );
          console.log("image upload response : ", res?.data);
          if (res?.data?.success === true) {
            toast.success("Image Uploaded SuccessFully");
          } else {
            toast.error("Image Could Not be Uploaded.");
          }
        }
        // getting updated data
        setIsFetching(true);
        const response = await getAllProductsOfVender();
        if (response?.data?.success === true) {
          let allDataArr = [],
            csvArray = [];
          for (
            let i = 0;
            i !== response?.data?.AllProducts[0]?.AllData.length;
            i++
          ) {
            let newArr = {
              username: response?.data?.AllProducts[0]?.AllData[i]?.Name,
              prodImage:
                response?.data?.AllProducts[0]?.AllData[i]?.Image?.indexOf(
                  "https"
                ) === 0
                  ? response?.data?.AllProducts[0]?.AllData[i]?.Image
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/productsImages/" +
                    response?.data?.AllProducts[0]?.AllData[i]?.Image,
              price: response?.data?.AllProducts[0]?.AllData[i]?.Price,
              store: response?.data?.AllProducts[0]?.AllData[i]?.Store,
              owner: response?.data?.AllProducts[0]?.AllData[i]?.OwnerName,
              parentCate: response?.data?.AllProducts[0]?.AllData[i]?.CateName,
              subCate: response?.data?.AllProducts[0]?.AllData[i]?.SubCateName,
              quantity: response?.data?.AllProducts[0]?.AllData[i]?.quantity,
              Id: response?.data?.AllProducts[0]?.AllData[i]?.Id,
              status:
                response?.data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? response?.data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            };
            allDataArr.push(newArr);

            // making CSV array
            csvArray.push({
              Product_Name: response?.data?.AllProducts[0]?.AllData[i]?.Name,
              Product_Image:
                response?.data?.AllProducts[0]?.AllData[i]?.Image?.indexOf(
                  "https"
                ) === 0
                  ? response?.data?.AllProducts[0]?.AllData[i]?.Image
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/productsImages/" +
                    response?.data?.AllProducts[0]?.AllData[i]?.Image,
              Product_Price: response?.data?.AllProducts[0]?.AllData[i]?.Price,
              Store: response?.data?.AllProducts[0]?.AllData[i]?.Store,
              Owner: response?.data?.AllProducts[0]?.AllData[i]?.OwnerName,
              ParentCategory:
                response?.data?.AllProducts[0]?.AllData[i]?.CateName,
              SubCategory:
                response?.data?.AllProducts[0]?.AllData[i]?.SubCateName,
              Quantity: response?.data?.AllProducts[0]?.AllData[i]?.quantity,
              Product_Id: response?.data?.AllProducts[0]?.AllData[i]?.Id,
              Added_On: moment(
                response?.data?.AllProducts[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              Age_Limit:
                response?.data?.AllProducts[0]?.AllData[i]?.isUnder18 ===
                undefined
                  ? "Any"
                  : response?.data?.AllProducts[0]?.AllData[i]?.isUnder18 ==
                    true
                  ? "Any"
                  : "18+",
              Status:
                response?.data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? response?.data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            });
          }
          setAllData(allDataArr);
          setCsvData(csvArray);
        }
        setIsFetching(false);

        await delay(1500);
        handleViewClose();
      } else {
        toast.error(data?.message);
      }
    }
    setIsSending(false);
  };

  // sending data object
  const [userData, setUserData] = useState({
    productName: "",
    desc: "",
    currentPrice: "",
    image: "",
    store: "",
    //owner : "",
    parentCategory: "",
    categories: "",
    isUnder18: true,
    isTopTen: false,
    isSpecial: false,
    quantity: 1,
  });

  // add new modal
  const [addNew, setAddNew] = useState(false);
  const handleAddNewClose = () => {
    setUploadImage(null);
    setUserData({
      productName: "",
      desc: "",
      currentPrice: "",
      image: "",
      store: "",
      owner: "",
      parentCategory: "",
      categories: "",
      isUnder18: true,
      isTopTen: false,
      isSpecial: false,
    });
    setAddNew(false);
    setSelectedMyCate("");
    setSelectedMyStore("");
    setAllParentNew([]);
    setAllSubParentNew([]);
    setIsSending(false);
  };
  // getting data of user
  const handleAddNew = async (id) => {
    setAddNew(true);
  };

  // sending data of any product
  const addNewVender = async () => {
    setIsSending(true);
    if (userData?.image === "") {
      toast.warning("Image Of Product is Required");
      return;
    }
    let formData = new FormData();
    formData.append("productName", userData?.productName);
    formData.append("productImage", userData?.image);
    formData.append("desc", userData?.desc);
    formData.append("currentPrice", userData?.currentPrice);
    formData.append("store", userData?.store);
    formData.append("parentCategory", userData?.parentCategory);
    formData.append("isUnder18", userData?.isUnder18);
    formData.append("isSpecial", userData?.isSpecial);
    formData.append("isTopTen", userData?.isTopTen);
    formData.append("categories", userData?.categories);
    formData.append("quantity", userData?.quantity);
    if (isAdmin === true) {
      const { data } = await addNewProduct(formData);
      if (data?.success === true) {
        formData.append("owner", userData?.owner);
        toast.success("New Product Added SuccessFully");
        setUserData({
          productName: "",
          desc: "",
          currentPrice: "",
          image: "",
          store: "",
          //owner : "",
          parentCategory: "",
          categories: "",
          isUnder18: true,
          isTopTen: false,
          isSpecial: false,
        });
        setUploadImage(null);

        setIsFetching(true);
        if (isAdmin === true) {
          const res = await getAllProducts();
          if (res?.data?.success === true) {
            let allDataArr = [],
              csvArray = [];
            for (
              let i = 0;
              i !== res?.data?.AllProducts[0]?.AllData.length;
              i++
            ) {
              let newArr = {
                username: res?.data?.AllProducts[0]?.AllData[i]?.Name,
                prodImage:
                  res?.data?.AllProducts[0]?.AllData[i]?.Image?.indexOf(
                    "https"
                  ) === 0
                    ? res?.data?.AllProducts[0]?.AllData[i]?.Image
                    : process.env.REACT_APP_LIVE_SERVER +
                      "/productsImages/" +
                      res?.data?.AllProducts[0]?.AllData[i]?.Image,
                price: res?.data?.AllProducts[0]?.AllData[i]?.Price,
                store: res?.data?.AllProducts[0]?.AllData[i]?.Store,
                owner: res?.data?.AllProducts[0]?.AllData[i]?.OwnerName,
                parentCate: res?.data?.AllProducts[0]?.AllData[i]?.CateName,
                subCate: res?.data?.AllProducts[0]?.AllData[i]?.SubCateName,
                quantity: res?.data?.AllProducts[0]?.AllData[i]?.quantity,
                Id: res?.data?.AllProducts[0]?.AllData[i]?.Id,
                addedOn: moment(
                  res?.data?.AllProducts[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                age:
                  res?.data?.AllProducts[0]?.AllData[i]?.isUnder18 === undefined
                    ? "Any"
                    : res?.data?.AllProducts[0]?.AllData[i]?.isUnder18 == true
                    ? "Any"
                    : "18+",
                status:
                  res?.data?.AllProducts[0]?.AllData[i]?.status !== undefined
                    ? res?.data?.AllProducts[0]?.AllData[i]?.status
                    : true,
              };
              allDataArr.push(newArr);

              // pushing data for CSV
              csvArray.push({
                Product_Name: res?.data?.AllProducts[0]?.AllData[i]?.Name,
                Product_Image:
                  res?.data?.AllProducts[0]?.AllData[i]?.Image?.indexOf(
                    "https"
                  ) === 0
                    ? res?.data?.AllProducts[0]?.AllData[i]?.Image
                    : process.env.REACT_APP_LIVE_SERVER +
                      "/productsImages/" +
                      res?.data?.AllProducts[0]?.AllData[i]?.Image,
                Product_Price: res?.data?.AllProducts[0]?.AllData[i]?.Price,
                Store: res?.data?.AllProducts[0]?.AllData[i]?.Store,
                Owner: res?.data?.AllProducts[0]?.AllData[i]?.OwnerName,
                ParentCategory: res?.data?.AllProducts[0]?.AllData[i]?.CateName,
                SubCategory: res?.data?.AllProducts[0]?.AllData[i]?.SubCateName,
                Quantity: res?.data?.AllProducts[0]?.AllData[i]?.quantity,
                Product_Id: res?.data?.AllProducts[0]?.AllData[i]?.Id,
                Added_On: moment(
                  res?.data?.AllProducts[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                Age_Limit:
                  res?.data?.AllProducts[0]?.AllData[i]?.isUnder18 === undefined
                    ? "Any"
                    : res?.data?.AllProducts[0]?.AllData[i]?.isUnder18 == true
                    ? "Any"
                    : "18+",
                Status:
                  res?.data?.AllProducts[0]?.AllData[i]?.status !== undefined
                    ? res?.data?.AllProducts[0]?.AllData[i]?.status
                    : true,
              });
            }
            setAllData(allDataArr);
            // setting csv file data
            //let newArr = allDataArr;
            // newArr.forEach(obj => {
            //     delete obj['prodImage'];
            // })
            //csvArray = newArr.map(object => object)
            setCsvData(csvArray);
          }
        } else {
          const res = await getAllProductsOfVender();
          if (res?.data?.success === true) {
            let allDataArr = [],
              csvArray = [];
            for (
              let i = 0;
              i !== res?.data?.AllProducts[0]?.AllData.length;
              i++
            ) {
              let newArr = {
                username: res?.data?.AllProducts[0]?.AllData[i]?.Name,
                prodImage:
                  res?.data?.AllProducts[0]?.AllData[i]?.Image?.indexOf(
                    "https"
                  ) === 0
                    ? res?.data?.AllProducts[0]?.AllData[i]?.Image
                    : process.env.REACT_APP_LIVE_SERVER +
                      "/productsImages/" +
                      res?.data?.AllProducts[0]?.AllData[i]?.Image,
                price: res?.data?.AllProducts[0]?.AllData[i]?.Price,
                store: res?.data?.AllProducts[0]?.AllData[i]?.Store,
                owner: res?.data?.AllProducts[0]?.AllData[i]?.OwnerName,
                parentCate: res?.data?.AllProducts[0]?.AllData[i]?.CateName,
                subCate: res?.data?.AllProducts[0]?.AllData[i]?.SubCateName,
                quantity: res?.data?.AllProducts[0]?.AllData[i]?.quantity,
                Id: res?.data?.AllProducts[0]?.AllData[i]?.Id,
                addedOn: moment(
                  res?.data?.AllProducts[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                status:
                  res?.data?.AllProducts[0]?.AllData[i]?.status !== undefined
                    ? res?.data?.AllProducts[0]?.AllData[i]?.status
                    : true,
              };
              allDataArr.push(newArr);
              // pushing data for CSV
              csvArray.push({
                Product_Name: res?.data?.AllProducts[0]?.AllData[i]?.Name,
                Product_Image:
                  res?.data?.AllProducts[0]?.AllData[i]?.Image?.indexOf(
                    "https"
                  ) === 0
                    ? res?.data?.AllProducts[0]?.AllData[i]?.Image
                    : process.env.REACT_APP_LIVE_SERVER +
                      "/productsImages/" +
                      res?.data?.AllProducts[0]?.AllData[i]?.Image,
                Product_Price: res?.data?.AllProducts[0]?.AllData[i]?.Price,
                Store: res?.data?.AllProducts[0]?.AllData[i]?.Store,
                Owner: res?.data?.AllProducts[0]?.AllData[i]?.OwnerName,
                ParentCategory: res?.data?.AllProducts[0]?.AllData[i]?.CateName,
                SubCategory: res?.data?.AllProducts[0]?.AllData[i]?.SubCateName,
                Quantity: res?.data?.AllProducts[0]?.AllData[i]?.quantity,
                Product_Id: res?.data?.AllProducts[0]?.AllData[i]?.Id,
                Added_On: moment(
                  res?.data?.AllProducts[0]?.AllData[i]?.createdAt
                ).format("DD MMM YY"),
                Age_Limit:
                  res?.data?.AllProducts[0]?.AllData[i]?.isUnder18 === undefined
                    ? "Any"
                    : res?.data?.AllProducts[0]?.AllData[i]?.isUnder18 == true
                    ? "Any"
                    : "18+",
                Status:
                  res?.data?.AllProducts[0]?.AllData[i]?.status !== undefined
                    ? res?.data?.AllProducts[0]?.AllData[i]?.status
                    : true,
              });
            }
            setAllData(allDataArr);
            // // setting csv file data
            // let newArr = allDataArr;
            // newArr.forEach(obj => {
            //     delete obj['profilePic'];
            // })
            // csvArray = newArr.map(object => object)
            setCsvData(csvArray);
          }
        }
        setIsFetching(false);

        await delay(1000);
        handleAddNewClose();
      } else {
        toast.error(data?.message);
      }
    } else {
      const { data } = await addNewProductByVender(formData);
      if (data?.success === true) {
        formData.append("owner", userData?.owner);
        toast.success("New Product Added SuccessFully");
        setUserData({
          productName: "",
          desc: "",
          currentPrice: "",
          image: "",
          store: "",
          //owner : "",
          parentCategory: "",
          categories: "",
          isUnder18: true,
          isTopTen: false,
          isSpecial: false,
        });
        setUploadImage(null);

        setIsFetching(true);
        const res = await getAllProductsOfVender();
        if (res?.data?.success === true) {
          let allDataArr = [],
            csvArray = [];
          for (
            let i = 0;
            i !== res?.data?.AllProducts[0]?.AllData.length;
            i++
          ) {
            let newArr = {
              username: res?.data?.AllProducts[0]?.AllData[i]?.Name,
              profilePic: res?.data?.AllProducts[0]?.AllData[i]?.Image,
              price: res?.data?.AllProducts[0]?.AllData[i]?.Price,
              store: res?.data?.AllProducts[0]?.AllData[i]?.Store,
              owner: res?.data?.AllProducts[0]?.AllData[i]?.OwnerName,
              parentCate: res?.data?.AllProducts[0]?.AllData[i]?.CateName,
              subCate: res?.data?.AllProducts[0]?.AllData[i]?.SubCateName,
              Id: res?.data?.AllProducts[0]?.AllData[i]?.Id,
              status:
                res?.data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? res?.data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            };
            allDataArr.push(newArr);
          }
          setAllData(allDataArr);
          // setting csv file data
          let newArr = allDataArr;
          newArr.forEach((obj) => {
            delete obj["profilePic"];
          });
          csvArray = newArr.map((object) => object);
          setCsvData(csvArray);
        }
        setIsFetching(false);

        await delay(1000);
        handleAddNewClose();
      } else {
        toast.error(data?.message);
      }
    }
    setIsSending(false);
  };

  // sorting data of table on clicking
  const onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, "Do MMMM YYYY").valueOf();
    },
  };

  // getting all data on blur
  const getAllData = async () => {
    if (text?.length < 1) {
      setIsFetching(true);
      if (isAdmin === true) {
        const { data } = await getAllProducts();
        if (data?.success === true) {
          let allDataArr = [],
            csvArray = [];
          for (let i = 0; i !== data?.AllProducts[0]?.AllData.length; i++) {
            let newArr = {
              username: data?.AllProducts[0]?.AllData[i]?.Name,
              profilePic:
                data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                  ? data?.AllProducts[0]?.AllData[i]?.Image
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/productsImages/" +
                    data?.AllProducts[0]?.AllData[i]?.Image,
              price: data?.AllProducts[0]?.AllData[i]?.Price,
              store: data?.AllProducts[0]?.AllData[i]?.Store,
              owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
              parentCate: data?.AllProducts[0]?.AllData[i]?.CateName,
              quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
              subCate: data?.AllProducts[0]?.AllData[i]?.SubCateName,
              Id: data?.AllProducts[0]?.AllData[i]?.Id,
              addedOn: moment(
                data?.AllProducts[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              status:
                data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            };
            allDataArr.push(newArr);

            // making CSV array
            csvArray.push({
              Product_Name: data?.AllProducts[0]?.AllData[i]?.Name,
              Product_Image:
                data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                  ? data?.AllProducts[0]?.AllData[i]?.Image
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/productsImages/" +
                    data?.AllProducts[0]?.AllData[i]?.Image,
              Product_Price: data?.AllProducts[0]?.AllData[i]?.Price,
              Store: data?.AllProducts[0]?.AllData[i]?.Store,
              Owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
              ParentCategory: data?.AllProducts[0]?.AllData[i]?.CateName,
              SubCategory: data?.AllProducts[0]?.AllData[i]?.SubCateName,
              Quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
              Product_Id: data?.AllProducts[0]?.AllData[i]?.Id,
              Added_On: moment(
                data?.AllProducts[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              Age_Limit:
                data?.AllProducts[0]?.AllData[i]?.isUnder18 === undefined
                  ? "Any"
                  : data?.AllProducts[0]?.AllData[i]?.isUnder18 == true
                  ? "Any"
                  : "18+",
              Status:
                data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            });
          }
          setAllData(allDataArr);
          // // setting csv file data
          // let newArr = allDataArr;
          // newArr.forEach(obj => {
          //     delete obj['profilePic'];
          // })
          // csvArray = newArr.map(object => object)
          setCsvData(csvArray);
        }
      } else {
        const { data } = await getAllProductsOfVender();
        if (data?.success === true) {
          let allDataArr = [],
            csvArray = [];
          for (let i = 0; i !== data?.AllProducts[0]?.AllData.length; i++) {
            let newArr = {
              username: data?.AllProducts[0]?.AllData[i]?.Name,
              prodImage:
                data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                  ? data?.AllProducts[0]?.AllData[i]?.Image
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/productsImages/" +
                    data?.AllProducts[0]?.AllData[i]?.Image,
              price: data?.AllProducts[0]?.AllData[i]?.Price,
              store: data?.AllProducts[0]?.AllData[i]?.Store,
              quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
              owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
              parentCate: data?.AllProducts[0]?.AllData[i]?.CateName,
              subCate: data?.AllProducts[0]?.AllData[i]?.SubCateName,
              Id: data?.AllProducts[0]?.AllData[i]?.Id,
              status:
                data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            };
            allDataArr.push(newArr);
            // making CSV array
            csvArray.push({
              Product_Name: data?.AllProducts[0]?.AllData[i]?.Name,
              Product_Image:
                data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                  ? data?.AllProducts[0]?.AllData[i]?.Image
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/productsImages/" +
                    data?.AllProducts[0]?.AllData[i]?.Image,
              Product_Price: data?.AllProducts[0]?.AllData[i]?.Price,
              Store: data?.AllProducts[0]?.AllData[i]?.Store,
              Owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
              ParentCategory: data?.AllProducts[0]?.AllData[i]?.CateName,
              SubCategory: data?.AllProducts[0]?.AllData[i]?.SubCateName,
              Quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
              Product_Id: data?.AllProducts[0]?.AllData[i]?.Id,
              Added_On: moment(
                data?.AllProducts[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              Age_Limit:
                data?.AllProducts[0]?.AllData[i]?.isUnder18 === undefined
                  ? "Any"
                  : data?.AllProducts[0]?.AllData[i]?.isUnder18 == true
                  ? "Any"
                  : "18+",
              Status:
                data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            });
          }
          //csvArray = allDataArr.map(object => object)
          setCsvData(csvArray);
          setAllData(allDataArr);
        }
      }
      setIsFetching(false);
    }
  };

  // getting searched item
  const getSearchedProducts = async () => {
    if (text?.length > 2) {
      // getting all data for searching
      if (isAdmin === true) {
        const { data } = await getAllProducts();
        if (data?.success === true) {
          let allDataArr = [],
            csvArray = [];
          for (let i = 0; i !== data?.AllProducts[0]?.AllData.length; i++) {
            let newArr = {
              username: data?.AllProducts[0]?.AllData[i]?.Name,
              profilePic:
                data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                  ? data?.AllProducts[0]?.AllData[i]?.Image
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/productsImages/" +
                    data?.AllProducts[0]?.AllData[i]?.Image,
              price: data?.AllProducts[0]?.AllData[i]?.Price,
              store: data?.AllProducts[0]?.AllData[i]?.Store,
              quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
              owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
              parentCate: data?.AllProducts[0]?.AllData[i]?.CateName,
              subCate: data?.AllProducts[0]?.AllData[i]?.SubCateName,
              Id: data?.AllProducts[0]?.AllData[i]?.Id,
              addedOn: moment(
                data?.AllProducts[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              status:
                data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            };
            allDataArr.push(newArr);
            // making CSV array
            csvArray.push({
              Product_Name: data?.AllProducts[0]?.AllData[i]?.Name,
              Product_Image:
                data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                  ? data?.AllProducts[0]?.AllData[i]?.Image
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/productsImages/" +
                    data?.AllProducts[0]?.AllData[i]?.Image,
              Product_Price: data?.AllProducts[0]?.AllData[i]?.Price,
              Store: data?.AllProducts[0]?.AllData[i]?.Store,
              Owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
              ParentCategory: data?.AllProducts[0]?.AllData[i]?.CateName,
              SubCategory: data?.AllProducts[0]?.AllData[i]?.SubCateName,
              Quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
              Product_Id: data?.AllProducts[0]?.AllData[i]?.Id,
              Added_On: moment(
                data?.AllProducts[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              Age_Limit:
                data?.AllProducts[0]?.AllData[i]?.isUnder18 === undefined
                  ? "Any"
                  : data?.AllProducts[0]?.AllData[i]?.isUnder18 == true
                  ? "Any"
                  : "18+",
              Status:
                data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            });
          }
          setAllData(allDataArr);
          // // setting csv file data
          // let newArr = allDataArr;
          // newArr.forEach(obj => {
          //     delete obj['profilePic'];
          // })
          // csvArray = newArr.map(object => object)
          setCsvData(csvArray);
        }
      } else {
        const { data } = await getAllProductsOfVender();
        if (data?.success === true) {
          let allDataArr = [],
            csvArray = [];
          for (let i = 0; i !== data?.AllProducts[0]?.AllData.length; i++) {
            let newArr = {
              username: data?.AllProducts[0]?.AllData[i]?.Name,
              prodImage:
                data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                  ? data?.AllProducts[0]?.AllData[i]?.Image
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/productsImages/" +
                    data?.AllProducts[0]?.AllData[i]?.Image,
              price: data?.AllProducts[0]?.AllData[i]?.Price,
              store: data?.AllProducts[0]?.AllData[i]?.Store,
              quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
              owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
              parentCate: data?.AllProducts[0]?.AllData[i]?.CateName,
              subCate: data?.AllProducts[0]?.AllData[i]?.SubCateName,
              Id: data?.AllProducts[0]?.AllData[i]?.Id,
              status:
                data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            };
            allDataArr.push(newArr);

            // making CSV array
            csvArray.push({
              Product_Name: data?.AllProducts[0]?.AllData[i]?.Name,
              Product_Image:
                data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                  ? data?.AllProducts[0]?.AllData[i]?.Image
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/productsImages/" +
                    data?.AllProducts[0]?.AllData[i]?.Image,
              Product_Price: data?.AllProducts[0]?.AllData[i]?.Price,
              Store: data?.AllProducts[0]?.AllData[i]?.Store,
              Owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
              ParentCategory: data?.AllProducts[0]?.AllData[i]?.CateName,
              SubCategory: data?.AllProducts[0]?.AllData[i]?.SubCateName,
              Quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
              Product_Id: data?.AllProducts[0]?.AllData[i]?.Id,
              Added_On: moment(
                data?.AllProducts[0]?.AllData[i]?.createdAt
              ).format("DD MMM YY"),
              Age_Limit:
                data?.AllProducts[0]?.AllData[i]?.isUnder18 === undefined
                  ? "Any"
                  : data?.AllProducts[0]?.AllData[i]?.isUnder18 == true
                  ? "Any"
                  : "18+",
              Status:
                data?.AllProducts[0]?.AllData[i]?.status !== undefined
                  ? data?.AllProducts[0]?.AllData[i]?.status
                  : true,
            });
          }
          setAllData(allDataArr);
          // // setting csv file data
          // let newArr = allDataArr;
          // newArr.forEach(obj => {
          //     delete obj['profilePic'];
          // })
          // csvArray = newArr.map(object => object)
          setCsvData(csvArray);
        }
      }
      let myText = text.toLocaleLowerCase();
      let newArr = [];
      for (let i = 0; i !== allData.length; i++) {
        if (
          allData[i]?.username?.toLowerCase()?.includes(myText) === true ||
          allData[i]?.owner?.toLowerCase()?.includes(myText) === true ||
          allData[i]?.parentCate?.toLowerCase()?.includes(myText) === true ||
          allData[i]?.price == text ||
          allData[i]?.store?.toLowerCase()?.includes(myText) === true
        ) {
          newArr.push(allData[i]);
        }
      }
      setText("");
      setAllData(newArr);
    }
  };

  // getting all categories of any store
  useEffect(() => {
    const getAllCatesOfAnyStore = async () => {
      const { data } = await getAllCategoriesOfAnyStore(selectedMyStore);
      if (data?.success === true) {
        setAllParentNew(data?.AllCategories);
      } else {
        setAllParentNew([]);
      }
    };
    if (selectedMyStore !== "") {
      getAllCatesOfAnyStore();
    }
  }, [selectedMyStore]);

  // getting all sub categories of any store
  useEffect(() => {
    const getAllCatesOfAnyStore = async () => {
      const { data } = await getAllSubCategoriesOfAnyCategory(selectedMyCate);
      if (data?.success === true) {
        setAllSubParentNew(data?.AllSubCategories);
      } else {
        setAllSubParentNew([]);
      }
    };
    if (selectedMyCate !== "") {
      getAllCatesOfAnyStore();
    }
  }, [selectedMyCate]);

  // clearing filters
  const clearFilters = async () => {
    setDateSelector({ startingDate: "", endingDate: "" });
    setIsFetching(true);
    if (isAdmin === true) {
      const { data } = await getAllProducts();
      if (data?.success === true) {
        let allDataArr = [],
          csvArray = [];
        for (let i = 0; i !== data?.AllProducts[0]?.AllData.length; i++) {
          let newArr = {
            username: data?.AllProducts[0]?.AllData[i]?.Name,
            profilePic:
              data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                ? data?.AllProducts[0]?.AllData[i]?.Image
                : process.env.REACT_APP_LIVE_SERVER +
                  "/productsImages/" +
                  data?.AllProducts[0]?.AllData[i]?.Image,
            price: data?.AllProducts[0]?.AllData[i]?.Price,
            store: data?.AllProducts[0]?.AllData[i]?.Store,
            quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
            owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
            parentCate: data?.AllProducts[0]?.AllData[i]?.CateName,
            subCate: data?.AllProducts[0]?.AllData[i]?.SubCateName,
            Id: data?.AllProducts[0]?.AllData[i]?.Id,
            addedOn: moment(data?.AllProducts[0]?.AllData[i]?.createdAt).format(
              "DD MMM YY"
            ),
            status:
              data?.AllProducts[0]?.AllData[i]?.status !== undefined
                ? data?.AllProducts[0]?.AllData[i]?.status
                : true,
          };
          allDataArr.push(newArr);

          // making CSV array
          csvArray.push({
            Product_Name: data?.AllProducts[0]?.AllData[i]?.Name,
            Product_Image:
              data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                ? data?.AllProducts[0]?.AllData[i]?.Image
                : process.env.REACT_APP_LIVE_SERVER +
                  "/productsImages/" +
                  data?.AllProducts[0]?.AllData[i]?.Image,
            Product_Price: data?.AllProducts[0]?.AllData[i]?.Price,
            Store: data?.AllProducts[0]?.AllData[i]?.Store,
            Owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
            ParentCategory: data?.AllProducts[0]?.AllData[i]?.CateName,
            SubCategory: data?.AllProducts[0]?.AllData[i]?.SubCateName,
            Quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
            Product_Id: data?.AllProducts[0]?.AllData[i]?.Id,
            Added_On: moment(
              data?.AllProducts[0]?.AllData[i]?.createdAt
            ).format("DD MMM YY"),
            Age_Limit:
              data?.AllProducts[0]?.AllData[i]?.isUnder18 === undefined
                ? "Any"
                : data?.AllProducts[0]?.AllData[i]?.isUnder18 == true
                ? "Any"
                : "18+",
            Status:
              data?.AllProducts[0]?.AllData[i]?.status !== undefined
                ? data?.AllProducts[0]?.AllData[i]?.status
                : true,
          });
        }
        setAllData(allDataArr);
        // // setting csv file data
        // let newArr = allDataArr;
        // newArr.forEach(obj => {
        //     delete obj['profilePic'];
        // })
        // csvArray = newArr.map(object => object)
        setCsvData(csvArray);
      }
    } else {
      const { data } = await getAllProductsOfVender();
      if (data?.success === true) {
        let allDataArr = [],
          csvArray = [];
        for (let i = 0; i !== data?.AllProducts[0]?.AllData.length; i++) {
          let newArr = {
            username: data?.AllProducts[0]?.AllData[i]?.Name,
            prodImage:
              data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                ? data?.AllProducts[0]?.AllData[i]?.Image
                : process.env.REACT_APP_LIVE_SERVER +
                  "/productsImages/" +
                  data?.AllProducts[0]?.AllData[i]?.Image,
            price: data?.AllProducts[0]?.AllData[i]?.Price,
            store: data?.AllProducts[0]?.AllData[i]?.Store,
            owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
            quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
            parentCate: data?.AllProducts[0]?.AllData[i]?.CateName,
            subCate: data?.AllProducts[0]?.AllData[i]?.SubCateName,
            Id: data?.AllProducts[0]?.AllData[i]?.Id,
            addedOn: moment(data?.AllProducts[0]?.AllData[i]?.createdAt).format(
              "DD MMM YY"
            ),
            status:
              data?.AllProducts[0]?.AllData[i]?.status !== undefined
                ? data?.AllProducts[0]?.AllData[i]?.status
                : true,
          };
          allDataArr.push(newArr);

          // making CSV array
          csvArray.push({
            Product_Name: data?.AllProducts[0]?.AllData[i]?.Name,
            Product_Image:
              data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                ? data?.AllProducts[0]?.AllData[i]?.Image
                : process.env.REACT_APP_LIVE_SERVER +
                  "/productsImages/" +
                  data?.AllProducts[0]?.AllData[i]?.Image,
            Product_Price: data?.AllProducts[0]?.AllData[i]?.Price,
            Store: data?.AllProducts[0]?.AllData[i]?.Store,
            Owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
            ParentCategory: data?.AllProducts[0]?.AllData[i]?.CateName,
            SubCategory: data?.AllProducts[0]?.AllData[i]?.SubCateName,
            Quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
            Product_Id: data?.AllProducts[0]?.AllData[i]?.Id,
            Added_On: moment(
              data?.AllProducts[0]?.AllData[i]?.createdAt
            ).format("DD MMM YY"),
            Age_Limit:
              data?.AllProducts[0]?.AllData[i]?.isUnder18 === undefined
                ? "Any"
                : data?.AllProducts[0]?.AllData[i]?.isUnder18 == true
                ? "Any"
                : "18+",
            Status:
              data?.AllProducts[0]?.AllData[i]?.status !== undefined
                ? data?.AllProducts[0]?.AllData[i]?.status
                : true,
          });
        }
        setAllData(allDataArr);
        // // setting csv file data
        // let newArr = allDataArr;
        // newArr.forEach(obj => {
        //     delete obj['profilePic'];
        // })
        // csvArray = newArr.map(object => object)
        setCsvData(csvArray);
      }
    }
    setIsFetching(false);
  };

  // getting filtered data
  const getFilteredData = async () => {
    setIsFetching(true);
    if (isAdmin === false) {
      const { data } = await getProductsWithDateRange(dateSelector);
      //if(data?.success === true){
      //if(data?.data?.AllProducts[0]?.length > 0){
      let allDataArr = [],
        csvArray = [];
      for (let i = 0; i !== data?.AllProducts[0]?.AllData.length; i++) {
        let newArr = {
          username: data?.AllProducts[0]?.AllData[i]?.Name,
          prodImage: data?.AllProducts[0]?.AllData[i]?.Image,
          price: data?.AllProducts[0]?.AllData[i]?.Price,
          store: data?.AllProducts[0]?.AllData[i]?.Store,
          owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
          parentCate: data?.AllProducts[0]?.AllData[i]?.CateName,
          subCate: data?.AllProducts[0]?.AllData[i]?.SubCateName,
          Id: data?.AllProducts[0]?.AllData[i]?.Id,
          addedOn: moment(data?.AllProducts[0]?.AllData[i]?.createdAt).format(
            "DD MMM YY"
          ),
          status:
            data?.AllProducts[0]?.AllData[i]?.status !== undefined
              ? data?.AllProducts[0]?.AllData[i]?.status
              : true,
        };
        allDataArr.push(newArr);

        // making CSV array
        csvArray.push({
          Product_Name: data?.AllProducts[0]?.AllData[i]?.Name,
          Product_Image:
            data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
              ? data?.AllProducts[0]?.AllData[i]?.Image
              : process.env.REACT_APP_LIVE_SERVER +
                "/productsImages/" +
                data?.AllProducts[0]?.AllData[i]?.Image,
          Product_Price: data?.AllProducts[0]?.AllData[i]?.Price,
          Store: data?.AllProducts[0]?.AllData[i]?.Store,
          Owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
          ParentCategory: data?.AllProducts[0]?.AllData[i]?.CateName,
          SubCategory: data?.AllProducts[0]?.AllData[i]?.SubCateName,
          Quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
          Product_Id: data?.AllProducts[0]?.AllData[i]?.Id,
          Added_On: moment(data?.AllProducts[0]?.AllData[i]?.createdAt).format(
            "DD MMM YY"
          ),
          Age_Limit:
            data?.AllProducts[0]?.AllData[i]?.isUnder18 === undefined
              ? "Any"
              : data?.AllProducts[0]?.AllData[i]?.isUnder18 == true
              ? "Any"
              : "18+",
          Status:
            data?.AllProducts[0]?.AllData[i]?.status !== undefined
              ? data?.AllProducts[0]?.AllData[i]?.status
              : true,
        });
      }
      setAllData(allDataArr);
      // // setting csv file data
      // let newArr = allDataArr;
      // newArr.forEach(obj => {
      //     delete obj['profilePic'];
      // })
      // csvArray = newArr.map(object => object)
      setCsvData(csvArray);
      //}else{
      //setAllData([])
      //toast.warning("No Record Found")
      //}
      //}else{
      //toast.error(data?.message)
      //setAllData([])
      //}
    } else {
      const { data } = await getProductsWithDateRangeForAdmin(dateSelector);
      if (data?.success === true) {
        //if(data?.AllOrders?.length > 0){
        let allDataArr = [],
          csvArray = [];
        for (let i = 0; i !== data?.AllProducts[0]?.AllData.length; i++) {
          let newArr = {
            username: data?.AllProducts[0]?.AllData[i]?.Name,
            prodImage: data?.AllProducts[0]?.AllData[i]?.Image,
            price: data?.AllProducts[0]?.AllData[i]?.Price,
            quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
            store: data?.AllProducts[0]?.AllData[i]?.Store,
            owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
            parentCate: data?.AllProducts[0]?.AllData[i]?.CateName,
            subCate: data?.AllProducts[0]?.AllData[i]?.SubCateName,
            Id: data?.AllProducts[0]?.AllData[i]?.Id,
            addedOn: moment(data?.AllProducts[0]?.AllData[i]?.createdAt).format(
              "DD MMM YY"
            ),
            status:
              data?.AllProducts[0]?.AllData[i]?.status !== undefined
                ? data?.AllProducts[0]?.AllData[i]?.status
                : true,
          };
          allDataArr.push(newArr);

          // making CSV array
          csvArray.push({
            Product_Name: data?.AllProducts[0]?.AllData[i]?.Name,
            Product_Image:
              data?.AllProducts[0]?.AllData[i]?.Image?.indexOf("https") === 0
                ? data?.AllProducts[0]?.AllData[i]?.Image
                : process.env.REACT_APP_LIVE_SERVER +
                  "/productsImages/" +
                  data?.AllProducts[0]?.AllData[i]?.Image,
            Product_Price: data?.AllProducts[0]?.AllData[i]?.Price,
            Store: data?.AllProducts[0]?.AllData[i]?.Store,
            Owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
            ParentCategory: data?.AllProducts[0]?.AllData[i]?.CateName,
            SubCategory: data?.AllProducts[0]?.AllData[i]?.SubCateName,
            Quantity: data?.AllProducts[0]?.AllData[i]?.quantity,
            Product_Id: data?.AllProducts[0]?.AllData[i]?.Id,
            Added_On: moment(
              data?.AllProducts[0]?.AllData[i]?.createdAt
            ).format("DD MMM YY"),
            Age_Limit:
              data?.AllProducts[0]?.AllData[i]?.isUnder18 === undefined
                ? "Any"
                : data?.AllProducts[0]?.AllData[i]?.isUnder18 == true
                ? "Any"
                : "18+",
            Status:
              data?.AllProducts[0]?.AllData[i]?.status !== undefined
                ? data?.AllProducts[0]?.AllData[i]?.status
                : true,
          });
        }
        setAllData(allDataArr);
        // // setting csv file data
        // let newArr = allDataArr;
        // newArr.forEach(obj => {
        //     delete obj['profilePic'];
        // })
        // csvArray = newArr.map(object => object)
        setCsvData(csvArray);
      } else {
        toast.warning("No Record Found");
        setAllData([]);
      }
      //}else{
      //toast.error(data?.message)
      //setAllData([])
      //}
    }
    setIsFetching(false);
  };

  return (
    <>
      {isFetching === true ? (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "auto" }}
        >
          <ThreeDots
            height="60"
            width="60"
            radius="9"
            color="green"
            ariaLabel="three-dots-loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      ) : (
        <DatatableWrapper
          body={allData}
          headers={TABLE_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 10,
              options: [5, 10, 15, 20],
            },
          }}
          initialSort={{ prop: "price", isAscending: false }}
          onSort={onSortFunction}
          classes={{
            table: "table-striped table-hover custom-table",
          }}
        >
          <Row className="p-4 mb-4">
            <Col
              xs={12}
              md={12}
              xl={2}
            //   className="flex-row d-flex justify-between"
            >
                <h4>All Products</h4>
                {csvData?.length > 0 ? (
                  <Button variant="success">
                    <CSVLink
                      data={csvData}
                      style={{ color: "white" }}
                      filename={`PriceApp_Products${moment().format(
                        "DD-MM-YY, hh:mm a"
                      )}.csv`}
                    >
                      Export
                    </CSVLink>
                  </Button>
                ) : null}
            </Col>
            <Col xs={12} sm={12} lg={12} xl={6} className="mb-2">
              <Row className="md:pt-4">
                {/* <div className="flex md:flex-row space-between flex-col" > */}
                <Col xs={12} sm={12} md={12} xl={2}  >
                    <div  className="p-2">
                  <Button
                    size="lg"
                    variant="info"
                    // className="md:m-10 xs:m-2"
                    onClick={handleAddNew}
                    style={{ fontSize: "15px", fontWeight: 600 }}
                  >
                    Add New
                  </Button>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={6} xl={3} className="border mr-2 p-2">
                  <h6>Starting Date:</h6>
                  <input
                    type="date"
                    max={moment().format("YYYY-MM-DD")}
                    value={dateSelector?.startingDate}
                    onChange={(e) =>
                      setDateSelector({
                        ...dateSelector,
                        startingDate: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={12} sm={12} md={6} xl={3} className="border mr-2 p-2">
                  <h6>Ending Date:</h6>
                  <input
                    type="date"
                    max={moment().format("YYYY-MM-DD")}
                    value={dateSelector?.endingDate}
                    onChange={(e) =>
                      setDateSelector({
                        ...dateSelector,
                        endingDate: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={12} sm={12} md={12} xl={2} className="p-2">
                  {/* <Button variant="success" ><CSVLink data={csvData} style={{color : "white"}} >Export</CSVLink></Button> */}
                  <Button variant="success" onClick={getFilteredData}>
                    Apply Filters
                  </Button>
                  {dateSelector?.endingDate !== "" ||
                  dateSelector?.startingDate !== "" ? (
                    <Button variant="danger" onClick={clearFilters}>
                      Clear Filters
                    </Button>
                  ) : null}
                </Col>
                {/* </div> */}
              </Row>
            </Col>
            <Col
              xs={12}
              sm={12}
              lg={12}
              xl={4}
              className="flex-col d-flex justify-content-end align-items-end"
            >
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Search any Product ..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  onBlur={() => getAllData()}
                  onChange={(e) => setText(e.target.value)}
                />
                <Button variant="info" onClick={getSearchedProducts}>
                  Search
                </Button>
              </InputGroup>
            </Col>
          </Row>
          <Table striped bordered hover className="ml-2 mr-2">
            <TableHeader />
            <TableBody />
          </Table>
          <Row className="p-2 mb-4">
            <Col
              xs={12}
              sm={6}
              lg={6}
              className="flex-col mb-2 d-flex justify-content-lg-start align-items-start justify-content-sm-start mb-sm-0"
            >
              <PaginationOptions />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={6}
              className="flex-col d-flex justify-content-end align-items-end"
            >
              <Pagination />
            </Col>
          </Row>
        </DatatableWrapper>
      )}

      {/* view and update modal */}
      <Modal
        show={view}
        onHide={handleViewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        //style={{marginTop : '100px'}}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isGetting === true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <ThreeDots
                height="60"
                width="60"
                radius="9"
                color="green"
                ariaLabel="three-dots-loading"
                wrapperStyle
                wrapperClass
              />
            </div>
          ) : (
            <div className="flex justify-center">
              <div className="flex justify-start  flex-col align-center pb-4 px-2 w-[100%] pt-3">
                <Row>
                  <Col
                    sm={12}
                    md={6}
                    className="flex flex-col pt-5 pl-3 font-semibold"
                  >
                    <img
                      src={
                        singleUserData?.productImage?.indexOf("https") === 0
                          ? singleUserData?.productImage
                          : process.env.REACT_APP_LIVE_SERVER +
                            "/productsImages/" +
                            singleUserData?.productImage
                      }
                      alt="cover pic"
                      style={{
                        maxWidth: "80px",
                        maxHeight: "80px",
                        borderRadius: "10px",
                      }}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      onChange={(e) => setUploadImage(e.target.files[0])}
                    />
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    className="flex flex-col pt-5 pl-3 font-semibold"
                  >
                    {uploadImage !== null && (
                      <div className="flex justify-between mt-4">
                        <img
                          src={URL.createObjectURL(uploadImage)}
                          alt="cover pic"
                          style={{
                            maxWidth: "80px",
                            maxHeight: "80px",
                            borderRadius: "10px",
                          }}
                        />
                        <button
                          className="bg-[#e74c3c] mt-3 rounded-md p-2 text-white font-semibold mx-auto max-h-[40px]"
                          onClick={() => setUploadImage(null)}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row style={{ width: "100%" }}>
                  <Col
                    sm={12}
                    md={6}
                    className="md:min-w-[300px] min-w-[100%]  "
                  >
                    <div className="flex flex-col pt-5  font-semibold">
                      <h6>Product Name:</h6>
                      <input
                        type="text"
                        placeholder="Enter user name ..."
                        className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                        style={{ border: "1px solid black" }}
                        value={singleUserData?.productName}
                        onChange={(e) =>
                          setSingleUserData({
                            ...singleUserData,
                            productName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    className="md:min-w-[300px] min-w-[100%]  "
                  >
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6>Description:</h6>
                      <textArea
                        rows="5"
                        className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                        style={{ border: "1px solid black" }}
                        onChange={(e) =>
                          setSingleUserData({
                            ...singleUserData,
                            desc: e.target.value,
                          })
                        }
                      >
                        {singleUserData?.desc}{" "}
                      </textArea>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm={12}
                    md={6}
                    className="md:min-w-[300px] min-w-[100%]  "
                  >
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6>Current Price:</h6>
                      <input
                        type="number"
                        placeholder="Enter user name ..."
                        className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                        style={{ border: "1px solid black" }}
                        value={singleUserData?.currentPrice}
                        onChange={(e) =>
                          setSingleUserData({
                            ...singleUserData,
                            currentPrice: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    className="md:min-w-[300px] min-w-[100%]  "
                  >
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6>Quantity:</h6>
                      <input
                        type="number"
                        placeholder="Enter user name ..."
                        className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                        style={{ border: "1px solid black" }}
                        value={singleUserData?.quantity}
                        onChange={(e) =>
                          setSingleUserData({
                            ...singleUserData,
                            quantity: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm={12}
                    md={6}
                    className="md:min-w-[300px] min-w-[100%]  "
                  >
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6>Rating:</h6>
                      <input
                        type="number"
                        className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                        style={{ border: "1px solid black" }}
                        value={
                          singleUserData?.rating &&
                          singleUserData?.rating[
                            "$numberDecimal"
                          ]?.toLocaleString()
                        }
                        disabled={true}
                      />
                    </div>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    className="md:min-w-[300px] min-w-[100%]  "
                  >
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6>is Special:</h6>
                      {singleUserData?.isSpecial == true ? (
                        <Form.Check
                          type="switch"
                          checked
                          id="custom-switch"
                          label="Remove from Special"
                          onChange={(e) =>
                            setSingleUserData({
                              ...singleUserData,
                              isSpecial: false,
                            })
                          }
                        />
                      ) : (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="make this Special"
                          onChange={(e) =>
                            setSingleUserData({
                              ...singleUserData,
                              isSpecial: true,
                            })
                          }
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm={12}
                    md={6}
                    className="md:min-w-[300px] min-w-[100%]  "
                  >
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6>is in Top 10:</h6>
                      {singleUserData?.isTopTen == true ? (
                        <Form.Check
                          type="switch"
                          checked
                          id="custom-switch"
                          label="Remove from Top 10"
                          onChange={(e) =>
                            setSingleUserData({
                              ...singleUserData,
                              isTopTen: false,
                            })
                          }
                        />
                      ) : (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Add to from Top 10"
                          onChange={(e) =>
                            setSingleUserData({
                              ...singleUserData,
                              isTopTen: true,
                            })
                          }
                        />
                      )}
                    </div>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    className="md:min-w-[300px] min-w-[100%]  "
                  >
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6> Store:</h6>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) =>
                          setSingleUserData({
                            ...singleUserData,
                            store: e.target.value,
                          })
                        }
                      >
                        <option selected disabled></option>
                        {allStores?.length > 0 ? (
                          allStores?.map((item) =>
                            singleUserData?.store == item?.Data?.Id ? (
                              <option value={item?.Data?.Id} selected>
                                {item?.Data?.Name}
                              </option>
                            ) : (
                              <option value={item?.Data?.Id}>
                                {item?.Data?.Name}
                              </option>
                            )
                          )
                        ) : (
                          <option>No Stores Found</option>
                        )}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm={12}
                    md={6}
                    className="md:min-w-[300px] min-w-[100%]  "
                  >
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6> Vender:</h6>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) =>
                          setSingleUserData({
                            ...singleUserData,
                            owner: e.target.value,
                          })
                        }
                      >
                        <option selected disabled></option>
                        {allVenders?.length > 0 ? (
                          allVenders?.map((item) =>
                            singleUserData?.owner == item?._id ? (
                              <option value={item?._id} selected>
                                {item?.username}
                              </option>
                            ) : (
                              <option value={item?._id}>
                                {item?.username}
                              </option>
                            )
                          )
                        ) : (
                          <option>No Venders Found</option>
                        )}
                      </select>
                    </div>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    className="md:min-w-[300px] min-w-[100%]  "
                  >
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6> Parent Category:</h6>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) =>
                          setSingleUserData({
                            ...singleUserData,
                            parentCategory: e.target.value,
                          })
                        }
                      >
                        <option selected disabled></option>
                        {allParent?.length > 0 ? (
                          allParent?.map((item) =>
                            singleUserData?.parentCategory == item?._id ? (
                              <option value={item?._id} selected>
                                {item?.name}
                              </option>
                            ) : (
                              <option value={item?._id}>{item?.name}</option>
                            )
                          )
                        ) : (
                          <option>No Parent Categories Found</option>
                        )}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm={12}
                    md={6}
                    className="md:min-w-[300px] min-w-[100%]  "
                  >
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6> Sub Category:</h6>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) =>
                          setSingleUserData({
                            ...singleUserData,
                            categories: e.target.value,
                          })
                        }
                      >
                        <option selected disabled></option>
                        {allSubParent?.length > 0 ? (
                          allSubParent?.map((item) =>
                            singleUserData?.categories == item?._id ? (
                              <option value={item?._id} selected>
                                {item?.name}
                              </option>
                            ) : (
                              <option value={item?._id}>{item?.name}</option>
                            )
                          )
                        ) : (
                          <option>No Sub Categories Found</option>
                        )}
                      </select>
                    </div>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    className="md:min-w-[300px] min-w-[100%]  "
                  >
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6>Is product Under 18</h6>
                      {singleUserData?.isUnder18 == true ? (
                        <Form.Check
                          type="switch"
                          checked
                          id="custom-switch"
                          label="Is Product under 18"
                          onChange={(e) =>
                            setSingleUserData({
                              ...singleUserData,
                              isUnder18: false,
                            })
                          }
                        />
                      ) : (
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Is Product under 18"
                          onChange={(e) =>
                            setSingleUserData({
                              ...singleUserData,
                              isUnder18: true,
                            })
                          }
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isSending === true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <ThreeDots
                height="60"
                width="60"
                radius="9"
                color="green"
                ariaLabel="three-dots-loading"
                wrapperStyle
                wrapperClass
              />
            </div>
          ) : (
            <>
              <Button variant="danger" onClick={handleViewClose}>
                Close
              </Button>
              <Button variant="success" onClick={updateData}>
                Update Now
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      {/* view and update modal end */}

      {/* add new modal */}
      <Modal
        show={addNew}
        onHide={handleAddNewClose}
        backdrop="static"
        keyboard={false}
        //style={{marginTop : '100px'}}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-center">
            <div className="flex justify-start flex-col align-center pb-4 w-[100%] px-2 mx-auto pt-3">
              <h6 className="text-2xl font-bold text-center">
                Add New Product
              </h6>
              <Row>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Product Name:</h6>
                    <input
                      type="text"
                      placeholder="Enter user name ..."
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      style={{ border: "1px solid black" }}
                      value={userData?.productName}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          productName: e.target.value,
                        })
                      }
                    />
                  </div>
                </Col>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Description:</h6>
                    <textArea
                      placeholder="Enter description ..."
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      rows="5"
                      style={{ border: "1px solid black" }}
                      value={userData?.desc}
                      onChange={(e) =>
                        setUserData({ ...userData, desc: e.target.value })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Current Price:</h6>
                    <input
                      type="number"
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      value={userData?.currentPrice}
                      style={{ border: "1px solid black" }}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          currentPrice: e.target.value,
                        })
                      }
                    />
                  </div>
                </Col>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Quantity:</h6>
                    <input
                      type="number"
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      value={userData?.quantity}
                      style={{ border: "1px solid black" }}
                      onChange={(e) =>
                        setUserData({ ...userData, quantity: e.target.value })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Image:</h6>
                    <input
                      type="file"
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      style={{ border: "1px solid black" }}
                      onChange={(e) =>
                        setUserData({ ...userData, image: e.target.files[0] })
                      }
                    />
                  </div>
                </Col>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  {userData?.image !== "" && (
                    <div className="flex justify-between mt-4 mb-5">
                      <img
                        src={URL.createObjectURL(userData?.image)}
                        alt="cover pic"
                        style={{
                          maxWidth: "80px",
                          maxHeight: "80px",
                          borderRadius: "10px",
                        }}
                      />
                      <button
                        className="bg-[#e74c3c] mt-3 rounded-md p-2 text-white font-semibold mx-auto max-h-[40px]"
                        onClick={(e) => setUserData({ ...userData, image: "" })}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>is Special:</h6>
                    {singleUserData?.isSpecial == true ? (
                      <Form.Check
                        type="switch"
                        checked
                        id="custom-switch"
                        label="Remove from Special"
                        onChange={(e) =>
                          setUserData({ ...userData, isSpecial: false })
                        }
                      />
                    ) : (
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="make this Special"
                        onChange={(e) =>
                          setUserData({ ...userData, isSpecial: true })
                        }
                      />
                    )}
                  </div>
                </Col>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>is in Top 10:</h6>
                    {singleUserData?.isTopTen == true ? (
                      <Form.Check
                        type="switch"
                        checked
                        id="custom-switch"
                        label="Remove from Top 10"
                        onChange={(e) =>
                          setUserData({ ...userData, isTopTen: false })
                        }
                      />
                    ) : (
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Add to from Top 10"
                        onChange={(e) =>
                          setUserData({ ...userData, isTopTen: true })
                        }
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Choose Store:</h6>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setUserData({ ...userData, store: e.target.value });
                        setSelectedMyStore(e.target.value);
                      }}
                    >
                      <option selected disabled></option>
                      {allStores?.length > 0 ? (
                        allStores?.map((item) => (
                          <option value={item?.Data?.Id}>
                            {item?.Data?.Name}
                          </option>
                        ))
                      ) : (
                        <option>No Stores Found</option>
                      )}
                    </select>
                  </div>
                </Col>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  {allParentNew?.length > 0 ? (
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6>Choose Parent Category:</h6>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            parentCategory: e.target.value,
                          });
                          setSelectedMyCate(e.target.value);
                        }}
                      >
                        <option selected disabled></option>
                        {allParentNew?.length > 0 ? (
                          allParentNew?.map((item) => (
                            <option value={item?._id}>{item?.name}</option>
                          ))
                        ) : (
                          <option>No Parent Categories Found</option>
                        )}
                      </select>
                    </div>
                  ) : (
                    <h6 style={{ marginTop: "20px" }}>
                      Please Choose Any Store For Parent Categories
                    </h6>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  {allSubParentNew?.length > 0 ? (
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6>Choose Sub Categories:</h6>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            categories: e.target.value,
                          })
                        }
                      >
                        <option selected disabled></option>
                        {allSubParentNew?.length > 0 ? (
                          allSubParentNew?.map((item) => (
                            <option value={item?._id}>{item?.name}</option>
                          ))
                        ) : (
                          <option>No Sub Categories Found</option>
                        )}
                      </select>
                    </div>
                  ) : (
                    <h6 style={{ marginTop: "20px" }}>
                      Please Choose Any Category For Sub Categories
                    </h6>
                  )}
                </Col>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Is product Under 18</h6>
                    {singleUserData?.isUnder18 == true ? (
                      <Form.Check
                        type="switch"
                        checked
                        id="custom-switch"
                        label="Is Product under 18"
                        onChange={(e) =>
                          setUserData({ ...userData, isUnder18: false })
                        }
                      />
                    ) : (
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Is Product under 18"
                        onChange={(e) =>
                          setUserData({ ...userData, isUnder18: true })
                        }
                      />
                    )}
                  </div>
                </Col>
              </Row>
              {/* {
                                    isAdmin === true && (
                                        <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                            <h6>Choose Vendor:</h6>
                                            <select class="form-select" aria-label="Default select example" onChange={(e) => setUserData({...userData , owner : e.target.value})}>
                                                <option selected disabled></option>
                                                {
                                                    allVenders?.length > 0 ? (
                                                        allVenders?.map((item) => (
                                                            <option value={item?._id}>{item?.username}</option>
                                                        ))
                                                    ) : (
                                                        <option >No Venders Found</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    )
                                } */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isSending === true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <ThreeDots
                height="60"
                width="60"
                radius="9"
                color="green"
                ariaLabel="three-dots-loading"
                wrapperStyle
                wrapperClass
              />
            </div>
          ) : (
            <>
              <Button variant="danger" onClick={handleAddNewClose}>
                Close
              </Button>
              <Button variant="success" onClick={addNewVender}>
                Add Now
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      {/* add new modal end */}
    </>
  );
};

export default ViewAllvendors;
