import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import {
  getAllStores,
  addNewStore,
  updateStatusOfAnyStore,
  updateIUmageOfAnyStore,
  getAllSubCategoriesOfAnyCategory,
  getAllParentCategories,
  getAllSubCategories,
  getAllAdmins,
  getMySingleStoreDeatils,
  updateOfAnyStore,
  getAllStoresOfAnyVender,
  addNewStoreByVender,
  getAllParentCategoriesForVenders,
  getAllSubCategoriesOfVender,
  updateOfAnyStoreByVender,
  updateStatusOfAnyStoreByVender,
  updateImageOfAnyStoreByVender,
  updateStatusOpenOfAnyStore,
} from "../../api/AdminApi";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import {
  Col,
  Row,
  Table,
  InputGroup,
  Form,
  Modal,
  Button,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import { AiOutlineEdit } from "react-icons/ai";
import moment from "moment";

const ViewAllvendors = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const TABLE_HEADERS = [
    {
      prop: "name",
      title: "Store Name",
      sortable: true,
    },
    {
      prop: "logo",
      title: "Logo",
      cell: (prop) => {
        return (
          <img
            src={prop?.logo}
            alt="profile pic"
            style={{ maxWidth: "50px", maxHeight: "50%", borderRadius: "10px" }}
          />
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      prop: "address",
      title: "Address",
      sortable: true,
    },
    // {
    //     prop: "category",
    //     title: "Category"
    // },
    {
      prop: "related",
      title: "Related Products",
      cell: (prop) => {
        return (
          <a
            target="_blank"
            style={{ textDecoration: "none" }}
            href={`/view-all-products-of-store/${prop.name}/${prop.Id}`}
          >
            view All
          </a>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      prop: "status",
      title: "Status",
      cell: (prop) => {
        return (
          <Dropdown as={ButtonGroup}>
            {prop?.status == false && (
              <Button
                size="sm"
                variant="danger"
                style={{ fontSize: "11px", fontWeight: 600 }}
              >
                Block
              </Button>
            )}
            {prop?.status == true && (
              <Button
                size="sm"
                variant="success"
                style={{ fontSize: "11px", fontWeight: 600 }}
              >
                Activate
              </Button>
            )}
            <Dropdown.Toggle
              split
              size="sm"
              variant="primary"
              id="dropdown-split-basic"
            />
            <Dropdown.Menu style={{ backgroundColor: "transparent" }}>
              <Dropdown.Item
                onClick={() => changeStatus(prop?.Id)}
                style={{ backgroundColor: "#c23616", color: "white" }}
              >
                Block
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => changeStatus(prop?.Id)}
                style={{ backgroundColor: "#10ac84", color: "white" }}
              >
                Activate
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      prop: "open",
      title: "Open/Close",
      cell: (prop) => {
        return (
          <Dropdown as={ButtonGroup}>
            {!prop?.open && (
              <Button
                size="sm"
                variant="danger"
                style={{ fontSize: "11px", fontWeight: 600 }}
              >
                Close
              </Button>
            )}
            {prop?.open && (
              <Button
                size="sm"
                variant="success"
                style={{ fontSize: "11px", fontWeight: 600 }}
              >
                Open
              </Button>
            )}
            <Dropdown.Toggle
              split
              size="sm"
              variant="primary"
              id="dropdown-open-close"
            />
            <Dropdown.Menu
            style={{ backgroundColor: "transparent" }}
            >
              <Dropdown.Item
                style={{ backgroundColor: "#10ac84", color: "white" }}
                onClick={() => changeStatusOpen(prop?.Id)}
              >
                Open
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => changeStatusOpen(prop?.Id)}
                style={{ backgroundColor: "#c23616", color: "white" }}
              >
                Close
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      prop: "action",
      title: "Action",
      cell: (prop) => {
        return (
          <Link target="_blank" to={`/store-details/${prop.Id}`}>
            <AiOutlineEdit style={{ cursor: "pointer" }} />
          </Link>
        );
      },
    },
  ];

  const [isFetching, setIsFetching] = useState(false);
  const [isGetting, setIsGetting] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [singleUserData, setSingleUserData] = useState({});
  const [uploadImage, setUploadImage] = useState(null);
  const [allParent, setAllParent] = useState([]);
  const [allSubParent, setAllSubParent] = useState([]);
  const [allSubParentNew, setAllSubParentNew] = useState([]);
  const [allVenders, setAllVenders] = useState([]);
  const [allData, setAllData] = useState([]);
  // sleeping
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  // checking if signed in or not
  const [isAdmin, setIsAdmin] = useState(false);
  const [allSelectedCates, setSelectedCates] = useState([]);
  useEffect(() => {
    console.log("calling inside of stores");
    let adminToken = JSON.parse(
      localStorage.getItem("priceApp-admin-auth-token")
    );
    if (!adminToken) {
      navigate("/signin");
    }
    let isMyAdmin = JSON.parse(localStorage.getItem("isAdmin"));
    if (isMyAdmin === false) {
      setIsAdmin(false);
    } else {
      setIsAdmin(true);
    }
  }, []);

  // getting data
  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      if (isAdmin === true) {
        const { data } = await getAllStores();
        if (data?.success === true) {
          let allDataArr = [];
          for (let i = 0; i !== data?.AllStores.length; i++) {
            let newArr = {
              name: data?.AllStores[i]?.Data?.Name,
              logo:
                data?.AllStores[i]?.Data?.Logo?.indexOf("https") === 0
                  ? data?.AllStores[i]?.Data?.Logo
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/storeImages/" +
                    data?.AllStores[i]?.Data?.Logo,
              address: data?.AllStores[i]?.Data?.Address,
              Id: data?.AllStores[i]?.Data?.Id,
              open: data?.AllStores[i]?.Data?.open,
              status:
                data?.AllStores[i]?.Data?.status !== undefined
                  ? data?.AllStores[i]?.Data?.status
                  : true,
            };
            allDataArr.push(newArr);
          }
          setAllData(allDataArr);
        }
      } else {
        const { data } = await getAllStoresOfAnyVender();
        if (data?.success === true) {
          let allDataArr = [];
          for (let i = 0; i !== data?.AllStores.length; i++) {
            let newArr = {
              name: data?.AllStores[i]?.Data?.Name,
              logo:
                data?.AllStores[i]?.Data?.Logo?.indexOf("https") === 0
                  ? data?.AllStores[i]?.Data?.Logo
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/storeImages/" +
                    data?.AllStores[i]?.Data?.Logo,
              address: data?.AllStores[i]?.Data?.Address,
              open: data?.AllStores[i]?.Data?.open,
              //category: data?.AllStores[i]?.Data?.CateName,
              Id: data?.AllStores[i]?.Data?.Id,
              status:
                data?.AllStores[i]?.Data?.status !== undefined
                  ? data?.AllStores[i]?.Data?.status
                  : true,
            };
            allDataArr.push(newArr);
          }
          setAllData(allDataArr);
        }
      }
      setIsFetching(false);
    };
    getData();
  }, [isAdmin]);

  // getting all categories + sub categories + venders
  useEffect(() => {
    const getAllCate = async () => {
      if (isAdmin === true) {
        const { data } = await getAllParentCategories();
        if (data?.success === true) {
          setAllParent(data?.AllCategories);
        }
      } else {
        const { data } = await getAllParentCategoriesForVenders();
        if (data?.success === true) {
          setAllParent(data?.AllCategories);
        }
      }
    };
    getAllCate();

    // const getAllSubCate = async () => {
    //     if(isAdmin === true){
    //         const {data} = await getAllSubCategories();
    //         if(data?.success === true){
    //             setAllSubParent(data?.AllCategories)
    //         }
    //     }else{
    //         const {data} = await getAllSubCategoriesOfVender();
    //         if(data?.success === true){
    //             setAllSubParent(data?.AllCategories)
    //         }
    //     }
    // }
    // getAllSubCate()

    const getAllVenders = async () => {
      const { data } = await getAllAdmins();
      if (data?.success === true) {
        setAllVenders(data?.AllAdmins);
      }
    };
    getAllVenders();
  }, [isAdmin]);

  // changing status of vender
  const changeStatus = async (id) => {
    let isFound = allData.find((item) => item.Id === id);
    if (isFound) {
      if (isAdmin === true) {
        const { data } = await updateStatusOfAnyStore(id);
        if (data?.success === true) {
          if (isFound.status == false) {
            isFound.status = true;
            toast.success("Store Activated Successfully");
          } else {
            isFound.status = false;
            toast.success("Store InActivated Successfully");
          }
          let newData = allData;
          let finalData = newData.filter((item) =>
            item.Id === id ? isFound : item
          );
          setAllData(finalData);
        } else {
          toast.success(data?.message);
        }
      } else {
        const { data } = await updateStatusOfAnyStoreByVender(id);
        if (data?.success === true) {
          if (isFound.status == false) {
            isFound.status = true;
            toast.success("Store Activated Successfully");
          } else {
            isFound.status = false;
            toast.success("Store InActivated Successfully");
          }
          let newData = allData;
          let finalData = newData.filter((item) =>
            item.Id === id ? isFound : item
          );
          setAllData(finalData);
        } else {
          toast.success(data?.message);
        }
      }
    }
  };

  // changing status of vender
  const changeStatusOpen = async (id) => {
    let isFound = allData.find((item) => item.Id === id);
    if (isFound) {
      const { data } = await updateStatusOpenOfAnyStore(id);
      if (data?.success === true) {
        if (!isFound.open) {
          isFound.open = true;
          toast.success("Store Activated Successfully");
        } else {
          isFound.open = false;
          toast.success("Store InActivated Successfully");
        }
        let newData = allData;
        let finalData = newData.filter((item) =>
          item.Id === id ? isFound : item
        );
        setAllData(finalData);
      } else {
        toast.success(data?.message);
      }
    }
  };

  // viuew modal
  const [view, setView] = useState(false);
  const handleViewClose = () => {
    setUploadImage(null);
    setView(false);
    setAllSubParent([]);
    setIsSending(false);
    setIsSending(false);
  };
  // getting data of user
  const handleView = async (id) => {
    // setView(true);
    // setIsGetting(true)
    // const {data} = await getMySingleStoreDeatils(id);
    // if(data?.success === true){
    //     setSingleUserData(data?.Stores);
    //     // getting data of new sub categories
    //     if(isAdmin === true){
    //         const res = await getAllSubCategories({categories : data?.Stores?.categories});
    //         if(res?.data?.success === true){
    //             setAllSubParent(res?.data?.AllSubCategories)
    //         }
    //     }else{
    //         const res = await getAllSubCategoriesOfVender({categories : data?.Stores?.categories});
    //         if(res?.data?.success === true){
    //             setAllSubParent(res?.data?.AllSubCategories)
    //         }
    //     }
    //     // getting all sub categories of related parent category
    //     for(let i = 0; i !== data?.Stores?.categories.length; i++){
    //         await getRelatedSub(data?.Stores?.categories[i])
    //     }
    // }else{
    //     toast.error(data?.message)
    // }
    // setIsGetting(false)
  };

  // updating data
  const updateData = async () => {
    setIsSending(true);
    if (isAdmin === true) {
      const { data } = await updateOfAnyStore(
        singleUserData._id,
        singleUserData
      );
      if (data?.success === true) {
        toast.success(data?.message);

        // uploading image
        if (uploadImage !== null) {
          let formData = new FormData();
          formData.append("storeImage", uploadImage);
          const res = await updateIUmageOfAnyStore(
            singleUserData._id,
            formData
          );
          console.log("response of image uploading : ", res?.data);
          if (res?.data?.success === true) {
            toast.success("Image Uploaded SuccessFully");
          } else {
            toast.error("Image Could Not be Uploaded.");
          }
        }
        // getting updated data
        setIsFetching(true);
        const response = await getAllStores();
        if (response?.data?.success === true) {
          let allDataArr = [];
          for (let i = 0; i !== response?.data?.AllStores.length; i++) {
            let newArr = {
              name: response?.data?.AllStores[i]?.Data?.Name,
              logo:
                response?.data?.AllStores[i]?.Data?.Logo.indexOf("https") === 0
                  ? response?.data?.AllStores[i]?.Data?.Logo
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/storeImages/" +
                    response?.data?.AllStores[i]?.Data?.Logo,
              address: response?.data?.AllStores[i]?.Data?.Address,
              //category: response?.data?.AllStores[i]?.Data?.CateName,
              Id: response?.data?.AllStores[i]?.Data?.Id,
              status:
                response?.data?.AllStores[i]?.Data?.status !== undefined
                  ? response?.data?.AllStores[i]?.Data?.status
                  : true,
            };
            allDataArr.push(newArr);
          }
          setAllData(allDataArr);
        }
        setIsFetching(false);

        await delay(1500);
        handleViewClose();
      } else {
        toast.error(data?.message);
      }
    } else {
      const { data } = await updateOfAnyStoreByVender(
        singleUserData._id,
        singleUserData
      );
      if (data?.success === true) {
        toast.success(data?.message);

        // uploading image
        if (uploadImage !== null) {
          let formData = new FormData();
          formData.append("storeImage", uploadImage);
          const res = await updateImageOfAnyStoreByVender(
            singleUserData._id,
            formData
          );
          console.log("response of image uploading : ", res?.data);
          if (res?.data?.success === true) {
            toast.success("Image Uploaded SuccessFully");
          } else {
            toast.error("Image Could Not be Uploaded.");
          }
        }
        // getting updated data
        setIsFetching(true);
        const response = await getAllStoresOfAnyVender();
        if (response?.data?.success === true) {
          let allDataArr = [];
          for (let i = 0; i !== response?.data?.AllStores.length; i++) {
            let newArr = {
              name: response?.data?.AllStores[i]?.Data?.Name,
              open: data?.AllStores[i]?.Data?.open,
              logo: response?.data?.AllStores[i]?.Data?.Logo
                ? process.env.REACT_APP_LIVE_SERVER +
                  "/storeImages/" +
                  response?.data?.AllStores[i]?.Data?.Logo
                : "https://islamabadclub.org.pk/ic/wp-content/uploads/2016/08/dummy-prod-1.jpg",
              address: response?.data?.AllStores[i]?.Data?.Address,
              //category: response?.data?.AllStores[i]?.Data?.CateName,
              Id: response?.data?.AllStores[i]?.Data?.Id,
              status:
                response?.data?.AllStores[i]?.Data?.status !== undefined
                  ? response?.data?.AllStores[i]?.Data?.status
                  : true,
            };
            allDataArr.push(newArr);
          }
          setAllData(allDataArr);
        }
        setIsFetching(false);

        await delay(1500);
        handleViewClose();
      } else {
        toast.error(data?.message);
      }
    }
    setIsSending(false);
  };

  // sending data object
  const [userData, setUserData] = useState({
    name: "",
    owner: "",
    address: "",
    isAdmin: isAdmin,
    logo: "",
    categories: [],
    subCategories: [],
    desc: "",
    url: "",
    timings: [
      {
        day: "Monday",
        openingTime: "",
        closingTime: "",
      },
      {
        day: "Tuesday",
        openingTime: "",
        closingTime: "",
      },
      {
        day: "Wednesday",
        openingTime: "",
        closingTime: "",
      },
      {
        day: "Thursday",
        openingTime: "",
        closingTime: "",
      },
      {
        day: "Friday",
        openingTime: "",
        closingTime: "",
      },
      {
        day: "Saturday",
        openingTime: "",
        closingTime: "",
      },
      {
        day: "Sunday",
        openingTime: "",
        closingTime: "",
      },
    ],
  });

  // getting all categories + sub categories + venders
  useEffect(() => {
    const getAllCate = async () => {
      const { data } = await getAllParentCategories();
      if (data?.success === true) {
        setAllParent(data?.AllCategories);
        setAllSubParent([]);
      }
    };
    getAllCate();

    const getAllSubCate = async () => {
      const { data } = await getAllSubCategories();
      if (data?.success === true) {
        setAllSubParent(data?.AllCategories);
      }
    };
    getAllSubCate();

    const getAllVenders = async () => {
      const { data } = await getAllAdmins();
      if (data?.success === true) {
        setAllVenders(data?.AllAdmins);
      }
    };
    getAllVenders();
  }, []);

  // add new modal
  const [addNew, setAddNew] = useState(false);
  const handleAddNewClose = () => {
    setUploadImage(null);
    setUserData({
      name: "",
      owner: "",
      address: "",
      isAdmin: isAdmin,
      logo: "",
      parent: "",
      categories: [],
      subCategories: [],
      desc: "",
      url: "",
      timings: [
        {
          day: "Monday",
          openingTime: "",
          closingTime: "",
        },
        {
          day: "Tuesday",
          openingTime: "",
          closingTime: "",
        },
        {
          day: "Wednesday",
          openingTime: "",
          closingTime: "",
        },
        {
          day: "Thursday",
          openingTime: "",
          closingTime: "",
        },
        {
          day: "Friday",
          openingTime: "",
          closingTime: "",
        },
        {
          day: "Saturday",
          openingTime: "",
          closingTime: "",
        },
        {
          day: "Sunday",
          openingTime: "",
          closingTime: "",
        },
      ],
    });
    setSelectedCates([]);
    setAddNew(false);
    setAllSubParentNew([]);
    setIsSending(false);
  };
  // getting data of user
  const handleAddNew = async (id) => {
    setAddNew(true);
  };

  // sending data
  const addNewVender = async () => {
    setIsSending(true);
    if (userData?.logo === "") {
      toast.warning("Logo of Store is Required");
    }
    let formData = new FormData();
    formData.append("name", userData?.name);
    formData.append("storeImage", userData?.logo);
    formData.append("address", userData?.address);
    formData.append("isAdmin", userData?.isAdmin);
    formData.append("desc", userData?.desc);
    formData.append("url", userData?.url);
    formData.append("timings", JSON.stringify(userData?.timings));
    formData.append("parent", JSON.stringify(userData?.categories));
    formData.append("subParent", JSON.stringify(userData?.subCategories));
    if (isAdmin === true) {
      formData.append("owner", userData?.owner);
      const { data } = await addNewStore(formData);
      if (data?.success === true) {
        toast.success("New Store Added SuccessFully");

        setIsFetching(true);
        const res = await getAllStores();
        if (data?.success === true) {
          let allDataArr = [];
          for (let i = 0; i !== res?.data?.AllStores.length; i++) {
            let newArr = {
              name: res?.data?.AllStores[i]?.Data?.Name,
              open: data?.AllStores[i]?.Data?.open,
              logo: res?.data?.AllStores[i]?.Data?.Logo
                ? process.env.REACT_APP_LIVE_SERVER +
                  "/storeImages/" +
                  res?.data?.AllStores[i]?.Data?.Logo
                : "https://islamabadclub.org.pk/ic/wp-content/uploads/2016/08/dummy-prod-1.jpg",
              address: res?.data?.AllStores[i]?.Data?.Address,
              //category: res?.data?.AllStores[i]?.Data?.CateName,
              Id: res?.data?.AllStores[i]?.Data?.Id,
              status:
                res?.data?.AllStores[i]?.Data?.status !== undefined
                  ? res?.data?.AllStores[i]?.Data?.status
                  : true,
            };
            allDataArr.push(newArr);
          }
          setAllData(allDataArr);
        }
        setIsFetching(false);
        setUserData({
          name: "",
          owner: "",
          address: "",
          isAdmin: isAdmin,
          logo: "",
          parent: "",
          subCategories: "",
          desc: "",
          timings: [
            {
              day: "Monday",
              openingTime: "",
              closingTime: "",
            },
            {
              day: "Tuesday",
              openingTime: "",
              closingTime: "",
            },
            {
              day: "Wednesday",
              openingTime: "",
              closingTime: "",
            },
            {
              day: "Thursday",
              openingTime: "",
              closingTime: "",
            },
            {
              day: "Friday",
              openingTime: "",
              closingTime: "",
            },
            {
              day: "Saturday",
              openingTime: "",
              closingTime: "",
            },
            {
              day: "Sunday",
              openingTime: "",
              closingTime: "",
            },
          ],
        });

        await delay(1000);
        handleAddNewClose();
      } else {
        toast.error(data?.message);
      }
    } else {
      //formData.append("owner" , userData?.owner)
      const { data } = await addNewStoreByVender(formData);
      if (data?.success === true) {
        toast.success("New Store Added SuccessFully");

        setIsFetching(true);
        const res = await getAllStoresOfAnyVender();

        if (data?.success === true) {
          let allDataArr = [];
          for (let i = 0; i !== res?.data?.AllStores?.length; i++) {
            let newArr = {
              name: res?.data?.AllStores[i]?.Data?.Name,
              open: data?.AllStores[i]?.Data?.open,
              logo: res?.data?.AllStores[i]?.Data?.Logo
                ? process.env.REACT_APP_LIVE_SERVER +
                  "/storeImages/" +
                  res?.data?.AllStores[i]?.Data?.Logo
                : "https://islamabadclub.org.pk/ic/wp-content/uploads/2016/08/dummy-prod-1.jpg",
              address: res?.data?.AllStores[i]?.Data?.Address,
              //category: res?.data?.AllStores[i]?.Data?.CateName,
              Id: res?.data?.AllStores[i]?.Data?.Id,
              status:
                res?.data?.AllStores[i]?.Data?.status !== undefined
                  ? res?.data?.AllStores[i]?.Data?.status
                  : true,
            };
            allDataArr.push(newArr);
          }
          setAllData(allDataArr);
        }
        setIsFetching(false);
        setUserData({
          name: "",
          owner: "",
          address: "",
          isAdmin: isAdmin,
          logo: "",
          parent: "",
          subCategories: "",
          desc: "",
          timings: [
            {
              day: "Monday",
              openingTime: "",
              closingTime: "",
            },
            {
              day: "Tuesday",
              openingTime: "",
              closingTime: "",
            },
            {
              day: "Wednesday",
              openingTime: "",
              closingTime: "",
            },
            {
              day: "Thursday",
              openingTime: "",
              closingTime: "",
            },
            {
              day: "Friday",
              openingTime: "",
              closingTime: "",
            },
            {
              day: "Saturday",
              openingTime: "",
              closingTime: "",
            },
            {
              day: "Sunday",
              openingTime: "",
              closingTime: "",
            },
          ],
        });

        await delay(1000);
        handleAddNewClose();
      } else {
        toast.error(data?.message);
      }
      setIsSending(false);
    }
    setIsSending(false);
  };

  const onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, "Do MMMM YYYY").valueOf();
    },
  };

  const [text, setText] = useState("");
  // getting all data on blur
  const getAllData = async () => {
    if (text?.length < 1) {
      setIsFetching(true);
      if (isAdmin === true) {
        const { data } = await getAllStores();
        if (data?.success === true) {
          let allDataArr = [];
          for (let i = 0; i !== data?.AllStores.length; i++) {
            let newArr = {
              name: data?.AllStores[i]?.Data?.Name,
              open: data?.AllStores[i]?.Data?.open,
              logo:
                data?.AllStores[i]?.Data?.Logo?.indexOf("https") === 0
                  ? data?.AllStores[i]?.Data?.Logo
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/storeImages/" +
                    data?.AllStores[i]?.Data?.Logo,
              address: data?.AllStores[i]?.Data?.Address,
              Id: data?.AllStores[i]?.Data?.Id,
              status:
                data?.AllStores[i]?.Data?.status !== undefined
                  ? data?.AllStores[i]?.Data?.status
                  : true,
            };
            allDataArr.push(newArr);
          }
          setAllData(allDataArr);
        }
      } else {
        const { data } = await getAllStoresOfAnyVender();
        if (data?.success === true) {
          let allDataArr = [];
          for (let i = 0; i !== data?.AllStores.length; i++) {
            let newArr = {
              name: data?.AllStores[i]?.Data?.Name,
              open: data?.AllStores[i]?.Data?.open,
              logo:
                data?.AllStores[i]?.Data?.Logo?.indexOf("https") === 0
                  ? data?.AllStores[i]?.Data?.Logo
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/storeImages/" +
                    data?.AllStores[i]?.Data?.Logo,
              address: data?.AllStores[i]?.Data?.Address,
              //category: data?.AllStores[i]?.Data?.CateName,
              Id: data?.AllStores[i]?.Data?.Id,
              status:
                data?.AllStores[i]?.Data?.status !== undefined
                  ? data?.AllStores[i]?.Data?.status
                  : true,
            };
            allDataArr.push(newArr);
          }
          setAllData(allDataArr);
        }
      }
      setIsFetching(false);
    }
  };

  // getting searched item
  const getSearchedStores = async () => {
    if (text?.length > 2) {
      if (isAdmin === true) {
        const { data } = await getAllStores();
        if (data?.success === true) {
          let allDataArr = [];
          for (let i = 0; i !== data?.AllStores.length; i++) {
            let newArr = {
              name: data?.AllStores[i]?.Data?.Name,
              open: data?.AllStores[i]?.Data?.open,
              logo:
                data?.AllStores[i]?.Data?.Logo?.indexOf("https") === 0
                  ? data?.AllStores[i]?.Data?.Logo
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/storeImages/" +
                    data?.AllStores[i]?.Data?.Logo,
              address: data?.AllStores[i]?.Data?.Address,
              Id: data?.AllStores[i]?.Data?.Id,
              status:
                data?.AllStores[i]?.Data?.status !== undefined
                  ? data?.AllStores[i]?.Data?.status
                  : true,
            };
            allDataArr.push(newArr);
          }
          setAllData(allDataArr);
        }
      } else {
        const { data } = await getAllStoresOfAnyVender();
        if (data?.success === true) {
          let allDataArr = [];
          for (let i = 0; i !== data?.AllStores.length; i++) {
            let newArr = {
              name: data?.AllStores[i]?.Data?.Name,
              open: data?.AllStores[i]?.Data?.open,
              logo:
                data?.AllStores[i]?.Data?.Logo?.indexOf("https") === 0
                  ? data?.AllStores[i]?.Data?.Logo
                  : process.env.REACT_APP_LIVE_SERVER +
                    "/storeImages/" +
                    data?.AllStores[i]?.Data?.Logo,
              address: data?.AllStores[i]?.Data?.Address,
              //category: data?.AllStores[i]?.Data?.CateName,
              Id: data?.AllStores[i]?.Data?.Id,
              status:
                data?.AllStores[i]?.Data?.status !== undefined
                  ? data?.AllStores[i]?.Data?.status
                  : true,
            };
            allDataArr.push(newArr);
          }
          setAllData(allDataArr);
        }
      }
      let newArr = [];
      let myText = text.toLocaleLowerCase();
      for (let i = 0; i !== allData.length; i++) {
        if (
          allData[i].name?.toLowerCase()?.includes(myText) === true ||
          allData[i].address?.toLowerCase()?.includes(myText) === true
        ) {
          newArr.push(allData[i]);
        }
      }
      setText("");
      setAllData(newArr);
    }
  };

  // updating timings
  const updateMyTiming = (day, type, value) => {
    let newArr = userData?.timings;
    let isFound = newArr.find((item) => item?.day === day);
    if (isFound) {
      if (type === "openingTime") {
        isFound.openingTime = value;
      } else {
        isFound.closingTime = value;
      }
      newArr.filter((item) => (item.day === day ? isFound : item));
    }
    setUserData({ ...userData, timings: newArr });
  };

  // updating timings while updating store
  const updateMyNewTiming = (day, type, value) => {
    let newArr = singleUserData?.timings;
    let isFound = newArr.find((item) => item?.day === day);
    if (isFound) {
      if (type === "openingTime") {
        isFound.openingTime = value;
      } else {
        isFound.closingTime = value;
      }
      newArr.filter((item) => (item.day === day ? isFound : item));
    }
    setSingleUserData({ ...singleUserData, timings: newArr });
  };

  // adding categories
  const changeData = async (e) => {
    let newData = userData;
    let isFound = newData.categories?.find((item) => item == e);
    if (isFound) {
      let newMyArr = newData.categories.filter((item) => item != e);
      newData.categories = newMyArr;
    } else {
      newData.categories.push(e);
    }

    // checking no parent category is selected
    if (newData.categories?.length < 1) {
      setAllSubParent([]);
    }

    getRelatedSub(e, isFound);
    setUserData(newData);
  };

  // adding sub categories
  const changeDataOne = async (e) => {
    let newData = userData;
    let isFound = newData.subCategories.find((item) => item == e);
    if (isFound) {
      let newMyArr = newData.subCategories.filter((item) => item != e);
      newData.subCategories = newMyArr;
    } else {
      newData.subCategories.push(e);
    }
    setUserData({ ...userData, ...newData });
  };

  // adding categories on update
  const changeDataUpdate = async (e) => {
    let newData = singleUserData;
    let isFound = newData.categories?.find((item) => item == e);
    if (isFound) {
      let newMyArr = newData.categories?.filter((item) => item != e);
      newData.categories = newMyArr;
    } else {
      newData.categories.push(e);
    }

    // getRelatedSub(e, isFound);

    // getting data of new sub categories
    if (isAdmin === true) {
      const { data } = await getAllSubCategories({
        categories: newData.categories,
      });
      console.log("all sub categories in update ", data);
      if (data?.success === true) {
        setAllSubParent(data?.AllSubCategories);
      }
    } else {
      const { data } = await getAllSubCategoriesOfVender({
        categories: newData.categories,
      });
      if (data?.success === true) {
        setAllSubParent(data?.AllSubCategories);
      }
    }

    // checking no parent category is selected
    if (newData.categories?.length < 1) {
      setAllSubParent([]);
    }

    // console.log("newData.categories : ", newData.categories);

    // getting all related sub categories
    // const {data} = await getAllSubCategoriesOfAnyCategory(newData.categories);
    // console.log("data got : ", data);
    // if(data?.success === true ){
    //     //let newArr = allSubParentNew;
    //     let myArr = allSubParentNew
    //     let newArr = [...myArr , data?.AllSubCategories]
    //     setAllSubParentNew(newArr)
    // }

    setSingleUserData(newData);
  };

  // adding sub categories on update
  const changeDataOneUpdate = (e) => {
    let newData = singleUserData;
    let isFound = newData.subCategories.find((item) => item == e);
    if (isFound) {
      let newMyArr = newData?.subCategories?.filter((item) => item != e);
      newData.subCategories = newMyArr;
    } else {
      newData.subCategories.push(e);
    }
    setSingleUserData(newData);
  };

  // getting all related sub categories
  const getRelatedSub = async (id, check) => {
    let finalArr = [];
    if (check) {
      for (let i = 0; i !== allSubParentNew?.length; i++) {
        let isFound = userData?.categories?.find(
          (item) => item == allSubParentNew[i]?.parentId
        );
        if (isFound) {
          finalArr.push(allSubParentNew[i]);
        } else {
          let newUser = userData;
          let newMyArr = newUser?.subCategories?.filter(
            (item) => item != allSubParentNew[i]._id
          );
          newUser.subCategories = newMyArr;
          setUserData(newUser);
        }
      }
      setAllSubParentNew(finalArr);
    } else {
      const { data } = await getAllSubCategoriesOfAnyCategory(id);
      if (data?.success === true) {
        setAllSubParentNew([...data?.AllSubCategories, ...allSubParentNew]);
      }
    }
  };

  return (
    <>
      {isFetching === true ? (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "auto" }}
        >
          <ThreeDots
            height="60"
            width="60"
            radius="9"
            color="green"
            ariaLabel="three-dots-loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      ) : (
        <DatatableWrapper
          body={allData}
          headers={TABLE_HEADERS}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 10,
              options: [5, 10, 15, 20],
            },
          }}
          initialSort={{ prop: "name", isAscending: false }}
          onSort={onSortFunction}
        >
          <Row className="p-4 mb-4">
            <Col
              xs={12}
              lg={4}
              className="flex-col d-flex justify-content-start align-items-start"
            >
              <h4>All Stores</h4>
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="flex-col mb-2 d-flex justify-content-lg-center align-items-center justify-content-sm-start mb-sm-0"
            >
              <Button
                size="lg"
                variant="info"
                onClick={handleAddNew}
                style={{ fontSize: "15px", fontWeight: 600 }}
              >
                Add New
              </Button>
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="flex-col d-flex justify-content-end align-items-end"
            >
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Search here..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  onBlur={() => getAllData()}
                  onChange={(e) => setText(e.target.value)}
                />
                <Button variant="info" onClick={getSearchedStores}>
                  Search
                </Button>
              </InputGroup>
            </Col>
          </Row>
          <Table striped bordered hover className="ml-2 mr-2">
            <TableHeader />
            <TableBody />
          </Table>
          <Row className="p-2 mb-4">
            <Col
              xs={12}
              sm={6}
              lg={6}
              className="flex-col mb-2 d-flex justify-content-lg-start align-items-start justify-content-sm-start mb-sm-0"
            >
              <PaginationOptions />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={6}
              className="flex-col d-flex justify-content-end align-items-end"
            >
              <Pagination />
            </Col>
          </Row>
        </DatatableWrapper>
      )}

      {/* view and update modal */}
      <Modal
        show={view}
        onHide={handleViewClose}
        backdrop="static"
        keyboard={false}
        //style={{marginTop : '100px'}}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Store Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isGetting === true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <ThreeDots
                height="60"
                width="60"
                radius="9"
                color="green"
                ariaLabel="three-dots-loading"
                wrapperStyle
                wrapperClass
              />
            </div>
          ) : (
            <div className="flex justify-center">
              <div className="flex justify-start flex-col align-center pb-4 px-2 w-[100%] mx-auto  pt-3">
                <Row>
                  <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <img
                        src={
                          process.env.REACT_APP_LIVE_SERVER +
                          "/storeImages/" +
                          singleUserData?.logo
                        }
                        alt="cover pic"
                        style={{
                          maxWidth: "80px",
                          maxHeight: "80px",
                          borderRadius: "10px",
                        }}
                      />
                      <input
                        type="file"
                        accept="image/*"
                        className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                        onChange={(e) => setUploadImage(e.target.files[0])}
                      />
                    </div>
                  </Col>
                  <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                    {uploadImage !== null && (
                      <div className="flex justify-between mt-4">
                        <img
                          src={URL.createObjectURL(uploadImage)}
                          alt="cover pic"
                          style={{
                            maxWidth: "80px",
                            maxHeight: "80px",
                            borderRadius: "10px",
                          }}
                        />
                        <button
                          className="bg-[#e74c3c] mt-3 rounded-md p-2 text-white font-semibold mx-auto max-h-[40px]"
                          onClick={() => setUploadImage(null)}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6> Name:</h6>
                      <input
                        type="text"
                        placeholder="Enter user name ..."
                        className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                        style={{ border: "1px solid black" }}
                        value={singleUserData?.name}
                        onChange={(e) =>
                          setSingleUserData({
                            ...singleUserData,
                            name: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                  <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6> Rating:</h6>
                      <input
                        type="number"
                        placeholder="Enter user name ..."
                        className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                        style={{ border: "1px solid black" }}
                        value={
                          singleUserData?.rating &&
                          singleUserData?.rating[
                            "$numberDecimal"
                          ]?.toLocaleString()
                        }
                        disabled={true}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6> Review:</h6>
                      <input
                        type="number"
                        placeholder="Enter user name ..."
                        className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                        style={{ border: "1px solid black" }}
                        value={
                          singleUserData?.review &&
                          singleUserData?.review[
                            "$numberDecimal"
                          ]?.toLocaleString()
                        }
                        disabled={true}
                      />
                    </div>
                  </Col>
                  <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6>Address:</h6>
                      <span style={{ color: "crimson", fontSize: "12px" }}>
                        Please always provide correct address
                      </span>
                      <input
                        type="email"
                        placeholder="Enter Email Please ..."
                        className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                        style={{ border: "1px solid black" }}
                        value={singleUserData?.address}
                        onChange={(e) =>
                          setSingleUserData({
                            ...singleUserData,
                            address: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6>Choose Parent Category:</h6>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        multiple
                      >
                        <option selected disabled></option>
                        {allParent?.length > 0 ? (
                          allParent?.map((item) =>
                            singleUserData?.categories?.length > 0 &&
                            singleUserData?.categories?.find(
                              (itemOne) => itemOne == item?._id
                            ) ? (
                              <option
                                value={item?._id}
                                selected
                                onClick={() => changeDataUpdate(item?._id)}
                              >
                                {item?.name}
                              </option>
                            ) : (
                              <option
                                value={item?._id}
                                onClick={() => changeDataUpdate(item?._id)}
                              >
                                {item?.name}
                              </option>
                            )
                          )
                        ) : (
                          <option>No Parent Categories Found</option>
                        )}
                      </select>
                    </div>
                  </Col>
                  <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                    {isAdmin === true ? (
                      <div className="flex flex-col pt-5 pl-3 font-semibold">
                        <h6>Update Vendor:</h6>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          style={{ maxWidth: "370px" }}
                          onChange={(e) =>
                            setSingleUserData({
                              ...singleUserData,
                              owner: e.target.value,
                            })
                          }
                        >
                          {allVenders?.length > 0 ? (
                            allVenders?.map((item) =>
                              singleUserData?.owner == item?._id ? (
                                <option value={item?._id} selected>
                                  {item?.username}
                                </option>
                              ) : (
                                <option value={item?._id}>
                                  {item?.username}
                                </option>
                              )
                            )
                          ) : (
                            <option>No Vendors Found</option>
                          )}
                        </select>
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      {allSubParentNew?.length > 0 ? (
                        <>
                          <h6>Choose Sub Categories:</h6>
                          <Form.Select size="lg" multiple>
                            {allSubParentNew?.length > 0 ? (
                              allSubParentNew?.map((item) =>
                                singleUserData?.subCategories?.length > 0 &&
                                singleUserData?.subCategories?.find(
                                  (itemOne) => itemOne == item?._id
                                ) ? (
                                  <option
                                    value={item?._id}
                                    selected
                                    onClick={() =>
                                      changeDataOneUpdate(item?._id)
                                    }
                                  >
                                    {item?.name}
                                  </option>
                                ) : (
                                  <option
                                    value={item?._id}
                                    onClick={() =>
                                      changeDataOneUpdate(item?._id)
                                    }
                                  >
                                    {item?.name}
                                  </option>
                                )
                              )
                            ) : (
                              <option>No Parent Categories Found</option>
                            )}
                          </Form.Select>
                        </>
                      ) : (
                        <h5>Please select any parent category</h5>
                      )}
                    </div>
                  </Col>
                  <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6>Store Description:</h6>
                      <textArea
                        rows="8"
                        placeholder="Write Store Description"
                        style={{
                          border: "1px solid #b2bec3",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        onChange={(e) =>
                          setSingleUserData({
                            ...singleUserData,
                            desc: e.target.value,
                          })
                        }
                      >
                        {singleUserData?.desc}
                      </textArea>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6>Store Timings:</h6>
                      <Row style={{ marginBottom: "25px" }}>
                        <Col xs={4} sm={3} md={4} lg={4}>
                          <h6 style={{ textAlign: "center" }}>Day</h6>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4}>
                          <h6 style={{ textAlign: "center" }}>Opening Time</h6>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4}>
                          <h6 style={{ textAlign: "center" }}>Closing Time</h6>
                        </Col>
                      </Row>
                      {singleUserData?.timings?.map((item, index) => (
                        <>
                          <Row
                            style={{
                              marginBottom: "10px",
                              borderBottom: "1px solid #dfe6e9",
                            }}
                            key={index}
                          >
                            <Col xs={4} sm={3} md={4} lg={4}>
                              <h6 style={{ textAlign: "center" }}>
                                {item?.day}
                              </h6>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4}>
                              <input
                                type="time"
                                value={item?.openingTime}
                                onChange={(e) =>
                                  updateMyNewTiming(
                                    item?.day,
                                    "openingTime",
                                    e.target.value
                                  )
                                }
                              />
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4}>
                              <input
                                type="time"
                                value={item?.closingTime}
                                onChange={(e) =>
                                  updateMyNewTiming(
                                    item?.day,
                                    "closingTime",
                                    e.target.value
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        </>
                      ))}
                    </div>
                  </Col>
                  <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6>URL:</h6>
                      <input
                        type="text"
                        placeholder="Enter Store website url ..."
                        className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                        style={{ border: "1px solid black" }}
                        value={singleUserData?.url}
                        onChange={(e) =>
                          setSingleUserData({
                            ...singleUserData,
                            url: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isSending === true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <ThreeDots
                height="60"
                width="60"
                radius="9"
                color="green"
                ariaLabel="three-dots-loading"
                wrapperStyle
                wrapperClass
              />
            </div>
          ) : (
            <>
              <Button variant="danger" onClick={handleViewClose}>
                Close
              </Button>
              <Button variant="success" onClick={updateData}>
                Update Now
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      {/* view and update modal end */}

      {/* add new modal */}
      <Modal
        show={addNew}
        onHide={handleAddNewClose}
        backdrop="static"
        keyboard={false}
        //style={{marginTop : '100px'}}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Store</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-center">
            <div className="flex justify-start flex-col align-center pb-4  w-[100%] mx-auto pt-3">
              <h6 className="text-2xl font-bold text-center">Add New Store</h6>
              <Row>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Name:</h6>
                    <input
                      type="text"
                      placeholder="Enter Store name ..."
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      style={{ border: "1px solid black" }}
                      value={userData?.name}
                      onChange={(e) =>
                        setUserData({ ...userData, name: e.target.value })
                      }
                    />
                  </div>
                </Col>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Address:</h6>
                    <span style={{ color: "crimson", fontSize: "12px" }}>
                      Please provide correct address
                    </span>
                    <input
                      type="text"
                      placeholder="Enter Email Please ..."
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      style={{ border: "1px solid black" }}
                      value={userData?.address}
                      onChange={(e) =>
                        setUserData({ ...userData, address: e.target.value })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Logo:</h6>
                    <input
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      style={{ border: "1px solid black" }}
                      onChange={(e) =>
                        setUserData({ ...userData, logo: e.target.files[0] })
                      }
                    />
                  </div>
                </Col>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  {userData?.logo !== "" && (
                    <div className="flex justify-between mt-4 mb-5">
                      <img
                        src={URL.createObjectURL(userData?.logo)}
                        alt="cover pic"
                        style={{
                          maxWidth: "80px",
                          maxHeight: "80px",
                          borderRadius: "10px",
                        }}
                      />
                      <button
                        className="bg-[#e74c3c] mt-3 rounded-md p-2 text-white font-semibold mx-auto max-h-[40px]"
                        onClick={(e) => setUserData({ ...userData, logo: "" })}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  {isAdmin === true && (
                    <div className="flex flex-col pt-5 pl-3 font-semibold">
                      <h6>Choose Vendor:</h6>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        style={{ maxWidth: "370px", overflow: "hidden" }}
                        onChange={(e) =>
                          setUserData({ ...userData, owner: e.target.value })
                        }
                      >
                        <option
                          selected
                          disabled
                          style={{ maxWidth: "370px", overflow: "hidden" }}
                        ></option>
                        {allVenders?.length > 0 ? (
                          allVenders?.map((item) => (
                            <option
                              value={item?._id}
                              style={{ maxWidth: "370px", overflow: "hidden" }}
                            >
                              {item?.username}
                            </option>
                          ))
                        ) : (
                          <option
                            style={{ maxWidth: "370px", overflow: "hidden" }}
                          >
                            No Venders Found
                          </option>
                        )}
                      </select>
                    </div>
                  )}
                </Col>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Choose Parent Category:</h6>
                    <div
                      size="lg"
                      className="overflow-scroll border rounded"
                      style={{ height: 140 }}
                      multiple
                    >
                      {allParent?.length > 0 ? (
                        allParent?.map((item) => (
                          <div
                            role="button"
                            className={`flex p-2 ${
                              userData?.categories?.find(
                                (val) => val === item?._id
                              ) && "bg-[#e5e7eb]"
                            }`}
                            value={item?._id}
                            onClick={() => {
                              changeData(item?._id);
                            }}
                          >
                            <img
                              src={
                                process.env.REACT_APP_LIVE_SERVER +
                                "/catgegoryImages/" +
                                item.image
                              }
                              style={{
                                height: 30,
                                width: 30,
                              }}
                            />
                            <div className="m-2">{item?.name}</div>
                          </div>
                        ))
                      ) : (
                        <option>No Parent Categories Found</option>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    {allSubParentNew?.length > 0 ? (
                      <>
                        <h6>Choose Sub Categories:</h6>
                        <div
                          size="lg"
                          className="overflow-scroll border rounded"
                          style={{ height: 140 }}
                          multiple
                        >
                          {allSubParentNew?.length > 0 ? (
                            allSubParentNew?.map((item) => (
                              <div
                                role="button"
                                className={`flex p-2 ${
                                  userData?.subCategories?.find(
                                    (val) => val === item?._id
                                  ) && "bg-[#e5e7eb]"
                                }`}
                                value={item?._id}
                                onClick={() => {
                                  changeDataOne(item?._id);
                                }}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_LIVE_SERVER +
                                    "/catgegoryImages/" +
                                    item.image
                                  }
                                  style={{
                                    height: 30,
                                    width: 30,
                                  }}
                                />
                                <div className="m-2">{item?.name}</div>
                              </div>
                            ))
                          ) : (
                            <option>No Parent Categories Found</option>
                          )}
                        </div>
                      </>
                    ) : (
                      <h5>Please select any parent category</h5>
                    )}
                  </div>
                </Col>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Store Description:</h6>
                    <textArea
                      rows="8"
                      placeholder="Write Store Description"
                      style={{
                        border: "1px solid #b2bec3",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                      value={userData?.desc}
                      onChange={(e) =>
                        setUserData({ ...userData, desc: e.target.value })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>Store Timings:</h6>
                    <Row style={{ marginBottom: "25px" }}>
                      <Col xs={4} sm={3} md={4} lg={4}>
                        <h6 style={{ textAlign: "center" }}>Day</h6>
                      </Col>
                      <Col xs={4} sm={4} md={4} lg={4}>
                        <h6 style={{ textAlign: "center" }}>Opening Time</h6>
                      </Col>
                      <Col xs={4} sm={4} md={4} lg={4}>
                        <h6 style={{ textAlign: "center" }}>Closing Time</h6>
                      </Col>
                    </Row>
                    {userData?.timings?.map((item, index) => (
                      <Row
                        style={{
                          marginBottom: "10px",
                          borderBottom: "1px solid #dfe6e9",
                        }}
                        key={index}
                      >
                        <Col xs={4} sm={3} md={4} lg={4}>
                          <h6 style={{ textAlign: "center" }}>{item?.day}</h6>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4}>
                          <input
                            type="time"
                            value={item?.day?.openingTime}
                            onChange={(e) =>
                              updateMyTiming(
                                item?.day,
                                "openingTime",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4}>
                          <input
                            type="time"
                            value={item?.day?.closingTime}
                            onChange={(e) =>
                              updateMyTiming(
                                item?.day,
                                "closingTime",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Col>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-5 pl-3 font-semibold">
                    <h6>URL:</h6>
                    <input
                      type="text"
                      placeholder="Enter Store website url ..."
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      style={{ border: "1px solid black" }}
                      value={userData?.url}
                      onChange={(e) =>
                        setUserData({ ...userData, url: e.target.value })
                      }
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isSending === true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <ThreeDots
                height="60"
                width="60"
                radius="9"
                color="green"
                ariaLabel="three-dots-loading"
                wrapperStyle
                wrapperClass
              />
            </div>
          ) : (
            <>
              <Button variant="danger" onClick={handleAddNewClose}>
                Close
              </Button>
              <Button variant="success" onClick={addNewVender}>
                Add Now
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      {/* add new modal end */}
    </>
  );
};

export default ViewAllvendors;
