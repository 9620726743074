import React, { useState, useEffect } from "react";
import { AreaChart } from "amazing-react-charts";
import {
  getDashboardOrdersOfPartner,
  getAlLProductsCountOfPartner,
  getAlLStoresCountOfPartner,
  getAlLOffersCountOfPartner,
} from "../api/AdminApi";
import { useNavigate } from "react-router-dom";
import { getPartnerGraphData } from "../api/AdminApi";
// importing chart
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";
// import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Monthly Sales",
    },
  },
};

const HomePage = () => {
  const [labels, setLables] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const data = {
    labels,
    datasets: [
      {
        label: "Monthly Sales",
        data: graphData,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  const navigate = useNavigate();
  const [allData, setAllData] = useState(null);
  const [allProdsCount, setAllProdsCount] = useState(0);
  const [allStoresCount, setAllStoresCount] = useState(0);
  const [allOffersCount, setAllOffersCount] = useState(0);
  const [lowStockProducts, setAllLowStockProducts] = useState([]);

  // getting data
  useEffect(() => {
    // getting orders related data
    const getData = async () => {
      const { data } = await getDashboardOrdersOfPartner();
      if (data?.success === true) {
        let newObj = {
          totalOrders: data?.TotalOrdersCount,
          newOrders: data?.TodayOrdersCount,
          completedOrders: data?.CompletedOrdersCount,
          cancelledOrders: data?.CancelledOrdersCount,
          recentOrders: data?.RecentOrders,
        };
        setAllData(newObj);
      } else {
        setAllData(null);
      }
    };

    // getting all stores count
    const getProductsCount = async () => {
      const { data } = await getAlLProductsCountOfPartner();
      if (data?.success === true) {
        setAllProdsCount(data?.AllProductCount);
        setAllLowStockProducts(data?.LessStockProducts);
      } else {
        setAllProdsCount(0);
      }
    };

    // getting all products count
    const getStoresCount = async () => {
      const { data } = await getAlLStoresCountOfPartner();
      if (data?.success === true) {
        setAllStoresCount(data?.AllStoreCount);
      } else {
        setAllStoresCount(0);
      }
    };

    // getting all offers count
    const getOfferCount = async () => {
      const { data } = await getAlLOffersCountOfPartner();
      if (data?.success === true) {
        setAllOffersCount(data?.AllOffers);
      } else {
        setAllOffersCount(0);
      }
    };

    // getting graph data
    const getGraphData = async () => {
      const { data } = await getPartnerGraphData();
      if (data?.success === true) {
        let newLabeles = [],
          newData = [],
          newMyArray = data?.Data.reverse();
        for (let i = 0; i !== newMyArray?.length; i++) {
          newLabeles.push(newMyArray[i]?.date);
          newData.push(newMyArray[i]?.amount);
        }
        setLables(newLabeles);
        setGraphData(newData);
      }
    };

    getData();
    getProductsCount();
    getStoresCount();
    getOfferCount();
    getGraphData();
  }, []);

  return (
    <>
      <div className="row" style={{ padding: "15px" }}>
        <h3 style={{ marginBottom: "15px" }}>Dashboard</h3>
        <div
          className="col-6 col-lg-3 mb-2"
          onClick={() => navigate("/orders")}
        >
          <div class="container-card">
            <div class="content">
              <p className="text-center">Total Orders</p>
              <h1 className="text-center">{allData?.totalOrders}</h1>
            </div>
          </div>
        </div>
        <div
          className="col-6 col-lg-3 mb-2"
          onClick={() => navigate("/orders")}
        >
          <div class="container-card">
            <div class="content">
              <p className="text-center">New Orders</p>
              <h1 className="text-center">{allData?.newOrders}</h1>
            </div>
          </div>
        </div>
        <div
          className="col-6 col-lg-3 mb-2"
          onClick={() => navigate("/orders")}
        >
          <div class="container-card">
            <div class="content">
              <p className="text-center">Completed Orders</p>
              <h1 className="text-center">{allData?.completedOrders}</h1>
            </div>
          </div>
        </div>
        <div
          className="col-6 col-lg-3 mb-2"
          onClick={() => navigate("/orders")}
        >
          <div class="container-card">
            <div class="content">
              <p className="text-center">Cancelled Orders</p>
              <h1 className="text-center">{allData?.cancelledOrders}</h1>
            </div>
          </div>
        </div>
        <div
          className="col-6 col-lg-4 mb-2"
          onClick={() => navigate("/our stores")}
        >
          <div class="container-card">
            <div class="content">
              <p className="text-center">Total Stores</p>
              <h1 className="text-center"> {allStoresCount}</h1>
            </div>
          </div>
        </div>
        <div
          className="col-6 col-lg-4 mb-2"
          onClick={() => navigate("/all products")}
        >
          <div class="container-card">
            <div class="content">
              <p className="text-center">Total Products</p>
              <h1 className="text-center">{allProdsCount}</h1>
            </div>
          </div>
        </div>
        <div
          className="col-6 col-lg-4 mb-2"
          onClick={() => navigate("/manage offers")}
        >
          <div class="container-card">
            <div class="content">
              <p className="text-center">Total Offers</p>
              <h1 className="text-center">{allOffersCount}</h1>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-6 mb-2">
          <div class="card border-0 p-3">
            <div class="content">
              <h4 className="text-start mb-3 border-bottom pb-2">Sales</h4>
              <Bar options={options} data={data} />
            </div>
          </div>
        </div>

        <div className="col-6 col-lg-6 mb-2">
          <div class="card border-0 p-3">
            <div class="content">
              <h4 className="text-start mb-3 border-bottom pb-2">
                Low Inventory Products
              </h4>
              <div className="d-flex justify-content-between border-bottom my-2 pb-1">
                <div className="min-w-[25%]">
                  <h4>Product</h4>
                </div>
                <div className="min-w-[25%]">
                  <h6>Store</h6>
                </div>
                <div className="min-w-[25%]">
                  <h6>Price</h6>
                </div>
                <div className="min-w-[25%]">
                  <h6>Quantity</h6>
                </div>
              </div>
              {lowStockProducts?.length > 0 ? (
                lowStockProducts[0]?.AllData?.map((item) => (
                  <div
                    className="d-flex justify-content-between border-bottom my-2 pb-1"
                    onClick={() => navigate(`/product-details/${item?._id}`)}
                  >
                    <div className="min-w-[25%]">
                      <img
                        src={
                          "http://41.76.110.60:8081" +
                          "/productsImages/" +
                          item?.customerPic
                        }
                        className="cust-img rounded-circle me-2"
                        alt=""
                      />
                      <span>
                        {item?.Name?.length > 20
                          ? item?.Name?.substring(0, 20) + "..."
                          : item?.Name}
                      </span>
                    </div>
                    <div className="min-w-[25%]">
                      <small>{item?.Store}</small>
                    </div>
                    <div className="min-w-[25%]">
                      <small>{item?.Price}</small>
                    </div>
                    <div className="min-w-[25%]">
                      <small>{item?.quantity}</small>
                    </div>
                  </div>
                ))
              ) : (
                <h4>No Low Inventory Products Found</h4>
              )}
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-12 mb-2">
          <div class="card border-0 p-3">
            <div class="content">
              <h4 className="text-start mb-3 border-bottom pb-2">
                Recent Orders
              </h4>
              <div className="d-flex border-bottom my-2 pb-1">
                <div className="w-[10%]">
                  <h6>Customer</h6>
                </div>
                <div className="w-[10%]">
                  <h6>Posted On</h6>
                </div>
                <div className="w-[10%]">
                  <h6>Store</h6>
                </div>
                <div className="w-[800px]">
                  <h6>Address</h6>
                </div>

                <div className="w-[10%]">
                  <h6>Status</h6>
                </div>
              </div>
              {allData?.recentOrders?.length > 0 ? (
                allData?.recentOrders[0]?.AllData?.map((item) => (
                  <div
                    className="d-flex border-bottom my-2 pb-1"
                    onClick={() => navigate(`/order-details/${item?.Id}`)}
                  >
                    <div className="w-[10%]">
                      <span>{item?.customerName}</span>
                    </div>
                    <div className="w-[10%]">
                      <small>
                        {moment(item?.createdAt).format("DD/MMM/YY")}
                      </small>
                    </div>
                    <div className="w-[10%]">
                      <small>{item?.store}</small>
                    </div>
                    <div className="w-[800px] pr-5">
                      <small>{item?.orderAddress}</small>
                    </div>
                    <div className="w-[10%]">
                      <small>{item?.status}</small>
                    </div>
                  </div>
                ))
              ) : (
                <h4>No Recent Orders Found</h4>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
