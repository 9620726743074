import React , {useState , useEffect } from 'react'
import { updateIUmageOfAnyStore , getAllSubCategoriesOfAnyCategory , changeDataUpdate , updateMyNewTiming ,  getRelatedSub ,  getAllParentCategories, getAllSubCategories , getAllAdmins , getMySingleStoreDeatils ,  updateOfAnyStore  , getAllStoresOfAnyVender  , getAllParentCategoriesForVenders , getAllSubCategoriesOfVender , updateOfAnyStoreByVender  , updateImageOfAnyStoreByVender } from '../../api/AdminApi'
import {useNavigate , useParams} from 'react-router-dom'
import { Col, Row,Form ,Modal , Button } from 'react-bootstrap';
import { ThreeDots } from  'react-loader-spinner'
import { toast } from 'react-toastify';


const StoreDetails = () => {
    const navigate = useNavigate()
    const [ isGetting , setIsGetting ] = useState(false)
    const [ singleUserData , setSingleUserData ] = useState({})
    const [ uploadImage , setUploadImage ] = useState(null)
    const [ allParent , setAllParent ] = useState([])
    const [ allSubParent , setAllSubParent ] = useState([])
    const [ allSubParentNew , setAllSubParentNew ] = useState([])
    const [ allVenders , setAllVenders ] = useState([])
    const {id} = useParams()

    // checking if signed in or not
    const [ isAdmin , setIsAdmin ] = useState(false)
    useEffect(() => {
        let adminToken = JSON.parse(localStorage.getItem('priceApp-admin-auth-token'))
        if(!adminToken){
            navigate('/signin')
        }
        let isMyAdmin = JSON.parse(localStorage.getItem('isAdmin'))
        if(isMyAdmin === false){
            setIsAdmin(false)
        }else{
        setIsAdmin(true)
        }
    },[])

    // getting all categories + sub categories + venders
    useEffect(() => {
        const getAllCate = async () => {
            if(isAdmin === true){
                const {data} = await getAllParentCategories();
                if(data?.success === true){
                    setAllParent(data?.AllCategories)
                }
            }else{
                const {data} = await getAllParentCategoriesForVenders();
                if(data?.success === true){
                    setAllParent(data?.AllCategories)
                }
            }
        }
        getAllCate()

        const getAllVenders = async () => {
            const {data} = await getAllAdmins();
            if(data?.success === true){
                setAllVenders(data?.AllAdmins)
            }
        }
        getAllVenders()
    },[isAdmin])

    // getting store details
    useEffect(() => {
        const getData = async () => {
            setIsGetting(true)
            const {data} = await getMySingleStoreDeatils(id);
            console.log("data got : ", data)
            if(data?.success === true){
                setSingleUserData(data?.Stores);

                // getting data of new sub categories
                if(isAdmin === true){
                    const res = await getAllSubCategories({categories : data?.Stores?.categories});
                    if(res?.data?.success === true){
                        setAllSubParent(res?.data?.AllSubCategories)
                    }
                }else{
                    const res = await getAllSubCategoriesOfVender({categories : data?.Stores?.categories});
                    if(res?.data?.success === true){
                        setAllSubParent(res?.data?.AllSubCategories)
                    }
                }

                // getting all sub categories of related parent category
                for(let i = 0; i !== data?.Stores?.categories.length; i++){
                    await getRelatedSub(data?.Stores?.categories[i])
                }
            }else{
                toast.error(data?.message)
            }
            setIsGetting(false)
        }

        getData()
    },[isAdmin])

    // updating data
    const updateData = async () => {
        setIsGetting(true)
        if(isAdmin === true){
            const {data} = await updateOfAnyStore(singleUserData._id , singleUserData);
            if(data?.success === true){
                toast.success(data?.message)
                // uploading image
                if(uploadImage !== null){
                    let formData = new FormData();
                    formData.append("storeImage", uploadImage)
                    const res = await updateIUmageOfAnyStore(singleUserData._id, formData);
                    console.log("response of image uploading : ", res?.data)
                    if(res?.data?.success === true){
                        toast.success("Image Uploaded SuccessFully")
                    }else{
                        toast.error("Image Could Not be Uploaded.")
                    }
                }
                
                setIsGetting(true)
                const res = await getMySingleStoreDeatils(id);
                if(res?.data?.success === true){
                    setSingleUserData(res?.data?.Stores);

                    // getting data of new sub categories
                    if(isAdmin === true){
                        const response = await getAllSubCategories({categories : res?.data?.Stores?.categories});
                        if(response?.data?.success === true){
                            setAllSubParent(response?.data?.AllSubCategories)
                        }
                    }else{
                        const response = await getAllSubCategoriesOfVender({categories : res?.data?.Stores?.categories});
                        if(response?.data?.success === true){
                            setAllSubParent(response?.data?.AllSubCategories)
                        }
                    }

                    // getting all sub categories of related parent category
                    for(let i = 0; i !== res?.data?.Stores?.categories.length; i++){
                        await getRelatedSub(res?.data?.Stores?.categories[i])
                    }
                }else{
                    toast.error(data?.message)
                }
                setIsGetting(false)
            }else{
                toast.error(data?.message)
            }
        }else{
            const {data} = await updateOfAnyStoreByVender(singleUserData._id , singleUserData);
            if(data?.success === true){
                toast.success(data?.message)
    
                // uploading image
                if(uploadImage !== null){
                    let formData = new FormData();
                    formData.append("storeImage", uploadImage)
                    const res = await updateImageOfAnyStoreByVender(singleUserData._id, formData);
                    console.log("response of image uploading : ", res?.data)
                    if(res?.data?.success === true){
                        toast.success("Image Uploaded SuccessFully")
                    }else{
                        toast.error("Image Could Not be Uploaded.")
                    }
                }
                setIsGetting(true)
                const res = await getMySingleStoreDeatils(id);
                if(res?.data?.success === true){
                    setSingleUserData(res?.data?.Stores);

                    // getting data of new sub categories
                    if(isAdmin === true){
                        const response = await getAllSubCategories({categories : res?.data?.Stores?.categories});
                        if(response?.data?.success === true){
                            setAllSubParent(response?.data?.AllSubCategories)
                        }
                    }else{
                        const response = await getAllSubCategoriesOfVender({categories : res?.data?.Stores?.categories});
                        if(response?.data?.success === true){
                            setAllSubParent(response?.data?.AllSubCategories)
                        }
                    }

                    // getting all sub categories of related parent category
                    for(let i = 0; i !== res?.data?.Stores?.categories.length; i++){
                        await getRelatedSub(res?.data?.Stores?.categories[i])
                    }
                }else{
                    toast.error(data?.message)
                }
                setIsGetting(false)
            }else{
                toast.error(data?.message)
            }
        }
        setIsGetting(false)
    }

    // updating timings while updating store
    const updateMyNewTiming = (day , type , value) => {
        let newArr = singleUserData?.timings;
        let isFound = newArr.find(item => item?.day === day);
        if(isFound){
            if(type === "openingTime"){
                isFound.openingTime = value
            }else{
                isFound.closingTime = value
            }
            newArr.filter(item => item.day === day ? isFound : item)
        }
        setSingleUserData({...singleUserData , timings : newArr})
    }

    // adding categories on update
    const changeDataUpdate = async (e) => {
        let newData = singleUserData;
        let isFound = newData.categories?.find(item => item == e)
        if(isFound){
            let newMyArr = newData.categories?.filter(item => item != e)
            newData.categories = newMyArr
        }else{
            newData.categories.push(e)
        }

        // getting data of new sub categories
        if(isAdmin === true){
            const {data} = await getAllSubCategories({categories : newData.categories});
            console.log("all sub categories in update ", data)
            if(data?.success === true){
                setAllSubParent(data?.AllSubCategories)
            }
        }else{
            const {data} = await getAllSubCategoriesOfVender({categories : newData.categories});
            if(data?.success === true){
                setAllSubParent(data?.AllSubCategories)
            }
        }

        // checking no parent category is selected
        if(newData.categories?.length < 1){
            setAllSubParent([])
        }
        setSingleUserData(newData)
    }

    // adding sub categories on update
    const changeDataOneUpdate = (e) => {
        let newData = singleUserData;
        let isFound = newData.subCategories.find(item => item == e)
        if(isFound){
            let newMyArr = newData?.subCategories?.filter(item => item != e)
            newData.subCategories = newMyArr
        }else{
            newData.subCategories.push(e)
        }
        setSingleUserData(newData)
    }
    
    // getting all related sub categories
    const getRelatedSub = async (id) => {
        const {data} = await getAllSubCategoriesOfAnyCategory(id);
        if(data?.success === true ){
            let finalArr = []
            for(let i = 0; i !== allSubParentNew?.length; i++){
                let isFound = finalArr.find(item => item == id)
                if(!isFound){
                    finalArr.push(allSubParentNew[i])
                }
            }
            for(let i = 0; i !== data?.AllSubCategories?.length; i++){
                let isFound = finalArr.find(item => item == id)
                if(!isFound){
                    finalArr.push(data?.AllSubCategories[i])
                }
            }
            setAllSubParentNew(finalArr)
        }
    }

    return (
        <div className="px-2">
            <h4 className="max-w-[200px] mx-auto" >Store Details</h4>
            {
                isGetting === true ? (
                    <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                        <ThreeDots
                            height = "60"
                            width = "60"
                            radius = "9"
                            color = 'green'
                            ariaLabel = 'three-dots-loading'
                            wrapperStyle
                            wrapperClass
                        />
                    </div>
                ) : (
                    <div className="flex justify-center" >
                        <div className='flex justify-start flex-col align-center pb-4 px-2 w-[100%] mx-auto  pt-3' >
                            <Row >
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                    <img src={ process.env.REACT_APP_LIVE_SERVER + "/storeImages/" + singleUserData?.logo} alt="cover pic" style={{maxWidth : '80px', maxHeight : '80px', borderRadius : '10px'}} />
                                        <input type="file" accept="image/*" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' onChange={(e) => setUploadImage(e.target.files[0])} />
                                    </div>                                                                      
                                </Col>
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                    {
                                        uploadImage !== null && (
                                            <div className="flex justify-between mt-4">
                                                <img src={URL.createObjectURL(uploadImage)} alt="cover pic" style={{maxWidth : '80px', maxHeight : '80px', borderRadius : '10px'}} />
                                                <button className='bg-[#e74c3c] mt-3 rounded-md p-2 text-white font-semibold mx-auto max-h-[40px]' onClick={() => setUploadImage(null)} >Remove</button>
                                            </div>
                                        )
                                    }                                                                           
                                </Col>
                            </Row>
                            <Row >
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6> Name:</h6>
                                        <input type="text" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.name} onChange={(e) => setSingleUserData({...singleUserData , name : e.target.value})} />
                                    </div>                                                                      
                                </Col>
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6> Rating:</h6>
                                        <input type="number" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.rating && singleUserData?.rating['$numberDecimal']?.toLocaleString()} disabled={true} />
                                    </div>                                                                      
                                </Col>
                            </Row>
                            <Row >
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6> Review:</h6>
                                        <input type="number" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.review && singleUserData?.review['$numberDecimal']?.toLocaleString()} disabled={true} />
                                    </div>                                                                      
                                </Col>
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>Address:</h6>
                                        <span style={{color : 'crimson' , fontSize : '12px' }} >Please always provide correct address</span>
                                        <input type="email" placeholder="Enter Email Please ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.address} onChange={(e) => setSingleUserData({...singleUserData , address : e.target.value})} />
                                    </div>                                                                      
                                </Col>
                            </Row>
                            <Row >
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>Choose Parent Category:</h6>
                                        <select class="form-select" aria-label="Default select example" multiple >
                                            <option selected disabled></option>
                                            {
                                                allParent?.length > 0 ? (
                                                    allParent?.map((item) => (
                                                        singleUserData?.categories?.length > 0 && singleUserData?.categories?.find(itemOne => itemOne == item?._id ) ? (
                                                            <option value={item?._id} selected onClick={() => changeDataUpdate(item?._id)} >{item?.name}</option>
                                                        ) : (
                                                            <option value={item?._id} onClick={() => changeDataUpdate(item?._id)} >{item?.name}</option>
                                                        )
                                                    ))
                                                ) : (
                                                    <option >No Parent Categories Found</option>
                                                )
                                            }
                                        </select>
                                </div>                                                                          
                                </Col>
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                        {
                                            isAdmin === true ? (
                                                <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                    <h6>Update Vendor:</h6>
                                                    <select class="form-select" aria-label="Default select example" style={{maxWidth : '370px'}}  onChange={(e) => setSingleUserData({...singleUserData , owner : e.target.value})}>
                                                        {
                                                            allVenders?.length > 0 ? (
                                                                allVenders?.map((item) => (
                                                                    singleUserData?.owner == item?._id ? (
                                                                        <option value={item?._id} selected >{item?.username}</option>
                                                                    ) : (
                                                                        <option value={item?._id} >{item?.username}</option>
                                                                    )
                                                                ))
                                                            ) : (
                                                                <option >No Vendors Found</option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            ) : (
                                                null
                                            )
                                        }                                                                       
                                </Col>
                            </Row>
                            <Row >
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        {
                                            allSubParentNew?.length > 0 ? (
                                                <>
                                                    <h6>Choose Sub Categories:</h6> 
                                                    <Form.Select size="lg" multiple >
                                                        {
                                                            allSubParentNew?.length > 0 ? (
                                                                allSubParentNew?.map((item) => (
                                                                    singleUserData?.subCategories?.length > 0 && singleUserData?.subCategories?.find(itemOne => itemOne == item?._id ) ? (
                                                                        <option value={item?._id} selected onClick={() => changeDataOneUpdate(item?._id)}  >{item?.name}</option>
                                                                    ) : (
                                                                        <option value={item?._id} onClick={() => changeDataOneUpdate(item?._id)}  >{item?.name}</option>
                                                                    )
                                                                ))
                                                            ) : (
                                                                <option >No Parent Categories Found</option>
                                                            )
                                                        }
                                                    </Form.Select>
                                                </>
                                            ) : (
                                                <h5>Please select any parent category</h5>
                                            )
                                        }
                                        
                                        
                                    </div>                                                                      
                                </Col>
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>Store Description:</h6>
                                        <textArea rows="8" placeholder="Write Store Description" style={{border : "1px solid #b2bec3", padding : "10px", borderRadius : "10px"} } onChange={(e) => setSingleUserData({...singleUserData , desc : e.target.value})} >
                                            {singleUserData?.desc}
                                        </textArea>
                                    </div>                                                                      
                                </Col>
                            </Row>
                            <Row >
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>Store Timings:</h6>
                                        <Row style={{marginBottom :"25px"}} >
                                            <Col xs={4} sm={3} md={4} lg={4} >
                                                <h6 style={{textAlign : "center"}} >Day</h6>
                                            </Col>
                                            <Col xs={4} sm={4} md={4} lg={4} >
                                                <h6 style={{textAlign : "center"}}>Opening Time</h6>
                                            </Col>
                                            <Col xs={4} sm={4} md={4} lg={4} >
                                                <h6 style={{textAlign : "center"}}>Closing Time</h6>
                                            </Col>
                                        </Row>
                                        {
                                            singleUserData?.timings?.map((item, index ) => (
                                                <>
                                                    {console.log("==>> : " , item)}
                                                    <Row style={{marginBottom : "10px", borderBottom : "1px solid #dfe6e9"}} key={index} >
                                                        <Col xs={4} sm={3} md={4} lg={4} >
                                                            <h6 style={{textAlign : "center"}}>{item?.day}</h6>
                                                        </Col>
                                                        <Col xs={4} sm={4} md={4} lg={4} >
                                                            <input type="time" value={item?.openingTime} onChange={(e) => updateMyNewTiming(item?.day , "openingTime" , e.target.value) } />
                                                        </Col>
                                                        <Col xs={4} sm={4} md={4} lg={4} >
                                                            <input type="time" value={item?.closingTime} onChange={(e) => updateMyNewTiming(item?.day , "closingTime" , e.target.value) } />
                                                        </Col>
                                                    </Row>
                                                </>
                                            ))
                                        }
                                    </div>                                                                      
                                </Col>
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%] flex flex-col justify-center items-start pl-6">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>URL:</h6>
                                        <input type="text"  placeholder="Enter Store website url ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72] ' style={{border: '1px solid black' , minWidth : "300px" , marginBottom : "15px"}} value={singleUserData?.url} onChange={(e) => setSingleUserData({...singleUserData , url : e.target.value})} />
                                    </div> 
                                    <Button variant="success" style={{width : "200px" , marginLeft : "10px"}} onClick={updateData} >Update Now</Button>                                                                        
                                </Col>
                            </Row>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default StoreDetails