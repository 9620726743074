import React , {useState , useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { ThreeDots } from  'react-loader-spinner'
import { toast } from 'react-toastify';
import {getAllSubCategoriesForMain , updateStatusOfAnyCategory , uploadImageofCategory , getAllParentCategories , getMySingleCategoryDeatils , updateOfAnyCategory  ,adNewSubCategory} from '../../api/AdminApi'
import {useNavigate} from 'react-router-dom'
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
import { Col, Row, Table ,InputGroup , Form , Modal , Button , Dropdown, ButtonGroup  } from 'react-bootstrap';
import { AiOutlineEdit} from 'react-icons/ai'

const ViewAllvendors = () => {
    const navigate = useNavigate()
    const TABLE_HEADERS = [
        {
            prop: "name",
            title: "Name"
        },
        {
            prop: "image",
            title: "Image",
            cell: (prop) => {
                return (
                    <img src={ prop?.image?.indexOf("https") == 0 ? prop?.image :  process.env.REACT_APP_LIVE_SERVER + "/catgegoryImages/" + prop.image} alt="category imag" style={{maxWidth : '50px', maxHeight : '50px', borderRadius : '10px'}} />
                )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            prop: "related",
            title: "Related Products",
            cell: (prop) => {
                    return (
                            <a target="_blank" style={{textDecoration : 'none'}} href={`/view-all-products-of-sub-category/${prop.name}/${prop.Id}`} >view All</a>
                        )
                },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            prop: "status",
            title: "Status",
            cell: (prop) => {
                return (
                        <Dropdown as={ButtonGroup}>
                            {
                                prop?.status == false && (
                                    <Button size="sm" variant="danger" style={{fontSize : '11px' , fontWeight : 600}} >In Active</Button>
                                )
                            }
                            {
                                prop?.status == true && (
                                    <Button size="sm" variant="success" style={{fontSize : '11px' , fontWeight : 600}} >Active</Button>
                                )
                            }
                            <Dropdown.Toggle split size="sm" variant="primary" id="dropdown-split-basic" />
                            <Dropdown.Menu style={{backgroundColor : 'transparent'}} >
                                <Dropdown.Item onClick={() => changeStatus(prop?.Id)} style={{backgroundColor : '#c23616', color : 'white'}} >Declined</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeStatus(prop?.Id)} style={{backgroundColor : '#10ac84', color : 'white'}} >Approved</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            prop: "action",
            title: "Action",
            cell: (prop) => {
            return (
                    <AiOutlineEdit style={{cursor : 'pointer'}} onClick={() => handleView(prop.Id)} ></AiOutlineEdit>
                )
            },
        },
    ];

    const [ isFetching , setIsFetching ] = useState(false)
    const [ isGetting , setIsGetting ] = useState(false)
    const [ isSending , setIsSending ] = useState(false)
    const [ singleUserData , setSingleUserData ] = useState({})
    const [ uploadImage , setUploadImage ] = useState(null)
    const [ allData , setAllData ] = useState([])
    const [ allParent , setAllParent ] = useState([])
    // sleeping
    const delay = ms => new Promise(res => setTimeout(res, ms));

    // getting all parent categories
    useEffect(() => {
        const getAllCate = async () => {
            const {data} = await getAllParentCategories();
            if(data?.success === true){
                setAllParent(data?.AllCategories)
            }
        }
        getAllCate()
    },[])

    // checking if signed in or not
    useEffect(() => {
        let adminToken = JSON.parse(localStorage.getItem('priceApp-admin-auth-token'))
        if(!adminToken){
            navigate('/signin')
        }
        let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
        if(!isAdmin){
            navigate('/signin')
        }
        if(isAdmin === false){
            navigate('/our stores')
        }
    },[])

    // getting data
    useEffect(() => {
        const getData = async () => {
            setIsFetching(true)
            const {data} = await getAllSubCategoriesForMain();
            if(data?.success === true){
                let allDataArr = []
                for(let i = 0; i !== data?.AllCategories.length; i++){
                    let newArr = {
                        name: data?.AllCategories[i]?.name,
                        Id : data?.AllCategories[i]?._id,
                        image : data?.AllCategories[i]?.image,
                        status : data?.AllCategories[i]?.status !== undefined ? data?.AllCategories[i]?.status : true
                    }
                    allDataArr.push(newArr)
                }
                setAllData(allDataArr)
            }
            setIsFetching(false)
        }
        getData()
    },[])

    // changing status of vender
    const changeStatus = async (id) => {
        let isFound = allData.find(item => item.Id === id);
        if(isFound){
            const {data} = await updateStatusOfAnyCategory(id);
            if(data?.success === true){
                if(isFound.status == false){
                    isFound.status = true
                    toast.success("Product Activated Successfully");
                }else{
                    isFound.status = false
                    toast.success("Product InActivated Successfully");
                }
                let newData = allData;
                let finalData = newData.filter(item => item.Id === id ? isFound : item );
                setAllData(finalData)
            }else{
                toast.success(data?.message);
            }
        }
    }

    // view modal
    const [view, setView] = useState(false);
    const handleViewClose = () => {
        setUploadImage(null)
        setView(false);
        setIsSending(false)
    }
    // getting data of user
    const handleView = async (id) => {
        console.log("id : ", id)
        setView(true);
        setIsGetting(true)
        const {data} = await getMySingleCategoryDeatils(id);
        console.log("data : ", data)
        if(data?.success === true){
            setSingleUserData(data?.Vender);
        }else{
            toast.error(data?.message)
        }
        setIsGetting(false)
    }

    // updating data
    const updateData = async () => {
        setIsSending(true)
        const {data} = await updateOfAnyCategory(singleUserData._id , singleUserData);
        if(data?.success === true){
            toast.success(data?.message)

            // uploading image
            if(uploadImage !== null){
                let formData = new FormData();
                formData.append("categoryMyImage", uploadImage)
                const res = await uploadImageofCategory(singleUserData._id, formData);
                if(res?.data?.success === true){
                    toast.success("Image Uploaded SuccessFully")
                }else{
                    toast.error("Image Could Not be Uploaded.")
                }
            }
            // getting updated data
            setIsFetching(true)
            const response = await getAllSubCategoriesForMain();
            if(response?.data?.success === true){
                let allDataArr = []
                for(let i = 0; i !== response?.data?.AllCategories.length; i++){
                    let newArr = {
                        name: response?.data?.AllCategories[i]?.name,
                        Id : response?.data?.AllCategories[i]?._id,
                        image : response?.data?.AllCategories[i]?.image,
                        status : response?.data?.AllCategories[i]?.status !== undefined ? response?.data?.AllCategories[i]?.status : true
                    }
                    allDataArr.push(newArr)
                }
                setAllData(allDataArr)
            }
            setIsFetching(false)

            await delay(1500)
            handleViewClose()
        }else{
            toast.error(data?.message)
        }
        setIsSending(false)
    }

    // getting parent categories
    useEffect(() => {
        const getAllCate = async () => {
            const {data} = await getAllParentCategories();
            if(data?.success === true){
                setAllParent(data?.AllCategories)
            }
        }
        getAllCate()
    },[])

    // add new modal
    const [addNew, setAddNew] = useState(false);
    const handleAddNewClose = () => {
        setUploadImage(null)
        setUserData({
            name : "",
            image : "",
            isParent : false,
            parentId : ""
        })
        setAddNew(false);
        setIsSending(false)
    }
    // getting data of user
    const handleAddNew = async (id) => {
        setAddNew(true);
    }

    const [userData , setUserData ] = useState({
        name : "",
        image : "",
        isParent : false,
        parentId : ""
    })

    // sending data
    const addNewVender = async () => {
        if(userData?.image === ""){
            toast.warning("Image ofd Sub Category is Required");
            return;
        }
        setIsSending(true)
        let formData = new FormData();
        formData.append("name" , userData?.name)
        formData.append("categoryImage" , userData?.image)
        formData.append("parentId" , userData?.parentId)
        formData.append("isParent" , userData?.isParent)
        const {data} = await adNewSubCategory(formData);
        if(data?.success === true){
            toast.success("New Sub  Category Added SuccessFully")

            setIsFetching(true)
            const res = await getAllSubCategoriesForMain();
            if(res?.data?.success === true){
                let allDataArr = []
                for(let i = 0; i !== res?.data?.AllCategories.length; i++){
                    let newArr = {
                        name: res?.data?.AllCategories[i]?.name,
                        Id : res?.data?.AllCategories[i]?._id,
                        image : res?.data?.AllCategories[i]?.image,
                        status : res?.data?.AllCategories[i]?.status !== undefined ? res?.data?.AllCategories[i]?.status : true
                    }
                    allDataArr.push(newArr)
                }
                setAllData(allDataArr)
            }
            setIsFetching(false)

            await delay(1000)
            handleAddNewClose()
        }else{
            toast.error(data?.message)
        }
        setIsSending(false)
    }

    return (
        <>
            {
                isFetching === true ? (
                    <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                        <ThreeDots
                            height = "60"
                            width = "60"
                            radius = "9"
                            color = 'green'
                            ariaLabel = 'three-dots-loading'
                            wrapperStyle
                            wrapperClass
                        />
                    </div>
                ) : (
                    <DatatableWrapper
                        body={allData}
                        headers={TABLE_HEADERS}
                        paginationOptionsProps={{
                            initialState: {
                                rowsPerPage: 10,
                                options: [5, 10, 15, 20]
                            }
                        }}
                        >
                        <Row className="p-4 mb-4">
                            <Col
                                xs={12}
                                lg={4}
                                className="flex-col d-flex justify-content-start align-items-start"
                            >
                                <h4>All Sub Categories</h4>
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="flex-col mb-2 d-flex justify-content-lg-center align-items-center justify-content-sm-start mb-sm-0"
                            >
                                <Button size="lg" variant="info" onClick={handleAddNew} style={{fontSize : '15px' , fontWeight : 600}} >Add New</Button>
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="flex-col d-flex justify-content-end align-items-end"
                            >
                                {/* <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Search here..."
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        onBlur = {() => getAllData()}
                                        onChange={(e) => setText(e.target.value)}
                                    />
                                        <Button variant="info" onClick={getSearchedMerchants}>Search</Button>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Table striped bordered hover className="ml-2 mr-2" >
                            <TableHeader />
                            <TableBody />
                        </Table>
                        <Row className="p-2 mb-4">
                            <Col
                                xs={12}
                                sm={6}
                                lg={6}
                                className="flex-col mb-2 d-flex justify-content-lg-start align-items-start justify-content-sm-start mb-sm-0"
                            >
                                <PaginationOptions />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={6}
                                className="flex-col d-flex justify-content-end align-items-end"
                            >
                                <Pagination />
                            </Col>
                        </Row>
                    </DatatableWrapper>
                )
            }

            {/* view and update modal */}
                <Modal
                    show={view}
                    onHide={handleViewClose}
                    backdrop="static"
                    keyboard={false}
                    //style={{marginTop : '100px'}}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Catgeory Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            isGetting === true ? (
                                <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                    <ThreeDots
                                        height = "60"
                                        width = "60"
                                        radius = "9"
                                        color = 'green'
                                        ariaLabel = 'three-dots-loading'
                                        wrapperStyle
                                        wrapperClass
                                    />
                                </div>
                            ) : (
                                <div className="flex justify-center" >
                                    <div className='flex justify-center flex-col align-center pb-4  max-w-[400px] w-[100%] mx-auto  pt-3' >
                                        <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <img src={ process.env.REACT_APP_LIVE_SERVER + "/catgegoryImages/" + singleUserData?.profilePic} alt="cover pic" style={{maxWidth : '80px', maxHeight : '80px', borderRadius : '10px'}} />
                                            <input type="file" accept="image/*" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' onChange={(e) => setUploadImage(e.target.files[0])} />
                                        </div>
                                        {
                                            uploadImage !== null && (
                                                <div className="flex justify-between mt-4">
                                                    <img src={URL.createObjectURL(uploadImage)} alt="cover pic" style={{maxWidth : '80px', maxHeight : '80px', borderRadius : '10px'}} />
                                                    <button className='bg-[#e74c3c] mt-3 rounded-md p-2 text-white font-semibold mx-auto max-h-[40px]' onClick={() => setUploadImage(null)} >Remove</button>
                                                </div>
                                            )
                                        }
                                        <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                            <h6>Name:</h6>
                                            <input type="text" placeholder="Enter Sub Category name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.name} onChange={(e) => setSingleUserData({...singleUserData , name : e.target.value})} />
                                        </div>
                                        <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                            <h6>Choose Parent Category:</h6>
                                            <select class="form-select" aria-label="Default select example" onChange={(e) => setSingleUserData({...singleUserData , parentId : e.target.value})} >
                                                {
                                                    allParent?.length > 0 ? (
                                                        allParent?.map((item) => (
                                                            singleUserData?.parentId == item?._id ? (
                                                                <option value={item?._id}selected >{item?.name}</option>
                                                            ) : (
                                                                <option value={item?._id}>{item?.name}</option>
                                                            )
                                                        ))
                                                    ) : (
                                                        <option >No Parent Categories Found</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            isSending === true ? (
                                <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                    <ThreeDots
                                        height = "60"
                                        width = "60"
                                        radius = "9"
                                        color = 'green'
                                        ariaLabel = 'three-dots-loading'
                                        wrapperStyle
                                        wrapperClass
                                    />
                                </div>
                            ) : (
                                <>
                                    <Button variant="danger" onClick={handleViewClose}>
                                        Close
                                    </Button>
                                    <Button variant="success" onClick={updateData} >Update Now</Button>
                                </>
                            )
                        }
                    </Modal.Footer>
                </Modal>
            {/* view and update modal end */}


            {/* add new modal */}
                <Modal
                    show={addNew}
                    onHide={handleAddNewClose}
                    backdrop="static"
                    keyboard={false}
                    //style={{marginTop : '100px'}}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Sub</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="flex justify-center" >
                        <div className='flex justify-center flex-col align-center pb-4  max-w-[400px] w-[100%] mx-auto pt-3' >
                            <h6 className='text-2xl font-bold text-center' >Add New Category</h6>
                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                <h6>Name:</h6>
                                <input type="text" placeholder="Enter Sub Category name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: '1px solid black'}} value={userData?.name} onChange={(e) => setUserData({...userData , name : e.target.value})} />
                            </div>
                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                <h6>Choose Image:</h6>
                                <input type="file" accept="image/png, image/gif, image/jpeg"  className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: '1px solid black'}} onChange={(e) => setUserData({...userData , image : e.target.files[0]})} />
                            </div>
                            {
                                userData?.image !== "" && (
                                    <div className="flex justify-between mt-4 mb-5">
                                        <img src={URL.createObjectURL(userData?.image)} alt="cover pic" style={{maxWidth : '80px', maxHeight : '80px', borderRadius : '10px'}} />
                                        <button className='bg-[#e74c3c] mt-3 rounded-md p-2 text-white font-semibold mx-auto max-h-[40px]' onClick={(e) => setUserData({...userData , image : ""})} >Remove</button>
                                    </div>
                                )
                            }
                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                <h6>Choose Parent Category:</h6>
                                <select class="form-select" aria-label="Default select example" onChange={(e) => setUserData({...userData , parentId : e.target.value})} >
                                    <option selected disabled></option>
                                    {
                                        allParent?.length > 0 ? (
                                            allParent?.map((item) => (
                                                <option value={item?._id}>{item?.name}</option>
                                            ))
                                        ) : (
                                            <option >No Parent Categories Found</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            isSending === true ? (
                                <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                    <ThreeDots
                                        height = "60"
                                        width = "60"
                                        radius = "9"
                                        color = 'green'
                                        ariaLabel = 'three-dots-loading'
                                        wrapperStyle
                                        wrapperClass
                                    />
                                </div>
                            ) : (
                                <>
                                    <Button variant="danger" onClick={handleAddNewClose}>
                                        Close
                                    </Button>
                                    <Button variant="success" onClick={addNewVender} >Add Now</Button>
                                </>
                            )
                        }
                    </Modal.Footer>
                </Modal>
            {/* add new modal end */}
        </>
    );
}

export default ViewAllvendors;
