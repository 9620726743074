import React, { useEffect, useState, useMemo } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { FiShoppingCart } from "react-icons/fi";
import { BsChatLeft } from "react-icons/bs";
import { RiNotification3Line } from "react-icons/ri";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { GrNotification } from "react-icons/gr";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import avatar from "../data/avatar.jpg";
import { Cart, Chat, Notification, UserProfile } from ".";
import { useStateContext } from "../contexts/ContextProvider";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const Navbar = () => {
  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    handleClick,
    isClicked,
  } = useStateContext();
  const [isUser, setIsUser] = useState(false);

  useEffect(() => {
    let adminToken = JSON.parse(
      localStorage.getItem("priceApp-admin-auth-token")
    );
    if (adminToken) {
      console.log("token found");
      setIsUser(true);
    } else {
      navigate("/signin");
      console.log("token not found");
    }
  }, []);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  // sleeping
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const navigate = useNavigate();

  const logout = async () => {
    localStorage.removeItem("priceApp-admin-auth-token");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("user");
    toast.success("Signed Out SuccessFully");
    await delay(1500);
    window.location.reload();
  };

  const [isDropdownOpend, setDropdownOpen] = useState(false);

  return (
    isUser == true && (
      <div
        className="relative flex justify-between p-2 md:ml-6 md:mr-6"
        style={{ maxWidth: "86%", width: "100%" }}
      >
        <NavButton
          title="Menu"
          customFunc={handleActiveMenu}
          color={currentColor}
          icon={<AiOutlineMenu />}
        />
        <div
          style={{
            display: "flex",
            maxWidth: "200px",
            minWidth: "150px",
            justifyContent: "space-between",
          }}
        >
          <NavButton
            title="Notification"
            dotColor="rgb(254, 201, 15)"
            customFunc={() => handleClick("notification")}
            color={"#2d3436"}
            icon={<RiNotification3Line />}
          />

          <Button
            variant="info"
            style={{ color: "white", fontWeight: 600, maxHeight: "40px" }}
            onClick={logout}
          >
            Logout
          </Button>
        </div>
        {/* <div className="flex"> */}
        {/* <NavButton title="Cart" customFunc={() => handleClick('cart')} color={currentColor} icon={<FiShoppingCart />} />
            <NavButton title="Chat" dotColor="#03C9D7" customFunc={() => handleClick('chat')} color={currentColor} icon={<BsChatLeft />} /> */}
        {/* <TooltipComponent content="Profile" position="BottomCenter">
              {/* <div
                className="flex items-center gap-2 p-1 rounded-lg cursor-pointer hover:bg-light-gray"
                onClick={() => handleClick('userProfile')}
              >
                <img
                  className="w-8 h-8 rounded-full"
                  src={avatar}
                  alt="user-profile"
                />
                <p>
                  <span className="text-gray-400 text-14">Hi,</span>{' '}
                  <span className="ml-1 font-bold text-gray-400 text-14">
                    Michael
                  </span>
                </p>
                <MdKeyboardArrowDown className="text-gray-400 text-14" />
              </div> */}
        {/* </TooltipComponent> */}

        {/* {isClicked.cart && (<Cart />)}
            {isClicked.chat && (<Chat />)}
            {isClicked.notification && (<Notification />)}
            {isClicked.userProfile && (<UserProfile />)} */}
        {isClicked.notification && <Notification />}
        {/* </div> */}
      </div>
    )
  );
};

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  //isUser == true && (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative p-3 text-xl rounded-full hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex w-2 h-2 rounded-full right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
  //)
);

export default Navbar;
