const axios = require('axios');

const API = axios.create({
    //baseURL : process.env.REACT_APP_LOCAL_SERVER,
    baseURL: process.env.REACT_APP_LIVE_SERVER,
});

// getting token from local storage , stored while logging in
let adminToken = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN))

// this is for using local storage in headers, otherwise it will not work
API.interceptors.request.use((req) => {
    if (localStorage.getItem('priceApp-admin-auth-token')) {
        req.headers = {
            'authorization': `Bearer ${adminToken}`,
            'priceApp-admin-auth-token': JSON.parse(localStorage.getItem("priceApp-admin-auth-token")),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }
    return req;
});

API.interceptors.response.use(
    (response) => response,
    error => {
        const { status } = error?.response || {};
        if (status === 401) {
            localStorage.clear()
            window.location.href = '/signin'
        }
        return error
    }
);

// Admin Routes
const signInAdmin = (data) => API.post(`/api/v1/admin/signin`, data);
const addNewVendor = (data) => API.post(`/api/v1/venders/register`, data);
const addNewRider = (data) => API.post(`/api/v1/riders/register`, data);
const addNewOffer = (data) => API.post(`/api/v1/offers/addNew`, data);
const addNewOfferByVender = (data) => API.post(`/api/v1/offers/addNewOfferByVender`, data);
const addNewCuratedLis = (data) => API.post(`/api/v1/curatedLists/addNew`, data);
const addNewBanner = (data) => API.post(`/api/v1/bannerImages/addNew`, data);
const updateIUmageOfAnyVendor = (venderId, data) => API.put(`/api/v1/admin/uploadPhoto/${venderId}`, data);
const updateIUmageOfAnyStore = (storeId, data) => API.put(`/api/v1/stores/uploadStoreImage/${storeId}`, data);
const updateImageOfAnyStoreByVender = (storeId, data) => API.put(`/api/v1/stores/uploadStoreImageByVender/${storeId}`, data);
const updateStatusOfAnyVendor = (venderId) => API.put(`/api/v1/admin/changeStatudsOfAnyVender/${venderId}`);
const updateIUmageOfAnyProduct = (prodId, data) => API.put(`/api/v1/products/uploadProductImage/${prodId}`,
    data);
const updateImageOfAnyProductByVender = (prodId, data) => API.put(`/api/v1/products/uploadProductImageByVender/${prodId}`, data);
const getAllAdmins = () => API.get(`/api/v1/venders/getAllAdmins`);
const getAllCuratedLists = () => API.get(`/api/v1/curatedLists/getAllCuratedListForAdmin`);
const getAllBanners = () => API.get(`/api/v1/bannerImages/getAllBanners`);
const getAllOrders = () => API.get(`/api/v1/orders/getAllOrdersForAdmin`);
const getAllOrdersOfVender = () => API.get(`/api/v1/orders/getAllOrdersOfAnyVender`);
const getAllRiders = () => API.get(`/api/v1/riders/getAllRiders`);
const addNewCategory = (data) => API.post(`/api/v1/categories/addNew`, data);
const getAllParentCategories = () => API.get(`/api/v1/categories/getAllNew`);
const getAllParentCategoriesForVenders = () => API.get(`/api/v1/categories/getAllForVenders`);
const getAllOffers = () => API.get(`/api/v1/offers/getAll`);
const getAllOffersOfVender = () => API.get(`/api/v1/offers/getAllOfAnyVender`);
const getAllOfferOfVender = () => API.get(`/api/v1/offers/getAllOffersOfAnyVender`);
const adNewSubCategory = (data) => API.post(`/api/v1/categories/addNewSubcategory`, data);
const uploadImageofCategory = (categoryId, data) => API.put(`/api/v1/categories/uploadImage/${categoryId}`, data);
const getAllSubCategoriesForMain = () => API.get(`/api/v1/categories/getAllSubNew`);
const getAllSubCategories = (data) => API.put(`/api/v1/categories/getAllSubCategoriesOfAnyCategoryNew`, data);
const getAllSubCategoriesOfVender = () => API.get(`/api/v1/categories/getAllSubCategoriesForVenders`);
const getAllUsers = () => API.get(`/api/v1/users/getAllUserAdmin`);
const getAllUsersForVender = () => API.get(`/api/v1/users/getAllUserVender`);
const updateStatusOfAnyuser = (user) => API.put(`/api/v1/users/updateStatusOfUser/${user}`);
const addNewStore = (data) => API.post(`/api/v1/stores/addNew`, data);
const addNewStoreByVender = (data) => API.post(`/api/v1/stores/addNewByVender`, data);
const getAllStores = () => API.get(`/api/v1/stores/getAll`);
const getAllStoresOfAnyVender = () => API.get(`/api/v1/stores/getAllOfAnyVender`);
const addNewProduct = (data) => API.post(`/api/v1/products/addNewNew`, data);
const addNewProductByVender = (data) => API.post(`/api/v1/products/addNewProductByVender`, data);
const getAllProducts = () => API.get(`/api/v1/products/getAllProductsForDashboard`);
const getAllProductsOfVender = () => API.get(`/api/v1/products/getAllProductsOfVender`);
const addNewCoupon = (data) => API.post(`/api/v1/coupons/addNew`, data);
const getAllCoupons = () => API.get(`/api/v1/coupons/getAllCouponsForAdmin`);
const getSingleVenderDeatils = (venderId) => API.get(`/api/v1/admin/getSingleAdminDetails/${venderId}`);
const getSingleBannerDetails = (bannerId) => API.get(`/api/v1/bannerImages/getSingle/${bannerId}`);
const getSingleOfferDetails = (offerId) => API.get(`/api/v1/offers/getSingleOffer/${offerId}`);
const getSingleOfferDetailsByVender = (offerId) => API.get(`/api/v1/offers/getSingleOfferByVender/${offerId}`);
const getMySingleCategoryDeatils = (categoryId) => API.get(`/api/v1/categories/getDetailsOfAnyCategory/${categoryId}`);
const getMySingleStoreDeatils = (storeId) => API.get(`/api/v1/stores/getSingleStore/${storeId}`);
const getMySingleProductDeatils = (prodId) => API.get(`/api/v1/products/getSingleProductNew/${prodId}`);
const getMySingleProductDetailsByVender = (prodId) => API.get(`/api/v1/products/getSingleProductByVender/${prodId}`);
const getMySingleUserDeatils = (userId) => API.get(`/api/v1/users/getSingleUserDetails/${userId}`);
const getMySingleCouponDeatils = (couponId) => API.get(`/api/v1/coupons/getDetailsOfAnyCoupon/${couponId}`);
const updateStatusOfAnyCoupon = (couponId) => API.put(`/api/v1/coupons/updateStatusOfAnyCoupon/${couponId}`);
const updateStatusOfAnyRider = (riderId) => API.put(`/api/v1/riders/updateStatusOfUser/${riderId}`);
const updateStatusOfAnyCuratedList = (curatedListId) => API.put(`/api/v1/curatedLists/changeStatusOfAnyListAdmin/${curatedListId}`);
const updateStatusOfAnyProduct = (prodId) => API.put(`/api/v1/products/updateStatusOfAnyproduct/${prodId}`);
const updateStatusOfAnyProductByVender = (prodId) => API.put(`/api/v1/products/updateStatusOfAnyProductByVender/${prodId}`);
const updateStatusOfAnyStore = (storeId) => API.put(`/api/v1/stores/updateSttausOfSingleStore/${storeId}`);
const updateStatusOpenOfAnyStore = (storeId) => API.put(`/api/v1/stores/updateStatusOpenOfAnyStore/${storeId}`);
const updateStatusOfAnyStoreByVender = (storeId) => API.put(`/api/v1/stores/updateStatusOfSingleStoreByVender/${storeId}`);
const updateStatusOfAnyVender = (venderId) => API.put(`/api/v1/admin/changeStatudsOfAnyVender/${venderId}`);
const updateStatusOfAnyBanner = (bannerId) => API.put(`/api/v1/bannerImages/updateSingleBannerStatus/${bannerId}`);
const updateStatusOfAnyOffer = (offerId) => API.put(`/api/v1/offers/updateStatusSingleOffer/${offerId}`);
const updateStatusOfAnyOfferByVender = (offerId) => API.put(`/api/v1/offers/updateStatusSingleOfferByVender/${offerId}`);
const updateStatusOfAnyCategory = (categoryId) => API.put(`/api/v1/categories/updateStatusOfAnyCategory/${categoryId}`);
const getAllProductsOfAnyVender = (venderId) => API.get(`/api/v1/products/getAllProductsOfAnyVender/${venderId}`);
const updateOfAnyVender = (venderId, data) => API.put(`/api/v1/admin/updateSingleAdminDetails/${venderId}`, data);
const updateOfAnyCategory = (categoryId, data) => API.put(`/api/v1/categories/updateDetailsOfAnyCategory/${categoryId}`, data);
const updateOfAnyStoreByVender = (storeId, data) => API.put(`/api/v1/stores/updateSingleStoreByVender/${storeId}`, data);
const updateOfAnyCoupon = (couponId, data) => API.put(`/api/v1/coupons/updateDetailsOfAnyCoupon/${couponId}`, data);
const updateOfAnyProduct = (prodId, data) => API.put(`/api/v1/products/updateDetailsOfAnyproduct/${prodId}`, data);
const updateOfAnyProductByVender = (prodId, data) => API.put(`/api/v1/products/updateDetailsOfAnyProductByVender/${prodId}`, data);
const updateOfAnyStore = (storeId, data) => API.put(`/api/v1/stores/updateSingleStore/${storeId}`, data);
const updateOfAnyBanner = (bannerId, data) => API.put(`/api/v1/bannerImages/updateBanner/${bannerId}`, data);
const updateOfAnyOffer = (offerId, data) => API.put(`/api/v1/offers/updateSingleOffer/${offerId}`, data);
const updateOfAnyOfferByVender = (offerId, data) => API.put(`/api/v1/offers/updateSingleOfferByVender/${offerId}`, data);
const getAllProductsOfAnyCategory = (categoryId) => API.get(`/api/v1/products/getAllProductsOfAnyCategory/${categoryId}`);
const getAllProductsOfAnySubCategory = (categoryId) => API.get(`/api/v1/products/getAllProductsOfAnySubCategory/${categoryId}`);
const getAllProductsOfAnyStore = (storeId) => API.get(`/api/v1/products/getAllProductsOfAnyStoreNew/${storeId}`);
const getAllCategoriesOfAnyStore = (storeId) => API.get(`/api/v1/stores/getAllCategoriesOfAnyStore/${storeId}`);
const getAllSubCategoriesOfAnyCategory = (categoryId) => API.get(`/api/v1/categories/getAllSubCategoriesOfAnyCategory/${categoryId}`);
const getOrdersWithDateRange = (data) => API.put(`/api/v1/orders/getOrdersWithStatingEndingDate`, data);
const getOrdersWithDateRangeForAdmin = (data) => API.put(`/api/v1/orders/getOrdersWithStatingEndingDateForAdmin`, data);
const getProductsWithDateRange = (data) => API.put(`/api/v1/products/getAllProductsDateWise`, data);
const getProductsWithDateRangeForAdmin = (data) => API.put(`/api/v1/products/getAllProductsDateWiseForAdmin`, data);
const getDashboardOrdersOfPartner = () => API.get(`/api/v1/orders/getDashboardDataOfPartner`);
const getAlLProductsCountOfPartner = () => API.get(`/api/v1/products/getAllRecentProductsAddedOfPartnerCount`);
const getAlLStoresCountOfPartner = () => API.get(`/api/v1/stores/getAllStoresCountOfPartner`);
const getAlLOffersCountOfPartner = () => API.get(`/api/v1/offers/getAllOffersCountOfPartner`);
const getDashboardOrders = () => API.get(`/api/v1/orders/getDashboardData`);
const getAlLProductsCount = () => API.get(`/api/v1/products/getAllProductsCount`);
const getAlLStoresCount = () => API.get(`/api/v1/stores/getAllStoresCount`);
const getAlLOffersCount = () => API.get(`/api/v1/offers/getAllOffersCount`);
const getPartnerGraphData = () => API.get(`/api/v1/venders/getDataForDashboard`);
const getAdminGraphData = () => API.get(`/api/v1/admin/getDataForGraph`);
const updateStatusOfOrderByVender = (orderId) => API.put(`/api/v1/orders/changeStatusOfOrderByVender/${orderId}`);
const updateStatusOfOrderByAdmin = (orderId) => API.put(`/api/v1/orders/changeStatusOfOrderByAdmin/${orderId}`);
const changeStatusOfOrderApprove = (orderId) => API.put(`/api/v1/orders/changeStatusOfOrderApprove/${orderId}`);
const acceptAnyOrderByVender = (orderId) => API.put(`/api/v1/orders/acceptAnyOrderByVender/${orderId}`);
const acceptAnyOrderByAdmin = (orderId) => API.put(`/api/v1/orders/acceptAnyOrderByAdmin/${orderId}`);
const getAllNotificationsForAdmin = () => API.get(`/api/v1/notifications/getAllForAdmin`);
const getAllNotificationsForPartner = () => API.get(`/api/v1/notifications/getAllForPartner`);
const getAnyOrderDetails = (orderId) => API.get(`/api/v1/orders/getAnyOrderDetailForAdmin/${orderId}`)
const addAmountToAnyCustomerByAdmin = (customerId, walletAmt) => API.put(`/api/v1/admin/addNewAmountToCustomer/${customerId}/${walletAmt}`);
const addAmountToAnyCustomerByVender = (customerId, walletAmt) => API.put(`/api/v1/admin/addNewAmountToCustomerByVender/${customerId}/${walletAmt}`);
const getAllTransactionsOfAUser = (customerId) => API.get(`/api/v1/transaction/getAllOfACustomerByAdmin/${customerId}`);
const getAllTransactionsOfAUserByVender = (customerId) => API.get(`/api/v1/transaction/getAllOfACustomerByVender/${customerId}`);
const cancelAnyOrderByAdmin = (orderId) => API.put(`/api/v1/orders/cancelAnyOrderbyAdmin/${orderId}/true`);
const cancelAnyOrderByVender = (orderId) => API.put(`/api/v1/orders/cancelAnyOrderbyVender/${orderId}/false`);
const addNewVehicleType = (data) => API.post(`/api/v1/vehicleType/addNew`, data);
const getAllVehicleType = () => API.get(`/api/v1/vehicleType/getAllVehicleTypes`);
const getSingleVehicleType = (vehicleId) => API.get(`/api/v1/vehicleType/getSingleVehicleType/${vehicleId}`);
const updateSingleVehicleType = (vehicleId, data) => API.put(`/api/v1/vehicleType/updateVehicleType/${vehicleId}`, data);
const updateStatusOfSingleVehicleType = (vehicleId) => API.put(`/api/v1/vehicleType/updateSingleVehicleTypeStatus/${vehicleId}`);
const getSingleRiderDetails = (riderId) => API.get(`/api/v1/riders/getSingleRider/${riderId}`);
const updateSingleRiderDetails = (riderId, data) => API.put(`/api/v1/riders/updateSingleRider/${riderId}`, data);
const updateSingleVehicleTypeByAdmin = (orderId, vehicleId) => API.put(`/api/v1/orders/updateOrderVehicleType/${orderId}/${vehicleId}`);
const updateSingleVehicleTypeByVender = (orderId, vehicleId) => API.put(`/api/v1/orders/updateOrderVehicleTypeByVender/${orderId}/${vehicleId}`);
const subtractAmountToAnyCustomerByAdmin = (customerId, walletAmt) => API.put(`/api/v1/admin/subtractNewAmountToCustomer/${customerId}/${walletAmt}`);
const subtractAmountToAnyCustomerByVender = (customerId, walletAmt) => API.put(`/api/v1/admin/subtractNewAmountToCustomerByVender/${customerId}/${walletAmt}`);
const changeOnlineStatusOfRider = (riderId) => API.put(`/api/v1/riders/updateRiderOnlineStatusByAdmin/${riderId}`);
const addNewServeArea = (data) => API.post(`/api/v1/serveArea/addNew`, data);
const getAllServeAreas = () => API.get(`/api/v1/serveArea/getAllServeArea`);
const getSingleServeAreas = (couponId) => API.get(`/api/v1/serveArea/getDetailsOfAnyServeArea/${couponId}`);
const updateStatusServeAreas = (idServerArea, data) => API.put(`/api/v1/serveArea/updateSingleServeArea/${idServerArea}`, data);
const updateSingleServeAreaStatus = (serverAreaId) => API.put(`/api/v1/serveArea/updateStatusOfAnyServeArea/${serverAreaId}`);
const updateConfig = (configId, data) => API.put(`/api/v1/config/updateSingleConfig/${configId}`, data);
const getConfig = () => API.get(`/api/v1/config/getAllConfig`);
const adNewCustomSubCategory = (data) => API.post(`/api/v1/CustomCategories/addNew`, data);
const uploadImageofCustomCategory = (categoryId, data) => API.put(`/api/v1/CustomCategories/uploadOrUpdateCustomCategory/${categoryId}`, data);
const getAllCustomSubCategoriesForMain = () => API.get(`/api/v1/CustomCategories/getAllCustomCategories`);
const getAllCustomSubCategories = (data) => API.put(`/api/v1/CustomCategories/getAllSubCategoriesOfAnyCategoryNew`, data);
const getAllCustomSubCategoriesOfVender = () => API.get(`/api/v1/CustomCategories/getAllSubCategoriesForVenders`);
const updateOfAnyCustomCategory = (categoryId, data) => API.put(`/api/v1/CustomCategories/updateCustomCategories/${categoryId}`, data);

module.exports = {
    signInAdmin,
    addNewVendor,
    getAllAdmins,
    addNewCategory,
    getAllParentCategories,
    adNewSubCategory,
    getAllSubCategories,
    getAllUsers,
    addNewStore,
    getAllStores,
    addNewProduct,
    getAllProducts,
    addNewCoupon,
    getAllCoupons,
    getAllProductsOfAnyVender,
    getAllProductsOfAnyCategory,
    getAllProductsOfAnySubCategory,
    getAllProductsOfAnyStore,
    updateIUmageOfAnyVendor,
    updateStatusOfAnyVendor,
    uploadImageofCategory,
    updateStatusOfAnyuser,
    updateStatusOfAnyCoupon,
    updateStatusOfAnyProduct,
    updateStatusOfAnyStore,
    updateStatusOfAnyVender,
    updateStatusOfAnyCategory,
    getSingleVenderDeatils,
    updateOfAnyVender,
    getMySingleCategoryDeatils,
    updateOfAnyCategory,
    getMySingleUserDeatils,
    getMySingleCouponDeatils,
    updateOfAnyCoupon,
    getMySingleStoreDeatils,
    updateOfAnyStore,
    updateIUmageOfAnyStore,
    getMySingleProductDeatils,
    updateIUmageOfAnyProduct,
    updateOfAnyProduct,
    addNewCuratedLis,
    getAllCuratedLists,
    updateStatusOfAnyCuratedList,
    getAllOrders,
    getAllBanners,
    addNewBanner,
    updateStatusOfAnyBanner,
    getSingleBannerDetails,
    updateOfAnyBanner,
    getAllOffers,
    addNewOffer,
    getSingleOfferDetails,
    updateOfAnyOffer,
    updateStatusOfAnyOffer,
    getAllRiders,
    addNewRider,
    updateStatusOfAnyRider,
    getAllStoresOfAnyVender,
    getAllProductsOfVender,
    getAllOrdersOfVender,
    getAllOfferOfVender,
    addNewStoreByVender,
    getAllParentCategoriesForVenders,
    getAllSubCategoriesOfVender,
    updateOfAnyStoreByVender,
    updateStatusOfAnyStoreByVender,
    addNewProductByVender,
    updateStatusOfAnyProductByVender,
    getMySingleProductDetailsByVender,
    updateOfAnyProductByVender,
    getAllOffersOfVender,
    addNewOfferByVender,
    getSingleOfferDetailsByVender,
    updateOfAnyOfferByVender,
    updateStatusOfAnyOfferByVender,
    updateImageOfAnyStoreByVender,
    updateImageOfAnyProductByVender,
    getAllSubCategoriesForMain,
    getAllCategoriesOfAnyStore,
    getAllSubCategoriesOfAnyCategory,
    getOrdersWithDateRange,
    getOrdersWithDateRangeForAdmin,
    getProductsWithDateRange,
    getProductsWithDateRangeForAdmin,
    getDashboardOrdersOfPartner,
    getAlLProductsCountOfPartner,
    getAlLStoresCountOfPartner,
    getAlLOffersCountOfPartner,
    getDashboardOrders,
    getAlLProductsCount,
    getAlLStoresCount,
    getAlLOffersCount,
    getPartnerGraphData,
    getAdminGraphData,
    updateStatusOfOrderByVender,
    updateStatusOfOrderByAdmin,
    acceptAnyOrderByVender,
    acceptAnyOrderByAdmin,
    getAllNotificationsForAdmin,
    getAllNotificationsForPartner,
    getAnyOrderDetails,
    addAmountToAnyCustomerByAdmin,
    addAmountToAnyCustomerByVender,
    getAllTransactionsOfAUser,
    getAllTransactionsOfAUserByVender,
    cancelAnyOrderByAdmin,
    cancelAnyOrderByVender,
    addNewVehicleType,
    getAllVehicleType,
    getSingleVehicleType,
    updateSingleVehicleType,
    updateStatusOfSingleVehicleType,
    getSingleRiderDetails,
    updateSingleRiderDetails,
    updateSingleVehicleTypeByAdmin,
    updateSingleVehicleTypeByVender,
    subtractAmountToAnyCustomerByAdmin,
    subtractAmountToAnyCustomerByVender,
    getAllUsersForVender,
    changeOnlineStatusOfRider,
    addNewServeArea,
    getAllServeAreas,
    getSingleServeAreas,
    updateStatusServeAreas,
    updateSingleServeAreaStatus,
    updateConfig,
    getConfig,

    adNewCustomSubCategory,
    uploadImageofCustomCategory,
    getAllCustomSubCategoriesForMain,
    getAllCustomSubCategories,
    getAllCustomSubCategoriesOfVender,
    updateOfAnyCustomCategory,
    updateStatusOpenOfAnyStore,
    changeStatusOfOrderApprove
}