import React , {useState , useEffect} from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import {useNavigate} from "react-router-dom"
import { Button } from '.';
import { chatData } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import { getAllNotificationsForAdmin , getAllNotificationsForPartner } from "../api/AdminApi"
import moment from "moment"
import { toast } from 'react-toastify';

const Notification = ({socket}) => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate()
  const [ allNotifications , setNotifications ] = useState([])
  const [ markUnSeen , setMarkUnSeen ] = useState(false)

  // checking if signed in or not
  const [ isAdmin , setIsAdmin ] = useState(false)
  const [ userId , setUserId ] = useState(null)
  useEffect(() => {
      let adminToken = JSON.parse(localStorage.getItem('priceApp-admin-auth-token'))
      if(!adminToken){
          navigate('/signin')
      }
      let isMyAdmin = JSON.parse(localStorage.getItem('isAdmin'))
      if(isMyAdmin === false){
          setIsAdmin(false)
      }else{
          setIsAdmin(true)
      }
      setUserId(JSON.parse(localStorage.getItem('user')))
  },[])

  // getting all notifications
  useEffect(() => {
      const getAll = async () => {
        if(isAdmin === true){
          const {data} = await getAllNotificationsForAdmin();
          if(data?.success === true){
            setNotifications(data?.AllNotifications)
          }else{
            setNotifications([])
          }
        }else{
          const {data} = await getAllNotificationsForPartner();
          if(data?.success === true){
            setNotifications(data?.AllNotifications)
          }else{
            setNotifications([])
          }
        }
      }

      getAll()
  },[isAdmin])

  // navigating user
  const navigateUser =  (type , id) => {
    if(type === "Order"){
        navigate(`/order-details/${id}`)
    }else if(type === "Product"){
        navigate(`/all products`)
    }else if(type === "Store"){
        navigate(`/our stores`)
    }else if(type === "Offer"){
      navigate(`/manage offers`)
    }
    // else if(type === "Product"){
    //     navigate(('/partner/all-products'))
    // }else{ // means restaurant
    //     navigate(('/partner/all-restaurants'))
    // }
  }

  // socket implementation
  useEffect(() => {
    // checking if any customer is signed in
    if(socket != null){
        //if(isMyAdmin == true){
            // socket.on("dashboardNotification",async (id) => {
            //   if(isAdmin == false){
            //     if(id == userId){
            //       toast.success("New Notification Received")
            //     }
            //   }else{
            //     toast.success("New Notification Received")
            //   }
            // })
        //}
    }

  },[socket])

  console.log("allNotifications ===> : ", allNotifications)

  return (
    <>
      <div className="nav-item absolute right-40 md:right-40 max-w-[350px] top-16 bg-[white] rounded-lg w-96 z-50" style={{zIndex : 99}} >
        <div className="flex justify-between items-center">
          <div className="flex gap-8 -mt-[5]">
            <p className="font-semibold text-lg text-[#2d3436] pl-4">Notifications</p>
            {/* <button type="button" className="text-white text-xs rounded p-1 px-2 bg-orange-theme "> 5 New</button> */}
          </div>
          <Button icon={<MdOutlineCancel />} color="crimson" bgHoverColor="light-gray" size="2xl" borderRadius="50%" style={{marginLeft : "150px"}} />
        </div>
        <div className="mt-1"  style={{zIndex : 99}} >
            <ul className="notification-list" style={{  width : "380px"  , height : "500px", maxHeight : "500px" , marginLeft : "-20px" , zIndex : 19 , overflowY : "scroll", }} >
              {
                allNotifications?.length > 0 ? (
                  allNotifications?.map((item) => (
                      <li className='p-2 pl-0' style={{display : "flex" , flexDirection : 'column', maxHeight : "60px", zIndex: 99}} onClick={() => navigateUser(item?.type, item?.id)} >
                          <b style={{color : "#2d3436"}} >{item?.message} </b>
                          <p style={{marginLeft : 'auto' , color : "#2d3436" , maxHeight : '5px'}} >{moment(item?.createdAt).format("Do/MMM/YY, h:mm a")}</p>
                          {/* <p  style={{color : "white"}} >{ item?.desc?.length > 120 ? item?.desc?.substring(0 , 120) : item?.desc }</p> */}
                          <hr />
                      </li>
                  ))
                ) : (
                  <b>No Notifications Found</b>
                )
              }
            </ul>
        </div>
      </div>
    </>
  );
};

export default Notification;
