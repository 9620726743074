import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

export default function PlacesAutocomplete({ onPlaceSelected, value }) {
  return (
    <GooglePlacesAutocomplete
      selectProps={{
        onChange: onPlaceSelected,
        defaultInputValue: value,
      }}
    />
  );
}