import React , {useState , useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { ThreeDots } from  'react-loader-spinner'
import { toast } from 'react-toastify';
import {useNavigate, useLocation} from 'react-router-dom'
import {getAllRiders ,  addNewRider  , updateSingleRiderDetails , updateStatusOfAnyRider , getAllVehicleType  , getSingleRiderDetails  ,changeOnlineStatusOfRider} from '../../api/AdminApi'
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
import { Col, Row, Table , Form , Modal , Button , Dropdown, ButtonGroup  } from 'react-bootstrap';
import { GrFormView} from 'react-icons/gr'
import moment from 'moment'



const ViewAllvendors = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const TABLE_HEADERS = [
        {
            prop: "username",
            title: "User Name",
        },
        {
            prop: "profilePic",
            title: "Image",
            cell: (prop) => {
                return (
                        <div style={{display : "flex" , minWidth : '100px'}} >
                            <img alt="product imag" style={{maxWidth : '50px' , maxHeight : '50px', borderRadius : '10px'}} src={ prop?.profilePic?.indexOf("https") == 0 ? prop?.profilePic : process.env.REACT_APP_LIVE_SERVER + "/riderImages/"  + prop.profilePic} />
                        </div>
                    )
            },

        },
        {
            prop: "vehicleType",
            title: "Vehicle Type"
        },
        {
            prop: "email",
            title: "Email"
        },
        {
            prop: "phoneNo",
            title: "Phone No"
        },
        {
            prop: "onlineStatus",
            title: "Online Status",
            cell: (prop) => {
                console.log("====== prop?.isOnlineStatus == true ===== : ", prop?.onlineStatus)
                return (
                    <div style={{display : "flex" , justifyContent : 'flex-end' , maxWidth : "100px" , paddingLeft : "20px"}} >
                            <>
                                {
                                    prop?.onlineStatus == true ? (
                                            <>
                                                <p  style={{fontSize : '11px' , fontWeight : 600 , color  : "green"}} >Online</p>
                                                <Form.Check 
                                                    type="switch"
                                                    id="custom-switch"
                                                    //label="Check this switch"
                                                    checked={true}
                                                    onChange={() => changeOnlineStatus(prop?.Id)}
                                                    style={{marginLeft : "10px"}}
                                                  />
                                            </>
                                        ) : (
                                            <>
                                                <p  style={{fontSize : '11px', fontWeight : 600 , color : "crimson"}} >Offline</p>
                                                <Form.Check 
                                                    type="switch"
                                                    id="custom-switch"
                                                    //label="Check this switch"
                                                    checked={false}
                                                    onChange={() => changeOnlineStatus(prop?.Id)}
                                                    style={{marginLeft : "10px"}}
                                                  />
                                            </>
                                            )
                                        }
                            </>
                        </div>
                    )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            prop: "status",
            title: "Status",
            cell: (prop) => {
                return (
                        <Dropdown as={ButtonGroup}>
                            {
                                prop?.status == false && (
                                    <Button size="sm" variant="danger" style={{fontSize : '11px' , fontWeight : 600}} >In Active</Button>
                                )
                            }
                            {
                                prop?.status == true && (
                                    <Button size="sm" variant="success" style={{fontSize : '11px' , fontWeight : 600}} >Active</Button>
                                )
                            }
                            <Dropdown.Toggle split size="sm" variant="primary" id="dropdown-split-basic" />
                            <Dropdown.Menu style={{backgroundColor : 'transparent'}} >
                                <Dropdown.Item onClick={() => changeStatus(prop?.Id)} style={{backgroundColor : '#c23616', color : 'white'}} >In Active</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeStatus(prop?.Id)} style={{backgroundColor : '#10ac84', color : 'white'}} >Active</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            prop: "action",
            title: "View Products",
            cell: (prop) => {
            return (
                    <GrFormView style={{cursor : 'pointer', fontSize : '25px'}} onClick={() => handleView(prop.Id)} ></GrFormView>
                )
            },
        },
    ];

    const [ isFetching , setIsFetching ] = useState(false)
    const [ sendData , setSendData ] = useState({
        username : "",
        email : "",
        password : "",
        phoneNo : "",
        vehicleId : ""
    })
    const [ isSending , setIsSending ] = useState(false)
    const [ isGetting , setIsGetting ] = useState(false)
    const [ uploadImage , setUploadImage ] = useState(null)
    const [ riderDetails , setRiderDetails ] = useState(null)
    const [ allProducts , setAllProducts ] = useState([])
    const [ allVehicleTypes , setVehicleTypes ] = useState([])
    const [ gotImage , setGotImage ] = useState(null)
    const [ isPassStrong , setIsPassStrong ] = useState(null)
    const [ bannerId , setBannerId ] = useState("")
    const [ allData , setAllData ] = useState([])
    // sleeping
    const delay = ms => new Promise(res => setTimeout(res, ms));

    // checking if signed in or not
    useEffect(() => {
        let adminToken = JSON.parse(localStorage.getItem('priceApp-admin-auth-token'))
        if(!adminToken){
            navigate('/signin')
        }
        let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
        if(!isAdmin){
            navigate('/signin')
        }
        if(isAdmin === false){
            navigate('/our stores')
        }
    },[])

    // getting data
    useEffect(() => {
        // getting all users
        const getData = async () => {
            setIsFetching(true)
            const {data} = await getAllRiders();
            if(data?.success === true){
                let allDataArr= []
                for(let i = 0; i !== data?.AllUsers.length; i++){
                    let newArr = {
                        username:  data?.AllUsers[i]?.username,
                        Id :  data?.AllUsers[i]?._id,
                        status :  data?.AllUsers[i]?.status !== undefined ? data?.AllUsers[i]?.status : true,
                        email :  data?.AllUsers[i]?.email,
                        phoneNo :  data?.AllUsers[i]?.phoneNo,
                        profilePic : data?.AllUsers[i]?.profilePic,
                        vehicleType : data?.AllUsers[i]?.vehicleId?.name,
                        onlineStatus : data?.AllUsers[i]?.isOnline,
                    }
                    allDataArr.push(newArr)
                }
                setAllData(allDataArr)
            }
            setIsFetching(false)
        }
            
        // getting all vehicle types
        const getAllMyVehicles = async () => {
            const {data} = await getAllVehicleType();
            if(data?.success === true){
                setVehicleTypes(data?.AllVehicles)
            }else{
                setVehicleTypes([])
            }
        }
        
        getData()
        getAllMyVehicles()
    },[location])

    // view modal
    const [view, setView] = useState(false);
    const handleViewClose = () => {
        setView(false);
        setBannerId("")
        setGotImage(null)
        setUploadImage(null)
        setIsSending(false)
    }
    // getting data of user
    const handleView = async (id) => {
        setView(true);

        //getting data
        setIsGetting(true)
        const {data} = await getSingleRiderDetails(id);
        if(data?.success === true){
            setRiderDetails(data?.Rider)
        }else{
            toast.error(data?.message)
        }
        setIsGetting(false)
    }

    // add new banner
    const [addNew, setAddNew] = useState(false);
    const handleAddNewClose = () => {
        setUploadImage(null)
        setAddNew(false)
        setIsSending(false)
    }
    // getting data of user
    const handleAddNew = () => {
        setAddNew(true);
    }

    // sending data
    const addNewMyList = async () => {
        setIsSending(true)
        const {data} = await addNewRider(sendData);
        if(data?.success === true){
            toast.success("New Rider Added SuccessFully")

            setIsFetching(true)
            const res = await getAllRiders();
            if(res?.data?.success === true){
                let allDataArr= []
                for(let i = 0; i !== res?.data?.AllUsers.length; i++){
                    let newArr = {
                        username:  res?.data?.AllUsers[i]?.username,
                        Id :  res?.data?.AllUsers[i]?._id,
                        status :  res?.data?.AllUsers[i]?.status !== undefined ? res?.data?.AllUsers[i]?.status : true,
                        email :  res?.data?.AllUsers[i]?.email,
                        phoneNo :  res?.data?.AllUsers[i]?.phoneNo,
                        profilePic : res?.data?.AllUsers[i]?.profilePic,
                        vehicleType : res?.data?.AllUsers[i]?.vehicleId?.name,
                        onlineStatus : res?.data?.AllUsers[i]?.isOnline
                    }
                    allDataArr.push(newArr)
                }
                setAllData(allDataArr)
            }
            setIsFetching(false)

            await delay(1500)
            handleAddNewClose()
        }else{
            toast.error(data?.message)
        }
        setIsSending(false)
    }

    // changing status of vender
    const changeStatus = async (id) => {
        let isFound = allData.find(item => item.Id === id);
        if(isFound){
            const {data} = await updateStatusOfAnyRider(id);
            if(data?.success === true){
                if(isFound.status == false){
                    isFound.status = true
                    toast.success("Rider Activated Successfully");
                }else{
                    isFound.status = false
                    toast.success("Rider InActivated Successfully");
                }
                let newData = allData;
                let finalData = newData.filter(item => item.Id === id ? isFound : item );
                setAllData(finalData)
            }else{
                toast.success(data?.message);
            }
        }
    }

    // update data of any rider
    const updateMyBanner = async () => {
        setIsSending(true)
        const {data} = await updateSingleRiderDetails(riderDetails._id , riderDetails);
        if(data?.success === true){
            toast.success("Rider Details Updated SuccessFully")

            // getting updated data
            setIsFetching(true)
            const res = await getAllRiders();
            if(res?.data?.success === true){
                let allDataArr= []
                for(let i = 0; i !== res?.data?.AllUsers.length; i++){
                    let newArr = {
                        username:  res?.data?.AllUsers[i]?.username,
                        Id :  res?.data?.AllUsers[i]?._id,
                        status :  res?.data?.AllUsers[i]?.status !== undefined ? res?.data?.AllUsers[i]?.status : true,
                        email :  res?.data?.AllUsers[i]?.email,
                        phoneNo :  res?.data?.AllUsers[i]?.phoneNo,
                        profilePic : res?.data?.AllUsers[i]?.profilePic,
                        vehicleType : res?.data?.AllUsers[i]?.vehicleId?.name,
                        isOnlineStatus : res?.data?.AllUsers[i]?.isOnline,
                    }
                    allDataArr.push(newArr)
                }
                setAllData(allDataArr)
            }
            setIsFetching(false)

            //await delay(1500)
            handleViewClose()
        }else{
            toast.error(data?.message)
        }
        setIsSending(false)
    }
    // comparing password
    const checkPassword = () => {
        if(sendData?.password.match(/^(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,40}$/) ===  null){
            setIsPassStrong(false)
        }else{
            setIsPassStrong(true)
        }
    }
    
    // changing online status of any rider by admin
    const changeOnlineStatus = async (id) => {
        let isFound = allData.find(item => item.Id === id);
        if(isFound){
            const {data} = await changeOnlineStatusOfRider(id);
            if(data?.success === true){
                if(isFound.onlineStatus == false){
                    isFound.onlineStatus = true
                    toast.success("Rider Made Online Successfully");
                }else{
                    isFound.onlineStatus = false
                    toast.success("Rider Made Offline Successfully");
                }
                let newData = allData;
                let finalData = newData.filter(item => item.Id === id ? isFound : item );
                setAllData(finalData)
            }else{
                toast.success(data?.message);
            }
        }
    }
    
    return (
        <>
            {
                isFetching === true ? (
                    <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                        <ThreeDots
                            height = "60"
                            width = "60"
                            radius = "9"
                            color = 'green'
                            ariaLabel = 'three-dots-loading'
                            wrapperStyle
                            wrapperClass
                        />
                    </div>
                ) : (
                    <DatatableWrapper
                        body={allData}
                        headers={TABLE_HEADERS}
                        paginationOptionsProps={{
                            initialState: {
                                rowsPerPage: 10,
                                options: [5, 10, 15, 20]
                            }
                        }}
                        >
                        <Row className="p-4 mb-4">
                            <Col
                                xs={12}
                                lg={4}
                                className="flex-col d-flex justify-content-start align-items-start"
                            >
                                <h4>All Riders</h4>
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="flex-col mb-2 d-flex justify-content-lg-center align-items-center justify-content-sm-start mb-sm-0"
                            >
                                <Button size="lg" variant="info" onClick={handleAddNew} style={{fontSize : '15px' , fontWeight : 600}} >Add New</Button>
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="flex-col d-flex justify-content-end align-items-end"
                            >
                                {/* <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Search here..."
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        onBlur = {() => getAllData()}
                                        onChange={(e) => setText(e.target.value)}
                                    />
                                        <Button variant="info" onClick={getSearchedMerchants}>Search</Button>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Table striped bordered hover className="ml-2 mr-2" >
                            <TableHeader />
                            <TableBody />
                        </Table>
                        <Row className="p-2 mb-4">
                            <Col
                                xs={12}
                                sm={6}
                                lg={6}
                                className="flex-col mb-2 d-flex justify-content-lg-start align-items-start justify-content-sm-start mb-sm-0"
                            >
                                <PaginationOptions />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={6}
                                className="flex-col d-flex justify-content-end align-items-end"
                            >
                                <Pagination />
                            </Col>
                        </Row>
                    </DatatableWrapper>
                )
            }

            {/* view and update modal */}
                <Modal
                    show={view}
                    onHide={handleViewClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>View Rider Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            isGetting === true ? (
                                <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                    <ThreeDots
                                        height = "60"
                                        width = "60"
                                        radius = "9"
                                        color = 'green'
                                        ariaLabel = 'three-dots-loading'
                                        wrapperStyle
                                        wrapperClass
                                    />
                                </div>
                            ) : (
                                <div className="flex justify-center" >
                                    <div className='flex justify-center flex-col align-center pb-4  max-w-[400px] w-[100%] mx-auto' >
                                        <div className='flex flex-col pt-0 pl-3 font-semibold' >
                                            <h6> Name</h6>
                                            <input type="text" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 ' style={{border: '1px solid black'}} value={riderDetails?.username} onChange={(e) => setRiderDetails({...riderDetails , username : e.target.value})} />
                                        </div>
                                        <div className='flex flex-col pt-2 pl-3 font-semibold' >
                                            <h6>Email:</h6>
                                            <input type="email" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 ' style={{border: '1px solid black'}} value={riderDetails?.email} onChange={(e) => setRiderDetails({...riderDetails , email : e.target.value})} />
                                        </div>
                                        {/* <div className='flex flex-col pt-2 pl-3 font-semibold' >
                                            <h6>Password:</h6>
                                            <input type="password" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 ' style={{border: '1px solid black'}} value={riderDetails?.password} onChange={(e) => setRiderDetails({...riderDetails , password : e.target.value})} onBlur={checkPassword} />
                                            {
                                                isPassStrong === false && (
                                                    <span style={{color : "crimson" , fontSize : "12px"}} >(Password must contain one capital or small letter, one special character, one number and length must be 8 or more )</span>
                                                )
                                            }
                                        </div> */}
                                        <div className='flex flex-col pt-2 pl-3 font-semibold' >
                                            <h6>Phone No:</h6>
                                            <input type="number" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 ' style={{border: '1px solid black'}} value={riderDetails?.phoneNo} onChange={(e) => setRiderDetails({...riderDetails , phoneNo : e.target.value})}  />
                                        </div>
                                        <div className='flex flex-col pt-0 pl-3 font-semibold' >
                                            <h6>Choose Vehicle Type:</h6>
                                            <select class="form-select" aria-label="Default select example" onChange={(e) => setRiderDetails({...riderDetails , vehicleId : e.target.value})} >
                                                <option selected disabled>choose vehicle type</option>
                                                {
                                                    allVehicleTypes?.length > 0 ? (
                                                        allVehicleTypes?.map((item) => (
                                                            item?._id == riderDetails?.vehicleId ? (
                                                                <option value={item?._id} selected >{item?.name}</option>
                                                            ) : (
                                                                <option value={item?._id}>{item?.name}</option>
                                                            )
                                                        ))
                                                    ) : (
                                                        <option >No  vehicle Type Found</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                            {
                                isSending === true ? (
                                    <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                        <ThreeDots
                                            height = "60"
                                            width = "60"
                                            radius = "9"
                                            color = 'green'
                                            ariaLabel = 'three-dots-loading'
                                            wrapperStyle
                                            wrapperClass
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <Button variant="danger" onClick={handleViewClose}>
                                            Close
                                        </Button>
                                        <Button variant="success" onClick={updateMyBanner}>
                                            Update
                                        </Button>
                                    </>
                                )
                            }
                    </Modal.Footer>
                </Modal>
            {/* view and update modal end */}

            {/* add new modal */}
                <Modal
                    show={addNew}
                    onHide={handleAddNewClose}
                    backdrop="static"
                    keyboard={false}
                    style={{marginTop : '40px'}}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Add New Rider</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="flex justify-center" >
                            <div className='flex justify-center flex-col align-center pb-4  max-w-[400px] w-[100%] mx-auto' >
                                <div className='flex flex-col pt-0 pl-3 font-semibold' >
                                    <h6>User Name</h6>
                                    <input type="text" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 ' style={{border: '1px solid black'}} value={sendData?.username} onChange={(e) => setSendData({...sendData , username : e.target.value})} />
                                </div>
                                <div className='flex flex-col pt-2 pl-3 font-semibold' >
                                    <h6>Email:</h6>
                                    <input type="email" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 ' style={{border: '1px solid black'}} value={sendData?.email} onChange={(e) => setSendData({...sendData , email : e.target.value})} />
                                </div>
                                <div className='flex flex-col pt-2 pl-3 font-semibold' >
                                    <h6>Password:</h6>
                                    <input type="password" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 ' style={{border: '1px solid black'}} value={sendData?.password} onChange={(e) => setSendData({...sendData , password : e.target.value})} onBlur={checkPassword} />
                                    {
                                        isPassStrong === false && (
                                            <span style={{color : "crimson" , fontSize : "12px"}} >(Password must contain one capital or small letter, one special character, one number and length must be 8 or more )</span>
                                        )
                                    }
                                </div>
                                <div className='flex flex-col pt-2 pl-3 font-semibold' >
                                    <h6>Phone No:</h6>
                                    <input type="number" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 ' style={{border: '1px solid black'}} value={sendData?.phoneNo} onChange={(e) => setSendData({...sendData , phoneNo : e.target.value})}  />
                                </div>
                                <div className='flex flex-col pt-2 pl-3 font-semibold' >
                                    <h6>Choose Vehicle Type:</h6>
                                    <select class="form-select" aria-label="Default select example" onChange={(e) => setSendData({...sendData , vehicleId : e.target.value})} >
                                        <option selected disabled>choose vehicle type</option>
                                        {
                                            allVehicleTypes?.length > 0 ? (
                                                allVehicleTypes?.map((item) => (
                                                    <option value={item?._id}>{item?.name}</option>
                                                ))
                                            ) : (
                                                <option >No  vehicle Type Found</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            isSending === true ? (
                                <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                    <ThreeDots
                                        height = "60"
                                        width = "60"
                                        radius = "9"
                                        color = 'green'
                                        ariaLabel = 'three-dots-loading'
                                        wrapperStyle
                                        wrapperClass
                                    />
                                </div>
                            ) : (
                                <>
                                    <Button variant="danger" onClick={handleAddNewClose}>
                                        Close
                                    </Button>
                                    <Button variant="success" onClick={addNewMyList} >Add Now</Button>
                                </>
                            )
                        }
                    </Modal.Footer>
                </Modal>
            {/* add new modal end */}
        </>
    );
}

export default ViewAllvendors;
