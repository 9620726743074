import React , {useState , useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { ThreeDots } from  'react-loader-spinner'
import { toast } from 'react-toastify';
import {useNavigate, useLocation} from 'react-router-dom'
import {getAllOffers ,  addNewOffer , getAllOfferOfVender , getSingleOfferDetails , updateOfAnyOffer ,updateStatusOfAnyOffer , getAllProducts , addNewOfferByVender , getAllProductsOfVender , getSingleOfferDetailsByVender , updateOfAnyOfferByVender , updateStatusOfAnyOfferByVender } from '../../api/AdminApi'
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
import { Col, Row, Table , Form , Modal , Button , Dropdown, ButtonGroup , InputGroup  } from 'react-bootstrap';
import { GrFormView} from 'react-icons/gr'
import moment from 'moment'



const ViewAllvendors = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const TABLE_HEADERS = [
        {
            prop: "title",
            title: "Title",
        },
        {
            prop: "discount",
            title: "Discount %age"
        },
        {
            prop: "product",
            title: "Product",
            cell: (prop) => {
                return (
                        <div style={{display : "flex" , minWidth : '100px'}} >
                            <img alt="product imag" style={{maxWidth : '50px' , maxHeight : '50px', borderRadius : '10px'}} src={process.env.REACT_APP_LIVE_SERVER + "/productsImages/"  + prop.productPic} />
                            <h6 style={{maxWidth : "100px" , textOverflow : 'ellipsis'}} >{prop.productName}</h6>
                        </div>
                    )
            },

        },
        {
            prop: "startDate",
            title: "Start Date"
        },
        {
            prop: "endDate",
            title: "End Date"
        },
        {
            prop: "status",
            title: "Status",
            cell: (prop) => {
                return (
                        <Dropdown as={ButtonGroup}>
                            {
                                prop?.status == false && (
                                    <Button size="sm" variant="danger" style={{fontSize : '11px' , fontWeight : 600}} >In Active</Button>
                                )
                            }
                            {
                                prop?.status == true && (
                                    <Button size="sm" variant="success" style={{fontSize : '11px' , fontWeight : 600}} >Active</Button>
                                )
                            }
                            <Dropdown.Toggle split size="sm" variant="primary" id="dropdown-split-basic" />
                            <Dropdown.Menu style={{backgroundColor : 'transparent'}} >
                                <Dropdown.Item onClick={() => changeStatus(prop?.Id)} style={{backgroundColor : '#c23616', color : 'white'}} >In Active</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeStatus(prop?.Id)} style={{backgroundColor : '#10ac84', color : 'white'}} >Active</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            prop: "action",
            title: "View Products",
            cell: (prop) => {
            return (
                    <GrFormView style={{cursor : 'pointer', fontSize : '25px'}} onClick={() => handleView(prop.Id)} ></GrFormView>
                )
            },
        },
    ];

    const [ isFetching , setIsFetching ] = useState(false)
    const [ offerDetails , setOfferDetails ] = useState({})
    const [ sendData , setSendData ] = useState({
        name : "",
        productId : "",
        startDate : "",
        discountPercent : "",
        endDate : ""
    })
    const [ isSending , setIsSending ] = useState(false)
    const [ isGetting , setIsGetting ] = useState(false)
    const [ allProducts , setAllProducts ] = useState([])
    const [ gotImage , setGotImage ] = useState(null)
    const [ bannerId , setBannerId ] = useState("")
    const [ allData , setAllData ] = useState([])
    // sleeping
    const delay = ms => new Promise(res => setTimeout(res, ms));

    // checking if signed in or not
    const [ isAdmin , setIsAdmin ] = useState(false)
    useEffect(() => {
        let adminToken = JSON.parse(localStorage.getItem('priceApp-admin-auth-token'))
        if(!adminToken){
            navigate('/signin')
        }
        let isMyAdmin = JSON.parse(localStorage.getItem('isAdmin'))
        if(isMyAdmin === false){
            setIsAdmin(false)
        }else{
            setIsAdmin(true)
        }
    },[])

    // getting data
    useEffect(() => {
        const getData = async () => {
            setIsFetching(true)
            if(isAdmin === true){
                const {data} = await getAllOffers();
                if(data?.success === true){
                    let allDataArr= []
                    for(let i = 0; i !==  data?.AllOffers[0]?.AllData.length; i++){
                        let newArr = {
                            title:  data?.AllOffers[0]?.AllData[i]?.title,
                            Id :  data?.AllOffers[0]?.AllData[i]?.Id,
                            status :  data?.AllOffers[0]?.AllData[i]?.status !== undefined ? data?.AllOffers[0]?.AllData[i]?.status : true,
                            discount :  data?.AllOffers[0]?.AllData[i]?.discountPercent,
                            productName :  data?.AllOffers[0]?.AllData[i]?.productName,
                            productPic :  data?.AllOffers[0]?.AllData[i]?.productPic,
                            startDate : moment(data?.AllOffers[0]?.AllData[i]?.startDate).format('MMM Do YY'),
                            endDate : moment(data?.AllOffers[0]?.AllData[i]?.endDate).endOf('day').fromNow(),
                        }
                        allDataArr.push(newArr)
                    }
                    setAllData(allDataArr)
                }
            }else{
                const {data} = await getAllOfferOfVender();
                if(data?.success === true){
                    let allDataArr= []
                    for(let i = 0; i !==  data?.AllOffers[0]?.AllData.length; i++){
                        let newArr = {
                            title:  data?.AllOffers[0]?.AllData[i]?.title,
                            Id :  data?.AllOffers[0]?.AllData[i]?.Id,
                            status :  data?.AllOffers[0]?.AllData[i]?.status !== undefined ? data?.AllOffers[0]?.AllData[i]?.status : true,
                            discount :  data?.AllOffers[0]?.AllData[i]?.discountPercent,
                            productName :  data?.AllOffers[0]?.AllData[i]?.productName,
                            productPic :  data?.AllOffers[0]?.AllData[i]?.productPic,
                            startDate : moment(data?.AllOffers[0]?.AllData[i]?.startDate).format('MMM Do YY'),
                            endDate : moment(data?.AllOffers[0]?.AllData[i]?.endDate).endOf('day').fromNow(),
                        }
                        allDataArr.push(newArr)
                    }
                    setAllData(allDataArr)
                }
            }
            setIsFetching(false)
        }
        getData()
    },[isAdmin])

    // view modal
    const [view, setView] = useState(false);
    const handleViewClose = () => {
        setView(false);
        setBannerId("")
        setGotImage(null)
        setIsSending(false)
    }
    // getting data of user
    const handleView = async (id) => {
        setView(true);

        //getting data
        setIsGetting(true)
        if(isAdmin === true){
            const {data} = await getSingleOfferDetails(id);
            if(data?.success === true){
                setOfferDetails(data?.Offer)
            }else{
                toast.error("Offer Details Not Found")
            }
        }else{
            const {data} = await getSingleOfferDetailsByVender(id);
            if(data?.success === true){
                setOfferDetails(data?.Offer)
            }else{
                toast.error("Offer Details Not Found")
            }
        }
        setIsGetting(false)
    }

    // add new banner
    const [addNew, setAddNew] = useState(false);
    const handleAddNewClose = () => {
        setAddNew(false)
        setIsSending(false)
    }
    // getting data of user
    const handleAddNew = () => {
        setAddNew(true);
    }

    // sending data
    const addNewMyList = async () => {
        setIsSending(true)
        if(isAdmin === true){
            const {data} = await addNewOffer(sendData);
            if(data?.success === true){
                toast.success("New Offer Added SuccessFully")
    
                setIsFetching(true)
                const res = await getAllOffers();
                if(res?.data?.success === true){
                    let allDataArr= []
                    for(let i = 0; i !==  res?.data?.AllOffers[0]?.AllData.length; i++){
                        let newArr = {
                            title:  res?.data?.AllOffers[0]?.AllData[i]?.title ? res?.data?.AllOffers[0]?.AllData[i]?.title : "N/A",
                            Id :  res?.data?.AllOffers[0]?.AllData[i]?.Id,
                            status :  res?.data?.AllOffers[0]?.AllData[i]?.status ? res?.data?.AllOffers[0]?.AllData[i]?.status : true,
                            discount :  res?.data?.AllOffers[0]?.AllData[i]?.discountPercent,
                            productName :  res?.data?.AllOffers[0]?.AllData[i]?.productName,
                            productName :  res?.data?.AllOffers[0]?.AllData[i]?.productName,
                            productPic :  res?.data?.AllOffers[0]?.AllData[i]?.productPic,
                            productName :  res?.data?.AllOffers[0]?.AllData[i]?.productName,
                            startDate : moment(res?.data?.AllOffers[0]?.AllData[i]?.startDate).format('MMM Do YY'),
                            endDate : moment(res?.data?.AllOffers[0]?.AllData[i]?.endDate).endOf('day').fromNow(),
                        }
                        allDataArr.push(newArr)
                    }
                    setAllData(allDataArr)
                }
                setIsFetching(false)
            }else{
                toast.error(data?.message)
            }
        }else{
            const {data} = await addNewOfferByVender(sendData);
            if(data?.success === true){
                toast.success("New Offer Added SuccessFully")
    
                setIsFetching(true)
                const res = await getAllOfferOfVender();
                if(res?.data?.success === true){
                    let allDataArr= []
                    for(let i = 0; i !==  res?.data?.AllOffers[0]?.AllData.length; i++){
                        let newArr = {
                            title:  res?.data?.AllOffers[0]?.AllData[i]?.title ? res?.data?.AllOffers[0]?.AllData[i]?.title : "N/A",
                            Id :  res?.data?.AllOffers[0]?.AllData[i]?.Id,
                            status :  res?.data?.AllOffers[0]?.AllData[i]?.status ? res?.data?.AllOffers[0]?.AllData[i]?.status : true,
                            discount :  res?.data?.AllOffers[0]?.AllData[i]?.discountPercent,
                            productName :  res?.data?.AllOffers[0]?.AllData[i]?.productName,
                            productName :  res?.data?.AllOffers[0]?.AllData[i]?.productName,
                            productPic :  res?.data?.AllOffers[0]?.AllData[i]?.productPic,
                            productName :  res?.data?.AllOffers[0]?.AllData[i]?.productName,
                            startDate : moment(res?.data?.AllOffers[0]?.AllData[i]?.startDate).format('MMM Do YY'),
                            endDate : moment(res?.data?.AllOffers[0]?.AllData[i]?.endDate).endOf('day').fromNow(),
                        }
                        allDataArr.push(newArr)
                    }
                    setAllData(allDataArr)
                }
                setIsFetching(false)
            }else{
                toast.error(data?.message)
            }
        }
        await delay(1500)
        handleAddNewClose()
        setIsSending(false)
    }

    // changing status of vender
    const changeStatus = async (id) => {
        let isFound = allData.find(item => item.Id === id);
        if(isFound){
            if(isAdmin === true){
                const {data} = await updateStatusOfAnyOffer(id);
                if(data?.success === true){
                    if(isFound.status == false){
                        isFound.status = true
                        toast.success("Offer Activated Successfully");
                    }else{
                        isFound.status = false
                        toast.success("Offer InActivated Successfully");
                    }
                    let newData = allData;
                    let finalData = newData.filter(item => item.Id === id ? isFound : item );
                    setAllData(finalData)
                }else{
                    toast.success(data?.message);
                }
            }else{
                const {data} = await updateStatusOfAnyOfferByVender(id);
                if(data?.success === true){
                    if(isFound.status == false){
                        isFound.status = true
                        toast.success("Offer Activated Successfully");
                    }else{
                        isFound.status = false
                        toast.success("Offer InActivated Successfully");
                    }
                    let newData = allData;
                    let finalData = newData.filter(item => item.Id === id ? isFound : item );
                    setAllData(finalData)
                }else{
                    toast.success(data?.message);
                }
            }
        }
    }

    // update data
    const updateMyBanner = async () => {
        setIsSending(true)
        if(isAdmin === true){
            const {data} = await updateOfAnyOffer(offerDetails._id , offerDetails);
            if(data?.success === true){
                toast.success("Offer Updated SuccessFully")
    
                // getting updated data
                setIsFetching(true)
                const res = await getAllOffers();
                if(res?.data?.success === true){
                    let allDataArr= []
                    for(let i = 0; i !==  res?.data?.AllOffers[0]?.AllData.length; i++){
                        let newArr = {
                            title:  res?.data?.AllOffers[0]?.AllData[i]?.title ? res?.data?.AllOffers[0]?.AllData[i]?.title : "N/A",
                            Id :  res?.data?.AllOffers[0]?.AllData[i]?.Id,
                            status :  res?.data?.AllOffers[0]?.AllData[i]?.status ? res?.data?.AllOffers[0]?.AllData[i]?.status : true,
                            discount :  res?.data?.AllOffers[0]?.AllData[i]?.discountPercent,
                            productName :  res?.data?.AllOffers[0]?.AllData[i]?.productName,
                            productPic :  res?.data?.AllOffers[0]?.AllData[i]?.productPic,
                            startDate : moment(res?.data?.AllOffers[0]?.AllData[i]?.startDate).format('MMM Do YY'),
                            endDate : moment(res?.data?.AllOffers[0]?.AllData[i]?.endDate).endOf('day').fromNow(),
                        }
                        allDataArr.push(newArr)
                    }
                    setAllData(allDataArr)
                }
                setIsFetching(false)
    
                await delay(1500)
                handleViewClose()
            }else{
                toast.error(data?.message)
            }
        }else{
            const {data} = await updateOfAnyOfferByVender(offerDetails._id , offerDetails);
            if(data?.success === true){
                toast.success("Offer Updated SuccessFully")
    
                // getting updated data
                setIsFetching(true)
                const res = await getAllOfferOfVender();
                if(res?.data?.success === true){
                    let allDataArr= []
                    for(let i = 0; i !==  res?.data?.AllOffers[0]?.AllData.length; i++){
                        let newArr = {
                            title:  res?.data?.AllOffers[0]?.AllData[i]?.title ? res?.data?.AllOffers[0]?.AllData[i]?.title : "N/A",
                            Id :  res?.data?.AllOffers[0]?.AllData[i]?.Id,
                            status :  res?.data?.AllOffers[0]?.AllData[i]?.status ? res?.data?.AllOffers[0]?.AllData[i]?.status : true,
                            discount :  res?.data?.AllOffers[0]?.AllData[i]?.discountPercent,
                            productName :  res?.data?.AllOffers[0]?.AllData[i]?.productName,
                            productPic :  res?.data?.AllOffers[0]?.AllData[i]?.productPic,
                            startDate : moment(res?.data?.AllOffers[0]?.AllData[i]?.startDate).format('MMM Do YY'),
                            endDate : moment(res?.data?.AllOffers[0]?.AllData[i]?.endDate).endOf('day').fromNow(),
                        }
                        allDataArr.push(newArr)
                    }
                    setAllData(allDataArr)
                }
                setIsFetching(false)
    
                await delay(1500)
                handleViewClose()
            }else{
                toast.error(data?.message)
            }
        }
        setIsSending(false)
    }

    // getting data
    useEffect(() => {
        const getData = async () => {
            if(isAdmin === true){
                const {data} = await getAllProducts();
                if(data?.success === true){
                    let allDataArr = []
                    for(let i = 0; i !== data?.AllProducts[0]?.AllData.length; i++){
                        let newArr = {
                            Name: data?.AllProducts[0]?.AllData[i]?.Name,
                            Id: data?.AllProducts[0]?.AllData[i]?.Id,
                        }
                        allDataArr.push(newArr)
                    }
                    setAllProducts(allDataArr)
                }
            }else{
                const {data} = await getAllProductsOfVender();
                if(data?.success === true){
                    let allDataArr = []
                    for(let i = 0; i !== data?.AllProducts[0]?.AllData.length; i++){
                        let newArr = {
                            Name: data?.AllProducts[0]?.AllData[i]?.Name,
                            Id: data?.AllProducts[0]?.AllData[i]?.Id,
                        }
                        allDataArr.push(newArr)
                    }
                    setAllProducts(allDataArr)
                }
            }
        }
        getData()
    },[isAdmin])

    const [ text , setText ] = useState("")
    // getting all data on blur
    const getAllData = async () => {
        if(text?.length < 1){
            setIsFetching(true)
            if(isAdmin === true){
                const {data} = await getAllOffers();
                if(data?.success === true){
                    let allDataArr= []
                    for(let i = 0; i !==  data?.AllOffers[0]?.AllData.length; i++){
                        let newArr = {
                            title:  data?.AllOffers[0]?.AllData[i]?.title,
                            Id :  data?.AllOffers[0]?.AllData[i]?.Id,
                            status :  data?.AllOffers[0]?.AllData[i]?.status !== undefined ? data?.AllOffers[0]?.AllData[i]?.status : true,
                            discount :  data?.AllOffers[0]?.AllData[i]?.discountPercent,
                            productName :  data?.AllOffers[0]?.AllData[i]?.productName,
                            productPic :  data?.AllOffers[0]?.AllData[i]?.productPic,
                            startDate : moment(data?.AllOffers[0]?.AllData[i]?.startDate).format('MMM Do YY'),
                            endDate : moment(data?.AllOffers[0]?.AllData[i]?.endDate).endOf('day').fromNow(),
                        }
                        allDataArr.push(newArr)
                    }
                    setAllData(allDataArr)
                }
            }else{
                const {data} = await getAllOfferOfVender();
                if(data?.success === true){
                    let allDataArr= []
                    for(let i = 0; i !==  data?.AllOffers[0]?.AllData.length; i++){
                        let newArr = {
                            title:  data?.AllOffers[0]?.AllData[i]?.title,
                            Id :  data?.AllOffers[0]?.AllData[i]?.Id,
                            status :  data?.AllOffers[0]?.AllData[i]?.status !== undefined ? data?.AllOffers[0]?.AllData[i]?.status : true,
                            discount :  data?.AllOffers[0]?.AllData[i]?.discountPercent,
                            productName :  data?.AllOffers[0]?.AllData[i]?.productName,
                            productPic :  data?.AllOffers[0]?.AllData[i]?.productPic,
                            startDate : moment(data?.AllOffers[0]?.AllData[i]?.startDate).format('MMM Do YY'),
                            endDate : moment(data?.AllOffers[0]?.AllData[i]?.endDate).endOf('day').fromNow(),
                        }
                        allDataArr.push(newArr)
                    }
                    setAllData(allDataArr)
                }
            }
            setIsFetching(false)
        }
    }

    // getting searched item
    const getSearchedOffers = async () => {
        if(text?.length > 2){
            let newArr = []
            let myText = text.toLocaleLowerCase()
            for(let i = 0; i !== allData.length; i++){
                if(allData[i].endDate?.toLowerCase()?.includes(myText)=== true || allData[i].productName?.toLowerCase()?.includes(myText)=== true || allData[i].discount == text || allData[i].startDate?.toLowerCase()?.includes(myText)=== true || allData[i].title?.toLowerCase()?.includes(myText)=== true  ){
                    newArr.push(allData[i])
                }
            }
            setAllData(newArr)
        }
    }

    return (
        <>
            {
                isFetching === true ? (
                    <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                        <ThreeDots
                            height = "60"
                            width = "60"
                            radius = "9"
                            color = 'green'
                            ariaLabel = 'three-dots-loading'
                            wrapperStyle
                            wrapperClass
                        />
                    </div>
                ) : (
                    <DatatableWrapper
                        body={allData}
                        headers={TABLE_HEADERS}
                        paginationOptionsProps={{
                            initialState: {
                                rowsPerPage: 10,
                                options: [5, 10, 15, 20]
                            }
                        }}
                        >
                        <Row className="p-4 mb-4">
                            <Col
                                xs={12}
                                lg={4}
                                className="flex-col d-flex justify-content-start align-items-start"
                            >
                                <h4>All Offers</h4>
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="flex-col mb-2 d-flex justify-content-lg-center align-items-center justify-content-sm-start mb-sm-0"
                            >
                                <Button size="lg" variant="info" onClick={handleAddNew} style={{fontSize : '15px' , fontWeight : 600}} >Add New</Button>
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="flex-col d-flex justify-content-end align-items-end"
                            >
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Search Offer here..."
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        onBlur = {() => getAllData()}
                                        onChange={(e) => setText(e.target.value)}
                                    />
                                        <Button variant="info" onClick={getSearchedOffers}>Search</Button>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Table striped bordered hover className="ml-2 mr-2" >
                            <TableHeader />
                            <TableBody />
                        </Table>
                        <Row className="p-2 mb-4">
                            <Col
                                xs={12}
                                sm={6}
                                lg={6}
                                className="flex-col mb-2 d-flex justify-content-lg-start align-items-start justify-content-sm-start mb-sm-0"
                            >
                                <PaginationOptions />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={6}
                                className="flex-col d-flex justify-content-end align-items-end"
                            >
                                <Pagination />
                            </Col>
                        </Row>
                    </DatatableWrapper>
                )
            }

            {/* view and update modal */}
                <Modal
                    show={view}
                    onHide={handleViewClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>View Offer Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            isGetting === true ? (
                                <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                    <ThreeDots
                                        height = "60"
                                        width = "60"
                                        radius = "9"
                                        color = 'green'
                                        ariaLabel = 'three-dots-loading'
                                        wrapperStyle
                                        wrapperClass
                                    />
                                </div>
                            ) : (
                                <div className="flex justify-center" >
                                    <div className='flex justify-center flex-col align-center pb-4  max-w-[400px] w-[100%] mx-auto' >
                                        <div className='flex flex-col pt-0 pl-3 font-semibold' >
                                            <h6>Name</h6>
                                            <input type="text" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 ' style={{border: '1px solid black'}} value={offerDetails?.name} onChange={(e) => setOfferDetails({...offerDetails , name : e.target.value})} />
                                        </div>
                                        <div className='flex flex-col pt-2 pl-3 font-semibold' >
                                            <h6>startDate:</h6>
                                            <input type="date"  className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 ' style={{border: '1px solid black'}} value={offerDetails?.startDate} onChange={(e) => setOfferDetails({...offerDetails , startDate : e.target.value})} />
                                        </div>
                                        <div className='flex flex-col pt-2 pl-3 font-semibold' >
                                            <h6>End Date:</h6>
                                            <input type="date" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 ' style={{border: '1px solid black'}} value={offerDetails?.endDate} onChange={(e) => setOfferDetails({...offerDetails , endDate : e.target.value})} />
                                        </div>
                                        <div className='flex flex-col pt-2 pl-3 font-semibold' >
                                            <h6>Discount Percent</h6>
                                            <input type="number" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 ' style={{border: '1px solid black'}} value={offerDetails?.discountPercent} onChange={(e) => setOfferDetails({...offerDetails , discountPercent : e.target.value})} />
                                        </div>
                                        <div className='flex flex-col pt-2 pl-3 font-semibold' >
                                            <h6>Product:</h6>
                                            <select class="form-select" aria-label="Default select example" onChange={(e) => setOfferDetails({...offerDetails , productId : e.target.value})} >
                                                <option selected disabled></option>
                                                {
                                                    allProducts?.length > 0 ? (
                                                        allProducts?.map((item) => (
                                                            item?.Id == offerDetails.productId ? (
                                                                <option value={item?.Id} selected style={{backgroundColor : 'darkGray'}} >{item?.Name}</option>
                                                            ) : (
                                                                <option value={item?.Id}>{item?.Name}</option>
                                                            )
                                                        ))
                                                    ) : (
                                                        <option >No Products Found</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                            {
                                isSending === true ? (
                                    <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                        <ThreeDots
                                            height = "60"
                                            width = "60"
                                            radius = "9"
                                            color = 'green'
                                            ariaLabel = 'three-dots-loading'
                                            wrapperStyle
                                            wrapperClass
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <Button variant="danger" onClick={handleViewClose}>
                                            Close
                                        </Button>
                                        <Button variant="success" onClick={updateMyBanner}>
                                            Update
                                        </Button>
                                    </>
                                )
                            }
                    </Modal.Footer>
                </Modal>
            {/* view and update modal end */}

            {/* add new modal */}
                <Modal
                    show={addNew}
                    onHide={handleAddNewClose}
                    backdrop="static"
                    keyboard={false}
                    style={{marginTop : '40px'}}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Add New Offer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="flex justify-center" >
                            <div className='flex justify-center flex-col align-center pb-4  max-w-[400px] w-[100%] mx-auto' >
                                <div className='flex flex-col pt-0 pl-3 font-semibold' >
                                    <h6>Name</h6>
                                    <input type="text" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 ' style={{border: '1px solid black'}} value={sendData?.name} onChange={(e) => setSendData({...sendData , name : e.target.value})} />
                                </div>
                                <div className='flex flex-col pt-2 pl-3 font-semibold' >
                                    <h6>startDate:</h6>
                                    <input type="date"  className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 ' style={{border: '1px solid black'}} value={sendData?.startDate} onChange={(e) => setSendData({...sendData , startDate : e.target.value})} />
                                </div>
                                <div className='flex flex-col pt-2 pl-3 font-semibold' >
                                    <h6>End Date:</h6>
                                    <input type="date" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 ' style={{border: '1px solid black'}} value={sendData?.endDate} onChange={(e) => setSendData({...sendData , endDate : e.target.value})} />
                                </div>
                                <div className='flex flex-col pt-2 pl-3 font-semibold' >
                                    <h6>Discount Percent</h6>
                                    <input type="number" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 ' style={{border: '1px solid black'}} value={sendData?.discountPercent} onChange={(e) => setSendData({...sendData , discountPercent : e.target.value})} />
                                </div>
                                <div className='flex flex-col pt-2 pl-3 font-semibold' >
                                    <h6>Choose Product:</h6>
                                    <select class="form-select" aria-label="Default select example" onChange={(e) => setSendData({...sendData , productId : e.target.value})} >
                                        <option selected disabled></option>
                                        {
                                            allProducts?.length > 0 ? (
                                                allProducts?.map((item) => (
                                                    <option value={item?.Id}>{item?.Name}</option>
                                                ))
                                            ) : (
                                                <option >No Products Found</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            isSending === true ? (
                                <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                    <ThreeDots
                                        height = "60"
                                        width = "60"
                                        radius = "9"
                                        color = 'green'
                                        ariaLabel = 'three-dots-loading'
                                        wrapperStyle
                                        wrapperClass
                                    />
                                </div>
                            ) : (
                                <>
                                    <Button variant="danger" onClick={handleAddNewClose}>
                                        Close
                                    </Button>
                                    <Button variant="success" onClick={addNewMyList} >Add Now</Button>
                                </>
                            )
                        }
                    </Modal.Footer>
                </Modal>
            {/* add new modal end */}
        </>
    );
}

export default ViewAllvendors;
