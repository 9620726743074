import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
//import { FiSettings } from 'react-icons/fi';
//import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
//import {useNavigate} from 'react-router-dom'

import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
//import { Ecommerce, Orders, Calendar, Employees, Stacked, Pyramid, Customers, Kanban, Line, Area, Bar, Pie, Financial, ColorPicker, ColorMapping, Editor } from './pages';
import SignIn from './pages/SignIn'
import './App.css';

import { useStateContext } from './contexts/ContextProvider';

//import UserProfile from './components/UserProfile'

// importing venders
//import AddNewVender from './pages/AddNewVender'
import ViewAllVenders from './pages/venders/ViewAllvendors'

// importing category
//import AddNewCategory from './pages/categories/AddNewCategory'
import ViewAllCategories from './pages/categories/ViewAllParentCategories'
//import AddNewSubCategory from './pages/categories/AddNewSubcategories'
import ViewAllSubCategories from './pages/categories/ViewAllSubcategories'

import ViewAllCustomCategories from './pages/categories/ViewAllCustomCategories'


// importing users
import ViewAllUsers from './pages/users/ViewAllUsers'

// importing stores
//import AddNewStore from './pages/stores/AddNewStore'
import ViewAllStores from './pages/stores/ViewAllStores'
import ViewStoreDetails from './pages/stores/StoreDetails'

// importing products
//import AddNewProduct from './pages/products/AddNewProduct'
import ViewAllProducts from './pages/products/ViewAllproducts'
import ViewAllProductsOfVender from './pages/products/VendorAllProducts'
import ViewAllProductsOfCategory from './pages/products/ParentCategoryRelatedProducts'
import ViewAllProductsOfSubCategory from './pages/products/SubCateProducts'
import ViewAllStoresOfProducts from './pages/products/StoresRelatedProducts'
import ProductDetails from './pages/products/ViewProduct'

// importing coupons
//import AddNewCoupon from './pages/coupons/AddNewCoupon'
import ViewAllCoupons from './pages/coupons/ViewAllCoupons'

// curated list
import ViewAllCuratedList from './pages/curatedList/ViewAllCuratedLists'

// all orders list
import ViewAllOrders from './pages/orders/ViewAllOrders'
import ViewSingleOrder from './pages/orders/NewOrderView'

// all banners
import ViewAllBanners from './pages/banners/ViewAllBanners'

// all offers
import ViewAllOffers from './pages/offers/ViewAllOffers'

// all Riders
import ViewAllRiders from './pages/riders/ViewAllRiders'

// home page for partner
import PartnerHomePage from './pages/HomePagePartner'

// home page for Admin
import HomePage from './pages/AdminHomePage'

// home vehicle Types
import VehicleTypes from './pages/vehicleTypes/ViewAllVehicleTypes'

// ServeArea
import ViewAllServeArea from './pages/serveArea/ViewAllServeArea'

// ServeArea
import ViewAllConfig from './pages/config/ViewAllConfig'


import { toast } from 'react-toastify';

import soundNotif from './data/sound.mp3'

// sockets implementation
import io from 'socket.io-client';
var connectionOptions = {
  "force new connection": true,
  "reconnectionAttempts": "Infinity",
  "timeout": 10000,
  "transports": ["websocket"]
};
const socket = io.connect(process.env.REACT_APP_LIVE_SERVER, connectionOptions);

const App = () => {
  // const [isUser, setIsUser] = useState(false)
  // const [isPartner, setIsPartner] = useState(false)
  const isUser = JSON.parse(localStorage.getItem('priceApp-admin-auth-token')) ? true : false
  const isPartner = JSON.parse(localStorage.getItem('isAdmin'))
  const { setCurrentColor, setCurrentMode, currentMode, setActiveMenu, activeMenu } = useStateContext();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 900) {
        setActiveMenu(false);
      } else {
        setActiveMenu(true);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }

    grantPermission()
  }, []);

  // socket implementation
  useEffect(() => {
    // checking if any customer is signed in
    let newUser = JSON.parse(localStorage.getItem('user'))
    if (socket !== null) {
      if (newUser !== null) {
        socket.on('connect', function () {
          socket.emit("newUserConnected", newUser)
          console.log("disconnect event caught in client side")
        });
      }
    }
  }, [socket])

  // socket implementation
  useEffect(() => {
    // checking if any customer is signed in
    if (socket != null) {
      let isMyAdmin = JSON.parse(localStorage.getItem("isAdmin"));
      if (isMyAdmin == true) {
        socket.on("newOrderReceivedByAdmin", async () => {
          console.log('newOrderReceivedByAdmin')
          toast.success("New order Received");
          playSound()
        });
      } else {
        socket.on("newOrderReceivedForPartner", async (partnerId) => {
          console.log('newOrderReceivedForPartner')
          console.log(partnerId)
          let myUser = JSON.parse(localStorage.getItem("user"));
          if (myUser?.Id == partnerId) {
            toast.success("New order Received By You");
            playSound()
          }
        });
      }
      // getting new order notification
      socket.on("newOrderForDashboardNotification", async (partnerId, msg) => {
        console.log('newOrderForDashboardNotification')
        console.log(msg)
        toast.success(msg);
        playSound()
      });
    }
  }, [socket]);

  const playSound = () => {
    const audio = new Audio(soundNotif);
    audio.play()
  }

  function grantPermission() {
    if (Notification.permission === 'granted') {
      new Notification('You are already subscribed to web notifications');
      return;
    }
    if (
      window.Notification &&
      Notification.permission !== 'granted'
    ) {
      Notification.requestPermission()
      Notification.requestPermission().then(result => {
        if (result === 'granted') {
          window.location.reload();
        }
      }).catch(error => {
        console.log(error, 'error')
      })
    }
  }

  const Layout = ({ children }) => {
    if (isUser)
      return <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full'
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className=" w-full bg-main-bg dark:bg-main-dark-bg navbar " style={{ zIndex: 2 }}  >
          <Navbar socket={socket} />
        </div>
        {children}
      </div>
      
    return <Navigate to="/signin" />;
  }
  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
        {/* for showing toast for 2 seconds */}
        <ToastContainer autoClose={2000} />
        <>
          <div className="relative flex dark:bg-main-dark-bg">
            {/* <div className="fixed right-4 bottom-4" style={{ zIndex: '0' }}>
                <TooltipComponent
                  content="Settings"
                  position="Top"
                >
                  {
                    // isUser == true && (
                    //   <button
                    //     type="button"
                    //     onClick={() => setThemeSettings(true)}
                    //     style={{ background: currentColor, borderRadius: '50%' }}
                    //     className="p-3 text-3xl text-white hover:drop-shadow-xl hover:bg-light-gray"
                    //   >
                    //     {
                    //       isUser == true && (
                    //         <FiSettings />
                    //       )
                    //     }
                    //   </button>
                    // )
                  }

                </TooltipComponent>
              </div> */}
            <div style={{ zIndex: 4 }}>
              {(activeMenu && isUser == true) ? (
                <div className="fixed bg-white w-72 sidebar dark:bg-secondary-dark-bg ">
                  <Sidebar />
                </div>
              ) : (
                isUser == true && (
                  <div className="w-0 dark:bg-secondary-dark-bg">
                    <Sidebar />
                  </div>
                )
              )}
            </div>
            <Routes>
              {/* dashboard  */}
              <Route path="/" element={
                // if isPartner is true the it means that not admin but any vender is logged in and vise versa
                <Layout>{isPartner == true ? <HomePage /> : <PartnerHomePage />}</Layout>
              } />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/venders" element={<Layout><ViewAllVenders /></Layout>} />
              <Route path="/dashboard" element={<Layout>{isPartner === true ? <HomePage /> : <PartnerHomePage />} </Layout>} />
              <Route path="/categories" element={<Layout><ViewAllCategories /></Layout>} />
              <Route path="/sub-categories" element={<Layout><ViewAllSubCategories /></Layout>} />
              <Route path="/custom categories" element={<Layout><ViewAllCustomCategories /></Layout>} />
              <Route path="/users" element={<Layout><ViewAllUsers /></Layout>} />
              <Route path="/our stores" element={<Layout><ViewAllStores /></Layout>} />
              <Route path="/store-details/:id" element={<Layout><ViewStoreDetails /></Layout>} />
              <Route path="/all products" element={<Layout><ViewAllProducts /></Layout>} />
              <Route path="/product-details/:id" element={<Layout><ProductDetails /></Layout>} />
              <Route path="/view-all-products-of-vender/:name/:id" element={<Layout><ViewAllProductsOfVender /></Layout>} />
              <Route path="/view-all-products-of-category/:name/:id" element={<Layout><ViewAllProductsOfCategory /></Layout>} />
              <Route path="/view-all-products-of-sub-category/:name/:id" element={<Layout><ViewAllProductsOfSubCategory /></Layout>} />
              <Route path="/view-all-products-of-store/:name/:id" element={<Layout><ViewAllStoresOfProducts /></Layout>} />
              <Route path="/coupons" element={<Layout><ViewAllCoupons /></Layout>} />
              <Route path="/curated-list" element={<Layout><ViewAllCuratedList /></Layout>} />
              <Route path="/orders" element={<Layout><ViewAllOrders socket={socket} /></Layout>} />
              <Route path="/order-details/:id" element={<Layout><ViewSingleOrder socket={socket} /></Layout>} />
              <Route path="/banners" element={<Layout><ViewAllBanners /></Layout>} />
              <Route path="/manage offers" element={<Layout><ViewAllOffers /></Layout>} />
              <Route path="/config app" element={<Layout><ViewAllConfig /></Layout>} />
              <Route path="/riders" element={<Layout><ViewAllRiders /></Layout>} />
              <Route path="/vehicleTypes" element={<Layout><VehicleTypes /></Layout>} />
              <Route path="/service area" element={<Layout><ViewAllServeArea /></Layout>} />
              {/* <Route path="*" element={isUser == true ? (isPartner == true ? <Layout><PartnerHomePage /></Layout> : <Layout><HomePage /></Layout>) : (<SignIn />)} /> */}
            </Routes>
          </div>
        </>
      </BrowserRouter>
    </div>
  );
};

export default App;

export { socket };
