import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { signInAdmin } from "../api/AdminApi";
import { useNavigate } from "react-router-dom";
import { socket } from "../App";

const SignIn = () => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [myUserData, setMyUserData] = useState(null);

  // sending data
  const signMeIn = async () => {
    setIsFetching(true);
    const { data } = await signInAdmin(userData);
    if (data?.success === true) {
      toast.success("Signed In SuccessFully");
      setMyUserData(data?.User);
      // emitting event
      socket.emit("newUserConnected", data?.User);
      localStorage.setItem(
        process.env.REACT_APP_ADMIN_TOKEN,
        JSON.stringify(data?.Token)
      );
      localStorage.setItem("isAdmin", JSON.stringify(data?.User?.isAdmin));
      localStorage.setItem("user", JSON.stringify(data?.User));
      navigate("/venders");
      window.location.reload();
    } else {
      toast.error(data?.message);
    }
    setIsFetching(false);
  };

  // socket implementation
  useEffect(() => {
    // checking if any customer is signed in
    if (socket !== null) {
      // CLIENT CODE
      socket.on("disconnect", function () {
        console.log("disconnect event caught in client side");
      });

      socket.on("accountBlocked", () => {
        toast.warning(
          "Your Account has been temporarily Blocked By 5 Chef's Catering App"
        );
        setTimeout(function () {
          toast.success("We are Logging You Out for Now");
        }, 5000);
      });
    }
  }, [socket]);

  // checking if signed in or not
  useEffect(() => {
    let adminToken = JSON.parse(
      localStorage.getItem("priceApp-admin-auth-token")
    );
    if (adminToken) {
      navigate("/venders");
    }
  }, []);

  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  return (
    <div className="dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full">
      <div className="flex justify-center mt-12">
        <div className="flex justify-center  flex-col align-center pb-4 border rounded-lg border-[#535c68]  max-w-[500px] w-[100%] mx-auto pt-6">
          <img
            alt="logo"
            className="max-w-[100px] max-h-[100px] rounded-lg mx-auto mb-5"
            src="/appLogo.png"
          />
          <h6 className="text-xl font-bold text-center">Sign In:</h6>
          <div className="flex flex-col pt-5 pl-3 font-semibold">
            <h6>Email:</h6>
            <input
              type="email"
              placeholder="Enter Email Please ..."
              className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
              value={userData?.email}
              onChange={(e) =>
                setUserData({ ...userData, email: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col pt-5 pl-3 font-semibold">
            <h6>Password:</h6>
            <input
              type="password"
              placeholder="Enter Password Please ..."
              className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
              value={userData?.password}
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
            />
          </div>
          {isFetching == true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <ThreeDots
                height="60"
                width="60"
                radius="9"
                color="green"
                ariaLabel="three-dots-loading"
                wrapperStyle
                wrapperClass
              />
            </div>
          ) : (
            <button
              className="bg-[#00b894] mt-3 rounded-md p-2 text-white font-semibold mx-auto"
              onClick={signMeIn}
            >
              Sign Now
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
