import React , {useState , useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { ThreeDots } from  'react-loader-spinner'
import { toast } from 'react-toastify';
import {getAllUsers , getAllUsersForVender, updateStatusOfAnyuser ,getMySingleUserDeatils ,addAmountToAnyCustomerByAdmin , addAmountToAnyCustomerByVender , getAllTransactionsOfAUser , getAllTransactionsOfAUserByVender ,subtractAmountToAnyCustomerByAdmin , subtractAmountToAnyCustomerByVender } from '../../api/AdminApi'
import {useNavigate} from 'react-router-dom'
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
import { Col, Row, Table ,InputGroup  , Modal , Button , Dropdown, ButtonGroup  } from 'react-bootstrap';
import {GrView} from 'react-icons/gr'
import moment from "moment"

const ViewAllvendors = () => {
    const navigate = useNavigate()
    const TABLE_HEADERS = [
        {
            prop: "username",
            title: "Name"
        },
        {
            prop: "profilePic",
            title: "Profile Picture",
            cell: (prop) => {
                    return (
                            <img src={ prop?.profilePic?.indexOf("https") == 0 ? prop?.profilePic :  process.env.REACT_APP_LIVE_SERVER + "/usersImages/" + prop?.profilePic} alt="profile pic" style={{maxWidth : '50px', maxHeight : '50%', borderRadius : '10px'}} />
                        )
                    },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
        },
        {
            prop: "phoneno",
            title: "Phone No"
        },
        {
            prop: "walletAmt",
            title: "Wallet Amt"
        },
        {
            prop: "monthlySpent",
            title: "Monthly Spent"
        },
        {
            prop: "status",
            title: "Status",
            cell: (prop) => {
                return (
                        <Dropdown as={ButtonGroup}>
                            {
                                prop?.status == false && (
                                    <Button size="sm" variant="danger" style={{fontSize : '11px' , fontWeight : 600}} >In Active</Button>
                                )
                            }
                            {
                                prop?.status == true && (
                                    <Button size="sm" variant="success" style={{fontSize : '11px' , fontWeight : 600}} >Active</Button>
                                )
                            }
                            <Dropdown.Toggle split size="sm" variant="primary" id="dropdown-split-basic" />
                            <Dropdown.Menu style={{backgroundColor : 'transparent'}} >
                                <Dropdown.Item onClick={() => changeStatus(prop?.Id)} style={{backgroundColor : '#c23616', color : 'white'}} >Declined</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeStatus(prop?.Id)} style={{backgroundColor : '#10ac84', color : 'white'}} >Approved</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            prop: "action",
            title: "Action",
            cell: (prop) => {
            return (
                    <GrView style={{cursor : 'pointer'}} onClick={() => {handleView(prop.Id); setSelectedId(prop.Id)}} ></GrView>
                )
            },
        },
    ];

    const [ isFetching , setIsFetching ] = useState(false)
    const [ isGetting , setIsGetting ] = useState(false)
    const [ singleUserData , setSingleUserData ] = useState({})
    const [ walletAmount , setWalletAmount ] = useState()
    const [ subtractAmount , setWalletSubAmount ] = useState()
    const [ allData , setAllData ] = useState([])
    const [ selectedId , setSelectedId ] = useState("")
    const [ showTransactions , setShowTransactions ] = useState(false)
    const [ allTransactions , setAllTransactions ] = useState([])
    // sleeping
    const delay = ms => new Promise(res => setTimeout(res, ms));

    // checking if signed in or not
    const [ isAdmin , setIsAdmin ] = useState(false)
    useEffect(() => {
        let adminToken = JSON.parse(localStorage.getItem('priceApp-admin-auth-token'))
        if(!adminToken){
            navigate('/signin')
        }
        let isMyAdmin = JSON.parse(localStorage.getItem('isAdmin'))
        if(isMyAdmin == false){
            setIsAdmin(false)
        }else{
           setIsAdmin(true)
        }
    },[])

    // changing status of vender
    const changeStatus = async (id) => {
        let isFound = allData.find(item => item.Id === id);
        if(isFound){
            const {data} = await updateStatusOfAnyuser(id);
            if(data?.success === true){
                if(isFound.status == false){
                    isFound.status = true
                    toast.success("Customer Activated Successfully");
                }else{
                    isFound.status = false
                    toast.success("Customer InActivated Successfully");
                }
                let newData = allData;
                let finalData = newData.filter(item => item.Id === id ? isFound : item );
                setAllData(finalData)
            }else{
                toast.success(data?.message);
            }
        }
    }

    // getting data
    useEffect(() => {
        const getData = async () => {
            setIsFetching(true)
            if(isAdmin == true){
                const {data} = await getAllUsers();
                if(data?.success === true){
                    let allDataArr = []
                    for(let i = 0; i !== data?.AllUsers.length; i++){
                        console.log("data?.AllUsers[i]?.profilePic : ",data?.AllUsers[i]?.profilePic)
                        let newArr = {
                            username: data?.AllUsers[i]?.username,
                            phoneno: data?.AllUsers[i]?.phoneNo,
                            profilePic: data?.AllUsers[i]?.profilePic ,
                            monthlySpent: data?.AllUsers[i]?.monthlySpent,
                            status: data?.AllUsers[i]?.status,
                            Id: data?.AllUsers[i]?._id,
                            walletAmt : data?.AllUsers[i]?.walletAmt
                        }
                        allDataArr.push(newArr)
                    }
                    setAllData(allDataArr)
                }
            }else{
                const {data} = await getAllUsersForVender();
                if(data?.success === true){
                    let allDataArr = []
                    for(let i = 0; i !== data?.AllUsers.length; i++){
                        console.log("data?.AllUsers[i]?.profilePic : ",data?.AllUsers[i]?.profilePic)
                        let newArr = {
                            username: data?.AllUsers[i]?.username,
                            phoneno: data?.AllUsers[i]?.phoneNo,
                            profilePic: data?.AllUsers[i]?.profilePic ,
                            monthlySpent: data?.AllUsers[i]?.monthlySpent,
                            status: data?.AllUsers[i]?.status,
                            Id: data?.AllUsers[i]?._id,
                            walletAmt : data?.AllUsers[i]?.walletAmt
                        }
                        allDataArr.push(newArr)
                    }
                    setAllData(allDataArr)
                }
            }
            setIsFetching(false)
        }
        getData()
    },[isAdmin])

    // viuew modal
    const [view, setView] = useState(false);
    const handleViewClose = () => {
        setView(false);
        setShowTransactions(false)
        setAllTransactions([])
    }
    // getting data of user
    const handleView = async (id) => {
        setView(true);
        setIsGetting(true)
        const {data} = await getMySingleUserDeatils(id);
        if(data?.success === true){
            setSingleUserData(data?.Vender);
        }else{
            toast.error(data?.message)
        }
        setIsGetting(false)
    }
    
    // adding wallet amount
    const updateMyUser = async () => {
        if(isAdmin == true){
            const {data} = await addAmountToAnyCustomerByAdmin(selectedId , walletAmount);
            if(data?.success === true){
                toast.success("Amount Added Successfully");
                
                // getting updated data
                const res = await getAllUsers();
                if(res?.data?.success === true){
                    let allDataArr = []
                    for(let i = 0; i !== res?.data?.AllUsers.length; i++){
                        let newArr = {
                            username: res?.data?.AllUsers[i]?.username,
                            phoneno: res?.data?.AllUsers[i]?.phoneNo,
                            profilePic: res?.data?.AllUsers[i]?.profilePic ,
                            monthlySpent: res?.data?.AllUsers[i]?.monthlySpent,
                            status: res?.data?.AllUsers[i]?.status,
                            Id: res?.data?.AllUsers[i]?._id,
                            walletAmt : res?.data?.AllUsers[i]?.walletAmt
                        }
                        allDataArr.push(newArr)
                    }
                    setAllData(allDataArr)
                }
                
                handleViewClose()
                setWalletAmount()
            }else{
                toast.error(data?.message)
            }
        }else{
            const {data} = await addAmountToAnyCustomerByVender(selectedId , walletAmount);
            if(data?.success === true){
                toast.success("Amount Added Successfully");
                
                // getting updated data
                const res = await getAllUsersForVender();
                if(res?.data?.success === true){
                    let allDataArr = []
                    for(let i = 0; i !== res?.data?.AllUsers.length; i++){
                        let newArr = {
                            username: res?.data?.AllUsers[i]?.username,
                            phoneno: res?.data?.AllUsers[i]?.phoneNo,
                            profilePic: res?.data?.AllUsers[i]?.profilePic ,
                            monthlySpent: res?.data?.AllUsers[i]?.monthlySpent,
                            status: res?.data?.AllUsers[i]?.status,
                            Id: res?.data?.AllUsers[i]?._id,
                            walletAmt : res?.data?.AllUsers[i]?.walletAmt
                        }
                        allDataArr.push(newArr)
                    }
                    setAllData(allDataArr)
                }
                
                handleViewClose()
                setWalletAmount()
            }else{
                toast.error(data?.message)
            }
        }
        setShowTransactions(false)
    }
    
    // adding wallet amount
    const subtractMyUser = async () => {
        if(isAdmin == true){
            const {data} = await subtractAmountToAnyCustomerByAdmin(selectedId , subtractAmount);
            if(data?.success === true){
                toast.success("Amount Added Successfully");
                
                // getting updated data
                const res = await getAllUsers();
                if(res?.data?.success === true){
                    let allDataArr = []
                    for(let i = 0; i !== res?.data?.AllUsers.length; i++){
                        let newArr = {
                            username: res?.data?.AllUsers[i]?.username,
                            phoneno: res?.data?.AllUsers[i]?.phoneNo,
                            profilePic: res?.data?.AllUsers[i]?.profilePic ,
                            monthlySpent: res?.data?.AllUsers[i]?.monthlySpent,
                            status: res?.data?.AllUsers[i]?.status,
                            Id: res?.data?.AllUsers[i]?._id,
                            walletAmt : res?.data?.AllUsers[i]?.walletAmt
                        }
                        allDataArr.push(newArr)
                    }
                    setAllData(allDataArr)
                }
                
                handleViewClose()
                setWalletSubAmount()
            }else{
                toast.error(data?.message)
            }
        }else{
            const {data} = await subtractAmountToAnyCustomerByVender(selectedId , subtractAmount);
            console.log("response in vender: ", data)
            if(data?.success === true){
                toast.success("Amount Added Successfully");
                
                // getting updated data
                const res = await getAllUsersForVender();
                if(res?.data?.success === true){
                    let allDataArr = []
                    for(let i = 0; i !== res?.data?.AllUsers.length; i++){
                        let newArr = {
                            username: res?.data?.AllUsers[i]?.username,
                            phoneno: res?.data?.AllUsers[i]?.phoneNo,
                            profilePic: res?.data?.AllUsers[i]?.profilePic ,
                            monthlySpent: res?.data?.AllUsers[i]?.monthlySpent,
                            status: res?.data?.AllUsers[i]?.status,
                            Id: res?.data?.AllUsers[i]?._id,
                            walletAmt : res?.data?.AllUsers[i]?.walletAmt
                        }
                        allDataArr.push(newArr)
                    }
                    setAllData(allDataArr)
                }
                
                handleViewClose()
                setWalletSubAmount()
            }else{
                toast.error(data?.message)
            }
        }
        setShowTransactions(false)
    }
    
    // getting all transactions of a user
    const getAllTransofUser = async() => {
        if(isAdmin == true){
            const {data} = await getAllTransactionsOfAUser(selectedId)
            if(data?.success === true){
                setAllTransactions(data?.AllTransactions)
            }else{
                toast.error(data?.message)
            }
        }else{
            const {data} = await getAllTransactionsOfAUserByVender(selectedId)
            if(data?.success === true){
                setAllTransactions(data?.AllTransactions)
            }else{
                toast.error(data?.message)
            }
        }
    }

    return (
        <>
            {
                isFetching === true ? (
                    <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                        <ThreeDots
                            height = "60"
                            width = "60"
                            radius = "9"
                            color = 'green'
                            ariaLabel = 'three-dots-loading'
                            wrapperStyle
                            wrapperClass
                        />
                    </div>
                ) : (
                    <DatatableWrapper
                        body={allData}
                        headers={TABLE_HEADERS}
                        paginationOptionsProps={{
                            initialState: {
                                rowsPerPage: 10,
                                options: [5, 10, 15, 20]
                            }
                        }}
                        >
                        <Row className="p-4 mb-4">
                            <Col
                                xs={12}
                                lg={4}
                                className="flex-col d-flex justify-content-start align-items-start"
                            >
                                <h4>All Users</h4>
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="flex-col mb-2 d-flex justify-content-lg-center align-items-center justify-content-sm-start mb-sm-0"
                            >
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="flex-col d-flex justify-content-end align-items-end"
                            >
                                {/* <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Search here..."
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        onBlur = {() => getAllData()}
                                        onChange={(e) => setText(e.target.value)}
                                    />
                                        <Button variant="info" onClick={getSearchedMerchants}>Search</Button>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Table striped bordered hover className="ml-2 mr-2" >
                            <TableHeader />
                            <TableBody />
                        </Table>
                        <Row className="p-2 mb-4">
                            <Col
                                xs={12}
                                sm={6}
                                lg={6}
                                className="flex-col mb-2 d-flex justify-content-lg-start align-items-start justify-content-sm-start mb-sm-0"
                            >
                                <PaginationOptions />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={6}
                                className="flex-col d-flex justify-content-end align-items-end"
                            >
                                <Pagination />
                            </Col>
                        </Row>
                    </DatatableWrapper>
                )
            }

            {/* view and update modal */}
            <Modal
                show={view}
                onHide={handleViewClose}
                keyboard={false}
                //style={{marginTop : '100px'}}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>User Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isGetting === true ? (
                            <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                <ThreeDots
                                    height = "60"
                                    width = "60"
                                    radius = "9"
                                    color = 'green'
                                    ariaLabel = 'three-dots-loading'
                                    wrapperStyle
                                    wrapperClass
                                />
                            </div>
                        ) : (
                            <div className="flex justify-center" >
                                <div className='flex justify-start flex-col align-center pb-4 w-[100%] mx-auto  pt-3' >
                                    <Row >
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <img src={ singleUserData?.profilePic?.indexOf("https") == 0 ? singleUserData?.profilePic :  process.env.REACT_APP_LIVE_SERVER + "/usersImages/" + singleUserData?.profilePic} alt="cover pic" style={{maxWidth : '80px', maxHeight : '80px', borderRadius : '10px'}} />
                                            </div>                                                                                    
                                        </Col>
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Name:</h6>
                                                <input type="text" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.username}  />
                                            </div>                                                                      
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Email:</h6>
                                                <input type="text" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.email}  />
                                            </div>                                                                      
                                        </Col>
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Phone No:</h6>
                                                <input type="text" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.phoneNo}  />
                                            </div>                                                                      
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Wallet Amount</h6>
                                                <input type="number" placeholder="" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.walletAmt} />
                                            </div>                                                                      
                                        </Col>
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Age </h6>
                                                <input type="number" placeholder="" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.age} />
                                            </div>                                                                      
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Gender</h6>
                                                <input type="string" placeholder="" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.gender} />
                                            </div>                                                                      
                                        </Col>
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Race</h6>
                                                <input type="string" placeholder="" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.race} />
                                            </div>                                                                      
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Profession</h6>
                                                <input type="string" placeholder="" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.profession} />
                                            </div>                                                                      
                                        </Col>
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Education Level</h6>
                                                <input type="string" placeholder="" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.educationLevel} />
                                            </div>                                                                      
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Marital Status</h6>
                                                <input type="string" placeholder="" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.maritalStatus} />
                                            </div>                                                                      
                                        </Col>
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Address</h6>
                                                <input type="string" placeholder="" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.address} />
                                            </div>                                                                      
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Monthly Spent</h6>
                                                <input type="text" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.monthlySpent}  />
                                            </div>                                                                      
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Add New Amount to Wallet</h6>
                                                <input type="number" placeholder="Enter New Amount ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={walletAmount} onChange={(e) => setWalletAmount( e.target.value)}  />
                                                <Button variant="success" style={{maxWidth : "150px", marginTop : "15px"}} onClick={updateMyUser} >Add Amount </Button>
                                            </div>                                                                      
                                        </Col>
                                        <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                                <h6>Subtract  Amount from Wallet</h6>
                                                <input type="number" placeholder="Enter New Amount ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={subtractAmount} onChange={(e) => setWalletSubAmount( e.target.value)}  />
                                                <Button variant="success" style={{maxWidth : "150px", marginTop : "15px"}} onClick={subtractMyUser} >Subtract Amount </Button>
                                            </div>                                                                            
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop : "20px", maxHeight : "400px", overflowY : "scroll"}} >
                                        <Col sm={12} >
                                            <div style={{display : "flex" , flexDirection : "column", justifyContent : "center", alignItems : "center"}} >
                                                {
                                                    showTransactions === true ? (
                                                        <Button variant="info" onClick={() => {setShowTransactions(!showTransactions); showTransactions == false && getAllTransofUser() }} >Hide Transactions:</Button>
                                                    ) : (
                                                        <Button variant="info" onClick={() => {setShowTransactions(!showTransactions); showTransactions == false && getAllTransofUser() }} >View All Transactions:</Button>
                                                    )
                                                }
                                                {
                                                    showTransactions === true ? (
                                                        <Table striped bordered hover style={{marginTop : "15px"}} >
                                                              <thead>
                                                                <tr>
                                                                  <th>Transaction #</th>
                                                                  <th>Date</th>
                                                                  <th>Previous Amt</th>
                                                                  <th>Debit/Credit Amt</th>
                                                                  <th>New Amt</th>
                                                                  <th>Order Id</th>
                                                                  <th>Is Added By Admin</th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {
                                                                    allTransactions?.length > 0 ? (
                                                                        allTransactions?.map((item) => (
                                                                            <tr key={item?._id} >
                                                                              <td>{item?.transactionId}</td>
                                                                              <td>{moment(item?.createdAt).format("DD/MM/YY")}</td>
                                                                              <td>{item?.previousAmt}</td>
                                                                              <td>{item?.amtToBeAddedOrSub}</td>
                                                                              <td>{item?.newAmt}</td>
                                                                              <td>{item?.orderId?.orderNo}</td>
                                                                              <td>{item?.isAmtAddedByAdmin === true ? "Yes" : "NO"}</td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <p> No Transactions made yet</p>
                                                                    )
                                                                }
                                                                
                                                              </tbody>
                                                        </Table>
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleViewClose}>
                        Close
                    </Button>
                    {/* <Button variant="success" onClick={updateMyUser}>
                        Update
                    </Button> */}
                </Modal.Footer>
            </Modal>
            {/* view and update modal end */}
        </>
    );
}

export default ViewAllvendors;
