import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { updateConfig, getConfig } from "../../api/AdminApi";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, Form } from "react-bootstrap";

const ViewAllvendors = () => {
  const navigate = useNavigate();

  const [isFetching, setIsFetching] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [allData, setAllData] = useState({});
  // checking if signed in or not
  useEffect(() => {
    let adminToken = JSON.parse(
      localStorage.getItem("priceApp-admin-auth-token")
    );
    if (!adminToken) {
      navigate("/signin");
    }
    let isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
    if (!isAdmin) {
      navigate("/signin");
    }
    if (isAdmin === false) {
      navigate("/our stores");
    }
  }, []);

  // sending data
  const onUpdate = async () => {
    setIsSending(true);
    try {
      const { data } = await updateConfig(allData._id, {
        link_payment: allData?.link_payment,
        name_app: allData?.name_app,
        name_app_driver: allData?.name_app_driver,
        price_5km: allData?.price_5km,
        price_bigger_5km: allData?.price_bigger_5km,
      });
      if (data?.success === true) {
        toast.success("New Serve Area Added SuccessFully");
        setIsFetching(true);
        const res = await getConfig();
        setAllData(res.data?.data);
      } else {
        toast.error(data?.message);
      }
    } finally {
      // handleAddNewClose();
      setIsFetching(false);
      setIsSending(false);
    }
  };

  // getting data
  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      const { data } = await getConfig();
      setAllData(data?.data);
      setIsFetching(false);
    };
    getData();
  }, []);

  return (
    <>
      {isFetching === true ? (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "auto" }}
        >
          <ThreeDots
            height="60"
            width="60"
            radius="9"
            color="green"
            ariaLabel="three-dots-loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      ) : (
        <div className="flex justify-center">
          <Form className="h-auto">
            <h4>Config App</h4>
            <div className="flex justify-start flex-col align-center pb-4 w-[100%] mx-auto">
              <Row>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-3 pl-3 font-semibold">
                    <h6>App Name:</h6>
                    <input
                      type="text"
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      style={{ border: "1px solid black" }}
                      value={allData.name_app}
                      disabled
                    />
                  </div>
                </Col>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-3 pl-3 font-semibold">
                    <h6>App Rider Name:</h6>
                    <input
                      type="text"
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      style={{ border: "1px solid black" }}
                      value={allData.name_app_driver}
                      disabled
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-3 pl-3 font-semibold">
                    <h6>Link Payment Credit Partner:</h6>
                    <input
                      type="text"
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      style={{ border: "1px solid black" }}
                      value={allData?.link_payment}
                      onChange={(e) =>
                        setAllData({
                          ...allData,
                          link_payment: e.target.value,
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <h4 className="pt-4">Price Delivery</h4>
              <Row>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-2 pl-3 font-semibold">
                    <h6>Price {'<'} 5km :</h6>
                    <input
                      type="text"
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      style={{ border: "1px solid black" }}
                      value={allData?.price_5km}
                      onChange={(e) =>
                        setAllData({
                          ...allData,
                          price_5km: e.target.value,
                        })
                      }
                    />
                  </div>
                </Col>
                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]">
                  <div className="flex flex-col pt-2 pl-3 font-semibold">
                    <h6>Extra Price If Beyond 5km :</h6>
                    <input
                      type="text"
                      className="min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]"
                      style={{ border: "1px solid black" }}
                      value={allData?.price_bigger_5km}
                      onChange={(e) =>
                        setAllData({
                          ...allData,
                          price_bigger_5km: e.target.value,
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Col sm={12} md={12}>
                <div className="pt-4 pl-3">
                  {isSending ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "auto",
                      }}
                    >
                      <ThreeDots
                        height="60"
                        width="60"
                        radius="9"
                        color="green"
                        ariaLabel="three-dots-loading"
                        wrapperStyle
                        wrapperClass
                      />
                    </div>
                  ) : (
                    <Button variant="success" onClick={onUpdate}>
                      Update
                    </Button>
                  )}
                </div>
              </Col>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default ViewAllvendors;
