import React , {useState , useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { ThreeDots } from  'react-loader-spinner'
import { toast } from 'react-toastify';
import {getAllVehicleType , updateStatusOfSingleVehicleType , getSingleVehicleType , updateSingleVehicleType , addNewVehicleType} from '../../api/AdminApi'
import {useNavigate} from 'react-router-dom'
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
import { Col, Row, Table ,InputGroup , Form , Modal , Button , Dropdown, ButtonGroup  } from 'react-bootstrap';
import { AiOutlineEdit} from 'react-icons/ai'
import moment from 'moment'


const ViewAllvendors = () => {
    const navigate = useNavigate()
    const TABLE_HEADERS = [
        {
            prop: "name",
            title: "Name"
        },
        {
            prop: "fee",
            title: "Price (per km)"
        },
        {
            prop: "status",
            title: "Status",
            cell: (prop) => {
                return (
                        <Dropdown as={ButtonGroup} style={{zIndex : "0"}} >
                            {
                                prop?.status == false && (
                                    <Button size="sm" variant="danger" style={{fontSize : '11px' , fontWeight :600, zIndex : 0}} >In Active</Button>
                                )
                            }
                            {
                                prop?.status == true && (
                                    <Button size="sm" variant="success" style={{fontSize : '11px' , fontWeight : 600 , zIndex : 0 }} >Active</Button>
                                )
                            }
                            <Dropdown.Toggle split size="sm" variant="primary" id="dropdown-split-basic" style={{zIndex : 0}} />
                            <Dropdown.Menu style={{backgroundColor : 'transparent', zIndex : -1}} >
                                <Dropdown.Item onClick={() => changeStatus(prop?.Id)}  >De-Activate</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeStatus(prop?.Id)}  >Activate</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            prop: "action",
            title: "Action",
            cell: (prop) => {
            return (
                    <AiOutlineEdit style={{cursor : 'pointer'}} onClick={() => handleView(prop.Id)} ></AiOutlineEdit>
                )
            },
        },
    ];

    const [ isFetching , setIsFetching ] = useState(false)
    const [ isGetting , setIsGetting ] = useState(false)
    const [ isSending , setIsSending ] = useState(false)
    const [ singleUserData , setSingleUserData ] = useState({})
    const [ uploadImage , setUploadImage ] = useState(null)
    const [ allData , setAllData ] = useState([])
    // sleeping
    const delay = ms => new Promise(res => setTimeout(res, ms));

    // checking if signed in or not
    useEffect(() => {
        let adminToken = JSON.parse(localStorage.getItem('priceApp-admin-auth-token'))
        if(!adminToken){
            navigate('/signin')
        }
        let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
        if(!isAdmin){
            navigate('/signin')
        }
    },[])

    // changing status of vender
    const changeStatus = async (id) => {
        let isFound = allData.find(item => item.Id === id);
        if(isFound){
            const {data} = await updateStatusOfSingleVehicleType(id);
            if(data?.success === true){
                if(isFound.status == false){
                    isFound.status = true
                    toast.success("Coupon Activated Successfully");
                }else{
                    isFound.status = false
                    toast.success("Coupon InActivated Successfully");
                }
                let newData = allData;
                let finalData = newData.filter(item => item.Id === id ? isFound : item );
                setAllData(finalData)
            }else{
                toast.success(data?.message);
            }
        }
    }

    // getting data
    useEffect(() => {
        const getData = async () => {
            setIsFetching(true)
            const {data} = await getAllVehicleType();
            if(data?.success === true){
                let allDataArr = []
                for(let i = 0; i !== data?.AllVehicles.length; i++){
                    let newArr = {
                        name: data?.AllVehicles[i]?.name,
                        Id: data?.AllVehicles[i]?._id,
                        fee : data?.AllVehicles[i]?.fee,
                        status: data?.AllVehicles[i]?.status,
                    }
                    allDataArr.push(newArr)
                }
                console.log("allDataArr : ",allDataArr)
                setAllData(allDataArr)
            }
            setIsFetching(false)
        }
        getData()
    },[])

    // viuew modal
    const [view, setView] = useState(false);
    const handleViewClose = () => {
        setUploadImage(null)
        setView(false);
        setIsSending(false)
    }
    // getting data of user
    const handleView = async (id) => {
        setView(true);
        setIsGetting(true)
        const {data} = await getSingleVehicleType(id);
        if(data?.success === true){
            setSingleUserData(data?.Vehicle);
        }else{
            toast.error(data?.message)
        }
        setIsGetting(false)
    }

    // updating data if any vehicle type
    const updateData = async () => {
        setIsSending(true)
        const {data} = await updateSingleVehicleType(singleUserData._id , singleUserData);
        if(data?.success === true){
            toast.success(data?.message)
            // getting updated data
            setIsFetching(true)
            const res = await getAllVehicleType();
            if(res?.data?.success === true){
                let allDataArr = []
                for(let i = 0; i !== res?.data?.AllVehicles.length; i++){
                    let newArr = {
                        name: res?.data?.AllVehicles[i]?.name,
                        Id: res?.data?.AllVehicles[i]?._id,
                        fee : res?.data?.AllVehicles[i]?.fee,
                        status: res?.data?.AllVehicles[i]?.status,
                    }
                    allDataArr.push(newArr)
                }
                console.log("allDataArr : ",allDataArr)
                setAllData(allDataArr)
            }
            setIsFetching(false)

            handleViewClose()
        }else{
            toast.error(data?.message)
        }
        setIsSending(false)
    }
    
    // user details object
    const [userData , setUserData ] = useState({
        name : "",
        fee : ""
    })

    // add new modal
    const [addNew, setAddNew] = useState(false);
    const handleAddNewClose = () => {
        setUploadImage(null)
        setUserData({
            fee : "",
            name : ""
        })
        setAddNew(false);
        setIsSending(false)
    }
    // getting data of user
    const handleAddNew = async (id) => {
        setAddNew(true);
    }

    // sending data
    const addNewVender = async () => {
        setIsSending(true)
        const {data} = await addNewVehicleType(userData);
        if(data?.success === true){
            toast.success("New vehicle Type Added SuccessFully")

            setIsFetching(true)
            const res = await getAllVehicleType();
            if(res?.data?.success === true){
                let allDataArr = []
                for(let i = 0; i !== res?.data?.AllVehicles.length; i++){
                    let newArr = {
                        name: res?.data?.AllVehicles[i]?.name,
                        Id: res?.data?.AllVehicles[i]?._id,
                        fee : res?.data?.AllVehicles[i]?.fee,
                        status: res?.data?.AllVehicles[i]?.status,
                    }
                    allDataArr.push(newArr)
                }
                console.log("allDataArr : ",allDataArr)
                setAllData(allDataArr)
            }
            setIsFetching(false)

            //await delay(1000)
            handleAddNewClose()
        }else{
            toast.error(data?.message)
        }
        setIsFetching(false)
    }
    

    return (
        <>
            {
                isFetching === true ? (
                    <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                        <ThreeDots
                            height = "60"
                            width = "60"
                            radius = "9"
                            color = 'green'
                            ariaLabel = 'three-dots-loading'
                            wrapperStyle
                            wrapperClass
                        />
                    </div>
                ) : (
                    <DatatableWrapper
                        body={allData}
                        headers={TABLE_HEADERS}
                        paginationOptionsProps={{
                            initialState: {
                                rowsPerPage: 10,
                                options: [5, 10, 15, 20]
                            }
                        }}
                        style={{zIndex : 0}}
                        >
                        <Row className="p-4 mb-4">
                            <Col
                                xs={12}
                                lg={4}
                                className="flex-col d-flex justify-content-start align-items-start"
                            >
                                <h4>All Vehicle Types</h4>
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="flex-col mb-2 d-flex justify-content-lg-center align-items-center justify-content-sm-start mb-sm-0"
                            >
                                <Button size="lg" variant="info" onClick={handleAddNew} style={{fontSize : '15px' , fontWeight : 600}} >Add New</Button>
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="flex-col d-flex justify-content-end align-items-end"
                            >
                                {/* <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Search here..."
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        onBlur = {() => getAllData()}
                                        onChange={(e) => setText(e.target.value)}
                                    />
                                        <Button variant="info" onClick={getSearchedMerchants}>Search</Button>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Table striped bordered hover className="ml-2 mr-2" >
                            <TableHeader />
                            <TableBody />
                        </Table>
                        <Row className="p-2 mb-4">
                            <Col
                                xs={12}
                                sm={6}
                                lg={6}
                                className="flex-col mb-2 d-flex justify-content-lg-start align-items-start justify-content-sm-start mb-sm-0"
                            >
                                <PaginationOptions />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={6}
                                className="flex-col d-flex justify-content-end align-items-end"
                            >
                                <Pagination />
                            </Col>
                        </Row>
                    </DatatableWrapper>
                )
            }

            {/* view and update modal */}
                <Modal
                    show={view}
                    onHide={handleViewClose}
                    backdrop="static"
                    keyboard={false}
                    //style={{marginTop : '100px'}}
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Coupon Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            isGetting === true ? (
                                <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                    <ThreeDots
                                        height = "60"
                                        width = "60"
                                        radius = "9"
                                        color = 'green'
                                        ariaLabel = 'three-dots-loading'
                                        wrapperStyle
                                        wrapperClass
                                    />
                                </div>
                            ) : (
                                <div className="flex justify-center" >
                                    <div className='flex justify-start flex-col align-center pb-4 w-[100%] mx-auto  pt-3' >
                                        <Row >
                                            <Col sm={12} md={12} className="md:min-w-[300px] min-w-[100%]">
                                                <div className='flex flex-col pt-0 pl-3 font-semibold' >
                                                    <h6>Vehicle Type:</h6>
                                                    <input type="text" placeholder="Enter type name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black" ,minWidth : "300px"}} value={singleUserData?.name} onChange={(e) => setSingleUserData({...singleUserData, name : e.target.value})}  />
                                                    <h6 style={{marginTop : "20px"}} >Fee (*):</h6>
                                                    <input type="number"  placeholder="Enter Vehicle Type ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: '1px solid black' , minWidth : "300px"}} value={singleUserData?.fee} onChange={(e) => setSingleUserData({...singleUserData , fee : e.target.value})} />
                                                </div>                                                                      
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            isSending === true ? (
                                <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                    <ThreeDots
                                        height = "60"
                                        width = "60"
                                        radius = "9"
                                        color = 'green'
                                        ariaLabel = 'three-dots-loading'
                                        wrapperStyle
                                        wrapperClass
                                    />
                                </div>
                            ) : (
                                <>
                                    <Button variant="danger" onClick={handleViewClose}>
                                        Close
                                    </Button>
                                    <Button variant="success" onClick={updateData} >Update Now</Button>
                                </>
                            )
                        }
                    </Modal.Footer>
                </Modal>
            {/* view and update modal end */}

            {/* add new modal */}
                <Modal
                    show={addNew}
                    onHide={handleAddNewClose}
                    backdrop="static"
                    keyboard={false}
                    //style={{marginTop : '100px'}}
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Vehicle Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="flex justify-center" >
                            <div className='flex justify-start flex-col align-center pb-4 w-[100%] mx-auto  pt-3' >
                                <Row >
                                    <Col sm={12} md={12} className="md:min-w-[300px] min-w-[100%]">
                                        <div className='flex justify-start flex-col align-center pb-4 w-[100%] mx-auto  pt-3' style={{justifyContent : "center" , paddingLeft : "30px"}} >
                                            <h6>Name (*):</h6>
                                            <input type="text"  placeholder="Enter Vehicle Type ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: '1px solid black' , minWidth : "300px"}} value={userData?.name} onChange={(e) => setUserData({...userData , name : e.target.value})} />
                                            <h6 style={{marginTop : "20px"}} >Fee (*):</h6>
                                            <input type="number"  placeholder="Enter Vehicle Type ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: '1px solid black' , minWidth : "300px"}} value={userData?.fee} onChange={(e) => setUserData({...userData , fee : e.target.value})} />
                                        </div>                                                                      
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            isSending === true ? (
                                <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                    <ThreeDots
                                        height = "60"
                                        width = "60"
                                        radius = "9"
                                        color = 'green'
                                        ariaLabel = 'three-dots-loading'
                                        wrapperStyle
                                        wrapperClass
                                    />
                                </div>
                            ) : (
                                <>
                                    <Button variant="danger" onClick={handleAddNewClose}>
                                        Close
                                    </Button>
                                    <Button variant="success" onClick={addNewVender} >Add Now</Button>
                                </>
                            )
                        }
                    </Modal.Footer>
                </Modal>
            {/* add new modal end */}
        </>
    );
}

export default ViewAllvendors;
