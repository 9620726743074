import React , {useState , useEffect } from 'react'
import { Button , Row , Col , Form } from 'react-bootstrap';
import {useParams , useNavigate} from "react-router-dom"
import { toast } from 'react-toastify';
import { ThreeDots } from  'react-loader-spinner'
import { getAllStores , getAllSubCategoriesForMain , getAllCategoriesOfAnyStore ,  getAllStoresOfAnyVender ,  getAllSubCategoriesOfVender , getAllParentCategoriesForVenders , getMySingleProductDetailsByVender ,  getAllSubCategoriesOfAnyCategory  ,  getAllParentCategories , getAllAdmins , getMySingleProductDeatils , updateIUmageOfAnyProduct , updateOfAnyProduct , updateOfAnyProductByVender , updateImageOfAnyProductByVender } from '../../api/AdminApi'


const ViewProduct = () => {
    const navigate = useNavigate()
    const [isGetting , setIsGetting ] = useState(false)
    const {name , id } = useParams()
    const [ singleUserData , setSingleUserData ] = useState({})
    const [ selectedMyStore , setSelectedMyStore ] = useState("")
    const [ uploadImage , setUploadImage ] = useState(null)
    const [ selectedMyCate , setSelectedMyCate ] = useState("")
    const [ allParent , setAllParent ] = useState([])
    const [ allSubParent , setAllSubParent ] = useState([])
    const [ allParentNew , setAllParentNew ] = useState([])
    const [ allSubParentNew , setAllSubParentNew ] = useState([])
    const [ allStores , setAllStores ] = useState([])
    const [ allVenders , setAllVenders ] = useState([])

    // checking if signed in or not
    const [ isAdmin , setIsAdmin ] = useState(false)
    useEffect(() => {
        let adminToken = JSON.parse(localStorage.getItem('priceApp-admin-auth-token'))
        if(!adminToken){
            navigate('/signin')
        }
        let isMyAdmin = JSON.parse(localStorage.getItem('isAdmin'))
        if(isMyAdmin == false){
            setIsAdmin(false)
        }else{
            setIsAdmin(true)
        }
    },[])

    // getting categories + sub categories + venders + stores
    useEffect(() => {
        const getAllCate = async () => {
            if(isAdmin === true){
                const {data} = await getAllParentCategories();
                if(data?.success === true){
                    setAllParent(data?.AllCategories)
                }
            }else{
                const {data} = await getAllParentCategoriesForVenders();
                if(data?.success === true){
                    setAllParent(data?.AllCategories)
                }
            }
        }
        getAllCate()

        const getAllSubCate = async () => {
            if(isAdmin === true){
                const {data} = await getAllSubCategoriesForMain();
                if(data?.success === true){
                    setAllSubParent(data?.AllCategories)
                }
            }else{
                const {data} = await getAllSubCategoriesOfVender();
                if(data?.success === true){
                    setAllSubParent(data?.AllCategories)
                }
            }
        }
        getAllSubCate()

        const getAllVenders = async () => {
            const {data} = await getAllAdmins();
            if(data?.success === true){
                setAllVenders(data?.AllAdmins)
            }
        }
        getAllVenders()

        const getAllMyStores = async () => {
            if(isAdmin === true){
                const {data} = await getAllStores();
                if(data?.success === true){
                    setAllStores(data?.AllStores)
                }
            }else{
                const {data} = await getAllStoresOfAnyVender();
                if(data?.success === true){
                    setAllStores(data?.AllStores)
                }
            }
        }
        getAllMyStores()
    },[isAdmin])
 
    // getting data of product
    useEffect(() => {
        const getData = async () => {
            setIsGetting(true)
            if(isAdmin === true){
                const {data} = await getMySingleProductDeatils(id);
                if(data?.success === true){
                    setSingleUserData(data?.Product);
                }else{
                    //toast.error(data?.message)
                }
            }else{
                const {data} = await getMySingleProductDetailsByVender(id);
                if(data?.success === true){
                    setSingleUserData(data?.Product);
                }else{
                    //toast.error(data?.message)
                }
            }
            setIsGetting(false)
        }

        getData()
    },[isAdmin , id])

    // getting all categories of any store
    useEffect(() => {
        const getAllCatesOfAnyStore = async () => {
            const {data} = await getAllCategoriesOfAnyStore(selectedMyStore);
            if(data?.success === true){
                setAllParentNew(data?.AllCategories)
            }else{
                setAllParentNew([])
            }
        }
        if(selectedMyStore !== ""){
            getAllCatesOfAnyStore()
        }
    },[selectedMyStore])

    // getting all sub categories of any store
    useEffect(() => {
        const getAllCatesOfAnyStore = async () => {
            const {data} = await getAllSubCategoriesOfAnyCategory(selectedMyCate);
            if(data?.success === true){
                setAllSubParentNew(data?.AllSubCategories)
            }else{
                setAllSubParentNew([])
            }
        }
        if(selectedMyCate !== ""){
            getAllCatesOfAnyStore()
        }
    },[selectedMyCate])

    // updating data
    const updateData = async () => {
        setIsGetting(true)
        if(isAdmin === true){
            const {data} = await updateOfAnyProduct(singleUserData._id , singleUserData);
            if(data?.success === true){
                toast.success(data?.message)
    
                // uploading image
                if(uploadImage !== null){
                    let formData = new FormData();
                    formData.append("productImage", uploadImage)
                    const res = await updateIUmageOfAnyProduct(singleUserData._id, formData);
                    if(res?.data?.success === true){
                        toast.success("Image Uploaded SuccessFully")
                    }else{
                        toast.error("Image Could Not be Uploaded.")
                    }
                }
                // getting updated data
                setIsGetting(true)
                if(isAdmin === true){
                    const res = await getMySingleProductDeatils(id);
                    if(res?.data?.success === true){
                        setSingleUserData(res?.data?.Product);
                    }else{
                        toast.error(res?.data?.message)
                    }
                }else{
                    const response = await getMySingleProductDetailsByVender(id);
                    if(response?.data?.success === true){
                        setSingleUserData(response?.data?.Product);
                    }else{
                        toast.error(response?.data?.message)
                    }
                }
                
                setIsGetting(false)
                }else{
                toast.error(data?.message)
            }
        }else{
            const {data} = await updateOfAnyProductByVender(singleUserData._id , singleUserData);
            if(data?.success === true){
                toast.success(data?.message)
    
                // uploading image
                if(uploadImage !== null){
                    let formData = new FormData();
                    formData.append("productImage", uploadImage)
                    const res = await updateImageOfAnyProductByVender(singleUserData._id, formData);
                    console.log("image upload response : ", res?.data)
                    if(res?.data?.success === true){
                        toast.success("Image Uploaded SuccessFully")
                    }else{
                        toast.error("Image Could Not be Uploaded.")
                    }
                }
                // getting updated data
                setIsGetting(true)
                if(isAdmin === true){
                    const res = await getMySingleProductDeatils(id);
                    if(res?.data?.success === true){
                        setSingleUserData(res?.data?.Product);
                    }else{
                        toast.error(res?.data?.message)
                    }
                }else{
                    const response = await getMySingleProductDetailsByVender(id);
                    if(response?.data?.success === true){
                        setSingleUserData(response?.data?.Product);
                    }else{
                        toast.error(response?.data?.message)
                    }
                }
                
                setIsGetting(false)
            }else{
                toast.error(data?.message)
            }
        }
        setIsGetting(false)
    }

    return (
        <div className="px-2">
            <h4 className="max-w-[200px] mx-auto" >Product Details</h4>
            {
                isGetting === true ? (
                    <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                        <ThreeDots
                            height = "60"
                            width = "60"
                            radius = "9"
                            color = 'green'
                            ariaLabel = 'three-dots-loading'
                            wrapperStyle
                            wrapperClass
                        />
                    </div>
                ) : (
                    <div className="flex justify-center" >
                        <div className='flex justify-start  flex-col align-center pb-4 px-2 w-[100%] pt-3' >
                            <Row >
                                <Col sm={12} md={6} className='flex flex-col pt-5 pl-3 font-semibold' >
                                    <img src={ singleUserData?.productImage?.indexOf("https") === 0 ? singleUserData?.productImage :  process.env.REACT_APP_LIVE_SERVER + "/productsImages/" + singleUserData?.productImage} alt="cover pic" style={{maxWidth : '80px', maxHeight : '80px', borderRadius : '10px'}} />
                                    <input type="file" accept="image/*" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' onChange={(e) => setUploadImage(e.target.files[0])} />
                                </Col>
                                <Col sm={12} md={6} className='flex flex-col pt-5 pl-3 font-semibold' >
                                    {
                                        uploadImage !== null && (
                                            <div className="flex justify-between mt-4">
                                                <img src={URL.createObjectURL(uploadImage)} alt="cover pic" style={{maxWidth : '80px', maxHeight : '80px', borderRadius : '10px'}} />
                                                <button className='bg-[#e74c3c] mt-3 rounded-md p-2 text-white font-semibold mx-auto max-h-[40px]' onClick={() => setUploadImage(null)} >Remove</button>
                                            </div>
                                        )
                                    }
                                </Col>
                            </Row>
                            <Row style={{width : "100%"}} >
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]  ">
                                    <div className='flex flex-col pt-5  font-semibold'  >
                                        <h6>Product Name:</h6>
                                        <input type="text" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.productName} onChange={(e) => setSingleUserData({...singleUserData , productName : e.target.value})} />
                                    </div>
                                </Col>
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]  ">
                                        <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                            <h6>Description:</h6>
                                            <textArea rows="5" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} onChange={(e) => setSingleUserData({...singleUserData , desc : e.target.value})}  >{singleUserData?.desc} </textArea>
                                        </div>
                                </Col>
                            </Row>
                            <Row  >
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]  ">
                                    <div style={{display : "flex" , flexDirection : "column" , justifyContent : "center"}} >
                                        <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                            <h6>Current Price:</h6>
                                            <input type="number" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.currentPrice} onChange={(e) => setSingleUserData({...singleUserData , currentPrice : e.target.value})} />
                                        </div>                                  
                                        <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                            <h6>Sold:</h6>
                                            <input type="number" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.sold} disabled={true}  />
                                        </div>  
                                    </div>
                                </Col>
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]  ">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>Quantity:</h6>
                                        <input type="number" placeholder="Enter user name ..." className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.quantity} onChange={(e) => setSingleUserData({...singleUserData , quantity : e.target.value})} />
                                    </div>                              
                                </Col>
                            </Row>
                            <Row >
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]  ">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>Rating:</h6>
                                        <input type="number"className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border: "1px solid black"}} value={singleUserData?.rating && singleUserData?.rating['$numberDecimal']?.toLocaleString()} disabled={true} />
                                    </div>                                  
                                </Col>
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]  ">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>is Special:</h6>
                                        {
                                            singleUserData?.isSpecial == true ? (
                                                <Form.Check 
                                                    type="switch"
                                                    checked
                                                    id="custom-switch"
                                                    label="Remove from Special"
                                                    onChange={(e) => setSingleUserData({...singleUserData , isSpecial : false})}
                                                />
                                            ) : (
                                                <Form.Check 
                                                    type="switch"
                                                    id="custom-switch"
                                                    label="make this Special"
                                                    onChange={(e) => setSingleUserData({...singleUserData , isSpecial : true})}
                                                />
                                            )
                                        }
                                    </div>                                  
                                </Col>
                            </Row>
                            <Row >
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]  ">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>is in Top 10:</h6>
                                        {
                                            singleUserData?.isTopTen == true ? (
                                                <Form.Check 
                                                    type="switch"
                                                    checked
                                                    id="custom-switch"
                                                    label="Remove from Top 10"
                                                    onChange={(e) => setSingleUserData({...singleUserData , isTopTen : false})}
                                                />
                                            ) : (
                                                <Form.Check 
                                                    type="switch"
                                                    id="custom-switch"
                                                    label="Add to from Top 10"
                                                    onChange={(e) => setSingleUserData({...singleUserData , isTopTen : true})}
                                                />
                                            )
                                        }
                                    </div>                                  
                                </Col>
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]  ">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6> Store:</h6>
                                        <select class="form-select" aria-label="Default select example" onChange={(e) => setSingleUserData({...singleUserData , store : e.target.value})}>
                                            <option selected disabled></option>
                                            {
                                                allStores?.length > 0 ? (
                                                    allStores?.map((item) => (
                                                        singleUserData?.store == item?.Data?.Id ? (
                                                            <option value={item?.Data?.Id} selected >{item?.Data?.Name}</option>
                                                        ) : (
                                                            <option value={item?.Data?.Id}>{item?.Data?.Name}</option>
                                                        )
                                                    ))
                                                ) : (
                                                    <option >No Stores Found</option>
                                                )
                                            }
                                        </select>
                                    </div>                                  
                                </Col>
                            </Row>
                            <Row >
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]  ">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6> Vender:</h6>
                                        <select class="form-select" aria-label="Default select example" onChange={(e) => setSingleUserData({...singleUserData , owner : e.target.value})}>
                                            <option selected disabled></option>
                                            {
                                                allVenders?.length > 0 ? (
                                                    allVenders?.map((item) => (
                                                        singleUserData?.owner == item?._id ? (
                                                            <option value={item?._id} selected >{item?.username}</option>
                                                        ) : (
                                                            <option value={item?._id}>{item?.username}</option>
                                                        )
                                                    ))
                                                ) : (
                                                    <option >No Venders Found</option>
                                                )
                                            }
                                        </select>
                                    </div>                                  
                                </Col>
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]  ">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6> Parent Category:</h6>
                                        <select class="form-select" aria-label="Default select example" onChange={(e) => setSingleUserData({...singleUserData , parentCategory : e.target.value})}>
                                            <option selected disabled></option>
                                            {
                                                allParent?.length > 0 ? (
                                                    allParent?.map((item) => (
                                                        singleUserData?.parentCategory == item?._id ? (
                                                            <option value={item?._id} selected >{item?.name}</option>
                                                        ) : (
                                                            <option value={item?._id}>{item?.name}</option>
                                                        )
                                                    ))
                                                ) : (
                                                    <option >No Parent Categories Found</option>
                                                )
                                            }
                                        </select>
                                    </div>                                  
                                </Col>
                            </Row>
                            <Row >
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]  ">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6> Sub Category:</h6>
                                        <select class="form-select" aria-label="Default select example" onChange={(e) => setSingleUserData({...singleUserData , categories : e.target.value})}>
                                            <option selected disabled></option>
                                            {
                                                allSubParent?.length > 0 ? (
                                                    allSubParent?.map((item) => (
                                                        singleUserData?.categories == item?._id ? (
                                                            <option value={item?._id} selected >{item?.name}</option>
                                                        ) : (
                                                            <option value={item?._id}>{item?.name}</option>
                                                        )
                                                    ))
                                                ) : (
                                                    <option >No Sub Categories Found</option>
                                                )
                                            }
                                        </select>
                                    </div>                                      
                                </Col>
                                <Col sm={12} md={6} className="md:min-w-[300px] min-w-[100%]  ">
                                    <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                        <h6>Is product Under 18</h6>
                                        {
                                            singleUserData?.isUnder18 == true ? (
                                                <Form.Check 
                                                    type="switch"
                                                    checked
                                                    id="custom-switch"
                                                    label="Is Product under 18"
                                                    onChange={(e) => setSingleUserData({...singleUserData , isUnder18 : false})}
                                                />
                                            ) : (
                                                <Form.Check 
                                                    type="switch"
                                                    id="custom-switch"
                                                    label="Is Product under 18"
                                                    onChange={(e) => setSingleUserData({...singleUserData , isUnder18 : true})}
                                                />
                                            )
                                        }
                                    </div>                                      
                                </Col>
                            </Row>
                            <Row style={{marginTop : "20px"}} >
                                <Col xs={12} >
                                    <div style={{display : "flex" , justifyContent : "center" , }} >
                                        <Button variant="success" onClick={updateData} style={{width : "200px"}} > Update </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default ViewProduct  