import React , {useState , useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { ThreeDots } from  'react-loader-spinner'
import { toast } from 'react-toastify';
import {useNavigate, useLocation} from 'react-router-dom'
import {getAllBanners ,addNewBanner , updateStatusOfAnyBanner ,updateOfAnyBanner } from '../../api/AdminApi'
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
import { Col, Row, Table , Form , Modal , Button , Dropdown, ButtonGroup  } from 'react-bootstrap';
import { GrFormView} from 'react-icons/gr'
import moment from 'moment'



const ViewAllvendors = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const TABLE_HEADERS = [
        {
            prop: "image",
            title: "Banner",
            cell: (prop) => {
                return (
                        <img alt='banner' style={{maxWidth : '70px', maxHeight : '70px', borderRadius : "10px"}} src={ prop?.banner?.indexOf("https") == 0 ? prop?.banner : process.env.REACT_APP_LIVE_SERVER + "/bannerImages/" + prop.banner} />
                    )
                },
        },
        {
            prop: "addedOn",
            title: "Added On"
        },
        {
            prop: "link",
            title: "Partner Link"
        },
        {
            prop: "status",
            title: "Status",
            cell: (prop) => {
                return (
                        <Dropdown as={ButtonGroup}>
                            {
                                prop?.status == false && (
                                    <Button size="sm" variant="danger" style={{fontSize : '11px' , fontWeight : 600}} >In Active</Button>
                                )
                            }
                            {
                                prop?.status == true && (
                                    <Button size="sm" variant="success" style={{fontSize : '11px' , fontWeight : 600}} >Active</Button>
                                )
                            }
                            <Dropdown.Toggle split size="sm" variant="primary" id="dropdown-split-basic" />
                            <Dropdown.Menu style={{backgroundColor : 'transparent'}} >
                                <Dropdown.Item onClick={() => changeStatus(prop?.Id)} style={{backgroundColor : '#c23616', color : 'white'}} >In Active</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeStatus(prop?.Id)} style={{backgroundColor : '#10ac84', color : 'white'}} >Active</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            prop: "action",
            title: "View Products",
            cell: (prop) => {
            return (
                    <GrFormView style={{cursor : 'pointer', fontSize : '25px'}} onClick={() => { setGotImage(process.env.REACT_APP_LIVE_SERVER + "/bannerImages/" + prop.banner); setBannerId(prop.Id) ; handleView(); setBannerLink(prop?.link)}} ></GrFormView>
                )
            },
        },
    ];

    const [ isFetching , setIsFetching ] = useState(false)
    const [ isSending , setIsSending ] = useState(false)
    const [ uploadImage , setUploadImage ] = useState(null)
    const [ gotImage , setGotImage ] = useState(null)
    const [ bannerId , setBannerId ] = useState("")
    const [ bannerLink , setBannerLink ] = useState("")
    const [ allData , setAllData ] = useState([])
    // sleeping
    const delay = ms => new Promise(res => setTimeout(res, ms));

    // checking if signed in or not
    useEffect(() => {
        let adminToken = JSON.parse(localStorage.getItem('priceApp-admin-auth-token'))
        if(!adminToken){
            navigate('/signin')
        }
        let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
        if(!isAdmin){
            navigate('/signin')
        }
        if(isAdmin === false){
            navigate('/our stores')
        }
    },[])

    // getting data
    useEffect(() => {
        const getData = async () => {
            setIsFetching(true)
            const {data} = await getAllBanners();
            if(data?.success === true){
                let allDataArr= []
                for(let i = 0; i !==  data?.AllBannerImages.length; i++){
                    let newArr = {
                        banner:  data?.AllBannerImages[i]?.bannerImage,
                        Id :  data?.AllBannerImages[i]?._id,
                        status :  data?.AllBannerImages[i]?.status,
                        link :  data?.AllBannerImages[i]?.link,
                        addedOn : moment(data?.AllBannerImages[i]?.createdAt).format('MMM Do YY'),
                    }
                    allDataArr.push(newArr)
                }
                setAllData(allDataArr)
            }
            setIsFetching(false)
        }
        getData()
    },[location])

    // view modal of offer
    const [view, setView] = useState(false);
    const handleViewClose = () => {
        setView(false);
        setBannerId("")
        setGotImage(null)
        setUploadImage(null)
        setIsSending(false)
        setBannerLink("")
    }
    // getting data of user
    const handleView = async (id) => {
        setView(true);
    }

    // add new banner modal
    const [addNew, setAddNew] = useState(false);
    const handleAddNewClose = () => {
        setUploadImage(null)
        setAddNew(false)
        setIsSending(false)
    }
    // getting data of user
    const handleAddNew = () => {
        setAddNew(true);
    }

    // sending data
    const addNewMyList = async () => {
        setIsSending(true)
        if(uploadImage === null){
            toast.warning("Image Of List is Required");
            return;
        }
        let formData = new FormData();
        formData.append("bannerImage" , uploadImage)
        formData.append("link" , bannerLink)
        const {data} = await addNewBanner(formData);
        console.log("data got : ", data)
        if(data?.success === true){
            toast.success("New Banner Added SuccessFully")

            // getting updated data
            setIsFetching(true)
            const res = await getAllBanners();
            if(res?.data?.success === true){
                let allDataArr = []
                for(let i = 0; i !==  res?.data?.AllBannerImages.length; i++){
                    let newArr = {
                        banner:  res?.data?.AllBannerImages[i]?.bannerImage,
                        Id :  res?.data?.AllBannerImages[i]?._id,
                        status :  res?.data?.AllBannerImages[i]?.status,
                        link :  res?.data?.AllBannerImages[i]?.link,
                        addedOn : moment(res?.data?.AllBannerImages[i]?.createdAt).format('MMM Do YY'),
                    }
                    allDataArr.push(newArr)
                }
                setAllData(allDataArr)
            }
            setIsFetching(false)

            await delay(1500)
            setBannerLink("")
            handleAddNewClose()
        }else{
            toast.error(data?.message)
        }
        setIsSending(false)
    }

    // changing status of vender
    const changeStatus = async (id) => {
        let isFound = allData.find(item => item.Id === id);
        if(isFound){
            const {data} = await updateStatusOfAnyBanner(id);
            if(data?.success === true){
                if(isFound.status == false){
                    isFound.status = true
                    toast.success("Banner Activated Successfully");
                }else{
                    isFound.status = false
                    toast.success("Banner InActivated Successfully");
                }
                let newData = allData;
                let finalData = newData.filter(item => item.Id === id ? isFound : item );
                setAllData(finalData)
            }else{
                toast.success(data?.message);
            }
        }
    }

    // update data
    const updateMyBanner = async () => {
        setIsSending(true)
        let formData = new FormData();
        if(uploadImage !== null){
            formData.append("bannerImage" , uploadImage)
        }
        formData.append("link" , bannerLink)
        const {data} = await updateOfAnyBanner(bannerId , formData);
        if(data?.success === true){
            toast.success("Banner Updated SuccessFully")

            // getting updated data
            setIsFetching(true)
            const res = await getAllBanners();
            if(res?.data?.success === true){
                let allDataArr = []
                for(let i = 0; i !==  res?.data?.AllBannerImages.length; i++){
                    let newArr = {
                        banner:  res?.data?.AllBannerImages[i]?.bannerImage,
                        Id :  res?.data?.AllBannerImages[i]?._id,
                        status :  res?.data?.AllBannerImages[i]?.status,
                        link :  res?.data?.AllBannerImages[i]?.link,
                        addedOn : moment(res?.data?.AllBannerImages[i]?.createdAt).format('MMM Do YY'),
                    }
                    allDataArr.push(newArr)
                }
                setAllData(allDataArr)
            }
            setIsFetching(false)

            //await delay(1500)
            handleViewClose()
        }else{
            toast.error(data?.message)
        }
        setIsSending(false)
    }

    return (
        <>
            {
                isFetching === true ? (
                    <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                        <ThreeDots
                            height = "60"
                            width = "60"
                            radius = "9"
                            color = 'green'
                            ariaLabel = 'three-dots-loading'
                            wrapperStyle
                            wrapperClass
                        />
                    </div>
                ) : (
                    <DatatableWrapper
                        body={allData}
                        headers={TABLE_HEADERS}
                        paginationOptionsProps={{
                            initialState: {
                                rowsPerPage: 10,
                                options: [5, 10, 15, 20]
                            }
                        }}
                        >
                        <Row className="p-4 mb-4">
                            <Col
                                xs={12}
                                lg={4}
                                className="flex-col d-flex justify-content-start align-items-start"
                            >
                                <h4>All Banners</h4>
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="flex-col mb-2 d-flex justify-content-lg-center align-items-center justify-content-sm-start mb-sm-0"
                            >
                                <Button size="lg" variant="info" onClick={handleAddNew} style={{fontSize : '15px' , fontWeight : 600}} >Add New</Button>
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="flex-col d-flex justify-content-end align-items-end"
                            >
                            </Col>
                        </Row>
                        <Table striped bordered hover className="ml-2 mr-2" >
                            <TableHeader />
                            <TableBody />
                        </Table>
                        <Row className="p-2 mb-4">
                            <Col
                                xs={12}
                                sm={6}
                                lg={6}
                                className="flex-col mb-2 d-flex justify-content-lg-start align-items-start justify-content-sm-start mb-sm-0"
                            >
                                <PaginationOptions />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={6}
                                className="flex-col d-flex justify-content-end align-items-end"
                            >
                                <Pagination />
                            </Col>
                        </Row>
                    </DatatableWrapper>
                )
            }

            {/* view and update modal */}
            <Modal
                show={view}
                onHide={handleViewClose}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>View Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex justify-center" >
                        <div className='flex justify-center flex-col align-center pb-4  max-w-[400px] w-[100%] mx-auto' >
                        <img src={gotImage} alt="cover pic" style={{maxWidth : '320px', maxHeight : '320px', margin: "auto" , borderRadius : '5%'}} />
                            <div className='flex flex-col pt-5 pl-3 font-semibold' >
                                <h6>Update Image:</h6>
                                <input type="file" accept="image/*" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' onChange={(e) => setUploadImage(e.target.files[0])} />
                            </div>
                            {
                                uploadImage !== null && (
                                    <div className="flex justify-between ">
                                        <img src={URL.createObjectURL(uploadImage)} alt="cover pic" style={{maxWidth : '80px', maxHeight : '80px', borderRadius : '10px'}} />
                                        <button className='bg-[#e74c3c] mt-3 rounded-md p-2 text-white font-semibold mx-auto max-h-[40px]' onClick={() => setUploadImage(null)} >Remove</button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className='flex flex-col pt-0 pl-3 font-semibold' >
                        <h6> Link of Website:</h6>
                        <input type="text"  className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border : "1px solid black", borderRadius : "10px", marginBottom : "15px"}}  value={bannerLink} onChange={(e) => setBannerLink(e.target.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                        {
                            isSending === true ? (
                                <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                    <ThreeDots
                                        height = "60"
                                        width = "60"
                                        radius = "9"
                                        color = 'green'
                                        ariaLabel = 'three-dots-loading'
                                        wrapperStyle
                                        wrapperClass
                                    />
                                </div>
                            ) : (
                                <>
                                    <Button variant="danger" onClick={handleViewClose}>
                                        Close
                                    </Button>
                                    <Button variant="success" onClick={updateMyBanner}>
                                        Update
                                    </Button>
                                </>
                            )
                        }
                </Modal.Footer>
            </Modal>
            {/* view and update modal end */}

            {/* add new modal */}
            <Modal
                show={addNew}
                onHide={handleAddNewClose}
                backdrop="static"
                keyboard={false}
                //style={{marginTop : '100px'}}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Add New Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex justify-center" >
                        <div className='flex justify-center flex-col align-center pb-4  max-w-[400px] w-[100%] mx-auto' >
                            <div className='flex flex-col pt-0 pl-3 font-semibold' >
                                <h6>Add Link of Website:</h6>
                                <input type="text"  className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' style={{border : "1px solid black", borderRadius : "10px", marginBottom : "15px"}} value={bannerLink} onChange={(e) => setBannerLink(e.target.value)} />
                            </div>
                            <div className='flex flex-col pt-0 pl-3 font-semibold' >
                                <h6>Choose Banner Image:</h6>
                                <input type="file" accept="image/*" className='min-h-[35px] p-2 rounded-md max-w-[95%] mt-2 border-[#636e72]' onChange={(e) => setUploadImage(e.target.files[0])} />
                            </div>
                            {
                                uploadImage !== null && (
                                    <div className="flex justify-between ">
                                        <img src={URL.createObjectURL(uploadImage)} alt="cover pic" style={{maxWidth : '80px', maxHeight : '80px', borderRadius : '10px'}} />
                                        <button className='bg-[#e74c3c] mt-3 rounded-md p-2 text-white font-semibold mx-auto max-h-[40px]' onClick={() => setUploadImage(null)} >Remove</button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        isSending === true ? (
                            <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                                <ThreeDots
                                    height = "60"
                                    width = "60"
                                    radius = "9"
                                    color = 'green'
                                    ariaLabel = 'three-dots-loading'
                                    wrapperStyle
                                    wrapperClass
                                />
                            </div>
                        ) : (
                            <>
                                <Button variant="danger" onClick={handleAddNewClose}>
                                    Close
                                </Button>
                                <Button variant="success" onClick={addNewMyList} >Add Now</Button>
                            </>
                        )
                    }
                </Modal.Footer>
            </Modal>
            {/* add new modal end */}
        </>
    );
}

export default ViewAllvendors;
