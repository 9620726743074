import React , {useState ,useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { SiShopware } from 'react-icons/si';
import { MdOutlineCancel } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { links , venderLinks } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';

const Sidebar = () => {
  const [ isUser , setIsUser ] = useState(false)
  const [ isAdmin , setIsAdmin ] = useState(false)
  const { currentColor, activeMenu, setActiveMenu } = useStateContext();

  const handleCloseSideBar = () => {
    if (window.innerWidth <= 900) {
      setActiveMenu(false);
    }
  };

  useEffect(() => {
    let adminToken = JSON.parse(localStorage.getItem('priceApp-admin-auth-token'))
    if(adminToken){
      console.log("token found in side bar")
      setIsUser(true)
    }else{
      console.log("token not found in side bar")
      setIsUser(false)
    }

    let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
    if(isAdmin === false){
        setIsAdmin(false)
    }else{
      setIsAdmin(true)
    }
  }, []);

  const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';

  return (
    //{
      isUser === true && (
        <div className="h-screen pb-10 ml-3 overflow-auto md:overflow-hidden md:hover:overflow-auto">
          {activeMenu && (
            <>
              <div className="flex items-center justify-between">
                <Link to="/" style={{textDecoration : 'none'}} onClick={handleCloseSideBar} className="flex items-center gap-3 mt-4 ml-3 text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
                  <img src="/appLogo.png" alt="Logo" style={{maxWidth : "50px" , maxHeight : "50px" , borderRadius : "10px"}} />  <span style={{textDecoration : 'none'}} >Price App</span>
                </Link>
                <TooltipComponent content="Menu" position="BottomCenter">
                  <button
                    type="button"
                    onClick={() => setActiveMenu(!activeMenu)}
                    style={{ color: currentColor }}
                    className="block p-3 mt-4 text-xl rounded-full hover:bg-light-gray md:hidden"
                  >
                    <MdOutlineCancel />
                  </button>
                </TooltipComponent>
              </div>
              {
                isAdmin == true ? (
                    <div className="mt-10 ">
                      {links.map((item) => (
                        <div key={item.title}>
                          <p className="m-3 mt-4 text-gray-400 uppercase dark:text-gray-400">
                            {item.title}
                          </p>
                          {item.links.map((link) => (
                            <NavLink
                              to={`/${link.name}`}
                              key={link.name}
                              onClick={handleCloseSideBar}
                              style={({ isActive }) => ({
                                backgroundColor: isActive ? currentColor : '',
                                textDecoration : 'none',
                                fontWeight : 600
                              })}
                              className={({ isActive }) => (isActive ? activeLink : normalLink)}
                            >
                              {link.icon}
                              <span className="capitalize ">{link.name}</span>
                            </NavLink>
                          ))}
                        </div>
                      ))}
                    </div>
                )  : (
                  <div className="mt-10 ">
                    {venderLinks.map((item) => (
                      <div key={item.title}>
                        <p className="m-3 mt-4 text-gray-400 uppercase dark:text-gray-400">
                          {item.title}
                        </p>
                        {item.links.map((link) => (
                          <NavLink
                            to={`/${link.name}`}
                            key={link.name}
                            onClick={handleCloseSideBar}
                            style={({ isActive }) => ({
                              backgroundColor: isActive ? currentColor : '',
                              textDecoration : 'none',
                              fontWeight : 600
                            })}
                            className={({ isActive }) => (isActive ? activeLink : normalLink)}
                          >
                            {link.icon}
                            <span className="capitalize ">{link.name}</span>
                          </NavLink>
                        ))}
                      </div>
                    ))}
                  </div>
                )
              }
            </>
          )}
        </div>
      )
    //}
  );
};

export default Sidebar;
