import React , {useState , useEffect} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { ThreeDots } from  'react-loader-spinner'
import { toast } from 'react-toastify';
import {getAllProductsOfAnyVender} from '../../api/AdminApi'
import {useNavigate , useParams} from 'react-router-dom'
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
import { Col, Row, Table ,InputGroup , Form , Button  } from 'react-bootstrap';


const ViewAllvendors = () => {
    const navigate = useNavigate()
    const {name , id} = useParams()
    const TABLE_HEADERS = [
        {
            prop: "username",
            title: "Name"
        },
        {
            prop: "profilePic",
            title: "Image",
            cell: (prop) => {
                    return (
                            <img src={ prop?.profilePic?.indexOf("https") == 0 ? prop?.profilePic :  process.env.REACT_APP_LIVE_SERVER + "/productsImages/" + prop?.profilePic} alt="profile pic" style={{maxWidth : '50px', maxHeight : '50%', borderRadius : '10px'}} />
                        )
                    },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
        },
        {
            prop: "price",
            title: "Price"
        },
        {
            prop: "age",
            title: "Age"
        },
        {
            prop: "store",
            title: "StoreName"
        },
        {
            prop: "owner",
            title: "Owner"
        },
        {
            prop: "parentCate",
            title: "Parent Category"
        },
        {
            prop: "subCate",
            title: "Sub Category"
        },
        // {
        //     prop: "desc",
        //     title: "Description"
        // },
    ];

    const [ isFetching , setIsFetching ] = useState(false)
    const [ allData , setAllData ] = useState([])
    // sleeping
    const delay = ms => new Promise(res => setTimeout(res, ms));

    // getting data
    useEffect(() => {
        const getData = async () => {
            setIsFetching(true)
            const {data} = await getAllProductsOfAnyVender(id);
            console.log("all data : ", data)
            if(data?.success === true){
                let allDataArr = []
                for(let i = 0; i !== data?.AllProducts[0]?.AllData.length; i++){
                    let newArr = {
                        username: data?.AllProducts[0]?.AllData[i]?.Name,
                        profilePic: data?.AllProducts[0]?.AllData[i]?.Image,
                        price: data?.AllProducts[0]?.AllData[i]?.Price,
                        store: data?.AllProducts[0]?.AllData[i]?.StoreName,
                        owner: data?.AllProducts[0]?.AllData[i]?.OwnerName,
                        parentCate: data?.AllProducts[0]?.AllData[i]?.CateName,
                        subCate: data?.AllProducts[0]?.AllData[i]?.SubCateName,
                        age : data?.AllProducts[0]?.AllData[i]?.isUnder18 === undefined ? "Any" : data?.AllProducts[0]?.AllData[i]?.isUnder18 == true ? "Any" : "18+",
                        //desc: data?.AllProducts[0]?.AllData[i]?.Desc,
                    }
                    allDataArr.push(newArr)
                }
                setAllData(allDataArr)
            }
            setIsFetching(false)
        }
        getData()
    },[])

    return (
        <>
            {
                isFetching === true ? (
                    <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                        <ThreeDots
                            height = "60"
                            width = "60"
                            radius = "9"
                            color = 'green'
                            ariaLabel = 'three-dots-loading'
                            wrapperStyle
                            wrapperClass
                        />
                    </div>
                ) : (
                    <DatatableWrapper
                        body={allData}
                        headers={TABLE_HEADERS}
                        paginationOptionsProps={{
                            initialState: {
                                rowsPerPage: 10,
                                options: [5, 10, 15, 20]
                            }
                        }}
                        >
                        <Row className="p-4 mb-4">
                            <Col
                                xs={12}
                                lg={4}
                                className="flex-col d-flex justify-content-start align-items-start"
                            >
                                <h4>{name}'s Products</h4>
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="flex-col mb-2 d-flex justify-content-lg-center align-items-center justify-content-sm-start mb-sm-0"
                            >
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="flex-col d-flex justify-content-end align-items-end"
                            >
                                {/* <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Search here..."
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        onBlur = {() => getAllData()}
                                        onChange={(e) => setText(e.target.value)}
                                    />
                                        <Button variant="info" onClick={getSearchedMerchants}>Search</Button>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Table striped bordered hover className="ml-2 mr-2" >
                            <TableHeader />
                            <TableBody />
                        </Table>
                        <Row className="p-2 mb-4">
                            <Col
                                xs={12}
                                sm={6}
                                lg={6}
                                className="flex-col mb-2 d-flex justify-content-lg-start align-items-start justify-content-sm-start mb-sm-0"
                            >
                                <PaginationOptions />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={6}
                                className="flex-col d-flex justify-content-end align-items-end"
                            >
                                <Pagination />
                            </Col>
                        </Row>
                    </DatatableWrapper>
                )
            }
        </>
    );
}

export default ViewAllvendors;
