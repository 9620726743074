import React , {useState , useEffect} from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import './order.css';
import { toast } from 'react-toastify';
import { ThreeDots } from  'react-loader-spinner'
import { getAnyOrderDetails , updateStatusOfOrderByVender , updateStatusOfOrderByAdmin , acceptAnyOrderByVender , acceptAnyOrderByAdmin  } from '../../api/AdminApi'
import moment from "moment"
import {useParams , useNavigate} from "react-router-dom"

const NewOrderView = () => {

    const navigate = useNavigate()
    const [ isGetting , setIsGetting ] = useState(false)
    const [ address , setAddress ] = useState([])
    const {id} = useParams()
    const [ singleUserData , setSingleUserData ] = useState({})

    // checking if signed in or not
    const [ isAdmin , setIsAdmin ] = useState(false)
    useEffect(() => {
        let adminToken = JSON.parse(localStorage.getItem('priceApp-admin-auth-token'))
        if(!adminToken){
            navigate('/signin')
        }
        let isMyAdmin = JSON.parse(localStorage.getItem('isAdmin'))
        if(isMyAdmin == false){
            setIsAdmin(false)
        }else{
            setIsAdmin(true)
        }
    },[])

    // getting data of product
    useEffect(() => {
        const getData = async () => {
            setIsGetting(true)
            const {data} = await getAnyOrderDetails(id);
            if(data?.success === true){
                setSingleUserData(data?.orderDetails);
                let newAddress = data?.orderDetails?.orderAddress.split(",");
                setAddress(newAddress)
            }else{
                toast.error(data?.message)
            }
            setIsGetting(false)
        }

        getData()
    },[isAdmin , id])

  return (
    <>
        {
            isGetting === true ? (
                <div style={{display : 'flex' , justifyContent: 'center' , margin: 'auto'}}>
                        <ThreeDots
                            height = "60"
                            width = "60"
                            radius = "9"
                            color = 'green'
                            ariaLabel = 'three-dots-loading'
                            wrapperStyle
                            wrapperClass
                        />
                    </div>
            ) : (
                <>
                    <header style={{padding : "10px",}} >
                        <h4 style={{marginBottom : "25px"}} >Order Details:</h4>
                        <div style={{display : "flex" , paddingLeft : "25px", justifyContent : "flex-start", marginBottom : "45px"}} >
                            <span>
                                <Image alt="app logo" src="/appLogo.png" className="rounded float-right align-top" style={{maxWidth : "70px" , maxWidth : "70px" , borderRadius : "10px"}} />          
                            </span>
                        </div>
                        <section style={{marginTop : "20px" , paddingLeft : "20px", minWidth : "100%",  marginBottom : "20px", paddingRight : "20px", display : "flex" , justifyContent : "between", margin : "auto"}} >
                            <Card.Body style = {{width: '18rem'}} >
                                <Card.Text>
                                <address>
                                    <h6>{singleUserData?.customerId?.username} </h6>
                                    {
                                        address?.map((item) => (
                                            <>
                                                <p style={{fontSize : "13px", fontWeight : 500}} >{item},</p>
                                            </>
                                        ))
                                    }
                                </address>
                                </Card.Text>          
                            </Card.Body>  

                            <Card.Body style = {{maxWidth: '18rem',display : "flex" , justifyContent : "flex-end" }} >
                                <Card.Text>
                                {/* <address  > */}
                                    <h6 style={{fontSize : "14px"}} >Order ID :<span style={{color : "gray", marginLeft : "5px"}} > {singleUserData?.orderNo}</span> </h6>
                                    <h6 style={{fontSize : "14px"}} >Date :<span style={{color : "gray", marginLeft : "5px"}} >{moment(singleUserData?.createdAt).format("DD/MM/YY")} </span> </h6>
                                    <h6 style={{fontSize : "14px"}} >Store :<span style={{color : "gray", marginLeft : "5px"}} >{singleUserData?.storeId?.name}</span> </h6>
                                    <h6 style={{fontSize : "14px"}} >Vender : <span style={{color : "gray", marginLeft : "5px"}} > {singleUserData?.vender?.username}</span> </h6>
                                {/* </address> */}
                                </Card.Text>          
                            </Card.Body> 
                        </section>        
                    </header>

                    <article style={{marginTop : "25px", padding : "15px",}} >
                            {/* <h4>Ordered Products:</h4> */}
                            <Table className="secondTable">
                                <thead style={{backgroundColor : "lightGray"}} >
                                    <tr>
                                    <th><span >Item</span></th>
                                    <th><span >Quantity</span></th>
                                    <th><span >Unit Price</span></th>
                                    <th><span >Total</span></th>
                                    {/* <th><span >Price</span></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {
                                            singleUserData?.allProducts?.length > 0 ? (
                                                singleUserData?.allProducts?.map((item) => (
                                                    <>
                                                        <td><span >{item?.name}</span></td>
                                                        {/* <td><img alt="product image" style={{maxWidth : "50px" , maxHeight : "50px" , borderRadius : "10px"}} src={"http://31.220.51.105:8080"  + "/productsImages/" + item?.image } /></td> */}
                                                        <td><span >{item?.qty}</span></td>
                                                        <td><span data-prefix>$</span><span >{item?.price}.00</span></td>
                                                        <td><span data-prefix>$</span><span >{item?.price * item?.qty}.00</span></td>
                                                    </>
                                                ))
                                            ) : (
                                                <p>No Products Found</p>
                                            )
                                        }
                                    </tr>
                                </tbody>
                            </Table>
                    </article>   
                    
                    <header style={{padding : "10px",}} >
                        <Card.Body style = {{width: '13rem',marginLeft : "auto",  display : "flex" ,  justifyContent : "center",}} >
                            <Card.Text>
                            {/* <address> */}
                                <h6 style={{fontSize : "14px"}} >Sub Total :<span style={{color : "gray", marginLeft : "5px"}} > ${singleUserData?.totalAmt }</span> </h6>
                                <h6 style={{fontSize : "14px" , marginBottom : "-10px"}} >Delivery Fee :<span style={{color : "gray", marginLeft : "5px"}} >${singleUserData?.deliveryFee} </span> </h6>
                                <hr />
                                <h6 style={{fontSize : "14px" , marginTop : "-12px"}} >Total :<span style={{color : "gray",  marginLeft : "35px"}} >${singleUserData?.totalAmt + singleUserData?.deliveryFee} </span> </h6>
                            {/* </address> */}
                            </Card.Text>          
                        </Card.Body> 
                    </header>
                </>
            )
        }
    </>
  )
}

export default NewOrderView